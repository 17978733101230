import { Grid, Typography } from "@material-ui/core";
import {
  AgendaList,
  CreateAgendaDialog,
  ItemEditor,
  CreateTodoDialoge,
} from "./minutes";
import { useState, useEffect } from "react";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { ControlPoint, PlusOne } from "@material-ui/icons";
import { ManageTodos } from "./manageTodos";
import {
  Post_Insert_Agenda,
  Get_All_Agenda,
  Update_Agenda,
  Delete_Agenda,
  Delete_Todo_List,
} from "../../../constants/apiUrls";
import { Create, Get } from "../../../actions";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { getTranslation } from "../../../heplers/translationHelper";
import DeleteDialog from "../../../components/DeleteDialog";

export const ManageAgendas = ({ meetingId,disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [todoId, setToDoId] = useState("");

  const [agendas, setAgendas] = useState([
    // {
    //     id:1,
    //     title:"Item 1",
    //     comments:"<h4>Item 1</h4>"
    // },
    // {
    //     id:2,
    //     title:"Item 2",
    //     comments:"<h4>Item 2</h4>"
    // },
    // {
    //     id:3,
    //     title:"Item 3",
    //     comments:"<h4>Item 3</h4>"
    // }
  ]);

  const [selectedAgenda, setSelectedAgenda] = useState({
    // id:1,
    // title:"Item 1",
    // comments:"<h4>Item 1</h4>"
  });

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openCreateDialogTodo, setOpenCreateDialogTodo] = useState(false);
  const [loadingToDo, setLaodingToDo] = useState(false);
  const [dopen, setOpen] = useState(false); // state for delete dialog box
  const [agendaId, setAgendaId] = useState("");
  const [agenda, setAgenda] = useState([]);
  const [editToDoId, setEditTodoId] = useState(null);

  useEffect(() => {
    loadAllAgendas();
  }, []);

  // function for opening todo dialoge in item editer
  const handleOpenTodoEditer = (id) => {
    setOpenCreateDialogTodo(true);
    setEditTodoId(id);
  };

  // new function close dialog fucntion
  const handleCloseTodoDialoge = () => {
    setOpenCreateDialogTodo(false);
    setEditTodoId("");
    setSelectedAgenda({ ...selectedAgenda });
  };

  const updateSelectedAgenda = (newItem) => {
    // console.log(newItem)
    let payload = {
      id: newItem.id,
      comments: newItem.comments,
    };

    Create(
      payload,
      Update_Agenda,
      history,
      (resp) => {
        //  actions.setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Agenda Updated",
            "Ordre du jour mis à jour",
            "Tagesordnung aktualisiert"
          ),
          {
            variant: "success",
          }
        );
        console.log(resp.data);
      },
      (error) => {
        try {
          //   actions.setSubmitting(false);
          enqueueSnackbar(error?.data, { variant: "error" });
        } catch (error) {
          enqueueSnackbar("Error Submitting shareholder action", {
            variant: "error",
          });
        }
      }
    );

    const arr = agendas;
    const index = agendas.findIndex((x) => x.id == newItem.id);
    arr[index] = newItem;
    setAgendas([...arr]);
    setSelectedAgenda(newItem);
  };

  const handleUpdateTodoId = (id) => {
    const comments = `${selectedAgenda.comments} <div data-todolist-id="${id}">Todo Table ${id}</div>`;
    updateSelectedAgenda({ ...selectedAgenda, comments: comments });
  };

  const handleSendAndNeew = async (values, actions, closeDialog) => {
    const payload = {
      ...values,
      meetingId: meetingId,
    };

    Create(
      payload,
      Post_Insert_Agenda,
      history,
      (resp) => {
        //  actions.setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Agenda Created",
            "Ordre du jour créé",
            "Agenda erstellt"
          ),
          {
            variant: "success",
          }
        );
        // agendas.push(resp.data);
        setAgendas([...agendas, resp.data]);
        setSelectedAgenda(resp.data);
        if (values.addNew == false) setOpenCreateDialog(false);

        //    if (closeDialog) {
        //    setOpenCreateDialog(false);
        //  }
        // setOpenCreateDialog(false)
        actions.resetForm();
        setAgendas([...agendas, resp.data]);
        setSelectedAgenda(resp.data);
        // loadAllAgendas()
      },
      (error) => {
        try {
          //   actions.setSubmitting(false);
          enqueueSnackbar(error?.data, { variant: "error" });
        } catch (error) {
          enqueueSnackbar("Error Submitting shareholder action", {
            variant: "error",
          });
        }
      }
    );
  };

  const handleSendAddClose = (values, actions) => {
    const payload = {
      ...values,
      meetingId,
    };
    console.log(payload);
    setOpenCreateDialog(false);
  };

  const loadAllAgendas = () => {
    setLaodingToDo(true);
    Get(
      {
        meetingId: meetingId,
      },
      Get_All_Agenda,
      history,
      (response) => {
        setLaodingToDo(false);
        setAgendas(response.data);
        setSelectedAgenda(response?.data[0]);
        //console.log(response.data)
      },
      (error) => {
        setLaodingToDo(false);
      }
    );
  };

  const handleDeleteDialoge = (subject, agenda) => {
    //console.log(todolistIds);
    setOpen(true);
    setAgendaId(subject);
    setAgenda(agenda);
  };

  const DeleteTodoListItem = () => {
    var parser = new DOMParser();
    var htmlDoc = parser.parseFromString(agenda.comments, "text/html");
    var todolist = htmlDoc.querySelectorAll("[data-todolist-id]");

    var todolistIds = Array.from(todolist).map(function (element) {
      return element.getAttribute("data-todolist-id");
    });

    // var todolistId = todolist[0].getAttribute("data-todolist-id");

    if (todolistIds) {
      todolistIds.map((item) => {
        Create(
          {
            id: item,
          },
          Delete_Todo_List,
          history,
          (resp) => {
            //  actions.setSubmitting(false);
            enqueueSnackbar(
              getTranslation(
                "Todo Deleted",
                "À faire supprimé",
                "Todo gelöscht"
              ),
              {
                variant: "success",
              }
            );
          },
          (error) => {
            enqueueSnackbar(error?.data, { variant: "error" });
          }
        );
      });
    } else return;
  };

  const handleDeleteAgenda = async () => {
    await DeleteTodoListItem();

    await Create(
      {
        id: agendaId,
      },
      Delete_Agenda,
      history,
      (resp) => {
        //  actions.setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Agenda Deleted",
            "Ordre du jour supprimé",
            "Tagesordnung gelöscht"
          ),
          {
            variant: "success",
          }
        );
        setSelectedAgenda({})
        setAgendas([])
        loadAllAgendas();
        setOpen(false);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} lg={12}>
        {disabled ? null :
        <Grid container spacing={2}>
          <Grid item>
            <ButtonWithLoading
              title={
                <>
                  <Typography>Add New Agenda</Typography>&nbsp;&nbsp;&nbsp;{" "}
                  <ControlPoint />{" "}
                </>
              }
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateDialog(true)}
            />
          </Grid>
          <Grid item>
            <ButtonWithLoading
              title={
                <>
                  <Typography>Add New Todo</Typography>&nbsp;&nbsp;&nbsp;{" "}
                  <ControlPoint />{" "}
                </>
              }
              disabled={agendas.length>0?false:true}
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateDialogTodo(true)}
            />
          </Grid>
          {/* <Grid item>
                    <ButtonWithLoading title="Add New Agenda" variant="contained" color="primary"/>
                </Grid> */}
        </Grid>}
      </Grid>
      <Grid item md={4} lg={4}>
        <AgendaList
          agendas={agendas}
          selectedAgenda={selectedAgenda}
          setSelectedAgenda={setSelectedAgenda}
          deleteBtn={handleDeleteDialoge}
          disabled={disabled}
        />
      </Grid>
      <DeleteDialog
        open={dopen}
        title="Delete agenda"
        onClose={() => setOpen(false)}
        onSubmit={handleDeleteAgenda}
      />
      <Grid item md={8} lg={8}>
        <ItemEditor
          selectedAgenda={selectedAgenda}
          todoId={todoId}
          updateAgenda={updateSelectedAgenda}
          openTodoDialog={handleOpenTodoEditer}
          disabled={disabled}
        />
      </Grid>

      <CreateAgendaDialog
        meetingId={meetingId}
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onSubmit={handleSendAndNeew}
      />

      <ManageTodos
        meetingId={meetingId}
        open={openCreateDialogTodo}
        onClose={handleCloseTodoDialoge}
        onUpdateTodoId={handleUpdateTodoId}
        editId={editToDoId}
        clearEid={() => setEditTodoId("")}
      />
    </Grid>
  );
};
