import { React, useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { getTranslation } from "../../../heplers/translationHelper";
import { useParams } from "react-router";
import { Get } from "../../../actions";
import { GET_Contact_URL } from "../../../constants/apiUrls";

export const WeekDaysAvailability = ({availableDays, handleChangeAvailability }) => {
  console.log("lkhsdkljsdfdsf", availableDays);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name="monday"
                  checked={availableDays.includes("monday")}
                  onChange={(e, checked) => {
                    handleChangeAvailability("monday", checked);
                  }}
                />
              }
              label={getTranslation("Monday", "Lundi", "Montag")}
            ></FormControlLabel>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name="tuesday"
                  checked={availableDays.includes("tuesday")}
                  onChange={(e, checked) => {
                    handleChangeAvailability("tuesday", checked);
                  }}
                />
              }
              label={getTranslation("Tuesday", "Mardi", "Dienstag")}
            ></FormControlLabel>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                checked={availableDays.includes("wednesday")}
                  name="wednesday"
                  onChange={(e, checked) => {
                    handleChangeAvailability("wednesday", checked);
                  }}
                />
              }
              label={getTranslation("Wednesday", "Mercredi", "Mittwoch")}
            ></FormControlLabel>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                checked={availableDays.includes("thursday")}
                  name="thursday"
                  onChange={(e, checked) => {
                    handleChangeAvailability("thursday", checked);
                  }}
                />
              }
              label={getTranslation("Thursday", "Jeudi", "Donnerstag")}
            ></FormControlLabel>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                checked={availableDays.includes("friday")}
                  name="friday"
                  onChange={(e, checked) => {
                    handleChangeAvailability("friday", checked);
                  }}
                />
              }
              label={getTranslation("Friday", "Vendredi", "Freitag")}
            ></FormControlLabel>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
      </Grid>
    </>
  );
};
