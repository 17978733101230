import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  CircularProgress,
  DialogContent,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { FormLoader } from "../../../../../components/FormLoader";
import * as Yup from "yup";
import { ClassicTextEditor } from "../../../../../components/TextEditor";
import { Get } from "../../../../../actions";
import {
  Get_AllLegalFormList_URL,
  Get_TemplateResolutionText_URL,
} from "../../../../../constants/apiUrls";
import { Autocomplete } from "@material-ui/lab";
import { LanguageSelection } from "../../../../../constants/languages";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { CancelButton } from "../../../../../components/ButttonsWithIcons";
import DialogComponent from "../../../../../components/Dialog";
import CardComponent from "../../../../../components/Card";

export const AddTemplateDialog = ({
  open,
  title,
  templateId,
  edit,
  onSubmit,
  onClose,
}) => {
  const [enableReloading, setEnableReloading] = useState(false);
  const [legalFormList, setLegalFormList] = useState([]);
  const [resolutionText, setResolutionText] = useState();
  const [loadingResolutionText, setLoadingResolutionText] = useState(false);

  //////////// Legal forms loading /////////////
  useEffect(() => {
    loadLegalForms();
  }, []);
  const loadLegalForms = (values) => {
    Get(
      values,
      Get_AllLegalFormList_URL,
      null,
      (resp) => {
        setLegalFormList(resp?.data);
      },
      (error) => {}
    );
  };
  /////////////////// load Resolution text against templateId ////////////////
  useEffect(() => {
    if(edit)
      loadResolutionText({ cRTemplateId: templateId });
  }, [edit]);

  const loadResolutionText = (values) => {
    setLoadingResolutionText(true)
    Get(
      values,
      Get_TemplateResolutionText_URL,
      null,
      (resp) => {
        edit.resolution = resp?.data;
          // setResolutionText(resp?.data);
          setEnableReloading(true);
        setLoadingResolutionText(false)
      },
      (error) => {
        setLoadingResolutionText(false)

      }
    );
  };

  console.log("jkdghfidsbf", edit);

  const initialValues = {
    title: "",
    resolution: "",
    language: "",
    legalForm:"",
  };

  const basicValidationSchema = Yup.object().shape({
    title: Yup.string().required("Field is required"),
    resolution: Yup.string().required("Field is required"),
    language: Yup.string().required("Field is required"),
    legalForm: Yup.string().required("Field is required"),
  });

  const getSelectedLegalForm=(legalForm) => legalFormList.length>0?legalFormList.find(x=>x.legalForm===legalForm):null;  
  const defaultValue = edit ? edit : initialValues;
  return (
    <DialogComponent maxWidth={"lg"} open={open} onClose={onClose} title={getTranslation("Add Template", "Ajouter un modèle", "Vorlage hinzufügen")}>
      <DialogContent>

        <div >
          <FormLoader>
            <Formik
              enableReinitialize
              initialValues={defaultValue}
              validationSchema={basicValidationSchema}
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                onSubmit(values, actions);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleSubmit,
                isSubmitting,
                getFieldProps,
                setFieldValue,
                handleChange,
              }) => (
                <Form>
                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextField
                        fullWidth
                        id="title"
                        label={getTranslation("Title", "Titre", "Titel")}
                        required
                        size="small"
                        variant="outlined"
                        name="title"
                        {...getFieldProps("title")}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <LanguageSelection
                        label={getTranslation("Language", "Langue", "Sprache")}
                        name="basic.language"
                        required
                        selected={values?.language}
                        onChange={(e, value) => {
                          setFieldValue("language", value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Autocomplete
                        value={getSelectedLegalForm(values.legalForm)}
                        options={legalFormList}
                        getOptionLabel={(option) =>
                          option.legalForm
                        }
                        size="small"
                        onChange={(e, value) => {
                          setFieldValue("legalForm", value?.legalForm);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label={getTranslation("Legal Form", "Forme juridique", "Rechtsform")}
                            variant="outlined"
                            error={
                              touched.legalForm && Boolean(errors.legalForm)
                            }
                            helperText={touched.legalForm && errors.legalForm}
                          />
                        )}
                      />
                    </Grid>

                    {/* <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ background: "#233044", color: "#FFFF" }}
                  >
                    <Typography variant="h4"></Typography>
                  </Grid> */}
                  </Grid>
                  {loadingResolutionText ? <CircularProgress /> :
                    <ClassicTextEditor
                      title={getTranslation("Resolution", "Resolution", "Resolution")}
                      text={values.resolution}
                      onChange={(text) => {
                        setFieldValue("resolution", text);
                        handleSubmit();
                      }}
                      showButton
                    />

                  }
                  {/* <Box
                    pr={1}
                    pb={1}
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ButtonWithLoading
                      style={{
                        margin: "10px",
                        width: "100px",
                        height: "45px",
                      }}
                      title={getTranslation("Save", "sauvegarder", "Speichern")}
                      size="large"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    />
                    <CancelButton
                      onClick={onClose}

                    />

                  </Box> */}
                </Form>
              )}
            </Formik>
          </FormLoader>
        </div>

      </DialogContent>
    </DialogComponent>
  );
};
