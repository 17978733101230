import { Box, Card, CardContent, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import { UploadFiles } from "../../../../../../components/Upload";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { Create } from "../../../../../../actions";
import { Post_Meeting_UpsertRegularMeetingDraft } from "../../../../../../constants/apiUrls";

const SelectFiles = ({ history, onSubmit, BackStep, initialValues }) => {
	// const initialValues = {
	//     meetingMembers: [],
	//     files: [],

	// }
	const [nextButtonClicked, setNextButtonClicked] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [draftLoading, setDraftLoading] = useState(false);
	const [loadingButton, setloading] = useState(false);

	const setActiveState = (activeState) => ({
		type: "SET_ACTIVE_STATE",
		payload: {
			activeState,
		},
	});

	const setMeetingData = (meetingData) => ({
		type: "SET_MEETING_DATA",
		payload: {
			meetingData,
		},
	});

	return (
		<Card
			title={getTranslation(
				"Upload Document",
				"Télécharger un document",
				"Dokument hochladen"
			)}
		>
			<CardContent>
				<Formik
				enableReinitialize
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						//onSubmit(values, actions);
						actions.setSubmitting(true);
						onSubmit(values, actions);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						getFieldProps,
					}) => (
						<Form>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<UploadFiles
										files={values.files}
										history={history}
										onAddFile={(file) => {
											setFieldValue("files", [
												...values.files,
												{ fileName: file },
											]);
										}}
										//onAddFile={handleAddFile}
										onDeleteFile={(file) => {
											const arr = values.files.filter(
												(x) => x.fileName == file
											);
											setFieldValue("files", arr);
										}}
										getFileName={(file) => file.fileName}
									/>
									<Box
										mt={5}
										pr={1}
										pb={1}
										width="100%"
										display="flex"
										alignItems="center"
										justifyContent="center"
									>
										{/* <div style={{ marginRight: "10px" }}>
											<ButtonWithLoading
												title={getTranslation(
													"Save as Draft",
													"Save as Draft",
													"Save as Draft"
												)}
												color="primary"
												variant="contained"
												size="large"
												//onClick={handleSubmit}
												loading={draftLoading}
												onClick={() => {
													setNextButtonClicked(false);
													handleSubmit();
												}}
											/>
										</div> */}
										<ButtonWithLoading
											title={getTranslation(
												"Generate",
												"produire",
												"Generieren"
											)}
											size="medium"
											variant="contained"
											color="primary"
											loading={isSubmitting}
											//onClick={handleSubmit}
											onClick={() => {
												handleSubmit();
											}}
										/>
									</Box>
								</Grid>
							</Grid>
							<Box
								display="flex"
								justifyContent="flex-start"
								alignItems="flex-start"
								m={2}
							>
								<ButtonWithLoading
									title={getTranslation("Back", "Précédent", "Zurück")}
									color="primary"
									variant="contained"
									size="large"
									onClick={() => BackStep()}
								/>
							</Box>
						</Form>
					)}
				</Formik>
			</CardContent>
		</Card>
	);
};
export default SelectFiles;
