import React, { useState, useEffect } from "react";
import {
	CardContent,
	Grid,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import Table from "../../components/table";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../actions";
import {
	GET_AllUsers_URL,
	Post_MakeUserActive_URL,
	Post_MakeUserInActive_URL,
	Post_Register_URL,
	Post_UpdateUSER_PROFILE_URL,
} from "../../constants/apiUrls";
import { CreateManagementCompany } from "./component";
import { getTranslation } from "../../heplers/translationHelper";
import CardComponent from "../../components/Card";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Switch from "../../components/Switch";

const columns = [
	{
		id: "name",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Name", "Nom", "Name"),
	},
	// {
	//   id: "userName",
	//   numeric: false,
	//   disablePadding: true,
	//   label: getTranslation("Username"),
	// },
	{
		id: "email",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Email", "E-mail", "Email"),
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: "Actions",
	},
];

const ListUsers = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let userManagement = localStorage.getItem("userManagement");
	if (userManagement == null) {
		localStorage.setItem("userManagement", 10);
		userManagement = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(userManagement);
	const [rows, setRows] = useState([]);
	const [loadingFunds, setloadingFunds] = useState(false);
	const [statusFilter, setStatusFilter] = useState("all");
	const [changingStatus, setChangingStatus] = useState(0);
	const [editUser, setEditUser] = useState(null);

	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: "User Management", url: "/userManagement" },
		});
	}, []);

	useEffect(() => {
		loadFunds();
	}, [page, rowsPerPage, searchString, statusFilter]);

	const loadFunds = () => {
		setloadingFunds(true);
		Get(
			{
				status: changingStatus,
				searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
				status: statusFilter,
			},
			GET_AllUsers_URL,
			history,
			(resp) => {
				setRows(resp.data);
				setloadingFunds(false);
			},
			(error) => {}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("userManagement", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleSearchFund = (searchString) => {
		setSearchString(searchString);
	};
	const handleCreateManagementCompany = async (values, actions) => {
		Create(
			values,
			editUser ? Post_UpdateUSER_PROFILE_URL : Post_Register_URL,
			history,
			(response) => {
				actions.setSubmitting(false);
				if (editUser) {
					enqueueSnackbar("User information updated", {
						variant: "success",
					});
					setEditUser(null);
				} else {
					enqueueSnackbar("New User Created", {
						variant: "success",
					});
				}
				actions.resetForm();
				loadFunds();
			},
			(error) => {
				actions.setSubmitting(false);
				enqueueSnackbar(error.data, {
					variant: "error",
				});
			}
		);
	};

	const handleMakeUserInactive = (row) => {
		setChangingStatus(row.Id);
		Create(
			{ appUserId: row.id },
			Post_MakeUserInActive_URL,
			history,
			(response) => {
				setChangingStatus(0);
				enqueueSnackbar("User status changed to inactive", {
					variant: "success",
				});
				loadFunds();
			},
			(error) => {
				setChangingStatus(0);
				enqueueSnackbar(error.data, {
					variant: "error",
				});
			}
		);
	};

	const handleMakeUserActive = (row) => {
		setChangingStatus(row.Id);
		Create(
			{ appUserId: row.id },
			Post_MakeUserActive_URL,
			history,
			(response) => {
				setChangingStatus(0);
				enqueueSnackbar("User status changed to active", {
					variant: "success",
				});
				loadFunds();
			},
			(error) => {
				setChangingStatus(0);
				enqueueSnackbar(error.data, {
					variant: "error",
				});
			}
		);
	};

	const userActions = (row) => {
		if (changingStatus && row.id === changingStatus) {
			return <CircularProgress />;
		}

		if (row.firstName !== "Super") {
			return (
				<Switch
					checked={!row.isInActive}
					onChange={(e, checked) => {
						if (checked) {
							handleMakeUserActive(row);
						} else {
							handleMakeUserInactive(row);
						}
					}}
				/>
			);
		}

		return null;
	};

	const handleEditUser = (row) => {
		setEditUser(row);
	};

	return (
		<div>
			<CreateManagementCompany
				onSubmit={handleCreateManagementCompany}
				editUser={editUser}
				onCancel={() => {
					setEditUser(null);
				}}
			/>
			<CardComponent title={getTranslation("Users")}>
				<CardContent>
					<Grid
						item
						xs={12}
						sm={12}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<ToggleButtonGroup
							size="small"
							value={statusFilter}
							exclusive
							onChange={(event, newAlignment) => {
								setStatusFilter(newAlignment);
							}}
						>
							<ToggleButton value="all" aria-label="left aligned">
								<Typography>
									{getTranslation("All", "Toute", "Alle")}
								</Typography>
							</ToggleButton>
							<ToggleButton value="active" aria-label="centered">
								<Typography>
									{getTranslation("Active", "Active", "Aktiv")}
								</Typography>
							</ToggleButton>
							<ToggleButton value="inactive" aria-label="centered">
								<Typography>
									{getTranslation("In Active", "Inactif", "Inaktiv")}
								</Typography>
							</ToggleButton>
						</ToggleButtonGroup>
					</Grid>
				</CardContent>
			</CardComponent>
			<Table
				dense
				loading={loadingFunds}
				colums={columns}
				sortBy="title"
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => handleEditUser(row)}
				viewSearch
				onSearch={(searchString) => handleSearchFund(searchString)}
				actions={[
					{
						component: (row) => userActions(row),
					},
				]}
			/>
		</div>
	);
};

export default ListUsers;
