import { Box, TextField } from "@material-ui/core"
import { Form, Formik } from "formik"
import ButtonWithLoading from "../../../components/ButtonWithLoading"
import DialogComponent from "../../../components/Dialog"
import * as Yup from 'yup'
export const DeclineReasonDialog = ({isOpen, onSubmit ,onClose}) => {
    const initialValue = {
        comments:"",
    }
    const basicValidationSchema = Yup.object().shape({
      comments:Yup.string().required("Your reason to decline this meeting please")
    })
    return (
      <DialogComponent
        title="Decline Reason"
        open={isOpen}
        onClose={onClose}
        fullWidth
      >
        <Formik
          initialValues={initialValue}
          validationSchema={basicValidationSchema}        
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            onSubmit(values.comments, actions);
            actions.resetForm();
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
          }) => (
            <Form>
              <TextField
              required
                fullWidth
                id="comments"
                multiline
                label="Comments"
                size="small"
                variant="outlined"
                {...getFieldProps("comments")}
                error={touched.comments && Boolean(errors.comments)}
                helperText={touched.comments && errors.comments}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box display="flex" alignContent="center" justifyContent="center">
                <ButtonWithLoading
                style={{
                  background:"red",
                  color:"white",
                  margin:"10px"
                }}
                  title="Decline"
                  variant="outlined"
                  size="small"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </DialogComponent>
    );
}