// import { Box, DialogActions, TextField } from "@material-ui/core";
// import DialogComponent from "../../../../components/Dialog";
// import { useEffect, useState } from "react";
// import ButtonWithLoading from "../../../../components/ButtonWithLoading";
// import { getTranslation } from "../../../../heplers/translationHelper";

// export const DeclineCommentsDialog = ({ open, onSubmit, loading, onClose }) => {
// 	const [comments, setComments] = useState("");

// 	return (
// 		<DialogComponent
// 			title="Decline"
// 			open={open}
// 			maxWidth="md"
// 			fullWidth
// 			onClose={onClose}
// 		>
// 			<TextField
// 				fullWidth
// 				variant="outlined"
// 				label={getTranslation("Comments", "Comments", "Comments")}
// 				multiline
// 				minRows={5}
// 				value={comments}
// 				onChange={(e) => setComments(e.target.value)}
// 			></TextField>
// 			<DialogActions>
// 				<Box
// 					width="100%"
// 					display="flex"
// 					alignItems="center"
// 					justifyContent="center"
// 				>
// 					<ButtonWithLoading
// 						style={{ margin: "15px" }}
// 						title={getTranslation("Cancel", "Cancel", "Cancel")}
// 						size="small"
// 						variant="contained"
// 						color="primary"
// 						//   loading={isSubmitting}
// 						onClick={(e) => {
// 							onClose();
// 						}}
// 					/>
// 					<ButtonWithLoading
// 						style={{ margin: "15px" }}
// 						title={getTranslation("Confirm", "Confirm", "Confirm")}
// 						size="small"
// 						variant="contained"
// 						color="default"
// 						loading={loading}
// 						onClick={(e) => {
// 							onSubmit(comments);
// 						}}
// 					/>
// 				</Box>
// 			</DialogActions>
// 		</DialogComponent>
// 	);
// };

import { Box, DialogActions, TextField } from "@material-ui/core";
import DialogComponent from "../../../../components/Dialog";
import { useEffect, useState } from "react";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../heplers/translationHelper";
import { useSnackbar } from "notistack";

export const DeclineCommentsDialog = ({ open, onSubmit, loading, onClose }) => {
	const [comments, setComments] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleCancel = () => {
		onClose();
		setComments("");
	};

	const handleConfirm = () => {
		if (comments.trim() !== "") {
			setIsSubmitting(true);
			onSubmit(comments);
		} else {
			enqueueSnackbar(
				getTranslation(
					"Comments Are Required",
					"Comments Are Required",
					"Comments Are Required"
				),
				{ variant: "error" }
			);
		}
	};

	return (
		<DialogComponent
			title="Decline"
			open={open}
			maxWidth="md"
			fullWidth
			onClose={handleCancel}
		>
			<TextField
				fullWidth
				variant="outlined"
				label={getTranslation("Comments", "Comments", "Comments")}
				multiline
				minRows={5}
				value={comments}
				onChange={(e) => setComments(e.target.value)}
				required // Add required attribute
			></TextField>
			<DialogActions>
				<Box
					width="100%"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<ButtonWithLoading
						style={{ margin: "15px" }}
						title={getTranslation("Cancel", "Cancel", "Cancel")}
						size="small"
						variant="contained"
						color="primary"
						onClick={handleCancel}
					/>
					<ButtonWithLoading
						style={{ margin: "15px" }}
						title={getTranslation("Confirm", "Confirm", "Confirm")}
						size="small"
						variant="contained"
						color="default"
						loading={loading || isSubmitting}
						onClick={handleConfirm}
					/>
				</Box>
			</DialogActions>
		</DialogComponent>
	);
};
