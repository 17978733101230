import { Card, CardContent, CardHeader, Typography } from "@material-ui/core"
import { getTranslation } from "../../../../heplers/translationHelper"

const FormIdentification = ({formFields}) => {
    return(
        <>
            <Card>
                <CardHeader title={getTranslation("Identification")} />
                <CardContent>
                    <Typography>
                        API is pending from the server side to get the form fields and their values. Please wait for the server to respond. . . .
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}
export default FormIdentification