import React, { useState, useEffect } from "react";
import {
	CardContent,
	CardActions,
	Grid,
	TextField,
	Box,
	Typography,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../components/Card";
import { makeStyles } from "@material-ui/core/styles";

import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import authUtils from "../../../utils/authUtils";
import checkResponse from "../../../heplers/responseHelper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getTranslation } from "../../../heplers/translationHelper";
import { CheckBox } from "@material-ui/icons";

const Details = (props) => {
	const { isNewRecord, loading, contact, onSubmit } = props;
	const { enqueueSnackbar } = useSnackbar();
	const [initialValues, setInitialValues] = useState({
		mail: "",
		phone1: "",
		firstName: "",
		name: "",
		city: "",
		zipCode: "",
		description: "",
		address: "",
		language: 0,
	});

	const initialDefault = contact ? contact : initialValues;

	console.log("bashfhdgasfdgasgdas", initialDefault);

	return (
    <Card
      title={
        isNewRecord === false
          ? getTranslation(
              "Basic Information",
              "Informations de base",
              "Grundinformation"
            )
          : ""
      }
    >
      {/* <CardHeader title='Details' /> */}

      <Formik
        enableReinitialize
        initialValues={initialDefault}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .matches(
              /^[a-zA-Z\sÀ-ÿ\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]+$/,
              "Only English, French, and German characters are allowed"
            )
            .required("First Name is required"),

          phone1: Yup.string()
            .matches(
              /^\+[0-9]+$/,
              'Phone must start with "+" and contain only numbers after that'
            )
            .required("Phone is Required"),

          mail: Yup.string()
            .email("Please enter a valid Email")
            .required("Email is required"),
        })}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);

          actions.resetForm();
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          getFieldProps,
          errors,
          touched,
          values,
          setFieldValue,
        }) => (
          <form>
            {console.log("vahjsghashcfasfcas", values)}
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
				{/* <FormGroup row>
				<FormControlLabel
                    control={
                      <Checkbox
                        checked={values.checkedManualSigning}
                        onChange={(e)=>setFieldValue("checkedManualSigning",e.target.checked)}
                        name="checkedA"
                      />
                    }
                    label={getTranslation("Enable Manual Signing","Enable Manual Signing","Enable Manual Signing")}
                  />
				   <FormControlLabel
                    control={
                      <Checkbox
					  checked={values.checkedDocuSigning}
					  onChange={(e)=>setFieldValue("checkedDocuSigning",e.target.checked)}
                        name="checkedA"
                      />
                    }
                    label={getTranslation("Enable Docu Signing","Enable Docu Signing","Enable Docu Signing")}
                  />
				</FormGroup> */}
                 
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {getTranslation("Language", "language", "Language")}
                    </InputLabel>
                    <Select
                      style={{ height: "34px" }}
                      labelId="demo-simple-select-label"
                      label={getTranslation("Language", "Langue", "Sprache")}
                      name="language"
                      value={values.language}
                      onChange={(e) => {
                        setFieldValue("language", e.target.value);
                      }}
                      error={touched.language && Boolean(errors.language)}
                    >
                      <MenuItem value={0}>English</MenuItem>
                      <MenuItem value={1}>German</MenuItem>
                      <MenuItem value={2}>French</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation(
                      "First Name *",
                      "Prénom *",
                      "Vorname *"
                    )}
                    size="small"
                    variant="outlined"
                    id="firstName"
                    name="firstName"
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("firstName")}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation(
                      "Last Name",
                      "Nom de famille",
                      "Familienname, Nachname"
                    )}
                    size="small"
                    variant="outlined"
                    name="name"
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("name")}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation("Email *", "E-mail *", "Email *")}
                    size="small"
                    variant="outlined"
                    name="mail"
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("mail")}
                    error={touched.mail && Boolean(errors.mail)}
                    helperText={touched.mail && errors.mail}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation("Phone", "Téléphoner", "Telefon")}
                    size="small"
                    variant="outlined"
                    name="phone1"
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("phone1")}
                    error={touched.phone1 && Boolean(errors.phone1)}
                    helperText={touched.phone1 && errors.phone1}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation("Zip", "Code postal", "Postleitzahl")}
                    size="small"
                    variant="outlined"
                    name="zipCode"
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("zipCode")}
                    error={touched.zipCode && Boolean(errors.zipCode)}
                    helperText={touched.zipCode && errors.zipCode}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation("City", "Ville", "Stadt")}
                    size="small"
                    variant="outlined"
                    name="city"
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("city")}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation(
                      "Description",
                      "Description",
                      "Description"
                    )}
                    size="small"
                    variant="outlined"
                    name="description"
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("description")}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    fullWidth
                    label={getTranslation("Address", "Adresse", "Adresse")}
                    size="small"
                    variant="outlined"
                    name="address"
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("address")}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <ButtonWithLoading
                  title={
                    isNewRecord === true
                      ? getTranslation("Create", "Créer", "Schaffen")
                      : getTranslation(
                          "Update",
                          "Mettre à jour",
                          "Aktualisieren"
                        )
                  }
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={loading}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default Details;
