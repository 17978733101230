import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { useReactToPrint } from "react-to-print";
import { PrintSignedCircularResolution } from "./printSignCircularResolution";
import { DeclineDialog } from "./DeclineDialog";
import {
	Get_CircularResolutionMemberContact_URL,
	Get_MemberContact_URL,
	Post_ConfirmCRByMember_URL,
	Post_UpdateMemberComment_URL,
} from "../../../constants/apiUrls";
import { Create, Get } from "../../../actions";
import { useSnackbar } from "notistack";
import { DraftSignedCircularResolution } from "./draftSignCircularResolution";
import { getTranslation } from "../../../heplers/translationHelper";
import { useNavigate } from "react-router";
import { savePdf } from "../../../heplers/pdfHelper";

export const SignDecision = ({ circularResolution, personalInfo,memberId,companyInfo }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [declineDialogState, setDeclineDialogState] = useState(false);
	const [isDraft, setIsDraft] = useState(false);
	const componentPrint = useRef();
	const componentDraft = useRef();
	const navigate = useNavigate();

	// const handlePrint = useReactToPrint({
	// 	documentTitle: "Resolution Signed",
	// 	content: () => componentPrint.current,
	// });
	const handlePrint=()=>{
		setIsDraft(false)

		setTimeout(()=>{
			savePdf('test.pdf',componentPrint.current,null,null,true,file=>{
			})
		},1000)

		
	}
	
	const handleDraft = ()=>{
		setIsDraft(true)
		setTimeout(()=>{
			savePdf('test.pdf',componentPrint.current,null,null,true,file=>{
			})
		},1000)
	} 

	const handleCancel = () => {
		setDeclineDialogState(false);
		//actions.resetForm();
	};
	//// Get Personal Info to print on signed/draft CR  Circular Resolution Confirmation ////

	////////////////////// handle submit  Circular Resolution Confirmation ////////////////////
	const handleSubmit = (values, actions) => {
		Create(
			{
				memberId: memberId,
				circularResolutionId: circularResolution.id,
				descision: values.descision,
				comments: values.comments,
			},
			Post_ConfirmCRByMember_URL,
			null,
			(resp) => {
		setDeclineDialogState(false);

				navigate("/response/Your Decision is saved.");

		actions.setSubmitting(false);
				enqueueSnackbar("Comments Added", { variant: "info" });
				//actions.resetForm();
			},
			(error) => {
				actions.setSubmitting(false);
				enqueueSnackbar("Unable to mark your decision, Please contact Aureto Team.", { variant: "error" });
			}
		);
	};
	const handleDecline = () => {
		setDeclineDialogState(true);
	};
	return (
    <Card>
		<CardActions title="Actions">

		</CardActions>
      <CardContent>
        <Box
          pr={1}
          pb={1}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleDraft}
			style={{marginRight:"20px"}}
          >
            {getTranslation("Print Draft", "Print Draft", "Print Draft")}
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handlePrint}
			style={{marginRight:"20px"}}

          >
            {getTranslation("Print & Sign", "Print & Sign", "Print & Sign")}
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => handleDecline()}
          >
            {getTranslation("Decline", "Decline", "Decline")}
          </Button>
        </Box>

        <DeclineDialog
          memberId={memberId}
          circularResolutionId={circularResolution?.id}
          isOpen={declineDialogState}
          onClose={handleCancel}
          onSubmit={handleSubmit}
        />
        <div style={{ display: "none" }}>
          {/* //circularResolution={circularResolution} */}
          <PrintSignedCircularResolution
		  companyInfo={companyInfo}
            ref={componentPrint}
            personalInfo={personalInfo}
            circularResolution={circularResolution}
            memberId={memberId}
			isDraft={isDraft}
          />
        </div>
        {/* <div style={{ display: "none" }}>
          <DraftSignedCircularResolution
            ref={componentDraft}
            personalInfo={personalInfo}
            circularResolution={circularResolution}
          />
        </div> */}
      </CardContent>
    </Card>
  );
};
