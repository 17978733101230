import axios from "../heplers/apiHelper";
import { LOGIN_URL, GET_USER_PROFILE_URL, Post_Logout_URL, Post_RefreshToken_URL, Get_FeatureToggles_URL } from "../constants/apiUrls";
import { Feature_Toggle, SET_USER_DATA } from "../constants/types";
import authService from "../utils/authUtils"
import { SERVER_URL } from "../constants";
import { validateAuthentication } from ".";


export const userLogin = (payload) => async (dispatch) => {
  try {
    const response = await axios().post(LOGIN_URL, payload);
    dispatch({ type: SET_USER_DATA, payload: response.data.user });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const tryGetRefreshToken = async (axiosRefreshInstance, payload) => {
  try {
    const response = await axiosRefreshInstance.post(`${SERVER_URL}${Post_RefreshToken_URL}`, payload);
    // dispatch({ type: SET_USER_DATA, payload: response.data });
    return response.data;
  } catch (error) {
    //onError(error.response)
  }
};
export const getUserData = (history,onError) => async dispatch => {
  await validateAuthentication("get", async () => {
  try {
    const response = await axios(history).get(GET_USER_PROFILE_URL);
    console.log("jklashdasjdasdasd response",response)
    dispatch({ type: SET_USER_DATA, payload: response.data });
  } catch (error) {
    dispatch({ type: SET_USER_DATA, payload: null });
    return onError(error.response);
  }})
}

export const getToggleFeature = (history,onError) => async dispatch => {
  await validateAuthentication("get", async () => {
  try {
    const response = await axios(history).get(Get_FeatureToggles_URL);
    dispatch({ type: Feature_Toggle, payload: response.data });
  } catch (error) {
    dispatch({ type: Feature_Toggle, payload: null });
    return onError(error.response);
  }})
}

export const Logout = (history) => async dispatch => {
  try {
    const response = await axios().post(Post_Logout_URL);
    dispatch({ type: "LOGOUT" });
    return response;
  } catch (error) {
    return error.response;
  }
}

// export const setUserData = (payload) => async (dispatch) => {
//   dispatch({ type: SET_USER_DATA, payload: payload });
// };

