import React from "react";
import { Typography, Breadcrumbs, Link } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

const SimpleBreadcrumbs = (props) => {
  console.log("hagdhsahjsagd",props)
  const nav=useNavigate();
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((x) => x);
  const { breadcrumbs } = useSelector((state) => state.breadcrumbs);

  return (
    <Breadcrumbs
      style={{ color: "#fff", padding: "10px" }}
      aria-label="breadcrumb"
    >

      {pathnames.length === 0 ? (
        <Typography variant="body2">
          <Link
            color="inherit"
            style={{ cursor: "pointer"}}
            onClick={() => nav("/")}
          >
            Home
          </Link>
        </Typography>
      ) : (
        <Typography variant="body2" 
        href="/"
        onClick={() => nav("/")}
        >Home</Typography>
      )}
      {breadcrumbs.map((breadCrumb, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return isLast ? (
          <Typography variant="body2">
            {breadCrumb.title}
          </Typography>
        ) : (
          <Typography variant="body2">
            <Link
              color="inherit"Users
              style={{ cursor: "pointer"}}
              onClick={() => nav(breadCrumb.url)}
            >
              {breadCrumb.title}
            </Link>
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default SimpleBreadcrumbs;
