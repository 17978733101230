import React, { useState, useEffect } from "react";
import Table from "../../components/table";
import { Create, Get } from "../../actions";
import {
	Create_Shareholders_URL,
	Get_AllShareholders_URL,
	Delete_Shareholders_URL,
	Create_UpdateShareholder_URL,
} from "../../constants/apiUrls";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import DeleteDialog from "../../components/DeleteDialog";
import { CreateShareholder } from "./component";
import { getTranslation } from "../../heplers/translationHelper";

const columns = [
	{
		id: "companyName",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Company", "Compagnie", "Gesellschaft"),
	},
	{
		id: "share",
		numeric: false,
		disablePadding: true,
		label: getTranslation(" Shares", "Actions", "Anteile"),
	},
	{
		id: "capital",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Capital", "Capitale", "Hauptstadt"),
	},
	{
		id: "date",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Date", "Date", "Datum"),
		format: "date",
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const Shareholders = () => {
	const { assetId, fundId, companyId, managementCompanyId } = useParams();
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let spvDetailShareHolder = localStorage.getItem("spvDetailShareHolder");
	if (spvDetailShareHolder == null) {
		localStorage.setItem("spvDetailShareHolder", 10);
		spvDetailShareHolder = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvDetailShareHolder);
	const [rows, setRows] = useState([]);
	const [loadingShareHolders, setLoadingShareHolders] = useState(false);
	const [shareholders, setShareholders] = useState([]);
	const [searchShareholdersString, setSearchShareholdersString] = useState("");
	const [editShareHolder, setEditShareHolder] = useState(null);

	useEffect(() => {
		setLoadingShareHolders(true);
		loadShareholders();
	}, [companyId, page, rowsPerPage, searchShareholdersString]);

	const loadShareholders = () => {
		Get(
			{
				id: companyId,
				searchString: searchShareholdersString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_AllShareholders_URL,
			history,
			(response) => {
				setLoadingShareHolders(false);
				setShareholders(response.data);
			},
			(error) => {
				setLoadingShareHolders(false);
			}
		);
	};

	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleDeleteActivity = async (row) => {
		await Create(
			{
				ids: [row.id],
			},
			Delete_Shareholders_URL,
			history,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Shareholder deleted",
						"Actionnaire supprimé",
						"Aktionär gelöscht"
					),
					{ variant: "success" }
				);
				loadShareholders();
				setOpen(false);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Unable to delete this shareholder",
						"Impossible de supprimer cet actionnaire",
						"Dieser Aktionär kann nicht gelöscht werden"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const handleEditActivity = (row) => {
		history(`/funds/add/${row.id}`);
		// dispatch(editActivityType(row));
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvDetailShareHolder", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const [submitting, setSubmitting] = useState(false);
	const handleCreateShareholder = async (values, actions) => {
		setSubmitting(true);
		Create(
			values,
			editShareHolder ? Create_UpdateShareholder_URL : Create_Shareholders_URL,
			history,
			(resp) => {
				setSubmitting(false);
				editShareHolder
					? enqueueSnackbar(
							getTranslation(
								"Shareholder Updated",
								"Actionnaire créé",
								"Aktionär erstellt"
							),
							{
								variant: "success",
							}
					  )
					: enqueueSnackbar(
							getTranslation(
								"Shareholder Created",
								"Actionnaire créé",
								"Aktionär erstellt"
							),
							{
								variant: "success",
							}
					  );
				setEditShareHolder(null);
				loadShareholders();
			},
			(error) => {
				try {
					setSubmitting(false);
					enqueueSnackbar(error?.data, { variant: "error" });
				} catch (error) {
					enqueueSnackbar("Error Submitting shareholder action", {
						variant: "error",
					});
				}
			}
		);
	};

	return (
		<>
			<div>
				<CreateShareholder
					onCancel={() => setEditShareHolder(null)}
					editShareHolder={editShareHolder}
					onSubmit={handleCreateShareholder}
					companyId={companyId}
					isSubmitting={submitting}
				/>
				<Table
					dense
					loading={loadingShareHolders}
					title={getTranslation(
						"Shareholders",
						"Actionnaires",
						"Aktionärinnen"
					)}
					colums={columns}
					// rows={activities?.data}
					// count={activities?.totalCount}
					sortBy="company"
					rows={shareholders?.data}
					count={shareholders?.totalCount}
					page={page}
					rowsPerPage={rowsPerPage}
					handleChangePage={(event, newPage) =>
						handleChangePage(event, newPage)
					}
					handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
					viewDelete
					onDelete={(row) => handleOpenDeleteDialog(row)}
					viewSearch
					viewEdit
					onEdit={(row) => setEditShareHolder(row)}
				/>
				<DeleteDialog
					open={open}
					title={getTranslation(
						"Delete Shareholder",
						"Supprimer l'actionnaire",
						"Aktionär löschens"
					)}
					onClose={() => setOpen(false)}
					onSubmit={(e) => handleDeleteActivity(deleteRow)}
				/>
			</div>
		</>
	);
};

export default Shareholders;
