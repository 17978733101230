import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../actions";
import Table from "../../components/table";
import { CreateService } from "./component";
import DeleteDialog from "../../components/DeleteDialog";

import {
	Get_AllCeresServicesWithPagination_URL,
	Post_InsertCeresService_URL,
	Post_DeleteCeresService_URL,
	Post_ChangeStatusCeresService_URL,
	Post_UpdateCeresServiceTitle_URL,
} from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
import Switch from "../../components/Switch";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const columns = [
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Title", "Titre", "Titele"),
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const ManageService = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let companyFeeServices = localStorage.getItem("companyFeeServices");
	if (companyFeeServices == null) {
		localStorage.setItem("companyFeeServices", 10);
		companyFeeServices = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(companyFeeServices);
	const [rows, setRows] = useState([]);
	const [loadingCeresServices, setloadingCeresServices] = useState(false);
	const [openCreateDialog, setOpenCreateDialog] = useState(false);
	const [status, setStatus] = useState("all");
	const [editCeresService, setEditCeresService] = useState(null);

	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: "Fees", url: "/Fees" },
		});
	}, []);

	useEffect(() => {
		loadCeresServices();
	}, [page, rowsPerPage, searchString, status]);

	const loadCeresServices = () => {
		setloadingCeresServices(true);
		Get(
			{
				searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
				status: status,
			},
			Get_AllCeresServicesWithPagination_URL,
			history,
			(resp) => {
				setRows(resp.data);
				setloadingCeresServices(false);
			},
			(error) => {}
		);
	};

	// const { activities } = useSelector((state) => state.activities);
	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleDeleteActivity = (row) => {
		const deleteArray = [];
		deleteArray.push(row.id);
		Create(
			{ ids: deleteArray },
			Post_DeleteCeresService_URL,
			history,
			(resp) => {
				setOpen(false);
				enqueueSnackbar("Service Deleted", {
					variant: "success",
				});
				loadCeresServices();
			},
			(error) => {
				enqueueSnackbar(
					"Unable to delete service because its being used by some company",
					{
						variant: "error",
					}
				);
			}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("companyFeeServices", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleSearchCeresService = (searchString) => {
		setSearchString(searchString);
	};

	const handleCreate = async (values, actions) => {
		let url = Post_InsertCeresService_URL;
		if (editCeresService) {
			url = Post_UpdateCeresServiceTitle_URL;
		}
		Create(
			values,
			url,
			history,
			(response) => {
				actions.setSubmitting(false);
				editCeresService
					? enqueueSnackbar("Service Updated", {
							variant: "success",
					  })
					: enqueueSnackbar("Service Created", {
							variant: "success",
					  });
				loadCeresServices();
				setOpenCreateDialog(false);
			},
			(error) => {
				actions.setSubmitting(false);
				enqueueSnackbar("Something went wrong", {
					variant: "error",
				});
			}
		);
	};

	const handleDetailCompany = (row) => {
		setEditCeresService(row);
		setOpenCreateDialog(true);
	};

	const feeActions = (row) => {
		console.log("hjdghfsdf", row.id);
		return (
			<>
				<Switch
					checked={row.isActive}
					onChange={(e, checked) => {
						Create(
							{
								id: row.id,
								isActive: checked,
							},
							Post_ChangeStatusCeresService_URL,
							null,
							(resp) => {
								loadCeresServices();
							},
							(error) => {}
						);
					}}
				/>
			</>
		);
	};
	return (
		<div>
			<ToggleButtonGroup
				size="small"
				style={{
					display: "flex",
					justifyContent: "center",
				}}
				value={status}
				exclusive
				onChange={(event, type) => {
					if (type !== null && typeof type !== "undefined") {
						setStatus(type);
					} else setStatus(type);
				}}
				aria-label="text alignment"
			>
				<ToggleButton value="all">All</ToggleButton>
				<ToggleButton value="active">Active</ToggleButton>
				<ToggleButton value="inactive">In Active</ToggleButton>
			</ToggleButtonGroup>
			<br />
			<Table
				dense
				loading={loadingCeresServices}
				title={getTranslation("Fees", "Frais", "Gebühren")}
				colums={columns}
				sortBy="title"
				rows={rows.data}
				peration
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				// viewEdit
				// onEdit={(row)=>handleDetailCompany(row)}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				viewSearch
				onSearch={(searchString) => handleSearchCeresService(searchString)}
				viewEdit
				onEdit={(row) => handleDetailCompany(row)}
				viewCreate
				onCreate={() => {
					setEditCeresService(null);
					setOpenCreateDialog(true);
				}}
				actions={[
					{
						component: (row) => feeActions(row),
					},
				]}
			/>

			<DeleteDialog
				open={open}
				title={getTranslation(
					"Delete Service",
					"Supprimer le service",
					"Dienst löschen"
				)}
				onClose={() => setOpen(false)}
				onSubmit={(e) => handleDeleteActivity(deleteRow)}
			/>
			<CreateService
				editService={editCeresService}
				open={openCreateDialog}
				onClose={() => setOpenCreateDialog(false)}
				onSubmit={handleCreate}
			/>
		</div>
	);
};

export default ManageService;
