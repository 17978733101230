import {
	Box,
	CardContent,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { Create, Get } from "../../../../../../actions";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import Card from "../../../../../../components/Card";
import {
	Get_AgendaTemplateList_URL,
	Get_AllAgendaTemplateItems_URL,
	Post_Meeting_UpsertRegularMeetingDraft,
} from "../../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { AskAgenda } from "../../../../../public";
import { SelectionCcList } from "../reminders/selectionCcList";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import TextEditor from "../../../../../settings/components/emailsetting/TextEditorCard";

const AskReminders = ({ NextStep, BackStep, initialValues }) => {
	const [templates, setTemplates] = useState([]);
	const [templatesLoading, setTemplatesLoading] = useState(false);
	const [nextButtonClicked, setNextButtonClicked] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [draftLoading, setDraftLoading] = useState(false);

	useEffect(() => {
		loadTemplates();
	}, []);

	const loadTemplates = () => {
		setTemplatesLoading(true);
		Get(
			{},
			Get_AgendaTemplateList_URL,
			null,
			(resp) => {
				setTemplates(resp.data);
				setTemplatesLoading(false);
			},
			(error) => {}
		);
	};
	const loadTemplateItems = (templateId, callback) => {
		// setTemplatesLoading(true);
		Get(
			{
				agendaTemplateId: templateId,
			},
			Get_AllAgendaTemplateItems_URL,
			null,
			(resp) => {
				callback(resp.data);
			},
			(error) => {}
		);
	};

	// const initialValues = {
	//     meetingMembers: [],
	//     meetingReminders: [],
	//     reminders: [],
	//     isReminderRequired: false,
	//     agendas: [],

	// }
	// const basicValidationSchema = Yup.object().shape({
	//     subject: Yup.string().required("Field is required"),
	//     address: Yup.string().required("Field is required"),
	//     meetingTime: Yup.string().required("Field Time is requied"),
	//     meetingDate: Yup.string().required("Field is required"),
	//     language: Yup.string().required("Field is required"),
	//     periodicity: Yup.number()
	//         .min(10, "Minimum 10 days allowed.")
	//         .required("Field is required"),
	//     personInchargeId: Yup.string().required("Field is required"),
	//     companyCeresServiceId: Yup.number().min(1).required("Field is required"),
	// });

	const setActiveState = (activeState) => ({
		type: "SET_ACTIVE_STATE",
		payload: {
			activeState,
		},
	});

	const setMeetingData = (meetingData) => ({
		type: "SET_MEETING_DATA",
		payload: {
			meetingData,
		},
	});
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				//NextStep(values);
				if(values.isReminderEnabled && values.reminderText=="")
					{
						enqueueSnackbar(getTranslation("Reminder text is mandatory.","Reminder text is mandatory.","Reminder text is mandatory."))
						return;
					}
				if (nextButtonClicked) {
					let payloadObj = {
						activeStep: 6,
						meetingDraftVm: {...values,activeStep:7},

					};
					console.log("guest draft", payloadObj);
					setDraftLoading(true);
					Create(
						payloadObj,
						Post_Meeting_UpsertRegularMeetingDraft,
						null,
						(resp) => {
							setDraftLoading(false);
							NextStep(values);
						},
						(error) => {
							setDraftLoading(false);
							enqueueSnackbar("Something Went Wrong", {
								variant: "error",
							});
						}
					);
				}
				if (!nextButtonClicked) {
					console.log("draft reminder", values);
					let payloadObj = {
						activeStep: 6,
						meetingDraftVm: values,
					};
					console.log("guest draft", payloadObj);
					setDraftLoading(true);
					Create(
						payloadObj,
						Post_Meeting_UpsertRegularMeetingDraft,
						null,
						(resp) => {
							dispatch(setActiveState(null));
							dispatch(setMeetingData(null));
							setDraftLoading(false);
							window.location.reload();
							enqueueSnackbar("Meeting Draft Successfully", {
								variant: "success",
							});
						},
						(error) => {
							setDraftLoading(false);
							enqueueSnackbar("Something Went Wrong", {
								variant: "error",
							});
						}
					);
				}
			}}
		>
			{({
				errors,
				touched,
				values,
				handleSubmit,
				isSubmitting,
				getFieldProps,
				setFieldValue,
				handleChange,
			}) => (
				<Form>
					<Grid container spacing={2} style={{ marginBottom: "10px" }}>
						{/* Step 4 */}
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Card
								title={getTranslation("Reminders", "Rappels", "Erinnerungen")}
							>
								<CardContent>
									<FormControl>
										<FormControlLabel
											label={getTranslation(
												"Send Reminder",
												"Envoyer un rappel",
												"Eine Erinnerung senden"
											)}
											labelPlacement="start"
											control={
												<Checkbox
													id="isReminderEnabled"
													checked={values.isReminderEnabled}
													onChange={handleChange}
												/>
											}
										/>
									</FormControl>
									{values.isReminderEnabled ? (
										<>
											<br />
											<br />
											<TextField
												style={{ width: "400px" }}
												id="reminderInterval"
												label={getTranslation(
													"Interval (days)",
													"Intervalle (jours)",
													"Intervall (Tage)"
												)}
												required
												size="small"
												type="number"
												variant="outlined"
												{...getFieldProps("reminderInterval")}
												error={
													touched.reminderInterval &&
													Boolean(errors.reminderInterval)
												}
												helperText={
													touched.reminderInterval && errors.reminderInterval
												}
												// InputLabelProps={{}}
											/>
											<br />
											<br />
											
											<TextField
												fullWidth
												id="reminderText"
												label={getTranslation("Email Text", "Email Text", "Email Text")}
												required
												size="small"
												variant="outlined"
												{...getFieldProps("reminderText")}
												error={
													touched.reminderText && Boolean(errors.reminderText)
												}
												helperText={touched.reminderText && errors.reminderText}
												InputLabelProps={{}}
												multiline
												rows={10}
											/>
											<br />
											<br />

											<SelectionCcList
												selectedMembers={values?.meetingMembers?.filter(
													(x) => x.sendReminderCC
												)}
												members={values.meetingMembers}
												onAddCC={(member) => {
													const index = values.meetingMembers.findIndex(
														(x) => x == member
													);
													const arr = values.meetingMembers;
													member.sendReminderCC = true;
													arr[index] = member;
													setFieldValue("meetingMembers", arr);
												}}
												onRemoveCC={(member) => {
													const index = values.meetingMembers.findIndex(
														(x) => x == member
													);
													const arr = values.meetingMembers;
													member.sendReminderCC = false;
													arr[index] = member;
													setFieldValue("meetingMembers", arr);
												}}
											/>
										</>
									) : null}
								</CardContent>
							</Card>
						</Grid>
					</Grid>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="space-between"
						m={2}
					>
						<ButtonWithLoading
							title={getTranslation("Back", "Précédent", "Zurück")}
							color="primary"
							variant="contained"
							size="large"
							onClick={() => BackStep()}
						/>
						<Box display="flex">
							{/* <div style={{ marginRight: "10px" }}>
								<ButtonWithLoading
									title={getTranslation(
										"Save as Draft",
										"Save as Draft",
										"Save as Draft"
									)}
									color="primary"
									variant="contained"
									size="large"
									//onClick={handleSubmit}
									loading={draftLoading}
									onClick={() => {
										setNextButtonClicked(false);
										handleSubmit();
									}}
								/>
							</div> */}

							<ButtonWithLoading
								title={getTranslation("Next", "Suivant", "Weiter")}
								color="primary"
								variant="contained"
								size="large"
								//onClick={handleSubmit}
								loading={draftLoading || nextButtonClicked}
								onClick={() => {
									setNextButtonClicked(true);
									handleSubmit();
								}}
							/>
						</Box>
						{/* <ButtonWithLoading
                            title={getTranslation("Next", "Suivant", "Weiter")}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleSubmit}
                        /> */}
					</Box>
				</Form>
			)}
		</Formik>
	);
};
export default AskReminders;
