import React, { useEffect, useState } from "react";
import { SettingsSubmenu } from "../../layout/subMenu";
import { DocuSign } from "./components";
import { useParams } from "react-router";
export const DocuSignSettings = (props) => {

 

  return (
    <>
    <SettingsSubmenu/>
    <DocuSign />
    </>
  );
};
