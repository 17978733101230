import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import DialogComponent from "../../components/Dialog";
import * as Yup from "yup";
import { getTranslation } from "../../heplers/translationHelper";
import { useEffect, useState } from "react";
import { Get } from "../../actions";
import { GET_AllContactList_URL } from "../../constants/apiUrls";
import { Autocomplete } from "@material-ui/lab";
export const ServiceProviderRepresentativeDialog = ({
  isOpen,
  serviceId,
  onClose,
  onSubmit,
}) => {
  const [contactList, setContactList] = useState([]);
  useEffect(() => {
    Get(
      {},
      GET_AllContactList_URL,
      null,
      (resp) => {
        setContactList(resp.data);
      },
      (error) => {}
    );
  }, []);

  const initialValues = {
    representativeRole: "",
    contactId: 0,
    serviceId: serviceId,
  };
  const basicValidationSchema = Yup.object().shape({
    representativeRole: Yup.string().required("Company is required"),
    contactId: Yup.number().required("ContactId is required"),
    serviceId: Yup.number().required("ServiceId is required"),
  });
  const defaultValue = initialValues;
  return (
    <DialogComponent
      title={getTranslation(
        "Add Service Provider Representative",
        "Ajouter un représentant du fournisseur de services",
        "Dienstanbietervertreter hinzufügen"
      )}
      fullWidth
      open={isOpen}
      onClose={onClose}
    >
      <Card>
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={defaultValue}
            validationSchema={basicValidationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              onSubmit(values, actions);
              actions.resetForm();
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Autocomplete
                    name="contactId"
                    options={contactList}
                    getOptionLabel={(option) =>
                      option.firstName
                    }
                    size="small"
                    onChange={(e, newContact) => {
                      setFieldValue("contactId", newContact?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation("Select Contact","Sélectionnez Contact","Wählen Sie Kontakt")}
                        variant="outlined"
                        error={touched.contactId && Boolean(errors.contactId)}
                      />
                    )}
                  />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="representativeRole"
                      label="Servide Provider Representative Role"
                      size="small"
                      variant="outlined"
                      {...getFieldProps("representativeRole")}
                      error={
                        touched.representativeRole &&
                        Boolean(errors.representativeRole)
                      }
                      helperText={
                        touched.representativeRole && errors.representativeRole
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <CardActions>
                  <Box
                    pr={1}
                    pb={1}
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ButtonWithLoading
                      title={getTranslation("Create", "Créer", "Schaffen")}
                      size="small"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    />
                  </Box>
                </CardActions>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </DialogComponent>
  );
};
