import { ReportTemplateSubmenu } from "../../../layout/subMenu/meeting"
import { ReportTemplateList } from "./components"

const ReportTemplateDashboard = () => {
    return(
        <>
            <ReportTemplateSubmenu/>
            <ReportTemplateList/>
        </>
    )
}
export default ReportTemplateDashboard