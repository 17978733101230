import { useParams } from "react-router";
import MeetingInformation from "../public/components/MeetingInformation";
import { useEffect } from "react";
import { Create, Get } from "../../actions";
import {
	Get_AllMeetingDocuments_URL,
	Get_CompanyInfo_URL,
	Get_Files_URL,
	Get_MeetingInfo_URL,
	Post_MeetingAcceptMinutes_URL,
	Post_MeetingDeclineMinutes_URL,
	Post_UploadMeetingDocument_URL,
} from "../../constants/apiUrls";
import { useState } from "react";
import { Box, CardContent, CardHeader, TextField, Typography } from "@material-ui/core";
import { getTranslation } from "../../heplers/translationHelper";
import CardComponent from "../../components/Card";
import { CompanyInformation } from "../public/components";
import { UploadFiles } from "../../components/Upload";
import { ManageAgendas } from "./component/manageAgendas";
import { MeetingsSubmenu } from "../../layout/subMenu";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { Save } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { ListAttendatns } from "./component/minutes";
import { MinutesProgressBar } from "./component";
import DialogComponent from "../../components/Dialog";

export const GenerateMinutesReport = () => {
	const { meetingId, managementCompanyId } = useParams();
	const [meetingInfo, setMeetingInfo] = useState({});
	const [companyInfo, setCompanyInfo] = useState({});
	const [meetingDocuments, setMeetingDocuments] = useState([]);
	const [submitingForReview, setSubmitingForReview] = useState(false);
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [declineComments, setDeclineComments] = useState("");

	useEffect(() => {
		Get(
			{
				meetingId: meetingId,
			},
			Get_MeetingInfo_URL,
			null,
			(resp) => {
				if (resp?.data?.minuteReportStatus > 2) {
					enqueueSnackbar(
						"Minutes report cannot be modified now as its submited for review.",
						{ variant: "info" }
					);
				}
				setMeetingInfo(resp?.data);
			},
			(error) => {}
		);

		Get(
			{
				meetingId: meetingId,
			},
			Get_CompanyInfo_URL,
			null,
			(resp) => {
				setCompanyInfo(resp?.data);
			},
			(error) => {}
		);
		Get(
			{
				meetingId: meetingId,
			},
			Get_Files_URL,
			null,
			(resp) => {
				setMeetingDocuments(resp?.data);
			},
			(error) => {}
		);
	}, [meetingId]);

	const handleSubmitForReview = () => {
		setSubmitingForReview(true);
		Create(
			{
				meetingId: meetingId,
				minuteReportStatus: meetingInfo?.minuteReportStatus,
			},
			Post_MeetingAcceptMinutes_URL,
			null,
			(resp) => {
				enqueueSnackbar("Minutes report submited for review.", {
					variant: "success",
				});
				setSubmitingForReview(false);
				// setMeetingInfo({
				// 	...meetingInfo,
				// 	minuteReportStatus: meetingInfo?.minuteReportStatus + 1,
				// });
				if (
					meetingInfo &&
					(meetingInfo.minuteReportStatus == 0 ||
						meetingInfo.minuteReportStatus == 1)
				) {
					setMeetingInfo({
						...meetingInfo,
						minuteReportStatus: 2,
					});
				} else {
					setMeetingInfo({
						...meetingInfo,
						minuteReportStatus: meetingInfo?.minuteReportStatus + 1,
					});
				}
			},
			(error) => {
				enqueueSnackbar(error?.data, { variant: "error" });
				setSubmitingForReview(false);
			}
		);
	};

	const handleDecline = () => {
		setSubmitingForReview(true);
		Create(
			{
				meetingId: meetingId,
				comments:declineComments
			},
			Post_MeetingDeclineMinutes_URL,
			null,
			(resp) => {
				setOpen(false)
				enqueueSnackbar("Minutes report declined.", {
					variant: "info",
				});
				setSubmitingForReview(false);
				setMeetingInfo({
					...meetingInfo,
					minuteReportStatus: 1,
				});
			},
			(error) => {
				enqueueSnackbar(error?.data, { variant: "error" });
				setSubmitingForReview(false);
			}
		);
	};

	return (
		<CardComponent
			style={{
				paddingTop: "30px",
				paddingLeft: "20px",
				paddingRight: "20px",
				paddingBottom: "30px",
			}}
		>
			<MeetingsSubmenu managementCompanyId={managementCompanyId} />
			<Typography variant="h4" align="center">
				{getTranslation("Minutes Report")}
			</Typography>
			<br />
			<br />

			{/* here treacker */}
			<MinutesProgressBar
				meetingInfo={meetingInfo}
				getTranslation={getTranslation}
			/>

			{/* here treacker closed */}
			<br />
			<br />
			<Box display="flex" alignItems={"flex-end"} justifyContent="flex-end">
			{meetingInfo?.minuteReportStatus === 2 ? 
			<ButtonWithLoading
					title={"Decline"}
					variant="contained"
					color="primary"
					loadingColor="white"
					loading={submitingForReview}
					onClick={() => setOpen(true)}
					disabled={meetingInfo?.minuteReportStatus > 2}
					style={{marginRight:"20px",background: "darkred", color: "white" }}
				/>
				:null}
				<ButtonWithLoading
					title={
						meetingInfo?.minuteReportStatus < 2 ? (
							<Typography>Submit for internal review</Typography>
						) : meetingInfo?.minuteReportStatus == 2 ? (
							<Typography>Submit to fund manager</Typography>
						) : (
							<Typography>Submitted</Typography>
						)
					}
					variant="contained"
					color="primary"
					loadingColor="white"
					loading={submitingForReview}
					onClick={() => handleSubmitForReview()}
					disabled={meetingInfo?.minuteReportStatus > 2}
				/>
			</Box>
			<br />
			<br />
			<MeetingInformation meetingInfo={meetingInfo} />

			<br />
			<br />
			<CompanyInformation companyInfo={companyInfo} />
			<br />
			<br />
			<CardComponent>
				<CardHeader
					title={getTranslation(
						"Uploaded Documents",
						"Uploaded Documents",
						"Uploaded Documents"
					)}
				/>
				<CardContent>
					<UploadFiles
						uploadedFiles={meetingDocuments}
						getFileName={(file) => {
							return file.fileName;
						}}
						onAddFile={(file) => {
							console.log(file);
						}}
						disabled
					/>
				</CardContent>
			</CardComponent>
			<br />
			<br />

			<ListAttendatns meetingId={meetingId} />
			<br />
			<br />
			<ManageAgendas
				meetingId={meetingId}
				disabled={meetingInfo?.minuteReportStatus > 1}
			/>
			<DialogComponent title="Decline" open={open} onClose={()=>setOpen(false)} fullWidth size='md' >
				<TextField label="Comments" variant="outlined" fullWidth multiline value={declineComments} onChange={(e)=>setDeclineComments(e.target.value)} minRows={10}/>
				<br/>
				<br/>
				<Box display="flex" justifyContent="center">
				<ButtonWithLoading
						title="Cancel"
						onClick={()=>setOpen(false)}
						loading={submitingForReview}
						variant="default"
						style={{marginRight:"20px",background: "darkred", color: "white" }}

					/>
					<ButtonWithLoading
						variant="contained"
						color="primary"
						title="Confirm"
						onClick={handleDecline}
						loading={submitingForReview}
					/>
				</Box>
			</DialogComponent>
		</CardComponent>
	);
};
