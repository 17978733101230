import { Form, Formik } from "formik";
import DialogComponent from "../../../../components/Dialog";
import CardComponent from "../../../../components/Card";
import {
  Box,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../heplers/translationHelper";
import * as Yup from "yup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Table from "../../../../components/table";
import React, { useState, useEffect } from "react";
import DeleteDialog from "../../../../components/DeleteDialog";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { EditButton } from "../../../../components/ButttonsWithIcons";
import {
  Post_Insert_Todo_URL,
  GET_AllContactList_URL,
  Get_All_Meeting_Todo,
} from "../../../../constants/apiUrls";
import { Create, Get } from "../../../../actions";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "../../../../heplers/format";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Cancel } from "@material-ui/icons";
import moment from "moment";
import { ro } from "date-fns/locale";

export const CreateTodoDialoge = ({
  counter,
  meetingId,
  rows,
  // open,
  // onClose,
  onSubmit11,
  onAdd,
  isSubmitting,
  onCancel,
  editPersonRepresentative,
  editId,
}) => {
  // const {meetingId} = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // const [dopen, setOpen] = useState(false); // state for delete dialog box
  // const [loadingToDo, setLaodingToDo] = useState(false);
  // const [rowws, setRowws] = useState([]);
  // const [deleteRow, setDeleteRow] = useState({});
  // const [submitting, setSubmitting] = useState(false);
  const [personInChargee, setPersonInCharge] = useState([]);
  const [loadingPersonInCharge, setLoadingPersonInCharge] = useState();
  const history = useNavigate();

  // Loading Data
  useEffect(() => {
    loadPersonInCharge();
    // loadTodos()
  }, []);

  console.log(editId);
  console.log(editPersonRepresentative);

  const loadPersonInCharge = async () => {
    setLoadingPersonInCharge(true);
    Get(
      {},
      GET_AllContactList_URL,
      history,
      (response) => {
        setLoadingPersonInCharge(false);
        setPersonInCharge(response.data);
      },
      (error) => {
        setLoadingPersonInCharge(false);
      }
    );
  };

  let itemid = "";
  if (editPersonRepresentative && editId) {
    itemid = editPersonRepresentative.id;
  }

  let uid = counter;

  const initialValues = {
    id: itemid,
    uuid: uid,
    personInChargeId: editPersonRepresentative
      ? editPersonRepresentative.personInChargeId
      : "",
    dueDate: editPersonRepresentative
      ? editPersonRepresentative.dueDate
      : moment().format("MM-DD-YYYY"),
    description: editPersonRepresentative
      ? editPersonRepresentative.description
      : "",
    meetingId: meetingId,
  };
  const basicValidationSchema = Yup.object().shape({
    personInChargeId: Yup.string().required("Field is required"),
    dueDate: Yup.date().required("Date is required"),
    description: Yup.string().required("Field is required "),
  });
  const defaultValue = initialValues;

  const getOptionLabel = (option) => {
    if (!option || !option.fullName) {
      return "";
    }

    return option.fullName;
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onAdd(values, actions);
          actions.setSubmitting(false);
          // actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form>
            <CardComponent>
              <CardContent>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Autocomplete
                        fullWidth
                        id="personInChargeId"
                        options={personInChargee}
                        getOptionLabel={getOptionLabel}
                        value={
                          personInChargee.find(
                            (option) => option.id === values.personInChargeId
                          ) || {}
                        }
                        onChange={(e, personIncharge) => {
                          setFieldValue("personInChargeId", personIncharge?.id);
                          setFieldValue(
                            "personInchargeName",
                            personIncharge?.fullName
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={getTranslation("Person in Charge")}
                            size="small"
                            variant="outlined"
                            name="personInCharge"
                            error={
                              touched.personInChargeId &&
                              Boolean(errors.personInChargeId)
                            }
                            helperText={
                              touched.personInChargeId &&
                              errors.personInChargeId
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          size="small"
                          id="Incorporation-Date-inline"
                          label={"Due date"}
                          autoOk
                          value={values.dueDate}
                          onChange={(value) => {
                            setFieldValue(
                              "dueDate",
                              moment(value).format("MM-DD-YYYY")
                            );
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "Date",
                          }}
                          required
                          error={touched.dueDate && Boolean(errors.dueDate)}
                          helperText={touched.dueDate && errors.dueDate}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label={getTranslation("Description")}
                        size="small"
                        variant="outlined"
                        name="description"
                        multiline
                        rows={4}
                        style={{ width: "200%" }}
                        {...getFieldProps("description")}
                        error={
                          touched.description && Boolean(errors.description)
                        }
                        helperText={touched.description && errors.description}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>

              <CardActions>
                <Box
                  width="97%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {editPersonRepresentative ? (
                    <IconButton onClick={onCancel}>
                      <Cancel />
                    </IconButton>
                  ) : null}
                  <ButtonWithLoading
                    style={{ marginRight: "15px" }}
                    title={editPersonRepresentative ? "Update" : "Insert"}
                    size="small"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                  {editId ? (
                    ""
                  ) : (
                    <ButtonWithLoading
                      title={"Submit"}
                      size="small"
                      variant="contained"
                      color="primary"
                      disabled={rows.length>0?false:true}
                      loading={isSubmitting}
                      onClick={onSubmit11}
                    />
                  )}
                </Box>
              </CardActions>
            </CardComponent>
          </Form>
        )}
      </Formik>
    </>
  );
};
