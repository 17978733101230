import React, { Suspense, Fragment, useState } from "react";
import { Route, Routes, Switch } from "react-router-dom";
// import SplashScreen from "./components/SplashScreen"
import Auth from "./components/Auth";
// import AuthGuard from "./components/AuthGuard";
//Website Layouts
import AppLayout from "./layout";
import PublicLayout from "./layout/public";
import { NotFound } from "./components/NotFound";

//Auth Views
import Login from "./views/auth/Login";
import ForgetPassword from "./views/auth/ForgetPassword";

//Account Views
import MyAccount from "./views/account/myAccount";
import UpgradeAccount from "./views/account/upgradeAccount";

//Company Views
import {
	CompanyList,
	CompanyBasic,
	CompanyShareholders,
	CompanyManagers,
	CompanyBoard,
} from "./views/company";

//Contact Views
import { Contacts, Details } from "./views/contacts";

//Asset Views
import {
	Assets,
	AssetNetAssetValue,
	FeeAgreement,
	Operation,
} from "./views/asset";

//Services Views
import { ManageService } from "./views/services";

//Funds Views
import { Funds } from "./views/funds";

//Management Companies Views
import {
	ManagementCompanies,
	ManagementCompanyFund,
	ManagementCompanyAsset,
} from "./views/managementCompany";

//Management User Views
import { UserManagement } from "./views/userManagement";
import { ManageCompanyService } from "./views/companyServices";

//Meeting Views
import {
	Meetings,
	RegularMeeting,
	RegularMeetingDetails,
	AdhocMeeting,
	CircularResolution,
	UpdateCircularResolution,
	SignAttorney,
	AuditLog,
	ToDos,
	GenerateMinutesReport,
	ReviewMinutes,
	ReviewMinutesDone,
	GenerateRegularMeetings,
	ManualSigningReviewMinutes,
	MeetingRoom,
} from "./views/meeting";

//Public Views
import { AskAgenda, Minutes, ReviewTodos } from "./views/public";
import { Comments } from "./views/public/invitationProcess/comments";
import { Settings } from "./views/settings/settings";
import { JoinMeeting } from "./views/public/invitationProcess/JoinMeeting";

import { CircularResolutionConfirmation } from "./views/public/circularResolution/circularResolutionConfirmation";
import { CircularResolutionDecision } from "./views/public/circularResolution/circularResolutionDecision";
import { TemplateList } from "./views/meeting/component/circularResolution/settings/templateList";
import { AgendaTemplateList } from "./views/meeting/component/regular/agendaTemplateSettings/agendaTemplateList";
import { AskForDocument } from "./views/public/askForDocument";
import { AutoGeneratedMeetings } from "./views/meeting/autoGeneratedMeetings";
import { MeetingApproval } from "./views/public/autoGeneratedMeetings/pendingApprovalMeetingList";
import { Calendar } from "./views/calender/calendar";
import { EmailTemplate, HolidayList } from "./views/settings/components";
import { ContactAvailabilityList } from "./views/contacts/component";
import {
	FormsDashboard,
	ReportTemplateDashboard,
	SectionsDashboard,
	TableDashboard,
} from "./views/reports";
import {
	ReportTemplateFormInfo,
	ReportTemplateTableInfo,
} from "./views/public/reports";
import { UpdateReport } from "./views/meeting/component/regular/attachReport";
import {
	PostDeadlineNotifyeeList,
	PublicReport,
	ReportDataCollection,
} from "./views/public/attachReports/dataCollection";
import { LegalFormList } from "./views/settings/legalFormList";
import { DocuSignSettings } from "./views/settings/docuSign";
import { NoResourceAvailble } from "./components/NoResourceAvaible";
import { ApproveMinutes } from "./components/ApproveMinutes";
import { MinutesAlreadyApproved } from "./components/MinutesAlreadyApproved";
////////////// V3.0 //////////////

const routesConfig = [
	{
		path: "/login",
		exact: true,
		component: () => <Login />,
	},
	{
		path: "/response/:message",
		exact: true,
		component: () => <NoResourceAvailble />,
	},
	{
		path: "/invalidData",
		exact: true,
		component: () => <NoResourceAvailble />,
	},
	{
		path: "/minutesApproved",
		exact: true,
		component: () => <ApproveMinutes />,
	},
	{
		path: "/alreadyApproved",
		exact: true,
		component: () => <MinutesAlreadyApproved />,
	},
	{
		path: "/forget_password",
		exact: true,
		component: () => <ForgetPassword />,
	},
	{
		path: "/public",
		layout: PublicLayout,
		//bootstrap: AuthGuard,
		childrens: [
			//           Meeting Invitation Process Public Screens
			{
				path: "/public/askagenda/:meetingId/:meetingMemberId",
				exact: true,
				component: () => <AskAgenda />,
			},
			{
				path: "/public/minutes/:meetingId/:teamMemberId",
				exact: true,
				component: () => <Minutes />,
			},
			{
				path: "/public/comments/:meetingId/:teamMemberId",
				exact: true,
				component: () => <Comments />,
			},
			{
				path: "/public/askfordocument/:meetingId/:meetingDocumentId",
				exact: true,
				component: () => <AskForDocument />,
			},
			{
				path: "/public/joinmeeting/:meetingId/:meetingMemberId",
				exact: true,
				component: () => <JoinMeeting />,
			},

			// added new routes for minutes report [huzefa]
			{
				path: "/public/meetings/reviewMinutesReport/:meetingId/:contactId",
				exact: true,
				component: () => <ReviewMinutes isMember={false} />,
			},

			{
				path: "/public/meetings/reviewMinutesReportByMember/:meetingId/:contactId",
				exact: true,
				component: () => <ReviewMinutes isMember={true} />,
			},
			{
				path: "/public/manualSigning/:dataPayload",
				exact: true,
				component: () => <ManualSigningReviewMinutes />,
			},

			//     Circular Resolution
			{
				path: "/public/circularResolutionConfirmation/:circularResolutionId",
				exact: true,
				component: () => <CircularResolutionConfirmation />,
			},
			{
				path: "/public/circularResolutionDecision/:circularResolutionId/:memberId",
				exact: true,
				component: () => <CircularResolutionDecision />,
			},

			//////// Just for testing MS 2.1
			{
				path: "/public/meetingapproval/:contactId",
				exact: true,
				component: () => <MeetingApproval />,
			},
			//////////// Report Template 3.0 //////////////
			{
				path: "/public/form",
				exact: true,
				component: () => <ReportTemplateFormInfo />,
			},
			{
				path: "/public/table",
				exact: true,
				component: () => <ReportTemplateTableInfo />,
			},
			{
				path: "/public/reviewTodos/:contactId",
				exact: true,
				component: () => <ReviewTodos />,
			},

			/////////////// Meeting 3.1 ///////////////
			{
				path: "/public/reportDetails/:meetingId",
				exact: true,
				component: () => <PublicReport />,
			},
			{
				path: "/public/reportFormDataCollection/:meetingId/:memberId",
				// path: "/public/reportDataCollection",
				exact: true,
				component: () => <ReportDataCollection isContact={true} />,
			},
			{
				path: "/public/reportFormDataCollectionByMember/:meetingId/:memberId",
				// path: "/public/reportDataCollection",
				exact: true,
				component: () => <ReportDataCollection isContact={false} />,
			},
			{
				path: "/public/notification",
				exact: true,
				component: () => <PostDeadlineNotifyeeList />,
			},

			{
				component: () => <NotFound />,
			},
		],
	},
	{
		path: "/",
		layout: AppLayout,
		guard: Auth,
		//bootstrap: AuthGuard,
		childrens: [
			{
				path: "/",
				exact: true,
				component: () => <MyAccount />,
			},
			{
				path: "/account/profile",
				exact: true,
				component: () => <MyAccount />,
			},
			{
				path: "/account/upgrade",
				exact: true,
				component: () => <UpgradeAccount />,
			},
			{
				path: "/auth/docusign/callback/",
				exact: true,
				component: () => <DocuSignSettings />,
			},

			{
				path: "/companies",
				exact: true,
				component: () => <CompanyList />,
			},
			{
				path: "/companies/:companyId/basic",
				exact: true,
				component: () => <CompanyBasic area="company" />,
			},
			{
				path: "/companies/:companyId/shareholders",
				exact: true,
				component: () => <CompanyShareholders />,
			},
			{
				path: "/companies/:companyId/managers",
				exact: true,
				component: () => <CompanyManagers area="company" />,
			},
			{
				path: "/companies/:companyId/board",
				exact: true,
				component: () => <CompanyBoard area="company" />,
			},
			{
				path: "/contacts",
				exact: true,
				component: () => <Contacts />,
			},
			{
				path: "/contacts/:id",
				exact: true,
				component: () => <Details />,
			},
			{
				path: "/contacts/:contactId/availability",
				exact: true,
				component: () => <ContactAvailabilityList />,
			},
			{
				path: "/assets/",
				exact: true,
				component: () => <Assets />,
			},
			{
				path: "/assets/:assetId/:companyId/basic",
				exact: true,
				component: () => <CompanyBasic area="asset" />,
			},
			{
				path: "/assets/:assetId/:companyId/shareholders",
				exact: true,
				component: () => <CompanyShareholders />,
			},
			{
				path: "/assets/:assetId/:companyId/managers",
				exact: true,
				component: () => <CompanyManagers area="asset" />,
			},
			{
				path: "/assets/:assetId/:companyId/board",
				exact: true,
				component: () => <CompanyBoard area="asset" />,
			},
			{
				path: "/assets/:assetId/:companyId/netAssetValue",
				exact: true,
				component: () => <AssetNetAssetValue />,
			},
			{
				path: "/assets/:assetId/:companyId/feeAgreement",
				exact: true,
				component: () => <FeeAgreement />,
			},
			{
				path: "/assets/:assetId/:companyId/operations",
				exact: true,
				component: () => <Operation />,
			},
			{
				path: "/funds/",
				exact: true,
				component: () => <Funds />,
			},
			{
				path: "/funds/:fundId/:companyId/basic",
				exact: true,
				component: () => <CompanyBasic area="asset" />,
			},
			{
				path: "/funds/:fundId/:companyId/shareholders",
				exact: true,
				component: () => <CompanyShareholders />,
			},
			{
				path: "/funds/:fundId/:companyId/managers",
				exact: true,
				component: () => <CompanyManagers area="asset" />,
			},
			{
				path: "/funds/:fundId/:companyId/board",
				exact: true,
				component: () => <CompanyBoard area="asset" />,
			},
			{
				path: "/funds/:fundId/:companyId/netAssetValue",
				exact: true,
				component: () => <AssetNetAssetValue />,
			},
			{
				path: "/funds/:fundId/:companyId/feeAgreement",
				exact: true,
				component: () => <FeeAgreement />,
			},
			{
				path: "/funds/:fundId/:companyId/operations",
				exact: true,
				component: () => <Operation />,
			},
			{
				path: "/managementCompanies/",
				exact: true,
				component: () => <ManagementCompanies />,
			},
			{
				path: "/managementCompanies/:managementCompanyId/:companyId/basic",
				exact: true,
				component: () => <CompanyBasic area="asset" />,
			},
			{
				path: "/managementCompanies/:managementCompanyId/:companyId/shareholders",
				exact: true,
				component: () => <CompanyShareholders />,
			},
			{
				path: "/managementCompanies/:managementCompanyId/:companyId/managers",
				exact: true,
				component: () => <CompanyManagers area="asset" />,
			},
			{
				path: "/managementCompanies/:managementCompanyId/:companyId/board",
				exact: true,
				component: () => <CompanyBoard area="asset" />,
			},
			{
				path: "/managementCompanies/:managementCompanyId/:companyId/feeAgreement",
				exact: true,
				component: () => <FeeAgreement />,
			},
			{
				path: "/managementCompanies/:managementCompanyId/:companyId/funds",
				exact: true,
				component: () => <ManagementCompanyFund />,
			},
			{
				path: "/managementCompanies/:managementCompanyId/:companyId/assets",
				exact: true,
				component: () => <ManagementCompanyAsset />,
			},
			{
				path: "/services/",
				exact: true,
				component: () => <ManageService />,
			},
			{
				path: "/companyServices/",
				exact: true,
				component: () => <ManageCompanyService />,
			},
			{
				path: "/userManagement/",
				exact: true,
				component: () => <UserManagement />,
			},
			{
				path: "/meetings/",
				exact: true,
				component: () => <Meetings />,
			},
			{
				path: "/meetings/:managementCompanyId/listtodos",
				exact: true,
				component: () => <ToDos />,
			},
			
			{
				path: "/meetings/autogenerated",
				exact: true,
				component: () => <AutoGeneratedMeetings />,
			},
			{
				path: "/meetings/:managementCompanyId/regular/",
				exact: true,
				component: () => <RegularMeeting />,
			},
			{
				path: "/meetings/:managementCompanyId/regular/generatenewRegularMeeting/:data",
				exact: true,
				component: () => <GenerateRegularMeetings />,
			},
			
			{
				path: "/meetings/:managementCompanyId/regular/:meetingId",
				exact: true,
				component: () => <RegularMeetingDetails />,
			},

			{
				path: "/meetings/:managementCompanyId/:meetingId/updatereport",
				exact: true,
				component: () => <UpdateReport />,
			},
			{
				path: "/meetings/:managementCompanyId/:meetingId/generateMinutesReport",
				exact: true,
				component: () => <GenerateMinutesReport />,
			},
			{
				path: "/meetings/:managementCompanyId/:meetingId/reviewMinutesReport",
				exact: true,
				component: () => <ReviewMinutes />,
			},
			{
				path: "/meetings/:managementCompanyId/:meetingId/reviewMinutesReport/response/:message",
				exact: true,
				component: () => <NoResourceAvailble />,
			},
			{
				path: "/meetings/:managementCompanyId/:meetingId/reviewMinutesDone",
				exact: true,
				component: () => <ReviewMinutesDone />,
			},
			{
				path: "/meetings/:managementCompanyId/adhoc/",
				exact: true,
				component: () => <AdhocMeeting />,
			},
			{
				path: "/meetings/:managementCompanyId/circularResolution/",
				exact: true,
				component: () => <CircularResolution />,
			},
			{
				path: "/meetings/:managementCompanyId/circularResolution/:circularResolutionId",
				exact: true,
				component: () => <UpdateCircularResolution />,
			},
			{
				path: "/meetings/:managementCompanyId/circularResolution/:circularResolutionId/auditLogs",
				exact: true,
				component: () => <AuditLog />,
			},
			{
				path: "/meetings/signAttorney/:powerOfAttorneyId/",
				exact: true,
				component: () => <SignAttorney />,
			},
			{
				path: "/settings/general",
				exact: true,
				component: () => <Settings />,
			},
			{
				path: "/settings/emailtemplate",
				exact: true,
				component: () => <EmailTemplate />,
			},
			{
				path: "/settings/crtemplates",
				exact: true,
				component: () => <TemplateList />,
			},
			{
				path: "/settings/agendatemplate",
				exact: true,
				component: () => <AgendaTemplateList />,
			},
			{
				path: "/settings/holidays",
				exact: true,
				component: () => <HolidayList />,
			},
			{
				path: "/settings/legalforms",
				exact: true,
				component: () => <LegalFormList />,
			},
			{
				path: "/settings/docusign",
				exact: true,
				component: () => <DocuSignSettings />,
			},
			{
				path: "/settings/meetingrooms",
				exact: true,
				// component: () => <Meetings />,
				component: () => <MeetingRoom />,
			},	
			{
				path: "/calendar",
				exact: true,
				component: () => <Calendar />,
			},

			////////////////////// Report Template V3.0 //////////////////////

			{
				path: "/reports/reportTemplates",
				exact: true,
				component: () => <ReportTemplateDashboard />,
			},
			{
				path: "/reports/form",
				exact: true,
				component: () => <FormsDashboard />,
			},
			{
				path: "/reports/table",
				exact: true,
				component: () => <TableDashboard />,
			},
			{
				path: "/reports/section",
				exact: true,
				component: () => <SectionsDashboard />,
			},

			{ component: () => <NotFound /> },
		],
	},

	{
		component: () => <NotFound />,
	},
];

const renderRoutes = (routes) => {
	return routes ? (
		<>
			{routes.map((route, i) => {
				const Guard = route.guard || Fragment;
				const Layout = route.layout || Fragment;
				const Component = route.component;
				const Bootstrap = route.bootstrap || Fragment;
				return (
					<Route
						path={route.childrens ? null : route.path}
						key={i}
						element={
							route.childrens ? (
								<Guard>
									{" "}
									<Bootstrap>
										{" "}
										<Layout />
									</Bootstrap>
								</Guard>
							) : (
								<Component />
							)
						}
					>
						{route.childrens ? renderRoutes(route.childrens) : null}
					</Route>
				);
			})}
		</>
	) : null;
};

const AppRoutes = () => {
	return renderRoutes(routesConfig);
};

export default AppRoutes;
