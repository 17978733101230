import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Logo } from "../../../components/Logo";
import { format } from "../../../heplers/format";
import { GenerateQRCode } from "../../../heplers/generateQR";

export const InvitationPrint = React.forwardRef(
  ({ meetingInfo, personalInfo, powerOfAttorney, powerOfAttorneyId }, ref) => {
    const [QrImage, setQRImage] = useState();
    useEffect(() => {
      if (powerOfAttorneyId) {
        GenerateQRCode(powerOfAttorneyId).then((Qrimage) => {
          setQRImage(Qrimage);
        });
      }
    }, [powerOfAttorneyId]);
    return (
      <div
        ref={ref}
        style={{
          background: "#FFFF",
          height: "313mm",
          padding: "30px",
        }}
      >
        <Box width="100%" display="flex" justifyContent="center">
          <div>
            <Logo />
            <Typography
              style={{
                fontFamily: "Sacramento",
                fontSize: "24px",
                fontStyle: "normal",
                textAlign: "center",
              }}
            >
              You are invited
            </Typography>
          </div>
        </Box>
        <Card
          style={{
            height: "auto",
            background: "#233044",
            color: "#FFFFFF",
            borderRadius: "8px",
          }}
        >
          <CardContent>
            <div style={{ float: "left" }}>
              <Typography variant="h6">Meeting Info</Typography>
              <div style={{ marginLeft: "10px" }}>
                <Typography variant="body1">{meetingInfo?.subject}</Typography>
                <Typography variant="body1">
                  {meetingInfo?.meetingTypeText}
                </Typography>
                <Typography variant="body1">
                  {format("date", meetingInfo?.meetingDate)}{" "}
                  {format("time", meetingInfo?.meetingTime)}
                </Typography>
                <Typography variant="body1">{meetingInfo?.address}</Typography>
              </div>
            </div>
            <div style={{ float: "right", marginRight: "10px" }}>
              <Typography variant="h6">Personal Info</Typography>
              <div style={{ marginLeft: "10px" }}>
                <Typography variant="body1">
                  {personalInfo?.fullName}
                </Typography>

                <Typography variant="body1">{personalInfo?.mail}</Typography>
                <Typography variant="body1">{personalInfo?.city}</Typography>
              </div>
            </div>
          </CardContent>
        </Card>
        <Grid
          style={{
            height: "150mm",
            right: "32px",
            background: "#FFFF",
          }}
        >
          <Typography
            style={{
              fontFamily: "Finger Paint",
              fontStyle: "normal",
              fontSize: "14px",
            }}
          >
            <br />
            Hi!
            <br />
            This letter is generated to transfer the power of attorney.
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            consectetur ultrices neque, at eleifend urna lobortis id. In hac
            habitasse platea dictumst. Nam ultrices, odio vitae efficitur
            pulvinar, nibh erat venenatis lacus, quis vestibulum nibh ipsum non
            lectus. Praesent egestas nulla id magna egestas, nec maximus nisi
            dapibus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Etiam consectetur ultrices neque, at eleifend urna lobortis id. In
            hac habitasse platea dictumst. Nam ultrices, odio vitae efficitur
            pulvinar, nibh erat venenatis lacus, quis vestibulum nibh ipsum non
            lectus. Praesent egestas nulla id magna egestas, nec maximus nisi
            dapibus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Etiam consectetur ultrices neque, at eleifend urna lobortis id. In
            hac habitasse platea dictumst. Nam ultrices, odio vitae efficitur
            pulvinar, nibh erat venenatis lacus, quis vestibulum nibh ipsum non
            lectus. Praesent egestas nulla id magna egestas, nec maximus nisi
            dapibus.
          </Typography>
        </Grid>
        <Card
          style={{
            height: "auto",
            background: "#233044",
            color: "#FFFFFF",
            borderRadius: "8px",
          }}
        >
          <CardContent>
            <Typography
              style={{
                fontSize: "14px",
              }}
            >
              I am glad to transfer the attorney to &ensp;{" "}
              <Typography variant="h6" style={{ display: "inline" }}>
                {powerOfAttorney?.firstName + " " + powerOfAttorney?.lastName}
              </Typography>
              &ensp;he will bejoining the meeting on my behaf and make decission
              according to the scenerio
            </Typography>
          </CardContent>
        </Card>
        <Grid
          container
          spacing={6}
          style={{
            margin: "25px",
            borderRadius: "8px",
          }}
        >
          <Grid
            container
            spacing={6}
            style={{
              margin: "25px",
              borderRadius: "8px",
            }}
          >
            <Grid item xs={3} sm={6} md={6}>
              <Typography>
                --------------------
                <h4>&emsp;Sign.</h4>
                <Typography style={{ fontSize: "9px" }}>
                  Please Sign the document and upload.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <br />

              <Typography style={{ fontSize: "10px" }}>
                This QR code is system generated and is only for Ceres employes
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <div>
                {" "}
                {QrImage ? (
                  <img
                    src={QrImage}
                    alt="Image"
                    style={{ width: "74px", height: "74px" }}
                  />
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <div>
              {" "}
              {QrImage ? (
                <img
                  src={QrImage}
                  alt="Image"
                  style={{ width: "100px", height: "100px" }}
                />
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
);
