import MeetingInformation from "../../components/MeetingInformation"
import FillInfo from "./FillingInfo";

const TableInfo = ()=>{
    return(
        <>
        <MeetingInformation/>
        <br/>
        <br/>
        <FillInfo/>
        </>
    )
}
export default TableInfo;
