import React, { useState, useEffect } from "react";
import Table from "../../../../../components/table";

import { useSnackbar } from "notistack";
import { Get, Create } from "../../../../../actions";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { AddAgendaTemplate } from "./addAgendaTemplate";
import DeleteDialog from "../../../../../components/DeleteDialog";
import {
  Get_AllAgendaTemplateItems_URL,
  Post_DeleteAgendaTemplateItem_URL,
  Post_InsertAgendaTemplateItem_URL,
} from "../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { SettingsSubmenu } from "../../../../../layout/subMenu";

const columns = [
  {
    id: "subject",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Subject","Sujette","Fach"),
  },
  {
    id: "comments",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Comments","commentaires","commentaires"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions","Actions","Aktionen"),
  },
];

export const AgendaTemplateList = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [agendaTemplateId, setAgendaTemplateId] = useState("");
  const [agendaTemplateList, setAgendaTemplateList] = useState([]);
  const [agendaTemplateListLoading, setAgendaTemplateListLoading] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  const [editItem,setEditItem]=useState(null);
  /////////////////// Handlers ////////////
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearchAgendaTemplate = (searchString) => {
    setSearchString(searchString);
  };

  const handleOpenDeleteDialog = (deleteItem) => {
    setOpenDelete(true);
    setDeleteRow(deleteItem);
  };
  const handleEditAgendaTemplate = (row) => {
    setEditItem(row)
  };
  ////////////////////// delete /////////////////////////
  const onDelete = async (id) => {
    setDeleteProgress(true);
    await Create(
      {
        ids: [deleteRow.id],
      },
      Post_DeleteAgendaTemplateItem_URL,
      null,
      (resp) => {
        loadAgendaTemplateListItems(agendaTemplateId);
        setDeleteProgress(false);
        setOpenDelete(false);
        enqueueSnackbar("Template Deleted", { variant: "success" });
      },
      (error) => {
        enqueueSnackbar("Template not deleted", { variant: "error" });
        setDeleteProgress(false);
      }
    );
  };

  //////////////////////

  const handleSubmitAgendaTemplate = async (values, actions) => {
    values.agendaTemplateId = agendaTemplateId;
    await Create(
      values,
      Post_InsertAgendaTemplateItem_URL,
      null,
      (resp) => {
        enqueueSnackbar("Template Added", { variant: "success" });
        loadAgendaTemplateListItems(agendaTemplateId);
        actions.resetForm();
      },
      (error) => {
        enqueueSnackbar("Template not Added", { variant: "error" });
      }
    );
  };

  /////////////////// Get Agenda Template w.r.t selected Agenda Id ////////////
  // useEffect(()=>{
  //   loadAgendaTemplateListItems({agendaTemplateId:agendaTemplateId,page, rowsPerPage, searchString})
  // },[agendaTemplateId,page, rowsPerPage, searchString]);

  const loadAgendaTemplateListItems = (id) => {
    setAgendaTemplateListLoading(true);
    // setAgendaTemplateId(id);
    setPage(0);
    // setRowsPerPage(10)
    setSearchString("");
    Get(
      { agendaTemplateId: id, page: 0, rowsPerPage, searchString: "" },
      Get_AllAgendaTemplateItems_URL,
      null,
      (resp) => {
        setAgendaTemplateListLoading(false);
        setAgendaTemplateList(resp?.data);
      },
      (error) => {
        enqueueSnackbar(
          "Can't load Agenda Template Items curruntly. Try again",
          { variant: "error" }
        );
      }
    );
  };
  return (
    <div>
      <SettingsSubmenu />
      <Card>
        <CardHeader
          title={getTranslation(
            "Agenda Template",
            "Modèle d'ordre du jour",
            "Agenda-Vorlage"
          )}
        />
        <CardContent>
          <AddAgendaTemplate
            onTemplateSelected={(template) => {
              setAgendaTemplateId(template?.id);
              loadAgendaTemplateListItems(template?.id);
            }}
            onSubmit={handleSubmitAgendaTemplate}
            editItem={editItem}
          />
          {agendaTemplateId ? (
            <Table
              auto
              loading={agendaTemplateListLoading}
              colums={columns}
              sortBy="subject"
              rows={agendaTemplateList}
              count={agendaTemplateList.length}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={(event, newPage) =>
                handleChangePage(event, newPage)
              }
              handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
              // viewEdit
              // onEdit={(row) => handleEditAgendaTemplate(row)}
              viewDelete
              onDelete={(row) => handleOpenDeleteDialog(row)}
              viewSearch
              onSearch={(searchString) =>
                handleSearchAgendaTemplate(searchString)
              }
            />
          ) : null}

          <DeleteDialog
            open={openDelete}
            deleting={deleteProgress}
            title={getTranslation(
              "Delete Template",
              "Supprimer le modèle",
              "Vorlage löschen"
            )}
            onClose={() => setOpenDelete(false)}
            onSubmit={(e) => {
              onDelete();
            }}
          />
        </CardContent>
      </Card>
    </div>
  );
};
