import React, { useState, useEffect } from "react";
import { Box, Paper, TextField, Typography } from "@material-ui/core";
import Table from "../../../../components/table";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../../../actions";
import {
	Get_GetAllManagementCompanyFundOperations_URL,
	Post_DeleteOperation_URL,
	Post_InsertFundOperation_URL,
	Post_ManagementCompanyDeleteOperation_URL,
	Post_UpdateFundOperation_URL,
} from "../../../../constants/apiUrls";
import { CreateFundOperation, CreateManagementCompanyFund } from "./index";
import { getTranslation } from "../../../../heplers/translationHelper";
import { format } from "../../../../heplers/format";

const FundOperation = () => {
	const columns = [
		{
			id: "fundTitle",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Fund Title", "Titre du fonds", "Titel des Fonds"),
		},
		{
			id: "amountIn",
			numeric: true,
			format: "number",
			disablePadding: true,
			label: getTranslation("Amount In", "Montant en", "Betrag in"),
      align:'right',
			format:'number'
			},
		{
			id: "amountOut",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Amount Out", "Montant sortant", "Betrag aus"),
      align:'right',
			format:'number'
		},
		{
			id: "paymentTypeText",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Payment Type", "Type de paiement", "Zahlungsart"),
		},
		{
			id: "interestRate",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Interest Rate", "Taux d'intérêt", "Zinsrate"),
      align:'right',
			format:'number'
		},
		{
			id: "date",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Date", "Date", "Datum"),
			format: "date",
		},
		{
			id: "endDate",
			numeric: true,
			disablePadding: true,
			label: getTranslation("End Date", "Date de fin", "Endtermin"),
			format: "date",
		},
		{
			id: "actions",
			numeric: true,
			disablePadding: false,
			align: "right",
			label: getTranslation("Actions", "Actions", "Aktionen"),
		},
	];

	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [submitting, setSubmitting] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	let spvDetailFundsOperations = localStorage.getItem(
		"spvDetailFundsOperations"
	);
	if (spvDetailFundsOperations == null) {
		localStorage.setItem("spvDetailFundsOperations", 10);
		spvDetailFundsOperations = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvDetailFundsOperations);
	const [rows, setRows] = useState([]);
	const [editOperation, setEditOperation] = useState(null);

	const [
		loadingManagementCompanyFundOperations,
		setloadingManagementCompanyFundOperations,
	] = useState(false);
	const { managementCompanyId } = useParams();
	useEffect(() => {
		loadManagementCompanyFunds();
	}, [page, rowsPerPage, searchString]);
	const loadManagementCompanyFunds = () => {
		setloadingManagementCompanyFundOperations(true);
		Get(
			{
				managementCompanyId: managementCompanyId,
				searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_GetAllManagementCompanyFundOperations_URL,
			history,
			(resp) => {
				setRows(resp.data);
				setloadingManagementCompanyFundOperations(false);
			},
			(error) => {}
		);
	};
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvDetailFundsOperations", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleSearchManagementCompanyFund = (searchString) => {
		setSearchString(searchString);
	};
	const handleCreateFundOperation = (values, actions) => {
		setSubmitting(true);
		Create(
			{
				...values,
				FundManagementCompanyId: managementCompanyId,
			},
			editOperation
				? Post_UpdateFundOperation_URL
				: Post_InsertFundOperation_URL,
			history,
			(response) => {
				editOperation
					? enqueueSnackbar(
							getTranslation(
								"Operation Updated",
								"Opération mise à jour",
								"Operation aktualisiert"
							),
							{ variant: "success" }
					  )
					: enqueueSnackbar(
							getTranslation(
								"Operation Created",
								"Opération créée",
								"Operation erstellt"
							),
							{ variant: "success" }
					  );
				setSubmitting(false);
				setEditOperation(null);
				loadManagementCompanyFunds();
			},
			(error) => {
				setSubmitting(false);
				enqueueSnackbar(error.data, {
					variant: "error",
				});
			}
		);
	};

	const handleDeleteFundOperation = (row) => {
		Create(
			{
				id: row.id,
			},
			Post_ManagementCompanyDeleteOperation_URL,
			null,
			(resp) => {
				loadManagementCompanyFunds();
				enqueueSnackbar(
					getTranslation(
						"Fund Operation Deleted",
						"Opération de fonds supprimée",
						"Fondsoperation gelöscht"
					),
					{ variant: "success" }
				);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Something went wrong",
						"Quelque chose a mal tourné",
						"Irgendwas ist schief gelaufen"
					),
					{ variant: "error" }
				);
			}
		);
	};
	return (
		<div>
			{/* <CreateFundOperation onSubmit={handleCreateFundOperation} /> */}
			<CreateManagementCompanyFund
				onSubmit={handleCreateFundOperation}
				editOperation={editOperation}
				isSubmitting={submitting}
			/>
			<Table
				dense
				loading={loadingManagementCompanyFundOperations}
				title={getTranslation("Operations", "Opérations", "Operationen")}
				colums={columns}
				sortBy="fundTitle"
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => setEditOperation(row)}
				viewSearch
				onSearch={(searchString) =>
					handleSearchManagementCompanyFund(searchString)
				}
				viewDelete
				onDelete={(row) => handleDeleteFundOperation(row)}
			/>
		</div>
	);
};

export default FundOperation;
