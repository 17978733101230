import DialogComponent from "../../../../../../components/Dialog";
import { Formik, Form } from "formik";
import { TextField, Box } from "@material-ui/core";
import * as Yup from "yup";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
const NotificationDialog = ({ open, onSubmit, onClose }) => {
  const initialValues = {
    message: "",
  };
  const basicValidationSchema = Yup.object().shape({
    message: Yup.string().required("The message is required"),
  });
  return (
    <DialogComponent
      fullWidth
      open={open}
      onClose={onClose}
      title="Notification"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true)
          onSubmit(values, actions);
        }}
      >
        {({
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          handleSubmit,
          getFieldProps,
          isSubmitting
        }) => (
          <Form>
            <br />

            <TextField
              fullWidth
              label="Message"
              variant="outlined"
              multiline
              {...getFieldProps("message")}
              error={touched.message && Boolean(errors.message)}
              helperText={touched.message && errors.message}
              rows={8}
            />
            <Box
              m={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonWithLoading
                title="Send"
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                size="small"
                loading={isSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </DialogComponent>
  );
};
export default NotificationDialog;
