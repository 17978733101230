import { ReportTemplateSubmenu } from "../../../layout/subMenu/meeting";
import { FormList } from "./components";

const FormsDashboard = () => {
  return (
    <>
      <ReportTemplateSubmenu />
      <FormList/>
    </>
  );
};
export default FormsDashboard;