

export const savePdf = async (filename,content,footer,header,open,callback) => {
    var XML = new XMLSerializer();
    const styleContent = getStyleTags();
    

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            htmlBody : `<style>${styleContent}</style>` + XML.serializeToString(content),
            htmlHeader:header?XML.serializeToString(header):"",
            htmlFooter:footer ? XML.serializeToString(footer):"",
            fileName : filename,
            styleSheets:getCSSFiles()
        })
    };
    fetch("https://appmanagerapi.impact-soft.com/FileManager/GeneratePdf",requestOptions).then(response=>{
        return response.blob();
    }).then(blob => {
        callback(blob)
        if (open) {
            var w = window.open("", "_blank");
            var file = window.URL.createObjectURL(blob);
            w.location.assign(file);
        }
     })
     
};
function getCSSFiles() {
    const links = document.querySelectorAll('link[rel="stylesheet"]');
    const cssFiles = [];
    links.forEach(link => {
      cssFiles.push(link.getAttribute('href'));
    });
    
    return cssFiles;
  }
  function getStyleTags() {
    // const styleTags = document.querySelectorAll('style');
    // // return styleTags;
    // return Array.from(styleTags).map(tag => tag.innerHTML);

    return `/* Card */
    .MuiCardContent-root {
      padding: 16px;
    }
    .MuiCardContent-root:last-child {
      padding-bottom: 24px;
    }
    
    .MuiCardHeader-root {
      display: flex;
      padding: 16px;
      align-items: center;
    }
    .MuiCardHeader-avatar {
      flex: 0 0 auto;
      margin-right: 16px;
    }
    .MuiCardHeader-action {
      flex: 0 0 auto;
      align-self: flex-start;
      margin-top: -8px;
      margin-right: -8px;
    }
    .MuiCardHeader-content {
      flex: 1 1 auto;
    }
    
    /* Card End*/
    
    
    /* Typography */
    .MuiTypography-root {
      margin: 0;
    }
    .MuiTypography-body2 {
      font-size: 0.875rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.43;
      letter-spacing: 0.01071em;
    }
    .MuiTypography-body1 {
      font-size: 12px;
      font-style: normal;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: normal;
      line-height: 2;
      letter-spacing: 0.00938em;
    }
    .MuiTypography-caption {
      font-size: 0.75rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.66;
      letter-spacing: 0.03333em;
    }
    .MuiTypography-button {
      font-size: 0.875rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
    }
    .MuiTypography-h1 {
      font-size: 35px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.167;
      letter-spacing: -0.24px;
    }
    .MuiTypography-h2 {
      font-size: 29px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: -0.24px;
    }
    .MuiTypography-h3 {
      font-size: 24px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.167;
      letter-spacing: -0.06px;
    }
    .MuiTypography-h4 {
      font-size: 20px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.235;
      letter-spacing: -0.06px;
    }
    .MuiTypography-h5 {
      font-size: 18px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.334;
      letter-spacing: -0.05px;
    }
    .MuiTypography-h6 {
      font-size: 14px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: -0.05px;
    }
    .MuiTypography-subtitle1 {
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.75;
      letter-spacing: 0.00938em;
    }
    .MuiTypography-subtitle2 {
      opacity: 60%;
      font-size: 12px;
      font-style: normal;
      text-align: left;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: normal;
      line-height: 2;
      letter-spacing: 0.00714em;
    }
    .MuiTypography-overline {
      font-size: 12px;
      font-style: normal;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: normal;
      line-height: 2;
      margin-left: 5px;
      margin-right: 5px;
      letter-spacing: 0.08333em;
      text-transform: uppercase;
    }
    .MuiTypography-srOnly {
      width: 1px;
      height: 1px;
      overflow: hidden;
      position: absolute;
    }
    .MuiTypography-alignLeft {
      text-align: left;
    }
    .MuiTypography-alignCenter {
      text-align: center;
    }
    .MuiTypography-alignRight {
      text-align: right;
    }
    .MuiTypography-alignJustify {
      text-align: justify;
    }
    .MuiTypography-noWrap {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .MuiTypography-gutterBottom {
      margin-bottom: 0.35em;
    }
    .MuiTypography-paragraph {
      margin-bottom: 16px;
    }
    .MuiTypography-colorInherit {
      color: inherit;
    }
    .MuiTypography-colorPrimary {
      color: #233044;
    }
    .MuiTypography-colorSecondary {
      color: #333;
    }
    .MuiTypography-colorTextPrimary {
      color: #263238;
    }
    .MuiTypography-colorTextSecondary {
      color: #546e7a;
    }
    .MuiTypography-colorError {
      color: #f44336;
    }
    .MuiTypography-displayInline {
      display: inline;
    }
    .MuiTypography-displayBlock {
      display: block;
    }
    .watermark
    {
     position:fixed;
     
     opacity:0.2;
     z-index:99;
     color:black;
     left: 50%;
        transform: translate(-50%, 0);
        top:50%;
        font-size:80px;
        transform: rotate(-40deg);
        transform-origin: right, top;
        -ms-transform: rotate(-40deg);
        -ms-transform-origin:right, top;
        -webkit-transform: rotate(-40deg);
        -webkit-transform-origin:right, top;
    }
    .top-watermark
    {
     position:fixed;
     opacity:0.2;
     z-index:99;
     color:black;
     left: 50%;
        transform: translate(-50%, 0);
        top:0%;
            font-size:80px;
        transform: rotate(-40deg);
        transform-origin: right, top;
        -ms-transform: rotate(-40deg);
        -ms-transform-origin:right, top;
        -webkit-transform: rotate(-40deg);
        -webkit-transform-origin:right, top;
        
    }
    .bottom-watermark
    {
     position:fixed;
      opacity:0.2;
     z-index:99;
     color:black;
     left: 50%;
        transform: translate(-50%, 0);
        bottom:0%;
            font-size:80px;
        transform: rotate(-40deg);
        transform-origin: right, top;
        -ms-transform: rotate(-40deg);
        -ms-transform-origin:right, top;
        -webkit-transform: rotate(-40deg);
        -webkit-transform-origin:right, top;
    }
    `
}