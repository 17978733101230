import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
} from "@material-ui/core";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import Card from "../../../components/Card";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { useSnackbar } from "notistack";

import { Create, Get } from "../../../actions";
import {
  GET_USER_PROFILE_URL,
  Post_UpdateUSER_PROFILE_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
const PersonalInfo = () => {
  const [personalInfo, setPersonalInfo] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    Get(
      {},
      GET_USER_PROFILE_URL,
      history,
      (resp) => {
        setPersonalInfo(resp.data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  const onSubmit = (values, actions) => {
    Create(
      values,
      Post_UpdateUSER_PROFILE_URL,
      history,
      (resp) => {
        enqueueSnackbar("Profile updated", { variant: "success" });
        actions.setSubmitting(false);
      },
      (error) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        actions.setSubmitting(false);
      }
    );
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
  };

  const basicValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    userName: Yup.string().required("Username is required"),
  });
  const defaultValue = loading ? initialValues : personalInfo;
  return (
    <Card title={getTranslation("Update Personal Information")}>
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          getFieldProps,
        }) => (
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label={getTranslation("First Name")}
                    size="small"
                    variant="outlined"
                    name="firstName"
                    {...getFieldProps("firstName")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label={getTranslation("Last Name")}
                    size="small"
                    variant="outlined"
                    name="lastName"
                    {...getFieldProps("lastName")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label={getTranslation("Email")}
                    size="small"
                    variant="outlined"
                    name="email"
                    {...getFieldProps("email")}
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label={getTranslation("Username")}
                    size="small"
                    variant="outlined"
                    name="userName"
                    disabled
                    {...getFieldProps("userName")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <ButtonWithLoading
                  title={getTranslation("Update")}
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default PersonalInfo;
