import { Grid, Typography } from "@material-ui/core";
import {
  CreateTodoDialoge,
  TodoList,
} from "./minutes";
import { useState, useEffect } from "react";
import {
  Post_Insert_Todo_URL,
  GET_AllContactList_URL,
  Get_All_Meeting_Todo,
  Get_Todo_List,
  Update_ToDo_ListItem,
  Delete_Todo_ListItem,
} from "../../../constants/apiUrls";
import { Create, Get } from "../../../actions";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { getTranslation } from "../../../heplers/translationHelper";
import DialogComponent from "../../../components/Dialog";

export const ManageTodos = ({
  meetingId,
  open,
  onClose,
  onUpdateTodoId,
  editId,
  clearEid,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [loadingToDo, setLaodingToDo] = useState(false);
  const [rowws, setRowws] = useState([]);
  const [editPersonRepresentative, setEditPersonRepresentative] = useState();
  const [counter, setCounter] = useState(1);

  // Loading Data
  useEffect(() => {
    // loadTodos()

    if (editId) {
      loadTodos();
    }
  }, [editId]);

  // function for closing dialoge and makeing rows array empty
  const onclose1 = () => {
    onClose();
    setRowws([]);
    setEditPersonRepresentative(null);
  };

  const loadTodos = async () => {
    setLaodingToDo(true);
    Get(
      {
        toDoId: editId,
      },
      Get_Todo_List,
      history,
      (response) => {
        setLaodingToDo(false);
        setRowws(response.data);
        console.log(rowws);
      },
      (error) => {
        setLaodingToDo(false);
      }
    );
  };

  const handleCreateShareholder = async () => {
    const requestBody = {
      meetingId: meetingId,
      toDoListItems: rowws,
    };

    // setSubmitting(true);
    Create(
      requestBody,
      Post_Insert_Todo_URL,
      history,
      (resp) => {
        // setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "ToDo List Added",
            "Liste de tâches ajoutée",
            "ToDo-Liste hinzugefügt"
          ),
          {
            variant: "success",
          }
        );
        onUpdateTodoId(resp.data);
        setEditPersonRepresentative(null);
        setRowws([]);
        onClose();
        //console.log(resp.data)
      },
      (error) => {
        try {
          setSubmitting(false);
          enqueueSnackbar(error?.data, { variant: "error" });
        } catch (error) {
          enqueueSnackbar("Error Submitting shareholder action", {
            variant: "error",
          });
        }
      }
    );
  };

  const handleEditPersonRepresentative = (row) => {
    setEditPersonRepresentative(row);
  };

  const handleAddRow = (item) => {
    if (editId) {
      console.log("here edit api");
      item.toDoListId = editId;
      console.log(item);

      Create(
        item,
        Update_ToDo_ListItem,
        history,
        (resp) => {
          setSubmitting(false);
          enqueueSnackbar(
            getTranslation(
              "Updated ToDo List Item",
              "Élément de liste de tâches mis à jour",
              "Aktualisierter ToDo-Listeneintrag"
            ),
            {
              variant: "success",
            }
          );
          setEditPersonRepresentative(null);
          onClose();
          //console.log(resp.data)
        },
        (error) => {
          try {
            setSubmitting(false);
            enqueueSnackbar(error?.data, { variant: "error" });
          } catch (error) {
            enqueueSnackbar("Error Editing in TodoList Item action", {
              variant: "error",
            });
          }
        }
      );
    } else {
      if (editPersonRepresentative) {
        console.log(item.uuid - 1);
        console.log(rowws);

        const updatedRows = rowws.map((row) => {
          if (row.uuid === item.uuid - 1) {
            console.log("inside match");
            return {
              ...row,
              description: item.description,
              dueDate: item.dueDate,
              meetingId: item.meetingId,
              personInChargeId: item.personInChargeId,
              personInchargeName: item.personInchargeName,
            };
          }
          return row;
        });

        setRowws(updatedRows);
      }
      if (!editPersonRepresentative) {
        setRowws([...rowws, item]);
        setCounter((prevCounter) => prevCounter + 1);
        console.log("conflict");
      }
    }
  };

  const handleDelete = (id) => {
    Create(
      {
        id: id,
      },
      Delete_Todo_ListItem,
      history,
      (resp) => {
        setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Delete ToDo List Item",
            "Supprimer l'élément de la liste des tâches",
            "ToDo-Listeneintrag löschen"
          ),
          {
            variant: "success",
          }
        );
        setEditPersonRepresentative(null);
        onClose();
      },
      (error) => {
        try {
          setSubmitting(false);
          enqueueSnackbar(error?.data, { variant: "error" });
        } catch (error) {
          enqueueSnackbar("Error Editing in TodoList Item action", {
            variant: "error",
          });
        }
      }
    );
  };

  return (
    <>
      <DialogComponent
        fullWidth={true}
        open={open}
        onClose={onclose1}
        title="New To Do"
      >
        <CreateTodoDialoge
          counter={counter}
          rows={rowws}
          // setCounter={setCounter}
          meetingId={meetingId}
          onSubmit11={handleCreateShareholder}
          onAdd={handleAddRow}
          isSubmitting={submitting}
          onCancel={() => setEditPersonRepresentative(null)}
          editPersonRepresentative={editPersonRepresentative}
          editId={editId}
        />

        <TodoList
          editId={editId}
          onDelete={handleDelete}
          rowws={rowws}
          setRowws={setRowws}
          loadingToDo={loadingToDo}
          viewEdit
          onEdit={(row) => {
            handleEditPersonRepresentative(row);
          }}
        />
      </DialogComponent>
    </>
  );
};
