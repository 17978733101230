import { useParams } from "react-router";

import { useEffect, useState } from "react";

import {
	Container,
	CssBaseline,
	Typography,
	TextField,
	Box,
	Grid,
	CircularProgress,
} from "@material-ui/core";
import { Formik } from "formik";
import { Link, useLocation, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useStyles from "../../assests/styles/views/auth/login";
import { Logo } from "../../components/Logo";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { getTranslation } from "../../heplers/translationHelper";
import Copyright from "../../utils/copyRightUtils";
import { userLogin } from "../../actions";
import checkResponse from "../../heplers/responseHelper";
import { useSnackbar } from "notistack";
import {
	scanCircularResolution,
	scanMinuteReport,
} from "../scanner/operations";

import {
	circularResolutionType,
	minuteReportByFundManagerType,
} from "../scanner/scannerTypes";
import { SERVER_URL } from "../../constants";
export const ManualSigningReviewMinutes = () => {
	const { dataPayload } = useParams();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	console.log("bbb", dataPayload);
	const [payloadData, setPayloadData] = useState();
	const [scanning, setScaning] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		if (dataPayload) {
			// Decode base64 data
			const decodedData = atob(dataPayload);

			setPayloadData(decodedData);
		}
	}, [dataPayload]);

	const handleScanDocument = async (res, result) => {
		console.log(res.data.accessToken);
		console.log(payloadData);
		const data = JSON.parse(payloadData);
		console.log("gaghfdghsa", data);

		if (data) {
			enqueueSnackbar("We read your QR Code sending for processing..", {
				variant: "info",
			});
			setScaning(true);

			console.log("chiecking stayis", data?.value?.isMember);

			switch (data.type) {
				case circularResolutionType:
					fetch(
						`${SERVER_URL}Meeting/UpdateMemberDecision`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${res.data.accessToken}`,
							},
							body: JSON.stringify(data.value),
						}
					).then((resp)=>{
						console.log("ressfsdfsddf", resp.ok);
						if (resp.ok) {
							return resp;
						  }
						  throw new Error('Something went wrong');

					}).then((resp)=>{

						navigate("/response/"+getTranslation("Circular Resolution is marked as signed."));
						setScaning(false);
					})						
					.catch((eer)=>{
						console.log("ressfsdfsddf error", eer);
						navigate("/response/"+getTranslation("We are unable to sign your document, it is may be due to wrong url or document is already signed."));

					})

					break;
				case minuteReportByFundManagerType:
					try {
						let url = data?.value?.isMember
							? `${SERVER_URL}Meeting/AcceptMemberMinutes`
							: `${SERVER_URL}Meeting/AcceptMinutes`;
						fetch(url, {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${res.data.accessToken}`,
							},
							body: JSON.stringify(data.value),
						}).then(resp=>{
							if (resp.ok) {
								return resp;
							  }
							  throw new Error('Something went wrong');
						}).then((resp)=>{
							console.log("ressfsdfsddf", resp);
	
							navigate("/response/"+getTranslation("Minute Report is marked as signed."));
							setScaning(false);
						})						
						.catch(()=>{
							navigate("/response/"+getTranslation("We are unable to sign your document, it is may be due to wrong url or document is already signed."));
	
						});
						// const apiData = await apiRespone.json();
						// console.log("API Response:", apiData);
						// alert("signed");
						// navigate("/minutesApproved");
						// setScaning(false);

						//actions.setSubmitting(false);
					} catch (error) {
						console.log("Error:", error.data);
						setScaning(false);
						//actions.setSubmitting(false);
					}
					break;
				default:
			}
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<div className={classes.logo}>
					<Logo />
				</div>
				{scanning ? (
					<CircularProgress />
				) : (
					<>
						<Typography component="h5" variant="h2" color="secondary">
							{getTranslation(
								"Kindly Enter Your Aureto Credentials To Verify your Identity",
								"Kindly Enter Your Aureto Credentials To Verify your Identity",
								"Kindly Enter Your Aureto Credentials To Verify your Identity"
							)}
						</Typography>
						<Formik
							initialValues={{
								email: "",
								password: "",
							}}
							validationSchema={Yup.object().shape({
								email: Yup.string()
									.required("Email is required")
									.email("Please enter a valid Email"),
								password: Yup.string().required("Password is required"),
							})}
							onSubmit={(values, actions) => {
								console.log(values);
								dispatch(userLogin(values)).then((res) => {
									const errors = checkResponse(res);
									if (!errors) {
										console.log("res", res);
										console.log("payload", payloadData);
										handleScanDocument(res, payloadData);
										actions.setSubmitting(false);
									} else {
										actions.setSubmitting(false);

										enqueueSnackbar("Please check your Email or Password", {
											variant: "error",
										});
									}
								});
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleSubmit,
								isSubmitting,
							}) => (
								<form className={classes.form} noValidate>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label={getTranslation(
											"Email Address",
											"Adresse e-mail",
											"E-Mail-Addresse"
										)}
										name="email"
										autoComplete="email"
										autoFocus
										size="small"
										value={values.email}
										onChange={handleChange}
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										size="small"
										name="password"
										label={getTranslation(
											"Password",
											"Mot de passe",
											"Passwort"
										)}
										type="password"
										id="password"
										value={values.password}
										onChange={handleChange}
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
									/>

									<ButtonWithLoading
										type="submit"
										title={getTranslation("Verify", "Verify", "Verify")}
										fullWidth
										variant="contained"
										loading={isSubmitting}
										onClick={handleSubmit}
										className={classes.submit}
									/>

									<Grid container>
										<Grid item xs>
											<Link
												to="/forget_password"
												color="secondary"
												variant="body1"
											>
												<Typography color="textSecondary">
													Forgot password
												</Typography>
											</Link>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					</>
				)}
			</div>
			<Box mt={3}>
				<Copyright />
			</Box>
		</Container>
	);
};
