import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@material-ui/core";

const FormFieldSelection = ({ fields, onFieldChange }) => {
  
  const handleChangefield = (event, field) => {
    var index = fields.findIndex(x => x.id === field.id)
    fields[index] = { ...field, isSelected: event.target.checked }
    onFieldChange([...fields]);
  }
  return (
    <>
      <Grid container>
        {fields?.map((field) => {
          return (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    label={field?.title}
                    control={
                      <Checkbox
                        value={field.id}
                        checked={field.isSelected}
                        onChange={(event) => {
                          handleChangefield(event, field);
                        }}
                      />
                    }
                  ></FormControlLabel>
                </FormGroup>
              </FormControl>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
export default FormFieldSelection;
