import React, { useState, useEffect } from "react";
import Table from "../../../../../components/table";
import {
  Get_MCBoardMembersByCircularResolution_URL,
  Get_MCBoardMembers_URL,
  Get_MCManagerMembersByCircularResolution_URL,
  Get_MCManagers_URL,
  Get_MCShareholderMembersByCircularResolution_URL,
  Get_MCShareholders_URL,
  Get_SelectedBoardMembersCircularResolution_URL,
  Get_SelectedManagersCircularResolution_URL,
  Get_SelectedShareholdersCircularResolution_URL,
  Post_AddNewMemberCircularResolution_URL,
  Post_DeleteMemberCircularResolution_URL,
} from "../../../../../constants/apiUrls";
import { Get, Create, Upload } from "../../../../../actions";
import { useSnackbar } from "notistack";
import { set } from "date-fns";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../../../../heplers/translationHelper";

export const SelectMember = ({
  options,
  enableCheckbox,
  enableDelete,
  managementCompanyId,
  circularResolutionId,
  title,
  type,
  selectedMembers,
  onSelectionChange,
  onRemove,
  isApproved,
  loading,
}) => {
	var _ = require("lodash");

  const columns = [
    {
      id: "fullName",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Name", "Nom", "Name"),
    },
    {
      id: "mail",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Email", "E-mail", "Email"),
    },
  ];
  if (enableDelete) {
    columns.push({
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Actions", "Actions", "Aktionen"),
    });
  }
  //////////////////////////////////     states for board,shareholders,managers and desion makers tables  ////////////////////////////
  const [members, setMembers] = useState([]);

  // const [selectedMembersTemp, setSelectedMembersTemp] = useState([]);

  const [membersLoading, setMembersLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  ///////////////////////////////// Get Members board,shareholder,managers //////////////////////

  const modifiedData = (arr) => {
    arr.forEach((item, index) => {
      arr[index].isDescisionMaker = false;
      arr[index].personId = item.id;
    });
    return arr;
  };
  ///////////////// Board Members ////////////////////
  const loadMCBoardMembers = () => {
    var params = managementCompanyId
      ? { MCid: managementCompanyId }
      : { id: circularResolutionId };
    var url = managementCompanyId
      ? Get_MCBoardMembers_URL
      : Get_MCBoardMembersByCircularResolution_URL;
    setMembersLoading(true);
    Get(
      params,
      url,
      null,
      (resp) => {
        setMembersLoading(false);
        setMembers(modifiedData(resp?.data));
      },
      (error) => {
        setMembersLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };

  const loadSelectedBoardMembers = () => {
    var params = { CrId: circularResolutionId };
    var url = Get_SelectedBoardMembersCircularResolution_URL;
    setMembersLoading(true);
    Get(
      params,
      url,
      null,
      (resp) => {
        setMembersLoading(false);
        setMembers(resp?.data);
      },
      (error) => {
        setMembersLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };
  ///////////////// Shareholder Members ////////////////////
  const loadMCShareholders = () => {
    var params = managementCompanyId
      ? { MCid: managementCompanyId }
      : { id: circularResolutionId };
    var url = managementCompanyId
      ? Get_MCShareholders_URL
      : Get_MCShareholderMembersByCircularResolution_URL;
    setMembersLoading(true);
    Get(
      params,
      url,
      null,
      (resp) => {
        setMembersLoading(false);
        setMembers(modifiedData(resp?.data));
      },
      (error) => {
        setMembersLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };

  const loadSelectedShareholderMembers = () => {
    var params = { CrId: circularResolutionId };
    var url = Get_SelectedShareholdersCircularResolution_URL;
    setMembersLoading(true);
    Get(
      params,
      url,
      null,
      (resp) => {
        setMembersLoading(false);
        setMembers(resp?.data);
      },
      (error) => {
        setMembersLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };
  ///////////////// Managers Members ////////////////////

  const loadMCManagers = () => {
    var params = managementCompanyId
      ? { MCid: managementCompanyId }
      : { id: circularResolutionId };
    var url = managementCompanyId
      ? Get_MCManagers_URL
      : Get_MCManagerMembersByCircularResolution_URL;
    setMembersLoading(true);
    Get(
      params,
      url,
      null,
      (resp) => {
        setMembersLoading(false);
        setMembers(modifiedData(resp?.data));
      },
      (error) => {
        setMembersLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };

  const loadSelectedManagerMembers = () => {
    var params = { CrId: circularResolutionId };
    var url = Get_SelectedManagersCircularResolution_URL;
    setMembersLoading(true);
    Get(
      params,
      url,
      null,
      (resp) => {
        setMembersLoading(false);
        setMembers(resp?.data);
      },
      (error) => {
        setMembersLoading(false);
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };
  /////// Load by Type ////
  useEffect(() => {
    if (typeof isApproved !== "undefined") {
      if (options) {
        setMembers(options);
      } else {
        switch (type) {
          case "board":
            if (isApproved) loadSelectedBoardMembers();
            else loadMCBoardMembers();
            break;
          case "shareholder":
            if (isApproved) loadSelectedShareholderMembers();
            else loadMCShareholders();
            break;
          case "manager":
            if (isApproved) loadSelectedManagerMembers();
            else loadMCManagers();
            break;
          default:
        }
      }
    }
  }, [type, isApproved, options]);

  const handleOnSelectionChange = (items) => {
    if (onSelectionChange) onSelectionChange(items);
  };
  const handleRemoveItem = async (item) => {
    if (circularResolutionId) {
      Create(
        { memberId: item.id, circularResolutionId: circularResolutionId },
        Post_DeleteMemberCircularResolution_URL,
        null,
        (resp) => {
          enqueueSnackbar("Member has been removed.", { variant: "success" });
        },
        (error) => {
          enqueueSnackbar(error?.data, { variant: "error" });
        }
      );
    }
  };
  const handleNewItem = async (item) => {
    if (circularResolutionId) {
      Create(
        { personId: item.id, circularResolutionId: circularResolutionId },
        Post_AddNewMemberCircularResolution_URL,
        null,
        (resp) => {
          enqueueSnackbar("Member has been added.", { variant: "success" });
        },
        (error) => {
          enqueueSnackbar(error?.data, { variant: "error" });
        }
      );
    }
  };
  return (
    <>
    {console.log("asdasdaasadsadadasd",selectedMembers,members)}
      <Table
        title={title}
        auto
        size="small"
        dense
        loading={membersLoading}
        colums={columns}
        //sortBy="fullName"
        rows={members}
        count={members?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        enableCheckbox={isApproved ? false : enableCheckbox}
        selected={selectedMembers?.filter(x=> members.findIndex(y=>y.id==x.id)>=0)}
        onSelectionChange={(items) => handleOnSelectionChange(items)}
        onSelectionChecked={handleNewItem}
        onSelectionUnChecked={handleRemoveItem}
        viewDelete={enableDelete}
        onDelete={onRemove}
      />
    </>
  );
};
