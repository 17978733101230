import React, { useState, useEffect } from "react";
import Table from "../../../../../components/table";
import {
  Get_DecisionMakers_URL,
  Get_GetDecisionMakerMembers_URL,
  Post_DeleteDecisionMakerCircularResolution_URL,
  Post_InsertDecisionMakerCircularResolution_URL,
  Post_UpdateMemberDecisionManually_URL,
} from "../../../../../constants/apiUrls";
import { Get, Create, Upload } from "../../../../../actions";
import { useSnackbar } from "notistack";
import { set } from "date-fns";
import { useDispatch } from "react-redux";
import { CheckCircleOutlined } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { getTranslation } from "../../../../../heplers/translationHelper";

export const SelectDecisionMember = ({
  circularResolutionId,
  isApproved,
  options,
}) => {
  const columns = [
    {
      id: "fullName",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Name", "Nom", "Name"),
    },
    {
      id: "mail",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Email", "E-mail", "Email"),
    },
    {
      id: "comments",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Comments", "commentaires", "Bemerkungen"),
    },
    {
      id: "descisionText",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Descision", "Décision", "Entscheidung"),
    },
    {
      id: "decisionDate",
      format:"date",
      disablePadding: true,
      label: getTranslation("Descision Date", "Date de décision", "Entscheidungsdatum"),
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Actions", "Actions", "Aktionen"),
    },
  ];

  const nonApprovedColumns = [
    {
      id: "fullName",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Name", "Nom", "Name"),
    },
    {
      id: "mail",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Email", "E-mail", "Email"),
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Actions", "Actions", "Aktionen"),
    },
  ];

  //////////////////////////////////     states for board,shareholders,managers and desion makers tables  ////////////////////////////
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const memberOptions = isApproved ? members : options;
  const columnOptions = isApproved ? columns : nonApprovedColumns;

  // const [selectedMembersTemp, setSelectedMembersTemp] = useState([]);

  const [membersLoading, setMembersLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  ///////////////// Decision Makers ////////////////////
  const loadDecisionMakers = () => {
    setMembersLoading(true);
    if (typeof isApproved !== "undefined") {
      const params = isApproved
        ? {
            CrId: circularResolutionId,
          }
        : {
            id: circularResolutionId,
          };
      Get(
        params,
        isApproved ? Get_DecisionMakers_URL : Get_GetDecisionMakerMembers_URL,
        null,
        (resp) => {
          setMembersLoading(false);

          isApproved ? setMembers(resp?.data) : setSelectedMembers(resp?.data);
        },
        (error) => {
          setMembersLoading(false);
          enqueueSnackbar(error?.data, { variant: "error" });
        }
      );
    }
  };

  useEffect(() => {
    loadDecisionMakers();
  }, [circularResolutionId, isApproved]);

  const handleMarkDecision = (row) => {
    console.log("jkalhdjkahdjklasjdh",row)
    Create(
      { memberId: row.id },
      Post_UpdateMemberDecisionManually_URL,
      null,
      (resp) => {
        enqueueSnackbar("Manual decision marked.", { variant: "success" });
        loadDecisionMakers();
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };
  const handleRemoveItem = async (item) => {
    Create(
      { memberId: item.id, circularResolutionId: circularResolutionId },
      Post_DeleteDecisionMakerCircularResolution_URL,
      null,
      (resp) => {
        enqueueSnackbar("Member has been removed.", { variant: "success" });
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };
  const handleNewItem = async (item) => {
    Create(
      { personId: item.id, circularResolutionId: circularResolutionId },
      Post_InsertDecisionMakerCircularResolution_URL,
      null,
      (resp) => {
        enqueueSnackbar("Decision Maker has been added.", {
          variant: "success",
        });
        setSelectedMembers([...selectedMembers, item]);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };
  return (
    <>
      <Table
        auto
        size="small"
        dense
        loading={membersLoading}
        title={getTranslation(
          "Decision Makers",
          "Décideurs",
          "Entscheidungsträger"
        )}
        colums={columnOptions}
        //sortBy="fullName"
        rows={memberOptions}
        count={memberOptions?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        actions={[
          {
            component: (row) => (
              row.descision==false?
              <IconButton onClick={() => handleMarkDecision(row)}>
                {" "}
                <CheckCircleOutlined />
              </IconButton>
              :null
            ),
          },
        ]}
        actionCondition={(row) => {
          return row.descision === false && isApproved;
        }}
        enableCheckbox={!isApproved}
        selected={selectedMembers}
        onSelectionChange={(items) => setSelectedMembers(items)}
        onSelectionChecked={handleNewItem}
        onSelectionUnChecked={handleRemoveItem}
      />
    </>
  );
};
