import React, { useState, useEffect } from "react";
import { Box, Paper, TextField, Typography } from "@material-ui/core";
import Table from "../../../../components/table";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../../../actions";
import {
	Create_AssetOperation_URL,
	Get_GetAllManagementCompanyAssetOperations_URL,
	Post_InsertAssetOperation_URL,
	Post_ManagementCompanyDeleteOperation_URL,
	Post_UpdateAssetOperation_URL,
} from "../../../../constants/apiUrls";
import { CreateAssetOperation } from "./index";
import { getTranslation } from "../../../../heplers/translationHelper";
import { AssetSubmenu } from "../../../../layout/subMenu";
import { CreateManagementCompanyAsset } from "../asset/index";
import { format } from "../../../../heplers/format";
const FundOperation = ({ onEdit }) => {
	const columns = [
		{
			id: "assetTitle",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Asset Title", "Titre de l'actif", "Asset-Titel"),
		},
		{
			id: "amountIn",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Amount In", "Montant en", "Betrag in"),
			align:'right',
			format:'number'
			},
		{
			id: "amountOut",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Amount Out", "Montant sortant", "Betrag aus"),
			align:'right',
			format:'number'
			},
		{
			id: "paymentTypeText",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Payment Type", "Type de paiement", "Zahlungsart"),
		},
		{
			id: "interestRate",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Interest Rate", "Taux d'intérêt", "Zinsrate"),
			align:'right',
			format:'number'
			},
		{
			id: "date",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Date", "Date", "Datum"),
			format: "date",
		},
		{
			id: "endDate",
			numeric: true,
			disablePadding: true,
			label: getTranslation("End Date", "Date de fin", "Endtermin"),
			format: "date",
		},
		{
			id: "actions",
			numeric: true,
			disablePadding: false,
			align: "right",
			label: getTranslation("Actions", "Actions", "Aktionen"),
		},
	];

	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [submitting, setSubmitting] = useState(false);
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let spvDetailAssetOperations = localStorage.getItem(
		"spvDetailAssetOperations"
	);
	if (spvDetailAssetOperations == null) {
		localStorage.setItem("spvDetailAssetOperations", 10);
		spvDetailAssetOperations = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvDetailAssetOperations);
	const [rows, setRows] = useState([]);
	const [editOperaton, setEditOperation] = useState();

	const [
		loadingManagementCompanyFundOperations,
		setloadingManagementCompanyFundOperations,
	] = useState(false);
	const { managementCompanyId } = useParams();

	useEffect(() => {
		loadManagementCompanyFunds();
	}, [page, rowsPerPage, searchString]);

	const loadManagementCompanyFunds = () => {
		setloadingManagementCompanyFundOperations(true);
		Get(
			{
				managementCompanyId: managementCompanyId,
				searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_GetAllManagementCompanyAssetOperations_URL,
			history,
			(resp) => {
				setRows(resp?.data);
				setloadingManagementCompanyFundOperations(false);
			},
			(error) => {}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvDetailAssetOperations", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleSearchManagementCompanyFund = (searchString) => {
		setSearchString(searchString);
	};

	const handleDeleteAssetOperation = (row) => {
		Create(
			{
				id: row.id,
			},
			Post_ManagementCompanyDeleteOperation_URL,
			null,
			(resp) => {
				loadManagementCompanyFunds();
				enqueueSnackbar(
					getTranslation(
						"Operation deleted successfully",
						"Opération supprimée avec succès",
						"Operation erfolgreich gelöscht"
					),
					{ variant: "success" }
				);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"An error occurred while deleting operation",
						"Une erreur est survenue lors de la suppression de l'opération",
						"Ein Fehler ist aufgetreten beim Löschen der Operation"
					),
					{ variant: "error" }
				);
			}
		);
	};

	const handleCreateManagementCompanyFund = async (values, actions) => {
		console.log("jishgfuisdg", values.date);
		setSubmitting(true);
		Create(
			{
				...values,
				assetManagementCompanyId: managementCompanyId,
			},
			editOperaton ? Post_UpdateAssetOperation_URL : Create_AssetOperation_URL,
			history,
			(response) => {
				setSubmitting(false);
				editOperaton
					? enqueueSnackbar(
							getTranslation(
								"Operation updated successfully",
								"Opération modifiée avec succès",
								"Operation erfolgreich aktualisiert"
							),
							{ variant: "success" }
					  )
					: enqueueSnackbar(
							getTranslation(
								"Operation created successfully",
								"Opération créée avec succès",
								"Operation erfolgreich erstellt"
							),
							{ variant: "success" }
					  );
				setEditOperation(null);
				loadManagementCompanyFunds();
			},
			(error) => {
				setSubmitting(false);
				enqueueSnackbar("Something went wrong", {
					variant: "error",
				});
			}
		);
	};
	return (
		<div>
			<CreateManagementCompanyAsset
				isSubmitting={submitting}
				editOperation={editOperaton}
				onSubmit={handleCreateManagementCompanyFund}
			/>
			<Table
				dense
				loading={loadingManagementCompanyFundOperations}
				title={getTranslation("Operations", "Opérations", "Operationen")}
				colums={columns}
				sortBy="assetTitle"
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewDelete
				onDelete={(row) => handleDeleteAssetOperation(row)}
				viewEdit
				onEdit={(row) => setEditOperation(row)}
				viewSearch
				onSearch={(searchString) =>
					handleSearchManagementCompanyFund(searchString)
				}
			/>
		</div>
	);
};

export default FundOperation;
