import { Box, Grid, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import * as Yup from "yup";
import { getTranslation } from "../../../../../../heplers/translationHelper";
export const AddAskingAgenda = ({ onAddGuest, onUpdate, agenda }) => {
  const initialValues = {
    //person:"",
    subject: "",
    comments: "",
  };
  const basicValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    comments: Yup.string().required("Comment is required"),
  });
  const defaultValue = agenda ? agenda : initialValues;
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          if (agenda) onUpdate(values, actions);
          else onAddGuest(values, actions);
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
          handleChange,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <TextField
                  fullWidth
                  label={getTranslation("Subject", "Sujette", "Fach")}
                  required
                  size="small"
                  variant="outlined"
                  name="subject"
                  {...getFieldProps("subject")}
                  error={touched.subject && Boolean(errors.subject)}
                  helperText={touched.subject && errors.subject}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  fullWidth
                  label={getTranslation(
                    "Comments",
                    "commentaires",
                    "Bemerkungen"
                  )}
                  required
                  size="small"
                  variant="outlined"
                  name="comments"
                  {...getFieldProps("comments")}
                  error={touched.comments && Boolean(errors.comments)}
                  helperText={touched.comments && errors.comments}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <ButtonWithLoading
                    title={
                      agenda
                        ? getTranslation(
                            "Update",
                            "Mettre à jour",
                            "Aktualisieren"
                          )
                        : getTranslation("Add", "Ajouter", "Addieren")
                    }
                    size="small"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
