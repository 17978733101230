import React, { useState, useEffect } from "react";
import { CreateNewContact, GetContact, UpdateContact } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import checkResponse from "../../heplers/responseHelper";
import { ContactBelongsTo, ContactEdit } from "./component";
import { getTranslation } from "../../heplers/translationHelper";
import { ContactSubmenu } from "../../layout/subMenu/meeting";

const Details = () => {
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, contact } = useSelector((state) => state.contacts);
  const handleUpdateSubmitContact = (values, actions) => {
    dispatch(UpdateContact(values, history)).then((response) => {
      const errors = checkResponse(response);
      if (!errors) {
        enqueueSnackbar(getTranslation("Contact Updated","Contact mis à jour","Kontakt aktualisiert"), {
          variant: "success",
        });
        dispatch(
          GetContact(
            {
              id,
            },
            history
          )
        );
      } else {
        enqueueSnackbar(getTranslation("Something went wrong","Quelque chose s'est mal passé","Etwas ist schief gelaufen"), {
          variant: "error",
        });
      }
      actions.setSubmitting(false);
    });
  };

  useEffect(() => {
    dispatch({
      type: "GET_Contacts",
      payload: null,
    });
    dispatch(
      GetContact(
        {
          id,
        },
        history
      )
    );
  }, [id]);

  return (
    <>
      <div>
        <ContactSubmenu contactId={id}/>
        <ContactEdit
          onSubmit={handleUpdateSubmitContact}
          contact={contact}
          isNewRecord={false}
        />
        <br></br>
        <ContactBelongsTo contactId={id} />
      </div>
    </>
  );
};

export default Details;
