import { Box, Grid, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import * as Yup from "yup";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Create, Get } from "../../../../../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Get_AgendaTemplateList_URL,
  Post_InsertAgendaTemplateItem_URL,
  Post_InsertAgendaTemplate_URL,
} from "../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../../../heplers/translationHelper";
export const AddAgendaTemplate = ({ onTemplateSelected, onSubmit,editItem }) => {
  const [agendaTemplateList, setAgendaTemplateList] = useState([]);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [enableAddTemplate, setEnableAddTemplate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const filter = createFilterOptions();
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const initialValues = {
    subject: "",
    comments: "",
    // agendaTemplateId: "",
    templateGroup: null,
    name: "",
  };
  const [defaultValue, setDefaultValue] = useState(initialValues);

  useEffect(() => {
    if (editItem)
      setDefaultValue(editItem)
    else
      setDefaultValue(initialValues)
  },[editItem])

  /////////// Get Agenda Template list ////////

  useEffect(() => {
    loadAgendaTemplateList();
  }, []);

  const loadAgendaTemplateList = (values) => {
    setTemplateLoading(true);
    Get(
      values,
      Get_AgendaTemplateList_URL,
      null,
      (resp) => {
        setTemplateLoading(false);
        setAgendaTemplateList(resp?.data);
      },
      (error) => {
        setTemplateLoading(false);
      }
    );
  };

  const CreateNewTemplate = async (value, onSuccess) => {
    await Create(
      { name: value },
      Post_InsertAgendaTemplate_URL,
      null,
      (resp) => {
        setEnableAddTemplate(true);
        onSuccess(resp?.data);
      },
      (error) => {}
    );
  };
  
  const basicValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    // comments: Yup.string().required("Comment is required"),
    // agendaTemplateId: Yup.string().required("Agenda Template Id is required"),
  });
  return (
    <div>
      <Autocomplete
        style={{ width: "400px" }}
        name="templateGroup"
        size="small"
        // value={values.templateGroup}
        value={selectedTemplate}
        options={agendaTemplateList}
        getOptionLabel={(option) => option.name}
        onChange={(e, value) => {
          if (value && value.inputValue) {
            // Create a new value from the user input
            CreateNewTemplate(value.inputValue, (resp) => {
              setAgendaTemplateList([...agendaTemplateList,resp])
              // setFieldValue("templateGroup", resp);
              // setFieldValue("agendaTemplateId", resp?.id);
              setSelectedTemplate(resp);
              onTemplateSelected(resp);
            });
          } else {
            // setFieldValue("templateGroup", value);
            setSelectedTemplate(value);
            onTemplateSelected(value);
            // setFieldValue("agendaTemplateId", value?.id);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (
            params.inputValue != "" &&
            agendaTemplateList.find((x) => x.name == params.inputValue) == null
          ) {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add new template "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={getTranslation("Template","Modèle","Vorlage")}
            variant="outlined"
            required
            // error={touched.name && Boolean(errors.name)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {templateLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {selectedTemplate ? (
        <Formik
          disabled
          enableReinitialize
          initialValues={defaultValue}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            onSubmit(values, actions);
            actions.resetForm();
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
            handleChange,
          }) => (
            <Form>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
              </Grid>
              <br />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextField
                    fullWidth
                    label={getTranslation("Subject","Sujette","Fach")}
                    required
                    size="small"
                    variant="outlined"
                    name="subject"
                    {...getFieldProps("subject")}
                    error={touched.subject && Boolean(errors.subject)}
                    helperText={touched.subject && errors.subject}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation("Comments","commentaires","Bemerkungen")}
                    size="small"
                    variant="outlined"
                    name="comments"
                    {...getFieldProps("comments")}
                    error={touched.comments && Boolean(errors.comments)}
                    helperText={touched.comments && errors.comments}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <ButtonWithLoading
                      title={editItem ? getTranslation("Update","Update","Update"):getTranslation("Add","Ajouter","Addieren")}
                      size="small"
                      variant="contained"
                      color="primary"
                      // loading={isSubmitting}
                      onClick={handleSubmit}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};
