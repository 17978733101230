import React, { useState, useEffect } from "react";
import ReactDOM, { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { Get, Create } from "../../actions";
import { Box, Grid, Paper, TextField, CardActions } from "@material-ui/core";
import { Formik } from "formik";
import Table from "../../components/table";
import { CreateService } from "./component";
import DeleteDialog from "../../components/DeleteDialog";

import Card from "../../components/Card";
import {
	Create_AddNewServiceProviderRepresentative_URL,
	Get_AllServices_URL,
	Get_AllServiceWithPagination_URL,
	Post_DeleteService_URL,
	Post_InsertService_URL,
	Post_UpdateService_URL,
} from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
import { ServiceProviderRepresentativeDialog } from "./serviceProviderRepresentativeDialog";

const columns = [
	// {
	//   id: "name",
	//   numeric: false,
	//   disablePadding: true,
	//   label:getTranslation("Name","Titre","Titel"),
	// },
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Title", "Titre", "Titel"),
	},
	// {
	//   id: "representativeRole",
	//   numeric: false,
	//   disablePadding: true,
	//   label:getTranslation("Representative Role","Titre","Titel"),
	// },
	{
		id: "serviceTypeTitle",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Type", "Taper", "Art"),
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const ManageCompanyService = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	let companysServicesProviderList = localStorage.getItem(
		"companysServicesProviderList"
	);
	if (companysServicesProviderList == null) {
		localStorage.setItem("companysServicesProviderList", 10);
		companysServicesProviderList = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(companysServicesProviderList);
	const [rows, setRows] = useState([]);
	const [loadingCeresServices, setloadingCeresServices] = useState(false);
	const [openRepresentativeDialog, setOpenRepresentativeDialog] =
		useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [editRepresentative, setEditRepresentative] = useState();
	const [serviceId, setServiceId] = useState(0);
	const [editService, setEditService] = useState(null);
	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: "Services", url: "/services" },
		});
	}, []);
	useEffect(() => {
		loadCeresServices();
	}, [page, rowsPerPage, searchString]);
	const loadCeresServices = () => {
		setloadingCeresServices(true);
		Get(
			{
				searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_AllServiceWithPagination_URL,
			history,
			(resp) => {
				setRows(resp.data);
				setloadingCeresServices(false);
			},
			(error) => {}
		);
	};
	// const { activities } = useSelector((state) => state.activities);
	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("companysServicesProviderList", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleSearchCeresService = (searchString) => {
		setSearchString(searchString);
	};
	const handleCreate = async (values, actions) => {
		setSubmitting(true);
		Create(
			values,
			editService ? Post_UpdateService_URL : Post_InsertService_URL,
			history,
			(response) => {
				actions.setSubmitting(false);
				enqueueSnackbar(
					getTranslation(
						"Operation Saved",
						"Opération créée",
						"Vorgang erstellt"
					),
					{
						variant: "success",
					}
				);
				setEditService(null);
				setSubmitting(false);
				loadCeresServices();
			},
			(error) => {
				actions.setSubmitting(false);
				// setSubmitting(false);
				enqueueSnackbar(
					getTranslation(
						"Something went wrong",
						"Quelque chose s'est mal passé",
						"Etwas ist schief gelaufen"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};
	const handleDeleteActivity = (row) => {
		const deleteArray = [];
		deleteArray.push(row.id);
		Create(
			{ ids: deleteArray },
			Post_DeleteService_URL,
			history,
			(resp) => {
				setOpen(false);
				enqueueSnackbar(
					getTranslation(
						"Service Deleted",
						"Prestation supprimée",
						"Dienst gelöscht"
					),
					{
						variant: "success",
					}
				);
				loadCeresServices();
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Unable to delete service because its being used by some company",
						"Impossible de supprimer le service car il est utilisé par une entreprise",
						"Der Dienst kann nicht gelöscht werden, da er von einem Unternehmen verwendet wird"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};
	const handleEditServideProvider = (row) => {
		setEditService(row);
		setServiceId(row.id);
		//setOpenRepresentativeDialog(true)
	};
	const handleSubmitSPRepresentative = (values, actions) => {
		Create(
			values,
			Create_AddNewServiceProviderRepresentative_URL,
			null,
			(resp) => {
				setOpenRepresentativeDialog(false);
				actions.setSubmitting(false);
			},
			(error) => {
				actions.setSubmitting(false);
			}
		);
	};

	return (
		<div>
			<CreateService
				onCancel={() => setEditService(null)}
				submitting={submitting}
				editService={editService}
				onSubmit={handleCreate}
			/>
			<br />
			<Table
				dense
				loading={loadingCeresServices}
				title={getTranslation(
					"Service Providers",
					"Les fournisseurs de services",
					"Dienstleister"
				)}
				colums={columns}
				sortBy="title"
				rows={rows?.data}
				peration
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				viewSearch
				onSearch={(searchString) => handleSearchCeresService(searchString)}
				// viewCreate
				// onCreate={() => setOpenRepresentativeDialog(true)}
				viewEdit
				onEdit={(row) => handleEditServideProvider(row)}
			/>
			<ServiceProviderRepresentativeDialog
				serviceId={serviceId}
				isOpen={openRepresentativeDialog}
				onClose={() => setOpenRepresentativeDialog(false)}
				onSubmit={handleSubmitSPRepresentative}
			/>
			<DeleteDialog
				open={open}
				title={getTranslation(
					"Delete Person",
					"Supprimer la personne",
					"Person löschen"
				)}
				onClose={() => setOpen(false)}
				onSubmit={(e) => handleDeleteActivity(deleteRow)}
			/>
		</div>
	);
};

export default ManageCompanyService;
