export const CeresTeamMemberResponsibilites=[
    {
        id:"internalreview",
        value: "internalreview",
        defaultEnglish: "Internal Review",
        defaultFrench: "Internal Review",
        defaultGerman: "Internal Review",
    },
    {
        id:"minutes",
        value: "Minutes",
        defaultEnglish: "Minutes",
        defaultFrench: "Minutes",
        defaultGerman: "Minutes",
    }
]