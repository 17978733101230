import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, TextField } from "@material-ui/core";
import {
  PrintButton,
  MessageButton,
  CreateButton,
} from "../../components/ButttonsWithIcons";

import Table from "../../components/table";
// import {
//     getActivities,
//     deleteActivity
// } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import checkResponse from "../../heplers/responseHelper";
import DeleteDialog from "../../components/DeleteDialog";
import FundOperations from "./component/FundOperations";
import { Autocomplete } from "@material-ui/lab";
import {
  CompaniesRepresentative,
  PersonsRepresentative,
  CreateCompanyRepresentative,
  CreatePersonRepresentative,
} from "./component";

import {
  Get_AllManagerRepresentative_URL,
  Create_ManagerCompanyRepresentative_URL,
  Create_ManagerPersonRepresentative_URL,
  Delete_CompanyRepresentative_URL,
  Delete_PersonRepresentative_URL,
  Update_BoardPersonRepresentative_URL,
  Update_PersonRepresentative_URL,
  Create_UpdateCompanyRepresentative_URL,
} from "../../constants/apiUrls";
import { Get, Create } from "../../actions";
import { getTranslation } from "../../heplers/translationHelper";

const Managers = () => {
  const { assetId,fundId,companyId,managementCompanyId } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAddCompanyRepresentative, setOpenAddCompanyRepresentative] =
    useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchPersonString, setSearchPersonString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [representative, setRepresentative] = useState();
  const [loadingRepresentative, setLoadingRepresentative] = useState(false);
  const [deletingRepresentative, setDeletingRepresentative] = useState(false);
  const [deletingType, setDeletingType] = useState("");
  const [deletingItem, setDeletingItem] = useState();
  const [editPersonRepresentative, setEditPersonRepresentative] =
    useState(null);
  const [editCompanyRepresentative, setEditCompanyRepresentative] =
    useState(null);
  // Loading Data
  useEffect(() => {
    loadRepresentatives();
  }, [companyId]);

  const loadRepresentatives = async () => {
    setLoadingRepresentative(true);
    Get(
      {
        companyId: companyId,
      },
      Get_AllManagerRepresentative_URL,
      history,
      (response) => {
        setLoadingRepresentative(false);
        setRepresentative(response.data);
      },
      (error) => {
        setLoadingRepresentative(false);
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleCreateCompanyRepresentative = async (values, actions) => {
    if(editCompanyRepresentative){
      values.id=editCompanyRepresentative.id;
      values.representedByPersonId=editCompanyRepresentative.representedByCompanies.contactId;
      values.representativeRole=editCompanyRepresentative.representedByCompanies.representativeRole;
    }
    Create(
      values,
      editCompanyRepresentative
        ? Create_UpdateCompanyRepresentative_URL
        : Create_ManagerCompanyRepresentative_URL,
      history,
      (resp) => {
        actions.setSubmitting(false);
        enqueueSnackbar(getTranslation("Company Representative Created"), {
          variant: "success",
        });
        actions.resetForm();
        setEditCompanyRepresentative(null);
        loadRepresentatives();
      },
      (error) => {
        enqueueSnackbar(getTranslation("Something went wrong"), {
          variant: "error",
        });
        actions.setSubmitting(false);
      }
    );
  };

  const handleCreatePersonRepresentative = async (values, actions) => {
    console.log("kjsghdkfjdsffsd", values);
    Create(
      values,
      editPersonRepresentative
        ? Update_PersonRepresentative_URL
        : Create_ManagerPersonRepresentative_URL,
      history,
      (resp) => {
        actions.setSubmitting(false);
        editPersonRepresentative
          ? enqueueSnackbar(getTranslation("Person Representative Updated"), {
              variant: "success",
            })
          : enqueueSnackbar(getTranslation("Person Representative Created"), {
              variant: "success",
            });
        actions.resetForm();
        setEditPersonRepresentative(null);
        loadRepresentatives();
      },
      (error) => {
        enqueueSnackbar(getTranslation("Something went wrong"), {
          variant: "error",
        });
        actions.setSubmitting(false);
      }
    );
  };

  const handleDeleteCompany = async (row) => {
    setDeletingRepresentative(true);
    await Create(
      {
        ids: [row.id],
      },
      Delete_CompanyRepresentative_URL,
      history,
      (resp) => {
        enqueueSnackbar(getTranslation("Company representative deleted"), {
          variant: "success",
        });
        loadRepresentatives();
        setDeletingRepresentative(false);
        setOpenDelete(false);
      },
      (error) => {
        enqueueSnackbar(getTranslation("Something went wrong"), {
          variant: "error",
        });
        setDeletingRepresentative(false);
      }
    );
  };

  const handleDeleteContact = async (row) => {
    setDeletingRepresentative(true);
    await Create(
      {
        ids: [row.id],
      },
      Delete_PersonRepresentative_URL,
      history,
      (resp) => {
        enqueueSnackbar(getTranslation("Person representative deleted"), {
          variant: "success",
        });
        loadRepresentatives();
        setDeletingRepresentative(false);
        setOpenDelete(false);
      },
      (error) => {
        enqueueSnackbar(getTranslation("Something went wrong"), {
          variant: "error",
        });
        setDeletingRepresentative(false);
      }
    );
  };

  const handleDeleteDialog = (type, deleteItem) => {
    setDeletingType(type);
    setOpenDelete(true);
    setDeletingItem(deleteItem);
  };

  return (
    <>
      <div>
        <CreatePersonRepresentative
        onCancel={()=>setEditPersonRepresentative(null)}
          editPersonRepresentative={editPersonRepresentative}
          companyId={companyId}
          onSubmit={handleCreatePersonRepresentative}
        />
        <PersonsRepresentative
          companyId={companyId}
          representative={representative}
          onDelete={(row) => handleDeleteDialog("person", row)}
          onEdit={(row) => {
            setEditPersonRepresentative(row);
          }}
        />

        <CreateCompanyRepresentative
          editCompanyRepresentative={editCompanyRepresentative}
          companyId={companyId}
          onSubmit={handleCreateCompanyRepresentative}
        />
        <CompaniesRepresentative
          companyId={companyId}
          representative={representative}
          onDelete={(row) => handleDeleteDialog("company", row)}
          onEdit={(row) => {
            setEditCompanyRepresentative(row);
          }}
        />
        <DeleteDialog
          open={openDelete}
          deleting={deletingRepresentative}
          title={getTranslation("Delete Company")}
          onClose={() => setOpenDelete(false)}
          onSubmit={(e) => {
            if (deletingType === "person") handleDeleteContact(deletingItem);
            if (deletingType === "company") handleDeleteCompany(deletingItem);
          }}
        />
      </div>
    </>
  );
};

export default Managers;


