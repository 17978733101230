import axios from "../heplers/apiHelper";
import {
    GET_AllCompanies_URL,
    GET_Company_URL,
    SAVE_Company_URL,
    DELETE_Company_URL,
    UPDATE_Company_URL,
    Get_AllCompanyGroups_URL,
    GET_AllCompaniesList_URL
} from "../constants/apiUrls";
import {
    GET_AllCompanies,
    GET_Company,
    Companies__LOADING,
    Company_ERROR,
    EDIT_Company,
    GET_AllCompanies_ERROR,
    GET_AllCompanyGroups,
    GET_AllCompanyGroups_ERROR,
    CompanyGroups__LOADING,
    GET_AllGeneralPartners
} from "../constants/types"


export const CreateNewCompany=(payload,history)=>async dispatch=>{
    try {
        const response = await axios(history).post(SAVE_Company_URL,payload);
        return response;
    } catch (error) {
        return error.response
    }
}

export const UpdateCompany=(payload,history)=>async dispatch=>{
    try {
        const response = await axios(history).post(UPDATE_Company_URL,payload);
        return response;
    } catch (error) {
        return error.response
    }
}

export const DeleteCompany=(payload,history)=>async dispatch=>{
    try {
        const response = await axios(history).post(DELETE_Company_URL,payload);
        return response;
        
    } catch (error) {
        return error.response
    }
}


export const GetCompany=(payload,history)=>async dispatch=>{
    try {
        const response = await axios(history).get(GET_Company_URL,{params:payload});
        dispatch({
            type:GET_Company,
            payload:response.data
        });
    } catch (error) {
        dispatch({
            type:Company_ERROR,
            payload:error.response.data
        });
    }
}

export const GetAllCompanies=(payload,history)=>async dispatch=>{
    try {
        dispatch({type:Companies__LOADING});
        const response = await axios(history).get(GET_AllCompanies_URL,{params:payload});
        dispatch({
            type:GET_AllCompanies,
            payload:response.data
        });
    } catch (error) {
        dispatch({
            type:GET_AllCompanies_ERROR,
            payload:error.response
        });
        

    }
}


export const GetAllGeneralPartners=(payload,history)=>async dispatch=>{
    try {
        dispatch({type:Companies__LOADING});
        const response = await axios(history).get(GET_AllCompaniesList_URL,{params:payload});
        dispatch({
            type:GET_AllGeneralPartners,
            payload:response.data
        });
    } catch (error) {
        dispatch({
            type:GET_AllCompanies_ERROR,
            payload:error.response
        });
        

    }
}


export const EditCompany=(payload)=>async dispatch=>{
    dispatch({
        type:UPDATE_Company_URL,
        payload:payload
    });

}

// Company Groups


export const GetAllCompanyGroups=(payload,history)=>async dispatch=>{
    try {
        dispatch({type:CompanyGroups__LOADING});
        const response = await axios(history).get(Get_AllCompanyGroups_URL,{params:payload});
        dispatch({
            type:GET_AllCompanyGroups,
            payload:response.data
        });
    } catch (error) {
        dispatch({
            type:GET_AllCompanyGroups_ERROR,
            payload:error.response
        });
        

    }
}


