import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Get } from "../../../../actions";
import DialogComponent from "../../../../components/Dialog";
import Table from "../../../../components/table";
import { Get_MeetingClashes_URL } from "../../../../constants/apiUrls";
import { getTranslation } from "../../../../heplers/translationHelper";
const columns = [
  {
    id: "memberName",
    nemeric: false,
    disablePadding: true,
    label: getTranslation("Member Name", "Nom du membre", "Mitgliedsname"),
  },
  {
    id: "clashComments",
    numeric: false,
    disablePadding: true,
    label: "Comment",
  },
];
export const ShowClashedContactsDialog = ({ open, onClose,meetingId }) => {
  const [meetingClashes, setMeetingClashes] = useState([]);
  const [meetingClashesLoading, setMeetingClashesLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    loadMeetingClashes({
      meetingId: meetingId,
    });
  }, [meetingId]);
  const loadMeetingClashes = () => {
    setMeetingClashesLoading(true);
    Get(
      {
        meetingId: meetingId,
      },
      Get_MeetingClashes_URL,
      null,
      (resp) => {
        setMeetingClashesLoading(false);
        setMeetingClashes(resp.data);
      },
      (error) => {
        enqueueSnackbar("Error loading meeting clashes", { variant: "error" });
        setMeetingClashesLoading(false);
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  return (
    <DialogComponent
      fullWidth
      open={open}
      onClose={onClose}
      title="Meeting Clashes"
    >
      <Table
        auto
        size="small"
        dense
        loading={meetingClashesLoading}
        colums={columns}
        sortBy="memberName"
        rows={meetingClashes}
        count={meetingClashes?.length}
        viewSearch
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
      />
    </DialogComponent>
  );
};
