import React from "react";
import {
	CardContent,
	CardActions,
	Grid,
	TextField,
	Box,
	IconButton,
} from "@material-ui/core";
import { Formik } from "formik";
import Card from "../../../components/Card";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import * as Yup from "yup";
import { getTranslation } from "../../../heplers/translationHelper";
import { Cancel } from "@material-ui/icons";

const CreateManagementCompany = (props) => {
	const initialValues = {
		firstName: "",
		lastName: "",
		email: "",
		userName: "",
		password: "",
		confirmPassword: "",
	};

	const basicValidationSchema = Yup.object().shape({
		firstName: Yup.string().required("First Name is required"),
		lastName: Yup.string().required("Last Name is required"),
		email: Yup.string().email().required("Email is required"),
		// userName: Yup.string().required("userName is required"),
		userName: Yup.string()
			.required("Username is required")
			.matches(/^\S*$/, "Username cannot contain spaces")
			.strict(true)
			.trim()
			.min(3, "Username must be at least 3 characters long"),
		password: Yup.string().min(6).max(50).required("Password is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("password")], "Password does not match")
			.required("Confirm Password is required"),
	});
	const { editUser } = props;

	const defaultValue = editUser ? editUser : initialValues;
	return (
		<Card title="Add User">
			<br />
			<Formik
				enableReinitialize
				initialValues={defaultValue}
				validationSchema={editUser ? null : basicValidationSchema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(true);
					props.onSubmit(values, actions);
				}}
			>
				{({
					errors,
					touched,
					values,
					handleSubmit,
					isSubmitting,
					getFieldProps,
					setFieldValue,
				}) => (
					<form>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<TextField
										fullWidth
										label={getTranslation("First Name", "Prénom", "Vorname")}
										required
										size="small"
										variant="outlined"
										name="firstName"
										{...getFieldProps("firstName")}
										error={touched.firstName && Boolean(errors.firstName)}
										helperText={touched.firstName && errors.firstName}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<TextField
										fullWidth
										label={getTranslation(
											"Last Name",
											"Nom de famille",
											"Familienname, Nachname"
										)}
										required
										size="small"
										variant="outlined"
										name="lastName"
										{...getFieldProps("lastName")}
										error={touched.lastName && Boolean(errors.lastName)}
										helperText={touched.lastName && errors.lastName}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<TextField
										fullWidth
										label={getTranslation(
											"User Name",
											"Nom d'utilisateur",
											"Benutzername"
										)}
										disabled={editUser ? true : false}
										required
										size="small"
										variant="outlined"
										name="userName"
										{...getFieldProps("userName")}
										error={touched.userName && Boolean(errors.userName)}
										helperText={touched.userName && errors.userName}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<TextField
										fullWidth
										disabled={editUser ? true : false}
										label={getTranslation("Email", "E-mail", "Email")}
										required
										size="small"
										variant="outlined"
										name="email"
										{...getFieldProps("email")}
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								{editUser ? null : (
									<>
										<Grid item xs={12} sm={12} md={4} lg={4}>
											<TextField
												fullWidth
												label={getTranslation(
													"Password",
													"Mot de passe",
													"Passwort"
												)}
												required
												size="small"
												variant="outlined"
												name="password"
												type="password"
												{...getFieldProps("password")}
												error={touched.password && Boolean(errors.password)}
												helperText={touched.password && errors.password}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={4} lg={4}>
											<TextField
												fullWidth
												label={getTranslation(
													"Confirm Password",
													"Confirmez le mot de passe",
													"Bestätige das Passwort"
												)}
												required
												size="small"
												variant="outlined"
												name="confirmPassword"
												type="password"
												{...getFieldProps("confirmPassword")}
												error={
													touched.confirmPassword &&
													Boolean(errors.confirmPassword)
												}
												helperText={
													touched.confirmPassword && errors.confirmPassword
												}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</Grid>
									</>
								)}
							</Grid>
						</CardContent>
						<CardActions>
							<Box
								pr={1}
								pb={1}
								width="100%"
								display="flex"
								alignItems="center"
								justifyContent="flex-end"
							>
								{editUser ? (
									<IconButton onClick={props.onCancel}>
										<Cancel />
									</IconButton>
								) : null}
								<ButtonWithLoading
									title={
										editUser
											? getTranslation(
													"Update",
													"Mettre à jour",
													"Aktualisieren"
											  )
											: getTranslation("Create", "Créer", "Schaffen")
									}
									size="small"
									variant="contained"
									color="primary"
									loading={isSubmitting}
									onClick={handleSubmit}
								/>
							</Box>
						</CardActions>
					</form>
				)}
			</Formik>
		</Card>
	);
};

export default CreateManagementCompany;
