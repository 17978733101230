import React, { useEffect, useState } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import Card from "../../../components/Card";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ClassicTextEditor } from "../../../components/TextEditor";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Get_AllMeetingAgendas_URL, Get_CompanyInfo_URL, Get_MeetingInfo_URL, Post_AskForAgenda_URL } from "../../../constants/apiUrls";
import { useNavigate, useParams } from "react-router";
import { Get, Create } from "../../../actions";
import { useSnackbar } from "notistack";
import { GenerateQRCode } from "../../../heplers/generateQR";
import { number } from "yup/lib/locale";
import { Label } from "@material-ui/icons";
import { getTranslation } from "../../../heplers/translationHelper";
import { CompanyInformation, MeetingInformation } from "../components";
import { AskAgendaList } from "./askAgendaList";

export const AskAgenda = () => {
  //testing URL
  // http://localhost:3000/public/askagenda/5a275206-8390-484a-9d76-08d98581a955/c066c62b-229d-4025-52a4-08d98581a966
  const { meetingMemberId, meetingId } = useParams();
  const history = useNavigate();
  const [enableTitle, setEnableTitle] = useState(false);
	const [companyInfo, setCompanyInfo] = useState({});
  const [meetingInfo, setMeetingInfo] = useState({});
  const [agendas, setAgendas] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    subject: "",
    comments: "",
    meetingId: meetingId,
    meetingMemberId: meetingMemberId,
  };
  const basicValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Field is required"),
    comments: Yup.string().required("Field is required"),
  });

useEffect(()=>{
  Get(
    {
      meetingId: meetingId,
    },
    Get_CompanyInfo_URL,
    null,
    (resp) => {
      setCompanyInfo(resp?.data);
    },
    (error) => {}
  );
  Get(
    {
      meetingId: meetingId,
    },
    Get_MeetingInfo_URL,
    history,
    (resp) => {
      setMeetingInfo(resp?.data);
      // handleAlredyGenerated();
    },
    (error) => {}
  );
  Get(
    {
      meetingId: meetingId,
    },
    Get_AllMeetingAgendas_URL,
    history,
    (resp) => {
      setAgendas(resp?.data);
      // handleAlredyGenerated();
    },
    (error) => {}
  );
},[])

  const defaultValue = initialValues;

  return enableTitle ? (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justify='center'
      style={{ minHeight: "50vh" }}
    >
      <Grid item xs={3}>
        <Typography align='center' variant='h2'>
          {getTranslation(
            "Thank you for your Time, Your Response Already Generated",
            "Thank you for your Time, Your Response Already Generated",
            "Thank you for your Time, Your Response Already Generated"
          )}
        </Typography>
      </Grid>
    </Grid>
  ) : <>
  <CompanyInformation companyInfo={companyInfo}/>
  <br/>
  <br/>
  <MeetingInformation meetingId={meetingId} meetingInfo={meetingInfo}/>
  <br/>
  <br/>
    <Formik
      enableReinitialize
      initialValues={defaultValue}
      validationSchema={basicValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        Create(
          values,
          Post_AskForAgenda_URL,
          history,
          (resp) => {
            actions.setSubmitting(false);
            enqueueSnackbar("Added Agenda", { variant: "info" });
            Get(
              {
                meetingId: meetingId,
              },
              Get_AllMeetingAgendas_URL,
              history,
              (resp) => {
                setAgendas(resp?.data);
                actions.resetForm();
                // handleAlredyGenerated();
              },
              (error) => {}
            );
          },
          (error) => {
            actions.setSubmitting(false);
            if (error.data?.message) {
              enqueueSnackbar(error.data?.message, {
                variant: "error",
              });
            } else
              enqueueSnackbar(error.data, {
                variant: "error",
              });
          }
        );
        //actions.resetForm();
      }}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue,
      }) => (
        <Form>
          <Card
            title={getTranslation(
              "Compose Agenda",
              "Composer l'ordre du jour",
              "Agenda verfassen"
            )}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item s={12} sm={12} md={12} lg={12}>
                  <TextField
                    id='subject'
                    fullWidth
                    label={getTranslation("Subject", "Sujette", "Fach")}
                    required
                    size='small'
                    variant='outlined'
                    name='subject'
                    {...getFieldProps("subject")}
                    error={touched.subject && Boolean(errors.subject)}
                    helperText={touched.subject && errors.subject}
                  />
                </Grid>
                <Grid item s={12} sm={12} md={12} lg={12}>
                  <TextField
                    id='comments'
                    fullWidth
                    label={getTranslation(
                      "Comments",
                      "commentaires",
                      "Bemerkungen"
                    )}
                    required
                    size='small'
                    variant='outlined'
                    name='comments'
                    {...getFieldProps("comments")}
                    error={touched.comments && Boolean(errors.comments)}
                    helperText={touched.comments && errors.comments}
                    multiline
                    minRows={10}
                  />
                  {/* <ClassicTextEditor
                    onChange={(content) => {
                      setFieldValue("text", content);
                    }}
                  /> */}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <ButtonWithLoading
                  style={{ margin: "15px" }}
                  title={getTranslation(
                    "Send and Close",
                    "Envoyer et fermer",
                    "Senden und schließen"
                  )}
                  size='small'
                  variant='contained'
                  color='primary'
                  loading={isSubmitting}
                  onClick={(e) => {
                    setEnableTitle(true);
                    handleSubmit(e);
                  }}
                />
                <ButtonWithLoading
                  title={getTranslation(
                    "Send and Add New",
                    "Envoyer et ajouter nouveau",
                    "Senden und neu hinzufügen"
                  )}
                  size='small'
                  variant='contained'
                  color='primary'
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  <br/>
  <br/>
  <AskAgendaList agendaList={agendas} meetingId={meetingId}  />
    </>
};
