import Table from "../../../../components/table";
import RefreshIcon from "@material-ui/icons/Refresh";
import { IconButton } from "@material-ui/core";
import { EditButton } from "../../../../components/ButttonsWithIcons";
import { NotificationDialog } from "../../../meeting/component/regular/attachReport/components";
import { useState } from "react";
import { SelectContactDialog } from "../../../meeting/component/regular/components/regularMeeting";
const columns = [
  {
    id: "title",
    numeric: false,
    label: "Title",
  },
  {
    id: "name",
    numeric: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    label: "Email",
  },
  {
    id: "actions",
    numeric: false,
    align: "right",
    label: "Actions",
  },
];

const rows = [
  {
    title: "hello",
    name: "schons marcel",
    email: "schon@s.marcel",
  },
];

const PostDeadlineNotifyeeList = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  return (
    <>
      <Table
        dense
        title="testing"
        colums={columns}
        sortBy="title"
        rows={rows}
        count={rows?.length}
        page={0}
        actions={[
          {
            component: (rowData) => (
              <IconButton onClick={() => setOpenNotification(true)}>
                <RefreshIcon fontSize="medium" />
              </IconButton>
            ),
          },
          {
            component: (rowData) => (
              <IconButton onClick={() => setOpenContact(true)}>
                <EditButton fontSize="large" />
              </IconButton>
            ),
          },
        ]}
      />
      <NotificationDialog
        open={openNotification}
        onClose={() => setOpenNotification(false)}
      />
      <SelectContactDialog
        open={openContact}
        onClose={() => setOpenContact(false)}
      />
    </>
  );
};
export default PostDeadlineNotifyeeList;
