import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router";
import {
  AskDocuments,
  SelectGuests,
  AddRegularMeetingInfo,
  AskReminders,
  AskAgenda,
  SelectFiles,
  AttachReport,
} from "../regular/components/regularMeeting";
import { Create } from "../../../../actions";
import { Post_GenerateAdhocMeetings_URL, Post_GenerateMeetings_URL } from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import GenerateAdhoc from "./generate";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Meeting Info",
    "Guests",
    "Reporting",
    "Documents",
    "Agendas",
    "Reminders",
    "Files",
  ];
}

export default function StepperAdhocMeeting({ managementCompanyId }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const initialValues={
    subject: "",
     meetingTime: new Date(),
    meetingDuration: new Date(),
    meetingDate: new Date(),
    meetingType: 2,
    language: "",
    personInchargeId: "",
    reminderInterval: 0,
    isReminderEnabled: false,
    reminderText: "",
    managementCompanyId: managementCompanyId,
    companyCeresServiceId: 0,
    address: "",
    isDocumentRequired: false,
    isAskingAgendaAllowed: false,
    askingAgendaInterval: 0,
    periodicity: 0,
    guests: [],
    meetingDocuments: [],
    agendas: [],
    meetingMembers: [],
    files: [],
    reportingUsers: [],
    reportFundManagerId: "",
    reportIntervalDays: 0,
    reportCollectDataBefore: 0,
    reportTemplateId: 0,
    fundManager: {}
  }
  const [regularMeetingModel, setRegularMeetingModel] = useState(initialValues);
  console.log("dfgdfghdfhh", regularMeetingModel);
  const steps = getSteps();
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          // <AddRegularMeetingInfo
          //   managementCompanyId={managementCompanyId}
          //   NextStep={handleNext}
          //   BackStep={handleBack}
          //   initialValues={regularMeetingModel}
          // />
          <GenerateAdhoc
            NextStep={handleNext}
            BackStep={handleBack}
            initialValues={regularMeetingModel}
            managementCompanyId={managementCompanyId}
          />
        );
      case 1:
        return <SelectGuests NextStep={handleNext} BackStep={handleBack} initialValues={regularMeetingModel} />;
      case 2:
        return <AttachReport NextStep={handleNext} BackStep={handleBack}  initialValues={regularMeetingModel} />;
      case 3:
        return <AskDocuments NextStep={handleNext} BackStep={handleBack} initialValues={regularMeetingModel}/>;
      case 4:
        return <AskAgenda NextStep={handleNext} BackStep={handleBack} initialValues={regularMeetingModel}/>;
      case 5:
        return <AskReminders NextStep={handleNext} BackStep={handleBack} initialValues={regularMeetingModel}/>;
      case 6:
        return (
          <SelectFiles
            onSubmit={handleSubmitRegularMeeting}
            BackStep={handleBack}
            initialValues={regularMeetingModel}
          />
        );
      default:
        return "Unknown step";
    }
  }

  const handleSubmitRegularMeeting = (values, actions) => {
    const payload = values
    payload.meetingDate=payload.meetingDate.toLocaleString()
    payload.meetingTime=payload.meetingTime.toLocaleString()
    payload.meetingDuration=payload.meetingDuration.toLocaleString()
    Create(
      payload,
      Post_GenerateAdhocMeetings_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        setRegularMeetingModel(initialValues)
        setActiveStep(0);
        actions.setSubmitting(false);
        enqueueSnackbar("Adhoc meeting has been generated.", {
          variant: "success",
        });
        actions.resetForm();

      },
      (error) => {
        actions.setSubmitting(false);
        if (error.data?.message) {
          enqueueSnackbar(error.data?.message, {
            variant: "error",
          });
        } else
          enqueueSnackbar(error.data, {
            variant: "error",
          });
      }
    );
  };

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = (values) => {
    setRegularMeetingModel(values);
    const newActiveStep = isLastStep()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
      console.log("kjgsdfgsdfg", newActiveStep);
      setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} >
            <StepButton >{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <Typography className={classes.instructions}>
            {getStepContent(activeStep)}
          </Typography>
          <div>
            {activeStep !== steps.length &&
              (completed[activeStep] ? (
                <Typography variant="caption" className={classes.completed}>
                  Step {activeStep + 1} already completed
                </Typography>
              ) : null)}
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}
