import React, { useState, useEffect } from "react";
import { Grid, TextField, IconButton } from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import ButtonWithLoading from "../../../../../components/ButtonWithLoading";

import * as Yup from "yup";
import { Create, Get } from "../../../../../actions";
import {
  Get_NotifyeeList_URL,
  Post_DeleteNotifyess_URL,
  Post_InsertNotifyess_URL,
  SAVE_Contact_URL,
} from "../../../../../constants/apiUrls";
import { Autocomplete } from "@material-ui/lab";
import { IconAdd } from "../../../../../components/Icons";
import { Close } from "@material-ui/icons";
import TableComponent from "../../../../../components/table";
import { getTranslation } from "../../../../../heplers/translationHelper";

const columns = [
  {
    id: "fullName",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Name", "Nom", "Name"),
  },
  {
    id: "mail",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Email", "E-mail", "Email"),
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Actions", "Actions", "Aktionen"),
  },
];

export const Notifyee = ({
  contactList,
  circularResolutionId,
  onPersonChanged,
  disabled,
}) => {
  const [enableAddNew, setEnableAddNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const initialValues = {
    addNewPerson: false,
    name: "",
    firstName: "",
    mail: "",
  };

  const basicValidationSchema = Yup.object().shape({
    name:Yup.string()
    .matches(
      /^[a-zA-Z\sÀ-ÿ\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]+$/,
      "Only English, French, and German characters are allowed"
    )
    .required("Last Name is required"),
    firstName:Yup.string()
    .matches(
      /^[a-zA-Z\sÀ-ÿ\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]+$/,
      "Only English, French, and German characters are allowed"
    )
    .required("First Name is required"),
    mail: Yup.string().email().required("Mail is required"),
  });

  useEffect(() => {
    if (circularResolutionId) loadCircularResolutionNotifyees();
  }, [circularResolutionId]);

  const loadCircularResolutionNotifyees = () => {
    Get(
      { CrId: circularResolutionId },
      Get_NotifyeeList_URL,
      null,
      (resp) => {
        if(onPersonChanged)onPersonChanged(resp?.data);
        setSelectedPersons(resp?.data);
      },
      (error) => {}
    );
  };

  const removeItemFromList = (item) => {
    setSelectedPersons(selectedPersons.filter((x) => x !== item));
    if (onPersonChanged)
      onPersonChanged(selectedPersons.filter((x) => x !== item));
  };

  const handleRemoveNotify = async (notify) => {
    if (circularResolutionId) {
      await Create(
        {
          circularResolutionId: circularResolutionId,
          notifyId: notify.id,
        },
        Post_DeleteNotifyess_URL,
        null,
        (resp) => {
          // loadCircularResolutionNotifyees();
        enqueueSnackbar("Notifyee removed.", { variant: "info" });
        removeItemFromList(notify);
        },
        (error) => {}
      );
    } else {
      removeItemFromList(notify);
    }
  };

  
  const handleAddNotify=(notify)=>{
    
    Create(
      {
        notifyId: notify.id,
        circularResolutionId: circularResolutionId,
      },
      Post_InsertNotifyess_URL,
      null,
      (resp) => {
        // setCircularResolution({ ...circularResolution, deadline: newDeadline });
        enqueueSnackbar("New notifyee added.", { variant: "info" });
        //setCircularResolutionFiles([...circularResolutionFiles,file])
      },
      (error) => {}
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  return (
    <>
      {disabled ? null : (
        <>
          <Grid container>
            <Grid xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                options={contactList}
                getOptionLabel={(option) => option.fullName}
                size="small"
                onChange={(e, value) => {
                  if (value !== null) {
                    if (selectedPersons.findIndex((x) => x === value) >= 0)
                      enqueueSnackbar("Already Exists!", {
                        variant: "warning",
                      });
                    else {
                      const arr = [...selectedPersons, value];
                      handleAddNotify(value)
                      if (onPersonChanged) onPersonChanged(arr);
                      setSelectedPersons(arr);
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getTranslation("Person", "La personne", "Person")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <br />
            <br />

            <Grid
              xs={1}
              sm={1}
              md={1}
              lg={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {enableAddNew === false ? (
                <IconAdd onClick={() => setEnableAddNew(true)} />
              ) : null}
            </Grid>
          </Grid>
          <br />
          {enableAddNew ? (
            <Formik
              initialValues={initialValues}
              validationSchema={basicValidationSchema}
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                Create(
                  values,
                  SAVE_Contact_URL,
                  null,
                  (resp) => {
                    actions.setSubmitting(false);
                    enqueueSnackbar("Notifyee Added", { variant: "info" });
                    setEnableAddNew(false);
                    onPersonChanged([...selectedPersons, resp?.data]);
                    setSelectedPersons([...selectedPersons, resp?.data]);
                    actions.resetForm();
                  },
                  (error) => {
                    actions.setSubmitting(false);
                    enqueueSnackbar(error?.data, { variant: "error" });
                  }
                );
                actions.resetForm();
              }}
            >
              {({
                errors,
                touched,
                values,
                handleSubmit,
                isSubmitting,
                getFieldProps,
                setFieldValue,
                handleChange,
              }) => (
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextField
                        fullWidth
                        id="firstName"
                        label={getTranslation(
                          "First Name",
                          "Prénom",
                          "Vorname"
                        )}
                        required
                        size="small"
                        variant="outlined"
                        name="firstName"
                        {...getFieldProps("firstName")}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextField
                        fullWidth
                        id="name"
                        label={getTranslation(
                          "Last Name",
                          "Nom de famille",
                          "Familienname, Nachname"
                        )}
                        required
                        size="small"
                        variant="outlined"
                        name="name"
                        {...getFieldProps("name")}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextField
                        fullWidth
                        id="mail"
                        label={getTranslation("Email", "E-mail", "Email")}
                        required
                        size="small"
                        variant="outlined"
                        name="mail"
                        {...getFieldProps("mail")}
                        error={touched.mail && Boolean(errors.mail)}
                        helperText={touched.mail && errors.mail}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <ButtonWithLoading
                        title={getTranslation("Add", "Ajouter", "Addieren")}
                        size="medium"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                        onClick={handleSubmit}
                      />
                      <IconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() => setEnableAddNew(false)}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          ) : null}
        </>
      )}
      {/* <SelectMember
        
        options={persons} 
        enableDelete={disabled}
        onRemove={(row) => {
          if (circularResolutionId) handleRemoveNotify(row);
          else {
            var arr = persons.filter((x) => x.id != row?.id);
            onPersonChanged(arr);
          }
        }}
        isApproved={disabled}
      /> */}

      <TableComponent
        auto
        size="small"
        dense
        loading={loading}
        colums={columns}
        sortBy="fullName"
        rows={selectedPersons}
        count={selectedPersons?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewDelete={disabled==false}
        onDelete={handleRemoveNotify}
      />
    </>
  );
};
