import React, { useState, useEffect } from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import Table from "../../components/table";
// import {
//   getActivities,
//   deleteActivity
// } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import checkResponse from "../../heplers/responseHelper";
import DeleteDialog from "../../components/DeleteDialog";
import { Get, Create } from "../../actions";
import {
	Get_AllAssetsWithPagination_URL,
	Post_DeleteAsset_URL,
	Post_CreateAsset_URL,
} from "../../constants/apiUrls";

import { CreateAsset } from "./component";
import { create } from "yup/lib/Reference";
import { getTranslation } from "../../heplers/translationHelper";
import { AssetSubmenu } from "../../layout/subMenu";

const columns = [
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Title", "Titre", "Titel"),
	},
	{
		id: "interestRelation",
		numeric: false,
		disablePadding: true,
		list: true,
		label: getTranslation(
			"Investors Relation",
			"Relation Investisseurs",
			"Investors-Relation"
		),
	},

	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const assets = [
	{
		id: "001",
		title: "Company",
		investerRelation: "Asghar",
		manager: "Khan",
	},
];

const ListAssets = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	let spvAssetsList = localStorage.getItem("spvAssetsList");
	if (spvAssetsList == null) {
		localStorage.setItem("spvAssetsList", 10);
		spvAssetsList = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvAssetsList);
	const [rows, setRows] = useState([]);
	const [loadingAssets, setloadingAssets] = useState(false);

	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: "Assets", url: "/assets" },
		});
	}, []);

	useEffect(() => {
		loadAssets();
	}, [page, rowsPerPage, searchString]);

	const loadAssets = () => {
		setloadingAssets(true);
		Get(
			{
				searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_AllAssetsWithPagination_URL,
			history,
			(resp) => {
				setRows(resp.data);
				setloadingAssets(false);
			},
			(error) => {}
		);
	};

	// const { activities } = useSelector((state) => state.activities);
	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleDeleteActivity = (row) => {
		const deleteArray = [];
		deleteArray.push(row.id);
		Create(
			{ ids: deleteArray },
			Post_DeleteAsset_URL,
			history,
			(resp) => {
				setOpen(false);
				enqueueSnackbar("Asset Deleted", {
					variant: "success",
				});
				loadAssets();
			},
			(error) => {
				enqueueSnackbar(
					"Unable to delete asset because its being used by some company",
					{
						variant: "error",
					}
				);
			}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvAssetsList", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleDetailCompany = (row) => {
		const url = `/assets/${row.id}/${row.companyId}/basic`;
		history(url);
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: row.title, url: url },
		});
	};
	const handleSearchAsset = (searchString) => {
		setSearchString(searchString);
	};
	const handleCreateCompany = (event) => {
		setCreateCompany(true);
	};
	const handleCreateCompanyClose = (event) => {
		setCreateCompany(false);
	};
	const handleCreateAsset = async (values, actions) => {
		Create(
			values,
			Post_CreateAsset_URL,
			history,
			(response) => {
				actions.setSubmitting(false);
				enqueueSnackbar("Asset Created", {
					variant: "success",
				});
				actions.resetForm();
				loadAssets();
			},
			(error) => {
				actions.setSubmitting(false);
				enqueueSnackbar(error?.data, {
					variant: "error",
				});
			}
		);
	};
	return (
		<div>
			<CreateAsset onSubmit={handleCreateAsset} />
			<Table
				dense
				loading={loadingAssets}
				title={getTranslation("Assets", "Actifs", "Vermögenswerte")}
				colums={columns}
				sortBy="title"
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => handleDetailCompany(row)}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				viewSearch
				onSearch={(searchString) => handleSearchAsset(searchString)}
			/>
			<DeleteDialog
				open={open}
				title={getTranslation(
					"Delete Asset",
					"Supprimer l'actif",
					"Asset löschen"
				)}
				onClose={() => setOpen(false)}
				onSubmit={(e) => handleDeleteActivity(deleteRow)}
			/>
		</div>
	);
};

export default ListAssets;
