import React, { useState, useEffect } from "react";
import { Info, Stats } from "./component/basic";
import { ManageService } from "./component/services";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { GetCompany, Create, Get } from "../../actions";
import {
  UPDATE_Company_URL,
  Get_Asset_URL,
  GET_Company_URL,
  Get_AssetCompany_URL,
} from "../../constants/apiUrls";
import { ManageTeam } from "./component/basic";
import { getTranslation } from "../../heplers/translationHelper";
import { ManagementCompaniesSubmenu } from "../../layout/subMenu/meeting";
import { AssetSubmenu,FundSubmenu,CompanySubmenu } from "../../layout/subMenu/meeting";
const Basic = ({ area }) => {
  const { companyId,assetId ,fundId,managementCompanyId } = useParams();
  console.log("frr",companyId)
  const [company, setCompany] = useState();
  const [asset, setAsset] = useState();
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleUpdateCompanySubmit = async (values, actions) => {
    await Create(
      values,
      UPDATE_Company_URL,
      history,
      (resp) => {
        enqueueSnackbar(getTranslation("Company information updated","Informations sur l'entreprise mises à jour","Unternehmensinformationen aktualisiert"), { variant: "success" });
        actions.setSubmitting(false);
      },
      (error) => {
        enqueueSnackbar(getTranslation("Something went wrong","Quelque chose s'est mal passé","Etwas ist schief gelaufen"), { variant: "error" });
        actions.setSubmitting(false);
      }
    );
  };

  useEffect(() => {
    loadCompany();
  }, [companyId]);

  const loadCompany = async () => {
    await Get(
      {
        id: companyId,
      },
      GET_Company_URL,
      history,
      (resp) => {
        setCompany(resp?.data);
      },
      (error) => {}
    );
  };

  return (
    <>
    {/* <ManagementCompaniesSubmenu /> */}
    {assetId ? (
        <AssetSubmenu  assetId={assetId} companyId={companyId} />
      ) : fundId ? (
        <FundSubmenu fundId={fundId} companyId={companyId} />
      ) : managementCompanyId ? (
        <ManagementCompaniesSubmenu managementCompanyId={managementCompanyId} companyId={companyId} />
      ) : (
        <CompanySubmenu  companyId={companyId}/>
      )}
      <Info
        isNewRecord={false}
        company={company}
        assetId={assetId}
        onSubmit={handleUpdateCompanySubmit}
      />
      <br></br>
      <ManageService />
      <br></br>
      <ManageTeam />
      <br></br>
      {/* <Stats companyId={companyId} /> */}
    </>
  );
};

export default Basic;
