export const periodicityPeriods = [
    {
        id:0,
        value:"Monthly",
        defaultEnglish:"Monthly",
        defaultFrench:"Mensuel",
        defaultGerman:"Monatlich",
    },
    {
        id:1,
        value:"Quarterly",
        defaultEnglish:"Quarterly",
        defaultFrench:"Trimestriel",
        defaultGerman:"Quartalsweise",
    },
    {
        id:2,
        value:"Annual",
        defaultEnglish:"Annual",
        defaultFrench:"Annuel",
        defaultGerman:"Jährlich",
    },
    {
        id:3,
        value:"End Term",
        defaultEnglish:"End Term",
        defaultFrench:"Fin de termes",
        defaultGerman:"Ende des Vertrags",
    }
]