import { IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DownloadFile, Get } from "../../../actions";
import { DownloadButton } from "../../../components/ButttonsWithIcons";
import TableComponent from "../../../components/table";
import {
	Get_AssetFeeAgreementHistory_URL,
	Get_FundFeeAgreementHistory_URL,
	Get_ManagementCompanyFeeAgreementHistory_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";

const columns = [
	{
		id: "dateOfAgreement",
		disablePadding: true,
		label: getTranslation(
			"Date of agreement",
			"Date de l'accord",
			"Datum der Vereinbarung"
		),
		format: "date",
	},
	{
		id: "comments",
		disablePadding: true,
		label: getTranslation("Comments", "commentaires", "Bemerkungen"),
	},
	{
		id: "actions",
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const FeeAgreementHistory = ({ managementCompanyId, assetId, fundId }) => {
	//         States

	const [loadingHistory, setLoadingHistory] = useState(false);
	const [history, sethistory] = useState([]);
	const [page, setPage] = useState(0);
	let spvDetailFeeHistory = localStorage.getItem("spvDetailFeeHistory");
	if (spvDetailFeeHistory == null) {
		localStorage.setItem("spvDetailFeeHistory", 10);
		spvDetailFeeHistory = 10;
	}
	const [pageSize, setPageSize] = useState(spvDetailFeeHistory);

	//         States End
	useEffect(() => {
		loadFeeAgreementHistory();
	}, []);
	const loadFeeAgreementHistory = async () => {
		setLoadingHistory(true);
		let id = 0;
		let url = "";
		let model = {};
		if (assetId) {
			model["assetId"] = assetId;
			url = Get_AssetFeeAgreementHistory_URL;
		} else if (fundId) {
			model["fundId"] = fundId;
			url = Get_FundFeeAgreementHistory_URL;
		} else if (managementCompanyId) {
			model["managementCompanyId"] = managementCompanyId;
			url = Get_ManagementCompanyFeeAgreementHistory_URL;
		}

		await Get(
			model,
			url,
			history,
			(resp) => {
				sethistory(resp?.data);
				setLoadingHistory(false);
			},
			(error) => {
				setLoadingHistory(false);
			}
		);
	};
	return (
		<TableComponent
			dense
			auto
			title={getTranslation("History", "Histoire", "Geschichte")}
			colums={columns}
			loading={loadingHistory}
			// rows={activities?.data}
			// count={activities?.totalCount}
			rowsPerPage={pageSize}
			page={page}
			handleChangeRowsPerPage={(event) => {
				localStorage.setItem("spvDetailFeeHistory", event.target.value);
				setPageSize(parseInt(event.target.value));
				setPage(0);
			}}
			handleChangePage={(event, newPage) => setPage(newPage)}
			sortBy="dateOfAgreement"
			rows={history}
			count={history.length}
			viewSearch
			actions={[
				{
					component: (row) => (
						<IconButton onClick={() => DownloadFile(row.uploadedContract)}>
							<DownloadButton />
						</IconButton>
					),
				},
			]}
		/>
	);
};

export default FeeAgreementHistory;
