export const representativeRoles = [
  {
    id:"president",
    value: "President",
    defaultEnglish: "Chairman",
    defaultFrench: "Président",
    defaultGerman: "Vorsitzende",
  },
  {
    id:"secretory",
    value: "Secretary",
    defaultEnglish: "Secretary",
    defaultFrench: "Secrétaire",
    defaultGerman: "Sekretärin",
  },
  {
    id:"ordinaryMember",
    value: "Ordinary Member",
    defaultEnglish: "Ordinary Member",
    defaultFrench: "Membre ordinaire",
    defaultGerman: "Ordentliches Mitglied",
  },
  {
    id:"generalPartner",
    value: "General Partner",
    defaultEnglish: "General Partner",
    defaultFrench: "Associé commandité",
    defaultGerman: "Komplementär",
  },
  {
    id:"managingLimitedPartner",
    value: "Managing Limited Partner",
    defaultEnglish: "Managing Limited Partner",
    defaultFrench: "Associé commanditaire gérant",
    defaultGerman: "Geschäftsführender Kommanditist",
  },
  {
    id:"manager",
    value: "Manager",
    defaultEnglish: "Manager",
    defaultFrench: "Manager",
    defaultGerman: "Manager",
  },
  {
    id:"relationshipManager",
    value: "Relationship Manager",
    defaultEnglish: "Relationship Manager",
    defaultFrench: "Relationship Manager",
    defaultGerman: "Relationship Manager",
  },
  {
    id:"AlternativeInvestmentFundManager(AIFM)",
    value: "Alternative Investment Fund Manager (AIFM)",
    defaultEnglish: "Alternative Investment Fund Manager (AIFM)",
    defaultFrench: "Gestionnaire de fonds d'investissement alternatifs (AIFM)",
    defaultGerman: "Verwalter alternativer Investmentfonds (AIFM)",
  },
];
