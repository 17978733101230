import { Form, Formik } from "formik";
import DialogComponent from "../../../../components/Dialog"
import CardComponent from "../../../../components/Card";
import { Box, CardActions, CardContent, Grid, TextField,Tooltip,IconButton } from "@material-ui/core";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../heplers/translationHelper";
import * as Yup from "yup";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from "@material-ui/pickers";
  import DateFnsUtils from "@date-io/date-fns";
  import Table from "../../../../components/table";
   import React, { useState,useEffect } from "react";
   import DeleteDialog from "../../../../components/DeleteDialog";
   import { useSnackbar } from "notistack";
   import { useDispatch, useSelector } from "react-redux";
   import { EditButton } from "../../../../components/ButttonsWithIcons";
   import {
    Post_Insert_Todo_URL,
    GET_AllContactList_URL,
    Get_All_Meeting_Todo
  } from "../../../../constants/apiUrls";
  import { Create, Get } from "../../../../actions";
  import { useNavigate, useParams } from "react-router-dom";
  import {format} from "../../../../heplers/format"
  import Autocomplete, {
    createFilterOptions,
  } from "@material-ui/lab/Autocomplete";


const columns = [
    {
      id: "personInchargeName",
      //numeric: false,
      disablePadding: true,
      label:getTranslation("Person in Charge","Personne en charge","Verantwortliche Person"),
    },
    {
      id:"description",
      numeric: false,
      disablePadding: true,
      label:getTranslation("Description","Description","Beschreibung"),
    },
    {
      id: "dueDate",
     numeric: false,
      disablePadding: true,
      label:getTranslation("Due Date","Date d'échéance","Geburtstermin"),
      format:"date"
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      align: "right",
      label:getTranslation("Actions","Actions","Aktionen"),
    },
  ];


 


export const TodoList=({rowws,loadingToDo,onEdit, setRowws,editId,onDelete})=>{

    
 // const {meetingId} = useParams();  
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [dopen, setOpen] = useState(false);  // state for delete dialog box
  const [deleteRow, setDeleteRow] = useState({});
  const [submitting,setSubmitting]=useState(false);
  const [personInChargee,setPersonInCharge]=useState();
  const [loadingPersonInCharge,setLoadingPersonInCharge]=useState()
  const history = useNavigate();


    const handleOpenDeleteDialog = (row) => {
        setDeleteRow(row);
        setOpen(true);
      };
    
    
      const handleDeleteTodo = (row) => {
        console.log(row)
        if (editId){
          return  onDelete(row.id)
        }

        setOpen(false)
        const updatedRows = rowws.filter((item) => item.uuid !== row.uuid);

        // Call the setRowws function from the prop to update the state in the manageTodo component
        setRowws(updatedRows);
      };
    
      const handleTodoEdit=(row)=>{;
        console.log(row)
      }
    
      const handleDetailCompany = (row) => {
        console.log("eidt")
      };


      const handleEditPersonRepresentative=(row)=>{
        if(onEdit) onEdit(row)
      }

    return <>
    <Box sx={{ marginY: "30px" }}>
    
      <Table
        dense
        loading={loadingToDo}
        colums={columns}
        sortBy="company"
        rows={rowws}
        count={rowws?.length}
        viewDelete
        onDelete={(row) => handleOpenDeleteDialog(row)}
        viewEdit
        onEdit={(row)=>handleEditPersonRepresentative(row)} 
      />
    
    <DeleteDialog
      open={dopen}
      title={getTranslation(
        "Delete ToDo",
        "Supprimer la tâche",
        "ToDo löschen"
      )}
      onClose={() => setOpen(false)}
      onSubmit={(e) => handleDeleteTodo(deleteRow)}
    />
    </Box>
    </>
   
}