import React, { useState, useEffect } from "react";
import {
  CardContent,
  Grid,
  TextField,
  Box,
  IconButton,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../components/Card";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Create, Get } from "../../../actions";
import {
  Get_AllServiceTypes_URL,
  Post_InsertExternalServiceType_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import { Cancel } from "@material-ui/icons";



const CreateService = ({ onCancel, editService, onSubmit, submitting }) => {
  console.log("oiadfodafod", editService);
  if(editService){
    editService.serviceTypeId = editService.serviceType.id;
  }
  const [ceresServiceTypes, setCeresServiceTypes] = useState([]);
  const history = useNavigate();
  const filter = createFilterOptions();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    loadAllCeresServiceTypes();
  }, []);

  const loadAllCeresServiceTypes = async () => {
    await Get(
      {},
      Get_AllServiceTypes_URL,
      history,
      (response) => {

        setCeresServiceTypes(response.data);
      },
      (error) => {}
    );
  };

  const initialValues = {
    title: "",
    serviceTypeId: editService?editService.serviceTypeId:0,
    serviceTypeTitle: "",
    serviceType: editService?editService.serviceType:{},
  };

  const basicValidationSchema = Yup.object().shape({
    title: Yup.string()
    .matches(
      /^[a-zA-Z\sÀ-ÿ\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]+$/,
      "Only English, French, and German characters are allowed"
    )
    .required("Company is required"),
    serviceTypeId: Yup.number()
      .min(1)
      .required(
        getTranslation(
          "Service Type is required",
          "Le type de service est requis",
          "Diensttyp ist erforderlich"
        )
      ),
  });

  const createServiceType = async (value, onSuccess) => {
    await Create(
      { title: value, comment: "" },
      Post_InsertExternalServiceType_URL,
      history,
      (resp) => {
        onSuccess(resp.data);
        enqueueSnackbar(
          getTranslation(
            "Company Service Created",
            "Service de l'entreprise créé",
            "Unternehmensdienst erstellt"
          ),
          {
            variant: "success",
          }
        );
        loadAllCeresServiceTypes();

      },
      (error) => {}
    );
  };

  const defaultValue = editService ? editService : initialValues;
  return (
    <Card
      title={getTranslation(
        "Add Service Provider",
        "Ajouter un fournisseur de services",
        "Dienstanbieter hinzufügen"
      )}
    >
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form>
            {console.log("jkdkjfdskfj", errors)}
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    label={getTranslation("Title", "Titre", "Titel")}
                    required
                    size="small"
                    variant="outlined"
                    value={values?.title}
                    name="title"
                    {...getFieldProps("title")}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Autocomplete
                    id="serviceTypeId"
                    name="serviceTypeId"
                    value={values.serviceType}
                    options={ceresServiceTypes}
                    getOptionLabel={(option) => option.title}
                    size="small"
                    onChange={(e, value) => {
                      if (value && value.inputValue) {
                        // Create a new value from the user input
                        createServiceType(value.inputValue, (resp) => {
                          setFieldValue("serviceTypeId", resp?.id);
                          setFieldValue("serviceTypeTitle", resp);
                          setFieldValue("serviceType", resp);
                        });
                      } else {
                        setFieldValue("serviceTypeId", value?.id);
                        setFieldValue("serviceTypeTitle", value);
                        setFieldValue("serviceType", value);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (
                        params.inputValue != "" &&
                        ceresServiceTypes.find(
                          (x) => x.title == params.inputValue
                        ) == null
                      ) {
                        filtered.push({
                          inputValue: params.inputValue,
                          title: `Add new service provider type "${params.inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Select Service Provider Type",
                          "Sélectionnez le type de fournisseur de services",
                          "Wählen Sie Dienstanbietertyp aus"
                        )}
                        variant="outlined"
                        error={
                          touched.serviceTypeId && Boolean(errors.serviceTypeId)
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <br />
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                
                {editService?
                <IconButton onClick={onCancel}>
                  <Cancel/>
                </IconButton>  
                :null}
                <ButtonWithLoading
                  title={
                    editService
                      ? getTranslation(
                          "Update",
                          "Mettre à jour",
                          "Aktualisieren"
                        )
                      : getTranslation("Create", "Créer", "Schaffen")
                  }
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={submitting}
                  onClick={() => {
                    if (Object.keys(errors).length > 0) {
                      enqueueSnackbar("Some fields are missing.", {
                        variant: "error",
                      });
                    } else handleSubmit();
                  }}
                />
              </Box>
            </CardContent>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default CreateService;
