import React, { useEffect, useState } from "react";
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  Box,
  Grid,
} from "@material-ui/core";
import { Formik } from "formik";
import { Link, useLocation, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../assests/styles/views/auth/login";
import Copyright from "../../utils/copyRightUtils";
import {Logo} from "../../components/Logo";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { userLogin, setUserData, Get } from "../../actions";
import checkResponse from "../../heplers/responseHelper";
import { useSnackbar } from "notistack";
import authUtils from "../../utils/authUtils";
import authService from "../../utils/authUtils";
import { getTranslation } from "../../heplers/translationHelper";
import { Get_LanguageDictionary_URL } from "../../constants/apiUrls";
import { useNavigate } from "react-router-dom";
const Login = ({ history,from }) => {
//const Login = ({history, onLoginSuccessfully }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate=useNavigate()
  const { enqueueSnackbar } = useSnackbar();

  const loadTranslations = () => {
    Get(
      {},
      Get_LanguageDictionary_URL,
      null,
      (resp) => {
        localStorage.setItem("dictionary", JSON.stringify(resp.data));
      },
      (error) => {}
    );
  };
  useEffect(() => {
    if (authService.isAuthenticated() || authService.isRefreshAuthenticated()) {

      // history("/");
    }else
    setLastLocation(from)
  }, []);
const [lastLocation,setLastLocation]=useState("") 
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.logo}>
          <Logo  />
        </div>
        <Typography component="h1" variant="h2" color="secondary">
          Login
        </Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required("Email is required")
              .email("Please enter a valid Email"),
            password: Yup.string().required("Password is required"),
          })}
          onSubmit={(values, actions) => {
            loadTranslations()
            if(localStorage.getItem("lang")===null || localStorage.getItem("lang")===undefined){
              localStorage.setItem("lang","en")
            }
            actions.setSubmitting(true);
            
            dispatch(userLogin(values)).then((res) => {
              
              const errors = checkResponse(res);
              if (!errors) {
                authUtils.setSession(res.data.accessToken);
                authUtils.setUserRefreshToken(res.data.refreshToken,res.data.refreshTokenExpire);
                //authUtils.setUserData(res.data.user)
				        // onLoginSuccessfully({ accessToken: res.data.accessToken, refreshToken: res.data.refreshToken, expiryDate: res.data.accessTokenExpire, refreshExpiryDate: res.data.refreshTokenExpire })

                navigate("/");
               // enqueueSnackbar("Login Successfully", { variant: "success" });
               
                actions.setSubmitting(false);
              } else {
                actions.setSubmitting(false);

                enqueueSnackbar("Please check your Email or Password", {
                  variant: "error",
                });
              }
            });
            // actions.setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={getTranslation("Email Address","Adresse e-mail","E-Mail-Addresse")}
                name="email"
                autoComplete="email"
                autoFocus
                size="small"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                size="small"
                name="password"
                label={getTranslation("Password","Mot de passe","Passwort")}
                type="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />

              <ButtonWithLoading
                type="submit"
                title={getTranslation("Login","Connexion","Anmeldung")}
                fullWidth
                variant="contained"
                loading={isSubmitting}
                onClick={handleSubmit}
                className={classes.submit}
              />

              <Grid container>
                <Grid item xs>
                  <Link to="/forget_password" color="secondary" variant="body1">
                    <Typography color="textSecondary">
                      Forgot password
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
      <Box mt={3}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
