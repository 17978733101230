import { Get } from "../actions";
import {
	Get_AllShareholders_URL,
	Get_CompanyGetAllShareHolder,
	Get_GetAllBoardMembers,
	Get_GetAllBoardMembersRepresentative,
	Get_GetAllManagerRepresentative_Url,
	Get_GetAllShareHolderWitoutPagination,
	Get_Meeting_GetCompany_URL,
	Get_ReportDataCollected_URL,
	Get_ReportingUser_URL,
	Get_SectionById_URL,
} from "../constants/apiUrls";
import { getTranslation } from "./translationHelper";
import { format } from "../heplers/format";
import _ from "lodash";
import ReactDOMServer from "react-dom/server";
export const parseHelper = async (meetingId, html, onChange) => {
	var parser = new DOMParser();
	var htmlDoc = parser.parseFromString(html, "text/html");
	var sectionList = htmlDoc.querySelectorAll("[data-section-id]");
	var companiesItems = htmlDoc.querySelectorAll("[data-company]");

	await parseSection(sectionList);
	var formList = htmlDoc.querySelectorAll("[data-form-id]");
	var companyBasicss = await getCompanyBasics(meetingId);

	await parseCompaniesItems(companiesItems, meetingId,companyBasicss);
	await parseForm(meetingId, formList);
	var tableList = htmlDoc.querySelectorAll("[data-table-id]");
	await parseTable(meetingId, tableList);
	var XML = new XMLSerializer();
	var divNode = document.createElement("div");
	divNode.innerHTML = htmlDoc.body.innerHTML;
	divNode.style = `width:100%;`;
	const result = await replaceCompanyBasicTags(XML.serializeToString(htmlDoc),companyBasicss)
	onChange(result);
};

const parseSection = async (sectionHtmlDOM) => {
	for await (const x of sectionHtmlDOM) {
		await Get(
			{
				id: x.getAttribute("data-section-id"),
			},
			Get_SectionById_URL,
			null,
			(resp) => {
				x.innerHTML = resp.data.sectionHtml;
				x.style = "";
			},
			(error) => {
				console.log("jhsdbgfsdfg error", error.data);
			}
		);
	}
};

const parseCompaniesItems = async (sectionHtmlDOM, meetingId,companyBasicss) => {
	let companyBoardMembers = await getAllBoardMember(companyBasicss.id);
	let comapnyMangers = await getAllCompanyManagers(companyBasicss.id);
	let companyShareHolders = await getAllCompanyShareHolders(companyBasicss.id);
	console.log("asjhdjakshjdashdjsa", sectionHtmlDOM);

	for await (const x of sectionHtmlDOM) {
		if (x.getAttribute("data-company") == "board") {
			// board parse
			//x.innerHTML = parseCompanyBoard(x);
			x.innerHTML = await parseCompanyBoard(companyBoardMembers);
			x.style = "";
		} else if (x.getAttribute("data-company") == "manager") {
			x.innerHTML = await parseCompanyManager(comapnyMangers);
			x.style = "";
		} else if (x.getAttribute("data-company") == "shareholder") {
			x.innerHTML = parseCompanyShareHolder(companyShareHolders);
			x.style = "";
		} 
	}
	// Replacing Company Basic Tags
};

const replaceCompanyBasicTags = (html, companyBasicss) => {
	return html
	.replaceAll('[Company Name]',companyBasicss.companyName)
	.replaceAll('[Company Group Name]',companyBasicss?.companyGroup?.title)
	.replaceAll('[Company Country Name]',companyBasicss.basic?.country)
	.replaceAll('[Company Investor Realtion]',companyBasicss.basic?.investorRelationName)
	.replaceAll('[Company ReferenceCode]',companyBasicss.basic?.referenceCode)
	.replaceAll('[Company RCS Number]',companyBasicss.basic?.rcsNumber)
	.replaceAll('[Company Incorporation Date]',companyBasicss.basic?.shortIncorporationDate)
	.replaceAll('[Company Street]',companyBasicss.basic?.street)
	.replaceAll('[Company City]',companyBasicss.basic?.city)
	.replaceAll('[Company Zip]',companyBasicss.basic?.zipCode)
	.replaceAll('[Company RBE]',companyBasicss.basic?.rbe)
	.replaceAll('[Company Financial Year Ends]',companyBasicss.basic?.finnancialYearEnds)
	.replaceAll('[Company Legal Form]',companyBasicss.basic?.basicLegalForm.legalForm)
	.replaceAll('[Company Vat]',companyBasicss.basic?.vat)
	.replaceAll('[Company Liquidation Date]',format("date",companyBasicss.basic?.liquidateDate))
	.replaceAll('[Company Comments]',companyBasicss.basic?.comments)


	// const dataField = html.getAttribute("data-field");

	// let result;

	// if (dataField == "companyName") {
	// 	result = companyBasicss.companyName;
	// } else if (dataField == "groupName") {
	// 	result = companyBasicss?.companyGroup?.title;
	// } else if (dataField == "countryName") {
	// 	result = companyBasicss?.basic?.country;
	// } else if (dataField == "investorRelation") {
	// 	result = companyBasicss.investorRelation;
	// }

	// return result;

	//return result !== undefined ? result : defaultValue;
};

const parseCompanyBoard = async (x) => {
	console.log("checking responseee", x);
	return ReactDOMServer.renderToString(
		<div>
			<h3>
				{getTranslation(
					"Board Person Representatives",
					"Board Person Representatives",
					"Board Person Representatives"
				)}
			</h3>
			<table width="100%" style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
				<thead style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}>
					<tr>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Name", "Name", "Name")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Role", "Role", "Role")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Company", "Company", "Company")}
						</th>
					</tr>
				</thead>
				<tbody style={{}}>
					{x.representedByPersons.map((obj) => (
						<tr>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.contactName}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.representativeRole}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.companyName}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<br />
			<h3>
				{getTranslation(
					"Board Companies Representatives",
					"Board Companies Representatives",
					"Board Companies Representatives"
				)}
			</h3>
			<table width="100%" style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
				<thead style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}>
					<tr>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Name", "Name", "Name")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Role", "Role", "Role")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation(
								"Represented By",
								"Represented By",
								"Represented By"
							)}
						</th>
					</tr>
				</thead>
				<tbody style={{}}>
					{x.representedByCompanies.map((obj) => (
						<tr>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.companyName}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.representativeRole}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.contactName}
							</td>
						</tr>
					))}
					{/* <tr>
					{x.representedByCompanies.map((obj) => (
						<td
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
								textAlign: "center",
							}}
						>
							{obj.companyName}
						</td>
					))}
				</tr> */}
				</tbody>
			</table>
		</div>
	);
};

const parseCompanyManager = (x) => {
	return ReactDOMServer.renderToString(
		<div>
			<h3>
				{getTranslation(
					"Managers Person Representatives",
					"Managers Person Representatives",
					"Managers Person Representatives"
				)}
			</h3>
			<table width="100%" style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
				<thead style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}>
					<tr>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Name", "Name", "Name")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Role", "Role", "Role")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Company", "Company", "Company")}
						</th>
					</tr>
				</thead>
				<tbody style={{}}>
					{x.representedByPersons.map((obj) => (
						<tr>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.contactName}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.representativeRole}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.companyName}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<br />
			<h3>
				{getTranslation(
					"Managers Companies Representatives",
					"Managers Companies Representatives",
					"Managers Companies Representatives"
				)}
			</h3>
			<table width="100%" style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
				<thead style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}>
					<tr>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Name", "Name", "Name")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Role", "Role", "Role")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation(
								"Represented By",
								"Represented By",
								"Represented By"
							)}
						</th>
					</tr>
				</thead>
				<tbody style={{}}>
					{x.representedByCompanies.map((obj) => (
						<tr>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.companyName}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.representativeRole}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.contactName}
							</td>
						</tr>
					))}
					{/* <tr>
					{x.representedByCompanies.map((obj) => (
						<td
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
								textAlign: "center",
							}}
						>
							{obj.companyName}
						</td>
					))}
				</tr> */}
				</tbody>
			</table>
		</div>
	);
};

const parseCompanyShareHolder = (x) => {
	return ReactDOMServer.renderToString(
		<div>
			<h3>
				{getTranslation(
					"Company Shareholders",
					"Company Shareholders",
					"Company Shareholders"
				)}
			</h3>
			<table width="100%" style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
				<thead style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}>
					<tr>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Company", "Company", "Company")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Shares", "Shares", "Shares")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Capital", "Capital", "Capital")}
						</th>
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
							}}
						>
							{getTranslation("Date", "Date", "Date")}
						</th>
					</tr>
				</thead>
				<tbody style={{}}>
					{x.map((obj) => (
						<tr>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.companyName}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.share}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{obj.capital}
							</td>
							<td
								style={{
									padding: "6px",
								}}
							>
								{format("date", obj.date)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<br />
		</div>
	);
};

const parseForm = async (meetingId, formElementList) => {
	const formsReportingUsers = [];
	for await (const x of _.uniqBy(formElementList, (e) =>
		e.getAttribute("data-form-id")
	)) {
		if (
			formsReportingUsers.findIndex(
				(user) => user.id == parseInt(x.getAttribute("data-form-id"))
			) == -1
		) {
			await Get(
				{
					templateId: parseInt(x.getAttribute("data-form-id")),
					meetingId: meetingId,
					reportingType: "form",
				},
				Get_ReportDataCollected_URL,
				null,
				(resp) => {
					formsReportingUsers.push(resp.data);
				},
				(error) => {
					console.log("parse  error");
				}
			);
		}
	}

	formsReportingUsers.forEach((reportingUser) => {
		const elements = _.filter(
			formElementList,
			(element) =>
				element.getAttribute("data-form-id") == reportingUser.templateId
		);
		const reportingValues = reportingUser?.reportingValues;
		elements.forEach((element) => {
			const fieldId = element.getAttribute("data-field-id");
			const field = reportingValues?.find((value) => value.id == fieldId);
			if (field && field.value != "") {
				element.innerHTML = field.value;
			}
		});
	});
};

const parseTable = async (meetingId, tablelementList) => {
	const tableReportingUsers = [];
	for await (const x of _.uniqBy(tablelementList, (e) =>
		e.getAttribute("data-table-id")
	)) {
		if (
			tableReportingUsers.findIndex(
				(user) => user.id == parseInt(x.getAttribute("data-table-id"))
			) == -1
		) {
			await Get(
				{
					templateId: parseInt(x.getAttribute("data-table-id")),
					meetingId: meetingId,
					reportingType: "table",
				},
				Get_ReportDataCollected_URL,
				null,
				(resp) => {
					tableReportingUsers.push(resp.data);
				},
				(error) => {
					console.log("parse  table error");
				}
			);
		}
	}

	tableReportingUsers.forEach((reportingUser) => {
		const elements = _.filter(
			tablelementList,
			(element) =>
				element.getAttribute("data-table-id") == reportingUser.templateId
		);
		const reportingValues = reportingUser?.reportingValues;
		elements.forEach((element) => {
			// tbody element
			element.innerHTML = renderTable(reportingValues);
			console.log("ajkgsdkjkhasdghdgas", element);
			// const fieldId = element.getAttribute("data-field-id")
			// const field = reportingValues?.find(value => value.id == fieldId)
			// if (field && field.value != "") {
			//   element.innerHTML = field.value
			// }
		});
	});
};

const renderTable = (reportingValues) => {
	return ReactDOMServer.renderToString(
		<thead>
			<tr style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}>
				{reportingValues[0].map((column) => {
					return (
						<th
							style={{
								border: "1px solid rgba(0, 0, 0, 0.2)",
								padding: "6px",
								textAlign: "center",
								fontWeight: "bold",
							}}
						>
							{column.title}
						</th>
					);
				})}
			</tr>
			{reportingValues.map((columns) => {
				return (
					<tr>
						{columns.map((column) => (
							<td
								style={{
									border: "1px solid rgba(0, 0, 0, 0.2)",
									padding: "6px",
									textAlign: "center",
								}}
							>
								{column.value}
							</td>
						))}
					</tr>
				);
			})}
		</thead>
	);
};

export const getReportingUsers = async (
	html,
	managementCompanyId,
	onUsersChange
) => {
	var parser = new DOMParser();
	var htmlDoc = parser.parseFromString(html, "text/html");
	var sectionList = htmlDoc.querySelectorAll("[data-section-id]");

	await parseSection(sectionList);
	var formList = htmlDoc.querySelectorAll("[data-form-id]");
	var tableList = htmlDoc.querySelectorAll("[data-table-id]");
	const users = [];

	for await (const x of _.uniqBy(formList, (form) =>
		form.getAttribute("data-form-id")
	)) {
		const user = await getFormUsers(
			x.getAttribute("data-form-id"),
			managementCompanyId
		);
		if (user != null && typeof user != "undefined") users.push(user);
	}
	for await (const table of _.uniqBy(tableList, (tab) =>
		tab.getAttribute("data-table-id")
	)) {
		const user = await getTableUsers(
			parseInt(table.getAttribute("data-table-id")),
			managementCompanyId
		);
		
		console.log("asdhashdashdasd",user)
		if (user != null && typeof user != "undefined") users.push(user);

	}

	onUsersChange(users);
};

const getFormUsers = async (formId, managementCompanyId) => {
	var user = null;
	
	await Get(
		{
			id: formId,
			managementCompanyId: managementCompanyId,
			type: "form",
		},
		Get_ReportingUser_URL,
		null,
		(resp) => {
			user = resp.data;
			console.log("jsgdfsdgfsdfg", resp);
		},
		(error) => {
			console.log("parse form user  error");
		}
	);
	return user;
};

const getTableUsers = async (tableId, managementCompanyId) => {
	var user;
	await Get(
		{
			id: tableId,
			managementCompanyId: managementCompanyId,
			type: "table",
		},
		Get_ReportingUser_URL,
		null,
		(resp) => {
			user = resp.data;
		},
		(error) => {
			console.log("parse table user  error");
		}
	);
	return user;
};

const getCompanyBasics = async (meetingId) => {
	var companyId;
	await Get(
		{
			meetingId: meetingId,
		},
		Get_Meeting_GetCompany_URL,
		null,
		(resp) => {
			companyId = resp.data;
		},
		(error) => {
			console.log("parse table user  error");
		}
	);
	return companyId;
};

const getAllBoardMember = async (cId) => {
	var memberBoardss;
	await Get(
		{
			companyId: cId,
		},
		Get_GetAllBoardMembersRepresentative,
		null,
		(resp) => {
			memberBoardss = resp.data;
		},
		(error) => {
			console.log("parse table user  error");
		}
	);
	return memberBoardss;
};

const getAllCompanyManagers = async (cId) => {
	var memberMangers;
	await Get(
		{
			companyId: cId,
		},
		Get_GetAllManagerRepresentative_Url,
		null,
		(resp) => {
			memberMangers = resp.data;
		},
		(error) => {
			console.log("parse table user  error");
		}
	);
	return memberMangers;
};

const getAllCompanyShareHolders = async (cId) => {
	var membershareholders;
	await Get(
		{
			cId: cId,
		},
		//Get_AllShareholders_URL,
		//Get_GetAllShareHolderWitoutPagination,
		Get_CompanyGetAllShareHolder,
		null,
		(resp) => {
			membershareholders = resp.data;
		},
		(error) => {
			console.log("parse table user  error");
		}
	);
	return membershareholders;
};
