import { Box, DialogContent, TextField } from "@material-ui/core"
import DialogComponent from "../../../components/Dialog"
import { Form, Formik } from "formik"
import { getTranslation } from "../../../heplers/translationHelper"
import * as Yup from 'yup'
import ButtonWithLoading from "../../../components/ButtonWithLoading"
export const MeetingInfoDialog=({openDialog,onClose,meetingRoom,onSubmit})=>{
    
    const defaultValue=meetingRoom? meetingRoom: {
        title:"",
        meetingLink:""
    }
    return (
      <DialogComponent
        onClose={onClose}
        fullWidth
        size="medium"
        open={openDialog}
        title={
          meetingRoom
            ? getTranslation(
                "Update Meeting Room",
                "Update Meeting Room",
                "Update Meeting Room"
              )
            : getTranslation(
                "Add Meeting Room",
                "Add Meeting Room",
                "Add Meeting Room"
              )
        }
      >
        <DialogContent>
          <Formik
            initialValues={defaultValue}
            validationSchema={Yup.object().shape({
              title: Yup.string().required("First Name is required"),

              meetingLink: Yup.string().required("Meeting link is Required"),
            })}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
                onSubmit(values,actions)
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              getFieldProps,
              errors,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <TextField
                  fullWidth
                  label={getTranslation("Title", "Title", "Title")}
                  size="small"
                  variant="outlined"
                  name="title"
                  id="title"
                  InputLabelProps={{ shrink: true }}
                  {...getFieldProps("title")}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
                <br />
                <br />
                <TextField
                  fullWidth
                  label={getTranslation(
                    "Meeting Link",
                    "Meeting Link",
                    "Meeting Link"
                  )}
                  size="small"
                  variant="outlined"
                  name="meetingLink"
                  id="meetingLink"
                  InputLabelProps={{ shrink: true }}
                  {...getFieldProps("meetingLink")}
                  error={touched.meetingLink && Boolean(errors.meetingLink)}
                  helperText={touched.meetingLink && errors.meetingLink}
                />
                 <br />
                <br />
                <Box
                  pr={1}
                  pb={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <ButtonWithLoading
                    title={
                      meetingRoom
                        ? getTranslation(
                            "Update",
                            "Mettre à jour",
                            "Aktualisieren"
                          )
                        : getTranslation("Create", "Créer", "Schaffen")
                    }
                    size="small"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </DialogComponent>
    );
} 