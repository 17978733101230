import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
// import ButtonWithLoading from "../../components/ButtonWithLoading";
import { useNavigate, useLo, useNavigatecation } from "react-router";
// import { Create, Get } from "../../actions";
// import { getTranslation } from "../../heplers/translationHelper";
// import { SettingsSubmenu } from "../../layout/subMenu";
import { useDispatch } from "react-redux";
import { Get_SystemSetting_URL, Get_ValidateDocuSign_URL, Post_UpdateDocuSignSetting_URL, Post_UpdateDocuSignStatus_URL } from "../../../constants/apiUrls";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Create, Get } from "../../../actions";
import { getTranslation } from "../../../heplers/translationHelper";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom";
export const DocuSign = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch({ type: "Clear_All_BreadCrumb" });
    dispatch({
      type: "Add_BreadCrumb",
      payload: { title: "Settings", url: "/settings" },
    });
  }, []);
  const initialValues = {
    docuSignClientId: "",
    docuSignIntegerationId: "",
    docuSignRSAPrivateKey:""
  };

  const basicValidationSchema = Yup.object().shape({
    docuSignClientId: Yup.string().required("Field is required"),
    docuSignIntegerationId: Yup.string().required("Field is required"),
    docuSignRSAPrivateKey: Yup.string().required("Field is required"),
  });

  const [settings,setSettings]=useState({
    docuSignClientId: "",
    docuSignIntegerationId: "",
  });
  useEffect(()=>{
    Get(
      {},
      Get_SystemSetting_URL,
      history,
      (resp) => {
        resp.data.docuSignRSAPrivateKey=""
        setSettings(resp.data);
      },
      (error) => { }
    );
  },[])

  const params=new URLSearchParams(window.location.search);
  const [apiError,setApiError]=useState();
  const [apiValidating,setApiValidating]=useState(false);


  useEffect(() => {
    if (window.location.pathname.includes("auth/docusign/callback"))
      Create(
        {
          docuSignConsent: true
        },
        Post_UpdateDocuSignStatus_URL,
        history,
        (resp) => {
          setApiValidating(true)
          Get(
            {},
            Get_ValidateDocuSign_URL,
            history,
            (resp) => {
              window.location.href="http://"+ window.location.host+"/settings/docusign"
            },
            (error) => {
              setApiValidating(false)
              setApiError(error.data)
            }
          );
          //window.location.href="http://"+ window.location.host+"/settings/docusign"
        },
        (error) => {
          setApiError(error.data)
        }
      );
  }, [])
  const [apiStatusMessage,setStatusMessage]=useState("");

  useEffect(()=>{
    if (window.location.pathname.includes("settings/docusign")){
      setApiValidating(true)
      Get(
      {},
      Get_ValidateDocuSign_URL,
      history,
      (resp) => {
        setApiValidating(false)
        setStatusMessage("Settings are working correctly.")
      },
      (error) => {
        setApiValidating(false)
        setApiError(error.data)
      }
    );}
  },[])

  return (
    <>
      <Card>
        <CardHeader title={getTranslation("Settings for Docu Sign","Settings for Docu Sign","Settings for Docu Sign")} />
        {apiValidating ? <CircularProgress/> : 
        <Formik
          enableReinitialize
          initialValues={settings}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            Create(
              values,
              Post_UpdateDocuSignSetting_URL,
              history,
              (resp) => {
                // actions.setSubmitting(false);
                    // enqueueSnackbar("Settings Updated for Meeting", {
                    //   variant: "info",
                    // });
                    const redirect_uri = "http://"+ window.location.host+"/auth/docusign/callback";
                    const url = `https://account-d.docusign.com/oauth/auth?response_type=code&scope=impersonation%20signature&client_id=${values.docuSignClientId}&redirect_uri=${redirect_uri}`
                    window.location.href=url;
                },
              (error) => {
                actions.setSubmitting(false);
                enqueueSnackbar("Something went wrong", { variant: "error" });
              }
            );
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
            handleChange,
          }) => (
            <form>
              <CardContent>
                <Grid container spacing={2}>

                  <Grid item xs={3} sm={3} md={3}></Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {apiError?
                    <Alert color={"error"} severity="error">
                        <Typography>{getTranslation("Check your details, we are not able to communicate with your Docu Sign Account.","Check your details, we are not able to communicate with your Docu Sign Account.","Check your details, we are not able to communicate with your Docu Sign Account.")}</Typography>
                    </Alert>:null}
                  <br/>
                    {apiStatusMessage!=""?
                    <Alert color={"success"} severity="success">
                        <Typography>{getTranslation(apiStatusMessage,apiStatusMessage,apiStatusMessage)}</Typography>
                    </Alert>:null}
                  </Grid>
                  <Grid item xs={3} sm={3} md={3}>
                    <Typography>{getTranslation("DocuSign Integeration Id","DocuSign Integeration Id","DocuSign Integeration Id")}</Typography>
                  </Grid>

                  <Grid item xs={3} sm={3} md={3}>
                    <TextField
                      fullWidth
                      id="docuSignClientId"
                      label={getTranslation("DocuSign Integeration Id","DocuSign Integeration Id","DocuSign Integeration Id")}
                      required
                      size="small"
                      variant="outlined"
                      name="docuSignClientId"
                      {...getFieldProps("docuSignClientId")}
                      error={
                        touched.docuSignClientId &&
                        Boolean(errors.docuSignClientId)
                      }
                      helperText={
                        touched.docuSignClientId &&
                        errors.docuSignClientId
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3} md={3}></Grid>

                  <Grid item xs={3} sm={3} md={3}></Grid>
                  <Grid item xs={3} sm={3} md={3}>
                    <Typography>{getTranslation("DocuSign User Id","DocuSign User Id","DocuSign User Id")}</Typography>
                  </Grid>

                  <Grid item xs={3} sm={3} md={3}>
                    <TextField
                      fullWidth
                      id="docuSignIntegerationId"
                      label={getTranslation("DocuSign User Id","DocuSign User Id","DocuSign User Id")}
                      required
                      size="small"
                      variant="outlined"
                      name="docuSignIntegerationId"
                      {...getFieldProps("docuSignIntegerationId")}
                      error={
                        touched.docuSignIntegerationId &&
                        Boolean(errors.docuSignIntegerationId)
                      }
                      helperText={
                        touched.docuSignIntegerationId &&
                        errors.docuSignIntegerationId
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3} md={3}></Grid>
                  <Grid item xs={3} sm={3} md={3}></Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography>DocuSign RSA Private Key (You will not be able to see it again so save it on your system as well)</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      fullWidth
                      id="docuSignRSAPrivateKey"
                      label={getTranslation("DocuSign RSA private key","DocuSign RSA private key","DocuSign RSA private key")}
                      required
                      size="small"
                      variant="outlined"
                      name="docuSignRSAPrivateKey"
                      {...getFieldProps("docuSignRSAPrivateKey")}
                      error={
                        touched.docuSignRSAPrivateKey &&
                        Boolean(errors.docuSignRSAPrivateKey)
                      }
                      helperText={
                        touched.docuSignRSAPrivateKey &&
                        errors.docuSignRSAPrivateKey
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      minRows={10}
                      type="textarea"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ButtonWithLoading
                    title={getTranslation("Save","sauvegarder","Speichern")}
                    size="large"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Box>
              </CardActions>
            </form>
          )}
        </Formik>
        }
      </Card>
    
    </>
  );
};
