import React, { useState, useEffect, useRef, createRef } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import Table from "../../../../components/table";
import { ErrorMessage, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../../components/Card";

import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { FormLoader } from "../../../../components/FormLoader";

import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Get, Create } from "../../../../actions";
import {
  GET_AllContactList_URL,
  Get_AllManagementCompanyFeeServicesByType_URL,
  GET_AllUsersWithoutAuthorization_URL,
  Post_AddNewFileCircularResolution_URL,
  Get_CircularResolutionsTemplates_URL,
  Get_AllCeresServices_URL,
} from "../../../../constants/apiUrls";
import { DatePicker } from "../../../../components/DatePicker";

import { UploadFiles } from "../../../../components/Upload";
import { ClassicTextEditor } from "../../../../components/TextEditor";
import { SelectMember } from "./components/SelectMembers";
import { Notifyee } from "./components/notifyee";
import { LanguageSelection } from "../../../../constants/languages";
import { getTranslation } from "../../../../heplers/translationHelper";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "16px",
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  card: {
    marginTop: "30px",
  },
}));


const GenerateCircularResolution = ({ managementCompanyId, isSubmitting, onGenerate }) => {
  const [contactListLoading, setContactListLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [usersList, setusersList] = useState([]);
  const [ceresServices, setCeresServices] = useState([]);
  const [ceresServicesLoading, setCeresServicesLoading] = useState(false);
  const [circularResolutionFiles, setCircularResolutionFiles] = useState();
  ////////// template setting states ////////////
  const [templateList, setTemplateList] = useState([]);
  const [templateListLoading, setTemplateListLoading] = useState(false);
  const [enableReloading, setEnableReloading] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();
  const filter = createFilterOptions();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [resolutionText, setResolutionText] = useState("<p>first</p>");

  /////////////// get user without authorization ///////////////

  useEffect(() => {
    loadUsersWithoutAuth();
  }, []);
  const loadUsersWithoutAuth = (values) => {
    Get(
      values,
      GET_AllUsersWithoutAuthorization_URL,
      null,
      (resp) => {
        setusersList(resp?.data);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    setContactListLoading(true);
    Get(
      {},
      GET_AllContactList_URL,
      history,
      (resp) => {
        setContactList(resp.data);
        setContactListLoading(false);
      },
      (error) => {}
    );
  }, []);

  const loadCeresServices = async () => {
    setCeresServicesLoading(true);

    await Get(
      {
        id: managementCompanyId,
        feeType: 0,
      },
      Get_AllManagementCompanyFeeServicesByType_URL,
      history,
      (resp) => {
        setCeresServices(resp.data);
        setCeresServicesLoading(false);
      },
      (error) => {
        setCeresServicesLoading(false);
      }
    );
  };

  useEffect(() => {
    loadCeresServices();
  }, []);

  const addNewCircularResolutionFile = (fileAddress) => {
    Create(
      {
        fileName: fileAddress,
        circularResolutionId: "8c075093-96a8-4563-a38e-08d996205ca3",
      },
      Post_AddNewFileCircularResolution_URL,
      null,
      (resp) => {
        //setCircularResolutionFiles([...circularResolutionFiles,files])
      },
      (error) => {}
    );
  };

  /////////////////// Load Templates ///////////////
  const loadTemplates = (language) => {
    Get(
      { language: language },
      Get_CircularResolutionsTemplates_URL,
      null,
      (resp) => {
        setTemplateList(resp?.data);
      },
      (error) => {
      }
    );
  };
  const initialValues = {
    subject: "",
    resolution: "",
    deadline: new Date(),
    language: "French",
    personInchargeId: "",
    sponsorId: 0,
    managementCompanyId: managementCompanyId,
    companyCeresServiceId: 0,
    members: [],
    files: [],
    notifyeesList: [],

    // for Generate only

    sponsor: null,
    companyCeresService: {},
    personIncharge: null,
    selectedBoardMembers: [],
    selectedShareholders: [],
    selectedManagers: [],
    selectedDecisionMakers: [],
    template: "",
  };

  const basicValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    resolution: Yup.string().required("Resolution is required"),
    language: Yup.string().required("Language is required"),
    personInchargeId: Yup.string().required("Person Incharge is required"),
    sponsorId: Yup.number()
      .min(1, "SponserId must be greater than 1")
      .required("Sponser is required"),
    companyCeresServiceId: Yup.number().min(0)
      .required("Company Ceres Service Id is required"),
    
  });

  const getDistinct = (arr) => {
    return arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  };

  const compareDecisionMakers = (selectedList, newList) => {
    const arr = [];
    selectedList.forEach((element) => {
      const check = newList.find((x) => x.id === element.id);
      if (check) {
        arr.push(check);
      }
    });
    return arr;
  };
  const errorDivRef = createRef();
  return (
    <FormLoader>
      <Formik
          //enableReinitialize
          initialValues={initialValues}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            values.selectedDecisionMakers.forEach((decisionMaker) => {
              const index = values.members.findIndex(
                (x) => x.id === decisionMaker.id
              );
              values.members[index].isDescisionMaker = true;
            });
            const arr = [];
            values.notifyeesList.forEach((notify) => {
              arr.push(notify.id);
            });
            values.notifyeesList = arr;
            onGenerate(values, actions);
            actions.resetForm();
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            handleChange,
          }) => (
            <Form>
              {console.log("lkjsfdslkhgjklfsg",values)}
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Autocomplete
                      id="companyCeresServiceId"
                      name="companyCeresServiceId"
                      value={values.companyCeresService}
                      options={ceresServices}
                      getOptionLabel={(option) => option.ceresServiceTitle}
                      size="small"
                      onChange={(e, value) => {
                        console.log("kjfkjdskfdsf",value);
                        setFieldValue("companyCeresServiceId", value?.id);
                        setFieldValue("companyCeresService", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getTranslation("Ceres Service","Service Cérès","Ceres-Dienst")}
                          variant="outlined"
                          required
                          error={
                            touched.companyCeresServiceId &&
                            Boolean(errors.companyCeresServiceId)
                          }
                          helperText={
                            touched.companyCeresServiceId &&
                            errors.companyCeresServiceId
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <LanguageSelection
                      label={getTranslation("Language","Langue","Sprache")}
                      name="language"
                      id="language"
                      required
                      onChange={(e, value) => {
                        setFieldValue("language", value);
                        loadTemplates(value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Autocomplete
                      name="template"
                      options={templateList}
                      getOptionLabel={(option) => option.title}
                      size="small"
                      onChange={(e, value) => {
                        setFieldValue("template", value?.id);
                        setFieldValue("resolution", value?.resolution);
                        setResolutionText("<p>Asghar hello</p>");
                        setEnableReloading(true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getTranslation("Template","Modèle","Vorlage")}
                          variant="outlined"
                          error={touched.template && Boolean(errors.template)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <TextField
                      fullWidth
                      id="subject"
                      label={getTranslation("Subject","Sujette","Fach")}
                      required
                      size="small"
                      variant="outlined"
                      name="subject"
                      {...getFieldProps("subject")}
                      error={touched.subject && Boolean(errors.subject)}
                      helperText={touched.subject && errors.subject}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                 

                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Autocomplete
                      options={contactList}
                      value={values.sponsor}
                      getOptionLabel={(option) => option.fullName}
                      size="small"
                      onChange={(e, value) => {
                        setFieldValue("sponsorId", value?.id);
                        setFieldValue("sponsor", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getTranslation("Sponser","Parrain","Sponsor")}
                          variant="outlined"
                          error={touched.sponsorId && Boolean(errors.sponsorId)}
                          helperText={touched.sponsorId && errors.sponsorId}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Autocomplete
                      options={usersList}
                      value={values.personIncharge}
                      getOptionLabel={(option) => option.name}
                      size="small"
                      onChange={(e, value) => {
                        setFieldValue("personInchargeId", value?.id);
                        setFieldValue("personIncharge", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getTranslation("Person Incharge","Personne en charge","Verantwortlicher")}
                          variant="outlined"
                          error={
                            touched.personInchargeId &&
                            Boolean(errors.personInchargeId)
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <DatePicker
                    fullWidth
                      id="deadline"
                      label={getTranslation("Deadline","Date limite","Termin")}
                      value={values.deadline}
                      onChange={(value) => {
                        setFieldValue("deadline", value);
                      }}
                      required
                      error={touched.deadline && Boolean(errors.deadline)}
                    />
                  </Grid>
                </Grid>
                <Card title={getTranslation("Members","Membres","Mitglieder")} className={classes.card}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={6} lg={6}>
                        <Typography className={classes.title} component="h2">
                          Board
                        </Typography>
                        <SelectMember
                          enableCheckbox={true}
                          type="board"
                          managementCompanyId={managementCompanyId}
                          onSelectionChange={(items) => {
                            setFieldValue("selectedBoardMembers", items);
                            const selectedDecisionMakers =
                              compareDecisionMakers(
                                values.selectedDecisionMakers,
                                items
                              );
                            setFieldValue(
                              "selectedDecisionMakers",
                              selectedDecisionMakers
                            );
                            setFieldValue("members", [...values.selectedManagers,...values.selectedShareholders,...items]);
                          }}
                          selectedMembers={values.members}
                          isApproved={false}
                        />
                      </Grid>
                      <Grid item sm={12} md={6} lg={6}>
                        <Typography className={classes.title} component="h2">
                          Shareholders
                        </Typography>

                        <SelectMember
                          type="shareholder"
                          managementCompanyId={managementCompanyId}
                          onSelectionChange={(items) => {
                            setFieldValue("selectedShareholders", items);
                            const selectedDecisionMakers =
                              compareDecisionMakers(
                                values.selectedDecisionMakers,
                                items
                              );
                            setFieldValue(
                              "selectedDecisionMakers",
                              selectedDecisionMakers
                            );
                            setFieldValue("members", [...values.selectedManagers,...values.selectedBoardMembers,...items]);

                          }}
                          enableCheckbox
                          selectedMembers={values.members}
                          isApproved={false}
                        />
                      </Grid>
                      <Grid item sm={12} md={6} lg={6}>
                        <Typography className={classes.title} component="h2">
                          Managers
                        </Typography>
                        <SelectMember
                          type="manager"
                          managementCompanyId={managementCompanyId}
                          onSelectionChange={(items) => {
                            setFieldValue("selectedManagers", items);
                            const selectedDecisionMakers =
                              compareDecisionMakers(
                                values.selectedDecisionMakers,
                                items
                              );
                            setFieldValue(
                              "selectedDecisionMakers",
                              selectedDecisionMakers
                            );
                            setFieldValue("members", [...values.selectedBoardMembers,...values.selectedShareholders,...items]);

                          }}
                          enableCheckbox
                          selectedMembers={values.members}
                          isApproved={false}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card title={getTranslation("Decision Makers","Décideurs","Entscheidungsträger")} className={classes.card}>
                  <CardContent>
                    <SelectMember
                      managementCompanyId={managementCompanyId}
                      selectedMembers={values.selectedDecisionMakers}
                      options={values.members}
                      onSelectionChange={(items) => {
                        setFieldValue("selectedDecisionMakers", items);
                      }}
                      enableCheckbox
                      isApproved={false}
                    />
                  </CardContent>
                </Card>

                <Card className={classes.card} title={getTranslation("Notifyees","Notifiés","Benachrichtigte")} >
                  <CardContent>
                    <Notifyee
                      contactList={contactList}
                      onPersonChanged={(persons) => {
                        setFieldValue("notifyeesList", persons);
                      }}
                    />
                  </CardContent>
                </Card>
                <Card title={getTranslation("Upload Documents","Télécharger des documents","Dokumente hochladen")} className={classes.card}>
                  <CardContent>
                    <UploadFiles
                      uploadedFiles={values.files}
                      getFileName={(x) => x.fileName}
                      // files={}
                      // onFilesChanged={(files) => {
                      //   setFieldValue("files", files);
                      // }}
                      onAddFile={(file) => {
                        setFieldValue("files", [
                          ...values.files,
                          { fileName: file },
                        ]);
                      }}
                      onDeleteFile={(file) => {
                        setFieldValue(
                          "files",
                          values.files.filter(
                            (x) => x.fileAddress != file.fileAddress
                          )
                        );
                      }}
                    />
                  </CardContent>
                </Card>
                <ClassicTextEditor
                    text={values.resolution}
                    onChange={(text) => {
                      setFieldValue("resolution", text);
                      if (Object.keys(errors).length > 0) {
                        enqueueSnackbar("Some fields are missing.", {
                          variant: "error",
                        });
                      }
                      handleSubmit();
                    }}
                    showButton
                  />
                <br />
                <br />
              </CardContent>
              
            </Form>
          )}
        </Formik>
    </FormLoader>
  );
};

export default GenerateCircularResolution;
