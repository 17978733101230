import React, { useEffect, useState } from "react";
import Table from "../../../../components/table";
import DeleteDialog from "../../../../components/DeleteDialog";
import { Create, Get } from "../../../../actions";
import AddSection from "./addSection";
import {
	Get_AllSections_URL,
	Post_DeleteSection_URL,
	Post_UpsertSection_URL,
} from "../../../../constants/apiUrls";
const columns = [
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: "Sections",
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: "Actions",
	},
];

const SectionList = () => {
	/////////// dd Dialog ///////////////
	const [open, setOpen] = useState(false);

	///////// Delete Dialog ///////////////
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [deleteRow, setDeleteRow] = useState(null);
	/////////// handler states ///////////
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let reportTemplateSections = localStorage.getItem("reportTemplateSections");
	if (reportTemplateSections == null) {
		localStorage.setItem("reportTemplateSections", 10);
		reportTemplateSections = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(reportTemplateSections);
	const [rowData, setRowData] = useState([]);
	const [editSection, setEditSection] = useState(null);
	/////////////// Table Handlers ///////////////
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("reportTemplateSections", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleSearch = (searchString) => {
		setSearchString(searchString);
	};
	const handleDeleteSection = () => {
		setIsDeleting(true);
		Create(
			{ id: deleteRow.id },
			Post_DeleteSection_URL,
			null,
			(resp) => {
				setIsDeleting(false);
				setOpenDeleteDialog(false);
				var temp = rowData.filter((x) => x.id !== deleteRow.id);
				setRowData(temp);
				setOpenDeleteDialog(false);
				setIsDeleting(false);
				setDeleteRow(null);
			},
			(error) => {
				setIsDeleting(false);
				setOpenDeleteDialog(false);
				setDeleteRow(null);
			}
		);
	};
	useEffect(() => {
		loadSections();
	}, []);
	const loadSections = () => {
		Get(
			{},
			Get_AllSections_URL,
			null,
			(resp) => {
				setRowData(resp.data);
			},
			(error) => {
				console.log(error);
			}
		);
	};
	const handleCreateSection = (values, actions) => {
		Create(
			values,
			Post_UpsertSection_URL,
			null,
			(resp) => {
				loadSections();
				actions.setSubmitting(false);
				actions.resetForm();
				setEditSection(null);
				setOpen(false);
			},
			(error) => {
				actions.setSubmitting(false);
				actions.resetForm();
			}
		);
	};
	return (
		<div>
			<Table
				dense
				title="Sections"
				colums={columns}
				sortBy="title"
				rows={rowData}
				count={rowData.length}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				viewSearch
				handleSearch={handleSearch}
				searchString={searchString}
				viewEdit
				viewCreate
				onCreate={() => {
					setEditSection(null);
					setOpen(true);
				}}
				onEdit={(row) => {
					setEditSection(row);
					setOpen(true);
				}}
				viewDelete
				onDelete={(row) => {
					setDeleteRow(row);
					setOpenDeleteDialog(true);
				}}
			/>
			<DeleteDialog
				title="Delete Section"
				deleting={isDeleting}
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				onSubmit={handleDeleteSection}
			/>
			<AddSection
				open={open}
				onClose={() => {
					setEditSection(null);
					setOpen(false);
				}}
				editSection={editSection}
				onSubmit={(values, actions) => handleCreateSection(values, actions)}
			/>
		</div>
	);
};

export default SectionList;
