import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { act } from "react-dom/test-utils";
import { useParams } from "react-router";
import { Create, Get } from "../../../actions";
import Table from "../../../components/table";
import {
	Get_AllAvailabilities_URL,
	GET_Contact_URL,
	Post_AddNewAvailability_URL,
	Post_DeleteAvailability_URL,
	Post_UpdateAvailability_URL,
	Post_UpsertAvailableDay_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import { ContactSubmenu } from "../../../layout/subMenu/meeting";
import { AddHoliday } from "../../settings/components";
import { WeekDaysAvailability } from "./weekDaysAvailability";
const columns = [
	{
		id: "startDate",
		numeric: false,
		disablePadding: true,
		format: "date",
		label: getTranslation("Start Date", "Date de début", "Startdatum"),
	},
	{
		id: "endDate",
		numeric: false,
		disablePadding: true,
		format: "date",
		label: getTranslation("End Date", "Date de fin", "Enddatum"),
	},
	{
		id: "comments",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Comments", "Commentaires", "Kommentare"),
	},
	{
		id: "actions",
		numeric: true,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const ContactAvailabilityList = () => {
	const { contactId } = useParams();
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(0);
	let spvContactHolidays = localStorage.getItem("spvContactHolidays");
	if (spvContactHolidays == null) {
		localStorage.setItem("spvContactHolidays", 10);
		spvContactHolidays = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvContactHolidays);
	const [searchString, setSearchString] = useState("");
	const [editDay, setEditDay] = useState(null);
	const [availabilitiesLoading, setAvailabilitiesLoading] = useState(false);
	const [openAddHoliday, setOpenAddHoliday] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [submitting, setSubmitting] = useState(false);
	const [availableDays, setAvailableDays] = useState([]);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvContactHolidays", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	///////// load All Availabilities ///////////////
	useEffect(() => {
		loadAllAvailabilities({
			contactId: contactId,
		});
	}, [searchString, page, rowsPerPage, contactId]);

	const loadAllAvailabilities = () => {
		setAvailabilitiesLoading(true);
		Get(
			{
				contactId: contactId,
			},
			Get_AllAvailabilities_URL,
			null,
			(resp) => {
				setRows(resp.data);
				setAvailabilitiesLoading(false);
			},
			(error) => {
				setAvailabilitiesLoading(false);
			}
		);
	};

	////////// Add Availability ///////////////

	const handleAddAvaialability = (values, actions) => {
		setSubmitting(true);
		Create(
			{
				...values,
				contactId: contactId,
			},
			editDay ? Post_UpdateAvailability_URL : Post_AddNewAvailability_URL,
			null,
			(resp) => {
				if (editDay) {
					enqueueSnackbar(
						getTranslation(
							"Holiday Updated Successfully",
							"Jour férié mis à jour avec succès",
							"Feiertag erfolgreich aktualisiert"
						),
						{
							variant: "success",
						}
					);
				} else
					enqueueSnackbar(
						getTranslation(
							"Holiday Added successfully",
							"Journée ajoutée avec succès",
							"Feiertag erfolgreich hinzugefügt"
						),
						{
							variant: "success",
						}
					);
				setOpenAddHoliday(false);
				loadAllAvailabilities();
				// if (editDay) {
				//   const newRows = rows.map((row) => {
				//     if (row.id === resp.data.id) {
				//       return resp.data;
				//     }
				//     return row;
				//   });
				//   setRows(newRows);
				// } else {
				//   setRows([...rows, resp.data]);
				// }
				setSubmitting(false);
				setEditDay(null);
			},
			(error) => {
				setOpenAddHoliday(false);
				setSubmitting(false);
				enqueueSnackbar("Error Adding Holiday ", { variant: "error" });
			}
		);
	};

	const handleDelete = (row) => {
		setSubmitting(true);
		Create(
			{
				id: row.id,
			},
			Post_DeleteAvailability_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Holiday Deleted Successfully",
						"Journée supprimée",
						"Feiertag erfolgreich gelöscht"
					),
					{
						variant: "success",
					}
				);
				setOpenAddHoliday(false);
				var rowsCopy = [...rows];
				var index = rowsCopy.indexOf(row);
				rowsCopy.splice(index, 1);
				setRows(rowsCopy);
				setSubmitting(false);
			},
			(error) => {
				setOpenAddHoliday(false);
				setSubmitting(false);
				enqueueSnackbar("Error Deleting Holiday ", { variant: "error" });
			}
		);
	};

	const handleEditAvailability = (row) => {
		setEditDay(row);
		setOpenAddHoliday(true);
	};
	useEffect(() => {
		loadContact();
	}, [contactId]);
	const loadContact = () => {
		Get(
			{
				id: contactId,
			},
			GET_Contact_URL,
			null,
			(resp) => {
				setAvailableDays(resp.data.availableDays);
			},
			(error) => {}
		);
	};

	/////////// Handle available week days ///////////////
	const handleWeeklyAvaialability = (value, isChecked) => {
		console.log("jksdghjkhsfdgfg", isChecked);
		Create(
			{
				contactId: contactId,
				day: value,
			},
			Post_UpsertAvailableDay_URL,
			null,
			(resp) => {
				if (isChecked) {
					enqueueSnackbar(
						getTranslation(
							"Day Added Successfully",
							"Jour ajouté avec succès",
							"Tag erfolgreich hinzugefügt"
						),
						{
							variant: "success",
						}
					);
				} else {
					enqueueSnackbar(
						getTranslation(
							"Day Deleted Successfully",
							"Jour supprimé avec succès",
							"Tag erfolgreich gelöscht"
						),
						{
							variant: "success",
						}
					);
				}
				loadContact();
			},
			(error) => {
				enqueueSnackbar(error?.data, { variant: "error" });
			}
		);
	};

	return (
		<>
			<ContactSubmenu contactId={contactId} />
			<WeekDaysAvailability
				availableDays={availableDays}
				handleChangeAvailability={(value, isChecked) =>
					handleWeeklyAvaialability(value, isChecked)
				}
			/>
			<br />
			<Table
				auto
				dense
				loading={availabilitiesLoading}
				title={getTranslation("My Holidays", "Mes congés", "Meine Urlaub")}
				colums={columns}
				sortBy="startDate"
				rows={rows}
				count={rows.length}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewCreate
				onCreate={() => {
					setEditDay(null);
					setOpenAddHoliday(true);
				}}
				viewDelete
				onDelete={(row) => handleDelete(row)}
				viewSearch
				onSearch={(searchString) => setSearchString(searchString)}
				viewEdit
				onEdit={(row) => handleEditAvailability(row)}
			/>
			<AddHoliday
				editDay={editDay}
				loading={submitting}
				open={openAddHoliday}
				onClose={() => setOpenAddHoliday(false)}
				onSubmit={(values, actions) => handleAddAvaialability(values, actions)}
			/>
		</>
	);
};
export default ContactAvailabilityList;
