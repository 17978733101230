import {
	Box,
	CardContent,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { Create, Get } from "../../../../../../actions";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import Card from "../../../../../../components/Card";
import {
	GET_AllContacts_URL,
	GET_AllTeamMembers_URL,
	Get_Spv_TeamMembers_URL,
	GET_AllContactList_URL,
	Post_Meeting_UpsertRegularMeetingDraft,
} from "../../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { AddGuest } from "../guest/addGuest";
import { GuestList } from "../guest/guestList";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CeresTeamMemberResponsibilites } from "../../../../../../constants/ceresTeamMemberResponsibilities";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../../../../../components/table";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { MinutesProgressBar } from "../../../minutesProgressBar";

const Minutes = ({ NextStep, BackStep, initialValues }) => {
	const columns = [
		{
			id: "name",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Name", "Nom", "Name"),
		},
		{
			id: "responsibility",
			numeric: false,
			disablePadding: true,
			label: getTranslation(
				"Responsibility",
				"Responsabilité",
				"Verantwortung"
			),
		},
	];

	const { managementCompanyId } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const [contacts, setContacts] = useState([]);
	const [team, setTeam] = useState({});
	const [loadingTeamMembers, setloadingTeamMembers] = useState(false);
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	let GmRegularMeetingMinutes = localStorage.getItem("GmRegularMeetingMinutes");
	if (GmRegularMeetingMinutes == null) {
		localStorage.setItem("GmRegularMeetingMinutes", 10);
		GmRegularMeetingMinutes = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(GmRegularMeetingMinutes);
	const [rows, setRows] = useState([]);
	const [nextButtonClicked, setNextButtonClicked] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [draftLoading, setDraftLoading] = useState(false);

	const loadTeamMembers = () => {
		setloadingTeamMembers(true);
		Get(
			{
				id: managementCompanyId,
			},
			Get_Spv_TeamMembers_URL,
			history,
			(resp) => {
				setTeam(resp.data);
				setloadingTeamMembers(false);
			},
			(error) => {}
		);
	};
	useEffect(() => {
		loadTeamMembers();
		loadContacts();
	}, []);
	const loadContacts = () => {
		Get(
			{},
			// GET_AllContacts_URL,
			GET_AllContactList_URL,
			null,
			(resp) => {
				const arr = [];
				resp?.data?.forEach((item) => {
					item.contactId = item.id;
					// item.memberId=item.id;
					arr.push(item);
				});
				setContacts(arr);
			},
			(error) => {}
		);
	};

	const setActiveState = (activeState) => ({
		type: "SET_ACTIVE_STATE",
		payload: {
			activeState,
		},
	});

	const setMeetingData = (meetingData) => ({
		type: "SET_MEETING_DATA",
		payload: {
			meetingData,
		},
	});

	const basicValidationSchema = Yup.object().shape({
		minutesFundManagerId: Yup.number().nullable(false).min(1,"Field is required").required("Field is required"),
		//contacts: Yup.object().nullable().required("Field is required"),
	});

	console.log(contacts);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("GmRegularMeetingMinutes", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleSearchManagementCompanyFund = (searchString) => {
		setSearchString(searchString);
	};

	const getSelectedContacts = (minutesFundManagerId) =>
		contacts.length
			? contacts.find((x) => x.id == minutesFundManagerId)
			: {};

	return (
		<Card title={getTranslation("Minutes", "Minutes", "Protokoll")}>
			<CardContent>
			{/* <MinutesProgressBar
						//meetingInfo={meetingInfo}
						getTranslation={getTranslation}
					/> */}
						<br />
					<br />
				<Formik
					initialValues={initialValues}
					validationSchema={basicValidationSchema}
					enableReinitialize
					onSubmit={(values,actions) => {
						actions.setSubmitting(true)
							let payloadObj = {
								activeStep: 3,
								meetingDraftVm: { ...values, activeStep: 4 },
							};
						
							Create(
								payloadObj,
								Post_Meeting_UpsertRegularMeetingDraft,
								null,
								(resp) => {
									NextStep(values);
						actions.setSubmitting(false)
								},
								(error) => {
								actions.setSubmitting(false)

									enqueueSnackbar("Something Went Wrong", {
										variant: "error",
									});
								}
							);
						
					}}
				>
					{({
						values,
						handleSubmit,
						setFieldValue,
						getFieldProps,
						setTouched,
						errors,
						touched,
						isSubmitting
					}) => (
						<Form>
							{console.log("aksjlgdjkhasgdjhksag",errors)}
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<Autocomplete
										fullWidth
										id="combo-box-demo"
										size="small"
										options={contacts}
										getOptionLabel={(option) => option?.fullName}
										//value={values.contacts}
										value={
											values.minutesFundManagerId ? getSelectedContacts(values.minutesFundManagerId):{}
										}
										defaultValue={
											{}
										}
										onChange={(event, value) => {
											setFieldValue("minutesFundManagerId", value?.id);
										}}
										onBlur={() => {
											setTouched("minutesFundManagerId", true);
											setTouched("contacts", true);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label={getTranslation(
													"Fund Manager",
													"Le chef du financement",
													"Fondsmanager"
												)}
												variant="outlined"
												error={Boolean(errors.minutesFundManagerId)}
											/>
										)}
									/>
								</Grid>
							</Grid>

							<br />
							<br />

							<Table
								dense
								loading={loadingTeamMembers}
								title={getTranslation(
									"Team Members",
									"Membres de l'équipe",
									"Teammitglieder"
								)}
								colums={columns}
								sortBy="title"
								rows={team}
								count={team.length}
								page={page}
								rowsPerPage={rowsPerPage}
								handleChangePage={(event, newPage) =>
									handleChangePage(event, newPage)
								}
								handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
								viewSearch
								onSearch={(searchString) =>
									handleSearchManagementCompanyFund(searchString)
								}
							/>

							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="space-between"
								m={2}
							>
								<ButtonWithLoading
									title={getTranslation("Back", "Précédent", "Zurück")}
									color="primary"
									variant="contained"
									size="large"
									onClick={() => BackStep()}
								/>

								<Box display="flex">
									{/* <div style={{ marginRight: "10px" }}>
										<ButtonWithLoading
											title={getTranslation(
												"Save as Draft",
												"Save as Draft",
												"Save as Draft"
											)}
											color="primary"
											variant="contained"
											size="large"
											loading={draftLoading}
											//onClick={handleSubmit}
											onClick={() => {
												setNextButtonClicked(false);
												handleSubmit();
											}}
										/>
									</div> */}

									<ButtonWithLoading
										title={getTranslation("Next", "Suivant", "Weiter")}
										color="primary"
										variant="contained"
										size="large"
										//onClick={handleSubmit}
										loading={isSubmitting}
										onClick={() => {
											if(team.findIndex(x=>x.responsibility=='minutes')==-1){
												enqueueSnackbar(getTranslation("Company does not have minute member, please add one to continue.","Company does not have minute member, please add one to continue.","Company does not have minute member, please add one to continue."),{variant:'info'})
											return;
											}
											if(team.findIndex(x=>x.responsibility=='internalreview')==-1){
												enqueueSnackbar(getTranslation("Company does not have internal review member, please add one to continue.","Company does not have internal review member, please add one to continue.","Company does not have internal review member, please add one to continue."),{variant:'info'})
											return;
											}
											handleSubmit();
										}}
									/>
								</Box>
								{/* <ButtonWithLoading
                  title={getTranslation("Next", "Suivant", "Weiter")}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                /> */}
							</Box>
						</Form>
					)}
				</Formik>
			</CardContent>
		</Card>
	);
};

export default Minutes;
