import React from "react";
import {
  Container,
  CssBaseline,
  Button,
  Typography,
  TextField,
  
  Box,
  Grid,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import useStyles from "../../assests/styles/views/auth/login";
import Copyright from "../../utils/copyRightUtils";
import {Logo} from "../../components/Logo";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { getTranslation } from "../../heplers/translationHelper";
import { Create } from "../../actions";
import { Post_ForgetPassword_URL } from "../../constants/apiUrls";
import { useSnackbar } from "notistack";


const ForgetPassword = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.logo}>
          <Logo height="150px" />
        </div>
        <Typography component="h1" variant="h2" color="secondary">
          Forget Password
        </Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required("Email is required")
              .email("Please enter a valid Email"),
           //password: Yup.string().required("Password is required"),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true)
            Create(
              values,
              Post_ForgetPassword_URL,
              null,
              resp=>{
                enqueueSnackbar("Password reset link sent to your email", { variant: "success" });
              },
              error=>{
                enqueueSnackbar("Something went wrong", { variant: "error" });
              }
            )
          }}
        >
          {
            ({ values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,})=>(
              <form className={classes.form} noValidate>
              <Box mt={3}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={getTranslation("Email Address","Adresse e-mail","E-Mail-Addresse")}
                name="email"
                autoComplete="email"
                autoFocus
                size="small"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              </Box>
    
    
              <ButtonWithLoading
                type="submit"
                title={getTranslation("Submit","Nous faire parvenir","einreichen")}
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                className={classes.submit}
                loading={isSubmitting}
              />
    
              <Grid container>
                <Grid item xs>
                  <Link to="/login"  color="secondary" variant="body1">
                   <Box display="flex" alignItems="center" mt={2} >
                   <KeyboardBackspaceIcon color="disabled" />
                   &nbsp;
                   <Typography color="textSecondary"  >Back To Login</Typography>
                   </Box>
                  </Link>
                </Grid>
              </Grid>
            </form>
            )
          }
        </Formik>
   
      </div>
      <Box mt={3}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default ForgetPassword;
