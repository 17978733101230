import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
  CardHeader,
} from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { Get_SystemSetting_URL, Post_SettingForMeeting_URL } from "../../constants/apiUrls";
import * as Yup from "yup";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { useNavigate } from "react-router-dom";
import { Create, Get } from "../../actions";
import { getTranslation } from "../../heplers/translationHelper";
import { SettingsSubmenu } from "../../layout/subMenu";
import { useDispatch } from "react-redux";
export const Settings = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch({ type: "Clear_All_BreadCrumb" });
    dispatch({
      type: "Add_BreadCrumb",
      payload: { title: "Settings", url: "/settings" },
    });
  }, []);
  const initialValues = {
    setInvitationInterval: 0,
    setMinutesCommentsInterval: 0,
  };

  const basicValidationSchema = Yup.object().shape({
    setInvitationInterval: Yup.number().required("Field is required"),
    setMinutesCommentsInterval: Yup.number().required("Field is required"),
  });

  const [settings,setSettings]=useState({});
  useEffect(()=>{
    Get(
      {},
      Get_SystemSetting_URL,
      history,
      (resp) => {
        setSettings(resp.data);
      },
      (error) => { }
    );
  },[])
  return (
    <>
    <SettingsSubmenu/>
    <Formik>
      <Card>
        <CardHeader title={getTranslation("Settings for meeting","Paramètres pour la réunion","Einstellungen für Besprechungen")} />
        <Formik
          enableReinitialize
          initialValues={settings}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            Create(
              values,
              Post_SettingForMeeting_URL,
              history,
              (resp) => {
                actions.setSubmitting(false);
                enqueueSnackbar("Settings Updated for Meeting", {
                  variant: "info",
                });
              },
              (error) => {
                actions.setSubmitting(false);
                enqueueSnackbar("Something went wrong", { variant: "error" });
              }
            );
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
            handleChange,
          }) => (
            <form>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} md={3}></Grid>
                  <Grid item xs={3} sm={3} md={3}>
                    <Typography>Set invitation interval</Typography>
                  </Grid>

                  <Grid item xs={3} sm={3} md={3}>
                    <TextField
                      fullWidth
                      id="setInvitationInterval"
                      label={getTranslation("days","journées","Tage")}
                      required
                      type="number"
                      size="small"
                      variant="outlined"
                      name="setInvitationInterval"
                      {...getFieldProps("setInvitationInterval")}
                      error={
                        touched.setInvitationInterval &&
                        Boolean(errors.setInvitationInterval)
                      }
                      helperText={
                        touched.setInvitationInterval &&
                        errors.setInvitationInterval
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3} md={3}></Grid>

                  <Grid item xs={3} sm={3} md={3}></Grid>
                  <Grid item xs={3} sm={3} md={3}>
                    <Typography>Set minutes/comments interval</Typography>
                  </Grid>

                  <Grid item xs={3} sm={3} md={3}>
                    <TextField
                      fullWidth
                      id="setMinutesCommentsInterval"
                      label={getTranslation("Days","journées","Tage")}
                      required
                      size="small"
                      variant="outlined"
                      name="setMinutesCommentsInterval"
                      {...getFieldProps("setMinutesCommentsInterval")}
                      error={
                        touched.setMinutesCommentsInterval &&
                        Boolean(errors.setMinutesCommentsInterval)
                      }
                      helperText={
                        touched.setMinutesCommentsInterval &&
                        errors.setMinutesCommentsInterval
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3} md={3}></Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ButtonWithLoading
                    title={getTranslation("Save","sauvegarder","Speichern")}
                    size="large"
                    variant="contained"
                    color="primary"
                    // loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Box>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </Formik>
    </>
  );
};
