import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { Create, Get, GetAllLegalForms } from "../../actions";
import TableComponent from "../../components/table";
import { Get_AllLegalFormListItems_URL, Get_AllLegalForm_URL, Post_DeleteBasicLegalForm_URL, Post_InsertBasicLegalForm_URL, Post_UpdateBasicLegalForm_URL } from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
import { SettingsSubmenu } from "../../layout/subMenu";
import AddLegalForm from "./components/addLegalForm";
const columns = [
    {
      id: "legalForm",
      label: getTranslation("Legal Form", "Legal Form", "Legal Form"),
    },
    {
      id: "country",
      label: getTranslation("Country", "Country", "Country"),
    },
    {
        id: "isGeneralPartnerNeeded",
        label: getTranslation("General Partner Needed", "General Partner Needed", "General Partner Needed"),
        component: ({ row }) => row.isGeneralPartnerNeeded ? <Typography>{getTranslation("Yes", "Yes", "Yes")}</Typography> : <Typography>{getTranslation("No", "No", "No")}</Typography>
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      align: "right",
      label: getTranslation("Actions", "Actions", "Aktionen"),
    },
  ];
export const LegalFormList=()=>{

    const [searchString, setSearchString] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowsLoading, setRowsLoading] = useState(false);
    const [openLegalFormDialog, setOpenLegalFormDialog] = useState(false);
    const [rows, setRows] = useState([]);
    const [editLegalForm, setEditLegalForm] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
      };
    //////////// Get All Legal Forms ////////////
    useEffect(() => {
        loadLegalForms();
    }, []);
    const loadLegalForms = () => {
        setRowsLoading(true);
        Get(
            {},
            Get_AllLegalFormListItems_URL,
            null,
            (resp) => {
                setRowsLoading(false);
                setRows(resp.data);
            },
            (error) => {
                setRowsLoading(false);
                enqueueSnackbar("Error loading Holidays", { variant: "error" });
            }
        );
    };

    const handleEditLegalForm=(values,actions)=>{
      Create(
        values,
        editLegalForm? Post_UpdateBasicLegalForm_URL : Post_InsertBasicLegalForm_URL,
        null,
        resp=>{
          actions.setSubmitting(false);
          setEditLegalForm(null)
          setOpenLegalFormDialog(false)
          loadLegalForms();
          enqueueSnackbar("New Legal Form Added", { variant: "success" });
        },
        error=>{
          enqueueSnackbar("System was not able to handle your request please try later", { variant: "error" });
          actions.setSubmitting(false);

        }
      )
    }

    const handleDeleteLegalForm=(row)=>{
      Create(
        {"ids":[row.id]},
        Post_DeleteBasicLegalForm_URL,
        null,
        resp=>{
          loadLegalForms();
          enqueueSnackbar("Legal Form Deleted", { variant: "success" });
        },
        error=>{
          enqueueSnackbar("System was not able to handle your request please try later", { variant: "error" });
        }
      )
  }

  return <>
    <SettingsSubmenu />
    <TableComponent
      auto
      dense
      loading={rowsLoading}
      title={getTranslation(
        "Legal Forms",
        "Legal Forms",
        "Legal Forms"
      )}
      colums={columns}
      rows={rows}
      count={rows?.length}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={(e, newPage) => handleChangePage(e, newPage)}
      handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
      viewSearch
      viewEdit
      onEdit={(row) => {
        setEditLegalForm(row);
        setOpenLegalFormDialog(true)
      }}
      viewDelete
      onDelete={(row) => handleDeleteLegalForm(row)}
      viewCreate
      onCreate={() => {
        setEditLegalForm(null);
        setOpenLegalFormDialog(true)
      }}
    />
    <AddLegalForm
      editLegalForm={editLegalForm}
      onClose={() => {
        setOpenLegalFormDialog(false)
        setEditLegalForm(null)
      }}
      onSubmit={handleEditLegalForm}
      open={openLegalFormDialog}
    />
  </>
}