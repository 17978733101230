import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../../components/Card";
import Switch from "../../../../components/Switch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { FormLoader } from "../../../../components/FormLoader";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Create, Get } from "../../../../actions";
import {
  Post_GenerateMeetings_URL,
  Get_AllCeresServices_URL,
  Get_File_URL,
  GET_AllUsersWithoutAuthorization_URL,
  SAVE_Contact_URL,
  GET_AllContactList_URL,
  Get_AllManagementCompanyFeeServicesByType_URL,
  Get_AgendaTemplateList_URL,
  Get_AllAgendaTemplateItems_URL,
  Post_GenerateAdhocMeetings_URL,
} from "../../../../constants/apiUrls";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { MyTimePicker } from "../../../../components/TimePicker";
import { DatePicker } from "../../../../components/DatePicker";
import { BoardMembers } from "../regular/boardMembers";
import { AskingAgendaList } from "../regular/components/agenda/askingAgendaList";
import { AddGuest } from "../regular/components/guest/addGuest";
import { GuestList } from "../regular/components/guest/guestList";
import { LanguageSelection } from "../../../../constants/languages";
import { UploadFiles } from "../../../../components/Upload";
import { getTranslation } from "../../../../heplers/translationHelper";

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Name", "Nom", "Name"),
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Email", "E-mail", "Email"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions", "Actions", "Aktionen"),
  },
];
const GenerateAdhoc = ({ managementCompanyId, initialValues, NextStep, BackStep }) => {
  const [userListLoading, setUserListLoading] = useState(false);
  const [ceresServiceLoading, setCeresServiceLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ceresServices, setCeresServices] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [filesList, setFilesList] = useState();
  const [enableAddNew, setEnableAddNew] = useState(false);

  useEffect(() => {
    setUserListLoading(true);
    Get(
      {},
      GET_AllUsersWithoutAuthorization_URL,
      history,
      (resp) => {
        setUserList(resp.data);
        setUserListLoading(false);
      },
      (error) => { }
    );
  }, []);

  useEffect(() => {
    loadCeresServices(0);
    loadContacts();
    loadTemplates();
  }, []);

  const loadCeresServices = (feeType) => {
    setCeresServiceLoading(true);
    Get(
      {
        id: managementCompanyId,
        feeType: feeType,
      },
      Get_AllManagementCompanyFeeServicesByType_URL,
      null,
      (resp) => {
        setCeresServices(resp.data);
        setCeresServiceLoading(false);
      },
      (error) => { }
    );
  };

  const loadContacts = () => {
    setContactsLoading(true);
    Get(
      {},
      GET_AllContactList_URL,
      null,
      (resp) => {
        const arr = [];
        resp.data.forEach((item) => {
          item.contactId = item.id;
          // item.memberId=item.id;
          arr.push(item);
        });
        setContacts(arr);
        setContactsLoading(false);
      },
      (error) => { }
    );
  };

  const loadTemplates = () => {
    setTemplatesLoading(true);
    Get(
      {},
      Get_AgendaTemplateList_URL,
      null,
      (resp) => {
        setTemplates(resp.data);
        setTemplatesLoading(false);
      },
      (error) => { }
    );
  };

  const loadTemplateItems = (templateId, callback) => {
    // setTemplatesLoading(true);
    Get(
      {
        agendaTemplateId: templateId,
      },
      Get_AllAgendaTemplateItems_URL,
      null,
      (resp) => {
        callback(resp.data);
        // setTemplatesLoading(false);
      },
      (error) => { }
    );
  };

  // const initialValues = {
  //   subject: "",
  //   meetingTime: new Date(),
  //   meetingDate: new Date(),
  //   language: "",
  //   address: "",
  //   personInchargeId: "",
  //   meetingType: 2,
  //   managementCompanyId: managementCompanyId,
  //   companyCeresServiceId: 0,
  //   meetingMembers: [],
  //   files: [],
  //   guests: [],
  //   agendas: [],
  // };

  const basicValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Field is required"),
    meetingTime: Yup.string().required("Field Time is requied"),
    meetingDate: Yup.string().required("Field is required"),
    language: Yup.string().required("Field is required"),
    personInchargeId: Yup.string().required("Field is required"),
    companyCeresServiceId: Yup.number().min(1).required("Field is required"),
  });

  return (
    <>
      <FormLoader progress={userListLoading}>
        {/* <CardHeader title='Details' /> */}
        <Formik
          initialValues={initialValues}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            if(values.meetingMembers.length==0){
              enqueueSnackbar("At least 1 member should be selected.",{variant:"error"})
              return;
            }
            NextStep(values);
          }
          }
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
            handleChange,
          }) => (
            <Form>
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToggleButtonGroup
                    size="small"
                    id="meetingType"
                    value={values.meetingType}
                    exclusive
                    onChange={(event, newAlignment) => {
                      if (newAlignment) {
                        setFieldValue("meetingType", newAlignment);
                        setFieldValue("meetingMembers", []);
                      } else {
                        setFieldValue("meetingType", 2);
                      }
                    }}
                  >
                    <ToggleButton value={2} aria-label="left aligned">
                      <Typography>
                        {getTranslation("Board", "Planche", "Tafel")}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="centered">
                      <Typography>
                        {getTranslation("Shareholder", "Actionnaire", "Aktionär")}
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Autocomplete
                    options={ceresServices}
                    getOptionLabel={(option) => option.ceresServiceTitle}
                    size="small"
                    value={values.companyCeresService}
                    onChange={(e, value) => {
                      setFieldValue("companyCeresServiceId", value?.id);
                      setFieldValue("companyCeresService", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Ceres Service",
                          "Service Cérès",
                          "Ceres-Dienst"
                        )}
                        variant="outlined"
                        error={
                          touched.companyCeresServiceId &&
                          Boolean(errors.companyCeresServiceId)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    id="subject"
                    label={getTranslation("Subject", "Sujette", "Fach")}
                    required
                    size="small"
                    variant="outlined"
                    name="subject"
                    {...getFieldProps("subject")}
                    error={touched.subject && Boolean(errors.subject)}
                    helperText={touched.subject && errors.subject}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Autocomplete
                    options={userList}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    onChange={(e, value) => {
                      setFieldValue("personInchargeId", value?.id);
                      setFieldValue("personIncharge", value);
                    }}
                    value={values.personIncharge}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Person Incharge",
                          "Personne en charge",
                          "Verantwortlicher"
                        )}
                        variant="outlined"
                        error={
                          touched.personInchargeId &&
                          Boolean(errors.personInchargeId)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <LanguageSelection
                    fullWidth
                    id="language"
                    label={getTranslation("Language", "Langue", "Sprache")}
                    required
                    size="small"
                    variant="outlined"
                    name="language"
                    selected={values.language}
                    onChange={(e, language) => setFieldValue("language", language)}
                    error={touched.language && Boolean(errors.language)}
                    helperText={touched.language && errors.language}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <DatePicker
                    fullWidth
                    variant="outlined"
                    label={getTranslation(
                      "Meeting Date",
                      "Date de la réunion",
                      "Datum des Treffens"
                    )}
                    value={values.meetingDate}
                    onChange={(value) => {
                      setFieldValue("meetingDate", value);
                    }}
                    required
                    error={touched.meetingDate && Boolean(errors.meetingDate)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <MyTimePicker
                    fullWidth
                    id="meetingTime"
                    variant="inline"
                    size="small"
                    // id="Incorporation-Date-inline"
                    label={getTranslation("Time", "Temps", "Zeit")}
                    name="meetingTime"
                    value={values.meetingTime}
                    onChange={(value) => {
                      setFieldValue("meetingTime", value);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "Meeting Time",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    error={touched.meetingTime && Boolean(errors.meetingTime)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <MyTimePicker
                    fullWidth
                    id="meetingDuration"
                    variant="inline"
                    size="small"
                    label={getTranslation(
                      "Meeting Duration",
                      "Durée de la réunion",
                      "Treffendauer"
                    )}
                    name="meetingDuration"
                    value={values.meetingDuration}
                    onChange={(value) => {
                      setFieldValue("meetingDuration", value);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "Meeting Duration",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    error={
                      touched.meetingDuration && Boolean(errors.meetingDuration)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    multiline
                    label="Address"
                    required
                    size="small"
                    variant="outlined"
                    {...getFieldProps("address")}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  {values.meetingType === 2 ? (
                    <BoardMembers
                      title={getTranslation("Board Members", "Membres du conseil d'administration", "Vorstandsmitglieder")}
                      meetingType={2}
                      MCId={managementCompanyId}
                      onSelectionChange={(members) =>
                        setFieldValue("meetingMembers", members)
                      }
                      selectedMembers={values.meetingMembers}
                    />
                  ) : (
                    <BoardMembers
                      title={getTranslation("Shareholder Members", "Membres actionnaires", "Aktionärsmitglieder")}
                      meetingType={3}
                      MCId={managementCompanyId}
                      onSelectionChange={(members) =>
                        setFieldValue("meetingMembers", members)
                      }
                      selectedMembers={values.meetingMembers}
                    />
                  )}
                </Grid>
              </Grid>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                m={2}
              >
                <ButtonWithLoading
                  title={getTranslation("Next", "Suivant", "Weiter")}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </FormLoader>
    </>
  );
};
export default GenerateAdhoc;
