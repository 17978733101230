import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import DialogComponent from "../../../components/Dialog";
import { Get_AllMeetingActivities_URL } from "../../../constants/apiUrls";
import { Get } from "../../../actions";
import Table from "../../../components/table";
import { getTranslation } from "../../../heplers/translationHelper";
export const MeetingAuditLog = ({ meetingId, loading, open, onClose }) => {
  const columns = [
    {
      id: "user",
      numeric: "false",
      label:getTranslation("User","Utilisatrice","Benutzer"),
    },
    {
      id: "dateAndTime",
      numeric: "false",
      label:getTranslation("Date","Date","Datum"),
      format: "date",
    },
    {
      id: "dateAndTime",
      numeric: "false",
      label:getTranslation("Time","Temps","Zeit"),
      format: "time",
    },

    {
      id: "activity",
      numeric: "false",
      label:getTranslation("Activity","Activité","Aktivität"),
    },
    {
      id: "description",
      numeric: "false",
      label:getTranslation("Description","La description","Beschreibung"),
    },
  ];

  const [auditLogs, setAuditLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingMeetingLog, setLoadingMeetingLog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  useEffect(() => {
    if (meetingId) loadAuditLogMeeting();
  }, [meetingId]);

  const loadAuditLogMeeting = () => {
    setLoadingMeetingLog(true);

    Get(
      { meetingId },
      Get_AllMeetingActivities_URL,
      null,
      (resp) => {
        setLoadingMeetingLog(false);
        setAuditLogs(resp?.data);
      },
      (error) => {
        setLoadingMeetingLog(false);
      }
    );
  };
  return (
    <DialogComponent
      title={getTranslation("Audit Log","Journal d'audit","Audit-Log")}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"md"}
    >
      <Table
        auto
        size="small"
        dense
        loading={loadingMeetingLog}
        colums={columns}
        //sortBy="fullName"
        rows={auditLogs}
        count={auditLogs.length}
        page={page}
        rowsPerPage={rowsPerPage}
        viewSearch
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        // enableCheckbox={isApproved? false: enableCheckbox}
        // selected={selectedMembers}
        // //onSelectionChange={(items)=>handleOnSelectionChange(items)}
        // onSelectionChecked={handleNewItem}
        // onSelectionUnChecked={handleRemoveItem}
        // viewDelete={enableDelete}
        // onDelete={onRemove}
      />
    </DialogComponent>
  );
};
