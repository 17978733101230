import React from "react";
import { Box, IconButton, Paper, TextField } from "@material-ui/core";
import Table from "../../../../components/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Get } from "../../../../actions";
import {
	Get_AllUpcomingMeetings_URL,
	Get_AllPreviousMeetings_URL,
} from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../../heplers/translationHelper";
import { Assessment } from "@material-ui/icons";

//name, address, zipcode, Town
const columns = [
	{
		id: "meetingDate",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Date", "Date", "Datum"),
		format: "date",
	},
	{
		id: "meetingTime",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Time", "Temps", "Zeit"),
		format: "time",
	},
	{
		id: "subject",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Subject", "Sujette", "Fach"),
	},
	{
		id: "personInchargeName",
		numeric: true,
		disablePadding: true,
		label: getTranslation(
			"Person Incharge",
			"Personne en charge",
			"Verantwortlicher"
		),
	},
	{
		id: "language",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Language", "Langue", "Sprache"),
	},
	{
		id: "address",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Address", "Adresse", "Adresse"),
	},

	{
		id: "actions",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Actions", "Actions", "Aktionen"),
		align: "right",
	},
];

// handlers
//handleEditActivity

const handleEditActivity = () => {};

//handleOpenDeleteDialog
const handleOpenDeleteDialog = () => {};

// //handleCreatebuilding
// const handleCreatebuilding=()=>{
// }

//handleSearchbuilding

const UpcomingMeetings = ({ rows, loading, onFilter }) => {
	const history = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let spvPreviousMeeting = localStorage.getItem("spvPreviousMeeting");
	if (spvPreviousMeeting == null) {
		localStorage.setItem("spvPreviousMeeting", 10);
		spvPreviousMeeting = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvPreviousMeeting);
	const [upcomingMeetinLoading, setUpcomingMeetingLoading] = useState(false);
	const [legalFormsLoading, setLegalFormsLoading] = useState(false);
	const [legalForms, setLegalForms] = useState([]);
	const { managementCompanyId } = useParams();
	const [sortDirection, setSortDirection] = useState("asc");
	const [sortBy, setSortBy] = useState("meetingDate");
	useEffect(() => {
		onFilter({
			searchString,
			pageNumber: page,
			pageSize: rowsPerPage,
			sort: sortBy,
			sortDirection: sortDirection == "asc" ? 0 : 1,
		});
	}, [page, rowsPerPage, searchString, sortBy, sortDirection]);

	const handleChangePage = (event, newPage) => {
		console.log("newpage", newPage);
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvPreviousMeeting", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleSearchUpcomingMeeting = (searchString) => {
		setSearchString(searchString);
	};

	const handleMinuteReportClick = (meeting) => {
		console.log("clicked", meeting.minuteReportStatus);
		if (meeting.minuteReportStatus == 0 || meeting.minuteReportStatus == 1) {
			var url = `/meetings/${managementCompanyId}/${meeting.id}/generateMinutesReport/`;
			history(url);
		} else if (meeting.minuteReportStatus == 2) {
			var url = `/meetings/${managementCompanyId}/${meeting.id}/reviewMinutesReport/`;
			history(url);
		} else {
			var url = `/meetings/${managementCompanyId}/${meeting.id}/reviewMinutesDone/`;
			history(url);
		}
	};

	return (
		<div>
			<Table
				dense
				loading={loading}
				title={getTranslation(
					"Previous Meetings",
					"Réunions précédentes",
					"Frühere Treffen"
				)}
				colums={columns}
				sortBy={sortBy}
				sortDirection={sortDirection}
				onSorting={(newSortBy, direction) => {
					setSortBy(newSortBy);
					setSortDirection(direction);
				}}
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => handleEditActivity(row)}
				onDelete={(row) => handleOpenDeleteDialog(row)}
				// viewCreate
				//onCreate={()=>handleCreatebuilding()}
				viewSearch
				onSearch={(searchString) => handleSearchUpcomingMeeting(searchString)}
				actions={[
					{
						component: (row) => (
							<IconButton onClick={() => handleMinuteReportClick(row)}>
								{" "}
								<Assessment />
							</IconButton>
						),
					},
				]}
			/>
		</div>
	);
};
export default UpcomingMeetings;
