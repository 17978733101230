import { combineReducers } from "redux";
import auth from "./authReducer";
import contacts from "./contactsReducer";
import companies from "./companyReducer";
import legalForms from "./legalFormReducer";
import breadcrumbs from "./breadcrumbReducer";
import submenues from "./submenuReducer";
import publicActionButtons from "./publicActionButtons";
import draftMeeting from "./draftMeetingReducer";

export default combineReducers({
	auth,
	contacts,
	companies,
	legalForms,
	breadcrumbs,
	submenues,
	publicActionButtons,
	draftMeeting,
});
