import { Form, Formik } from "formik";
import DialogComponent from "../../../../components/Dialog"
import CardComponent from "../../../../components/Card";
import { Box, CardActions, CardContent, Grid, TextField } from "@material-ui/core";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../heplers/translationHelper";
import * as Yup from "yup";


export const CreateAgendaDialog=({meetingId, open,onClose,onSubmit,handleSendAddClose})=>{
    const initialValues = {
        subject: "",
        comments: "",
        meetingId: meetingId,
        addNew:false
      };
      const basicValidationSchema = Yup.object().shape({
        subject: Yup.string().required("Field is required"),
        // comments: Yup.string().required("Field is required"),
      });
  const defaultValue = initialValues;
  

    return <DialogComponent open={open} onClose={onClose} title="Create Agenda">
          <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          
          //actions.setSubmitting(true);
          onSubmit(values, actions);
        }}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue,
      }) => (
        <Form>
           <CardComponent >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item s={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="subject"
                    fullWidth
                    label={getTranslation("Subject","Sujette","Fach")}
                    required
                    size="small"
                    variant="outlined"
                    name="subject"
                    onChange={(e)=>{
                      setFieldValue("subject",e.target.value)
                      setFieldValue("comments",`<h2>${e.target.value}</h2>`)
                    }}
                    error={touched.subject && Boolean(errors.subject)}
                    helperText={touched.subject && errors.subject}
                  />
                </Grid>
                
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ButtonWithLoading
                  style={{ margin: "15px" }}
                  title={getTranslation("Save and Close","Save and Close","Save and Close")}
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={(e) => handleSubmit(e)}
                />
                <ButtonWithLoading
                  title={getTranslation("Save and Add New","Save and Add New","Save and Add New")}
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                 // onClick={handleSubmit}
                 onClick={(e)=>{
                  setFieldValue("addNew",true)
                  handleSubmit(e)
                }}
                />
              </Box>
            </CardActions>
            
          </CardComponent>
        </Form>
      )}
    </Formik>
    </DialogComponent>
}




// title={getTranslation("Compose Agenda","Composer l'ordre du jour","Agenda verfassen")}