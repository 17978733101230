import React from "react";
import {
	Box,
	Container,
	CssBaseline,
	Grid,
	Typography,
	makeStyles,
} from "@material-ui/core";
import { getTranslation } from "../heplers/translationHelper";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "100vh",
	},
	heading: {
		fontSize: "3rem",
		fontWeight: "bold",
		color: theme.palette.primary.main,
		textAlign: "center",
		textTransform: "uppercase",
		letterSpacing: "2px",
	},
	subheading: {
		fontSize: "1.5rem",
		color: theme.palette.text.secondary,
		textAlign: "center",
		margin: theme.spacing(2, 0),
	},
}));

export const MinutesAlreadyApproved = () => {
	const classes = useStyles();

	return (
		<Container component="main" className={classes.container}>
			<CssBaseline />
			<Grid container alignItems="center" justify="center">
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Typography variant="h2" className={classes.heading}>
						{getTranslation(
							"You Have Alredy Approved Minutes",
							"You Have Alredy Approved Minutes",
							"You Have Alredy Approved Minutes"
						)}
					</Typography>
				</Grid>
			</Grid>
		</Container>
	);
};
