import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Sidebar from "./sidebar";
import TopBar from "./topbar";
import Breadcrumbs from "../components/BreadCrumbs"
import useStyles from "../assests/styles/layout/main"
import clsx from 'clsx';
import { useDispatch } from "react-redux";
import { Outlet } from "react-router";





export default function DashboardLayout(props) {
  const classes = useStyles();
  const [navOpen, setNavOpen] = useState(true);
  const dispatch = useDispatch();
  useEffect(
    ()=>{
      if(window.innerWidth<1000)
        setNavOpen(false);
      else
        setNavOpen(true);
    }
    ,[])

  return (
    <div className={classes.root}>
      <TopBar className="" navOpen={navOpen} handleDrawerOpen={()=>setNavOpen(true)} />
      <Sidebar
        onMobileClose={() => setNavOpen(false)}
        openMobile={navOpen}
      />
       <main  className={clsx(classes.content, {
          [classes.contentShift]: navOpen,
        })}>
            <Box mb={2} className={classes.breadcrums} >
              <Breadcrumbs />
            </Box>
            <Outlet/>
          </main>
    </div>
  );
}
