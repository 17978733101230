import React from "react";
import { SettingsSubmenu } from "../../layout/subMenu";
import { Collapser } from "../../components/Collapser";
// import MeetingMails from "./components/emailsetting/MeetingMails";
// import CircularResolutionMail from "./components/emailsetting/CircularResolutionMail";
import {
  MeetingMails,
  MeetingReportMail,
  MinutesReportMail,
  CircularResolutionMail,
} from "./components/emailsetting";

const emailTemplate = () => {
  return (
    <>
      <SettingsSubmenu />
      <Collapser title="Meeting Mails">
        <MeetingMails />
      </Collapser>
      <br />
      <Collapser title="Circular Resolution Mails">
        <CircularResolutionMail />
      </Collapser>
      <br />

      <Collapser title="Meeting Report Mails">
        <MeetingReportMail />
      </Collapser>
      <br />

      <Collapser title="Minute Report Mails">
        <MinutesReportMail />
      </Collapser>
      <br />
    </>
  );
};

export default emailTemplate;
