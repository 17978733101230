import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Autocomplete from "@material-ui/lab/Autocomplete";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { getTranslation } from "../../../heplers/translationHelper";

// 1.	presence
// 2.	by phone
// 3.	visioconference
// 4.	excused
// 5.	absend
// 6.	replaced
const joinMethod = [
  {
    id: 1,
    title: "Presence",
  },
  {
    id: 2,
    title: "By Phone",
  },
  {
    id: 3,
    title: "Visio Conference",
  },
  {
    id: 4,
    title: "Excused",
  },
  {
    id: 5,
    title: "Absent",
  },
  {
    id: 6,
    title: "Replaced",
  },
];

const JoinMethod = ({ meetingMemberId, onSubmit, printPowerOfAttorney }) => {
  const [contactList, setContactList] = useState([]);
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    firstName: "",
    lastName: "",
    mail: "",
    meetingMemberId: meetingMemberId,
    representativeStatus: 0,
    enablePowerOfAttorney: false,
  };

  const basicValidationSchema = Yup.object().shape({
    firstName: Yup.string().when("enablePowerOfAttorney", {
      is: true,
      then: Yup.string().required("Field is required"),
    }),
    lastName: Yup.string().when("enablePowerOfAttorney", {
      is: true,
      then: Yup.string().required("Field is required"),
    }),
    mail: Yup.string()
      .email()
      .when("enablePowerOfAttorney", {
        is: true,
        then: Yup.string().required("Must enter email address"),
      }),
    // representativeStatus: Yup.number().test(
    //   "representativeStatus",
    //   "representativeStatus is required",
    //   (value) => value !== 0
    // ),
  });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={basicValidationSchema}
      onSubmit={(values, actions) => {
        if (typeof values.representativeStatus === "undefined") {
          enqueueSnackbar("Joining Meeting status is Required", {
            variant: "error",
          });
          actions.setSubmitting(false);
          return;
        }
        actions.setSubmitting(true);
        onSubmit(values, actions);
        actions.resetForm();
      }}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue,
        handleChange,
        setTouched,
      }) => (
        <form>
          <Card style={{ margin: "1%", height: "auto" }}>
            <CardContent
              style={{
                background: "#FFFFFF",
              }}
            >
              <Typography variant='label'>How you want to join ?</Typography>
              <Grid container style={{ margin: "10px" }}>
                <Grid item lg={3}></Grid>
                <Grid item lg={2}></Grid>
                <Grid item lg={2}>
                  <Autocomplete
                    options={joinMethod}
                    value={
                      joinMethod.find(
                        (option) => option.id === values.representativeStatus
                      ) || null
                    }
                    getOptionLabel={(option) => option.title}
                    size='small'
                    onChange={(e, value) => {
                      setFieldValue("representativeStatus", value?.id);
                      setTouched("representativeStatus", true);
                      if (value?.id === 5) {
                        setFieldValue("enablePowerOfAttorney", true);
                      } else {
                        setFieldValue("enablePowerOfAttorney", false);
                      }
                    }}
                    onBlur={() => {
                      setTouched("representativeStatus", true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Select*",
                          "Sélectionner",
                          "Auswählen"
                        )}
                        variant='outlined'
                        error={
                          touched.representativeStatus &&
                          Boolean(errors.representativeStatus)
                        }
                        helperText={
                          touched.representativeStatus &&
                          errors.representativeStatus
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={2}></Grid>
                <Grid item lg={3}></Grid>
                {values.enablePowerOfAttorney ? (
                  <Grid container spacing={2} style={{ marginTop: "90px" }}>
                    <Grid item lg={12}>
                      <Typography align='center' style={{ opacity: "60%" }}>
                        Plesae enter Details to give permission
                      </Typography>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <Grid item lg={3}></Grid>
                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "First Name",
                          "Prénom",
                          "Vorname"
                        )}
                        required
                        size='small'
                        variant='outlined'
                        name='firstName'
                        // {...getFieldProps("firstName")}
                        onChange={(e) =>
                          setFieldValue("firstName", e.target.value)
                        }
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label={getTranslation(
                          "Last Name",
                          "Nom de famille",
                          "Familienname, Nachname"
                        )}
                        required
                        size='small'
                        variant='outlined'
                        name='lastName'
                        // {...getFieldProps("lastName")}
                        onChange={(e) =>
                          setFieldValue("lastName", e.target.value)
                        }
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <TextField
                        fullWidth
                        label={getTranslation("Email", "E-mail", "Email")}
                        required
                        size='small'
                        variant='outlined'
                        name='mail'
                        //  {...getFieldProps("mail")}
                        onChange={(e) => setFieldValue("mail", e.target.value)}
                        error={touched.mail && Boolean(errors.mail)}
                        helperText={touched.mail && errors.mail}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item lg={3}></Grid>
                  </Grid>
                ) : null}
                <Grid item lg={12} style={{ marginTop: "30px" }}>
                  <Typography align='center' style={{ opacity: "60%" }}>
                    By clicking done you agree to sign and print
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <ButtonWithLoading
                  title={getTranslation("Done", "Terminé", "Getan")}
                  size='small'
                  variant='contained'
                  color='primary'
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default JoinMethod;
