import React from "react";
import {
	Box,
	IconButton,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import Table from "../../../../components/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Get } from "../../../../actions";
import {
	Get_AllUpcomingMeetings_URL,
	Get_AllPreviousMeetings_URL,
	Post_DeleteDraftMeeting_URL,
} from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../../heplers/translationHelper";
import { Assessment } from "@material-ui/icons";
import { format } from "../../../../heplers/format";
import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Create } from "../../../../actions";
import DeleteDialog from "../../../../components/DeleteDialog";

const getStepName = (activeStep) => {
	const steps = [
		"Meeting Info",
		"Guests",
		"Reporting",
		"Minutes",
		"Documents",
		"Agendas",
		"Reminders",
		"Files",
	];

	return steps[activeStep] || "";
};

const columns = [
	// {
	// 	id: "activeStep",
	// 	numeric: false,
	// 	disablePadding: true,
	// 	label: getTranslation("Step Name", "Step Name", "Step Name"),
	// 	component: ({ row }) => {
	// 		return <Typography>{getStepName(row.activeStep)}</Typography>;
	// 	},
	// },
	{
		id: "subject",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Subject", "Sujette", "Fach"),
		// component: ({ row }) => {
		// 	return <Typography>{row.meetingDraftVm.subject}</Typography>;
		// },
	},
	{
		id: "meetingDate",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Date", "Date", "Datum"),
		format: "date",
		// component: ({ row }) => {
		// 	return (
		// 		<Typography>
		// 			{format("date", row.meetingDraftVm.meetingDate)}
		// 		</Typography>
		// 	);
		// },
	},
	{
		id: "meetingTime",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Time", "Temps", "Zeit"),
		format: "time",

		// component: ({ row }) => {
		// 	return (
		// 		<Typography>
		// 			{format("time", row.meetingDraftVm.meetingTime)}
		// 		</Typography>
		// 	);
		// },
	},

	{
		id: "language",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Language", "Langue", "Sprache"),
		// component: ({ row }) => {
		// 	return <Typography>{row.meetingDraftVm.language}</Typography>;
		// },
	},

	{
		id: "address",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Address", "Adresse", "Adresse"),

		// component: ({ row }) => {
		// 	return <Typography>{row.meetingDraftVm.address}</Typography>;
		// },
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const DraftsMeetings = ({ rows, loading, onFilter, loadDraftsMeetings }) => {
	const location = useLocation();
	const history = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let spvDraftsMeeting = localStorage.getItem("spvDraftsMeeting");
	if (spvDraftsMeeting == null) {
		localStorage.setItem("spvDraftsMeeting", 10);
		spvDraftsMeeting = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvDraftsMeeting);
	const [upcomingMeetinLoading, setUpcomingMeetingLoading] = useState(false);
	const [legalFormsLoading, setLegalFormsLoading] = useState(false);
	const [legalForms, setLegalForms] = useState([]);
	const { managementCompanyId } = useParams();
	const [open, setOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});

	const dispatch = useDispatch();

	//dispatch(setMeetingData({ one: "1", two: "2" }));

	const activeState = useSelector((state) => state.draftMeeting.activeState);
	const meetingData = useSelector((state) => state.draftMeeting.meetingData);

	console.log("dratted meeting", rows);
	console.log("Active State:before", activeState);

	console.log("locationn", location.pathname);

	const handleEditActivity = (row) => {
		history(
			`/meetings/${managementCompanyId}/regular/generatenewRegularMeeting/${row.draftMeetingId}`
		);
	};

	useEffect(() => {
		// Set activeState to null when the route changes
		dispatch(setActiveState(null));
	}, [dispatch, location.pathname]);

	useEffect(() => {
		onFilter({
			searchString,
			pageNumber: page,
			pageSize: rowsPerPage,
		});
	}, []);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvDraftsMeeting", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleSearchUpcomingMeeting = (searchString) => {
		setSearchString(searchString);
	};

	const handleMinuteReportClick = (meeting) => {
		console.log("asasasasasaxacssa", meeting.meetingDraftVm);
		dispatch(setActiveState(meeting.activeStep));
		dispatch(setMeetingData(meeting.meetingDraftVm));
		console.log("baogi", meeting.activeStep);
		console.log("Active State:after", activeState);
	};

	const setActiveState = (activeState) => ({
		type: "SET_ACTIVE_STATE",
		payload: {
			activeState,
		},
	});

	const setMeetingData = (meetingData) => ({
		type: "SET_MEETING_DATA",
		payload: {
			meetingData,
		},
	});

	//handleOpenDeleteDialog
	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleDeleteDraftMeeting = (id) => {
		console.log("adsfasd id", id);
		setIsDeleting(true);
		Create(
			{
				draftMeetingId: id,
			},
			Post_DeleteDraftMeeting_URL,
			null,
			(resp) => {
				setOpen(false);
				setIsDeleting(false);
				onFilter({
					searchString,
					pageNumber: page,
					pageSize: rowsPerPage,
				});
				enqueueSnackbar(
					getTranslation(
						"meeting deleted successfully",
						"meeting deleted successfully",
						"meeting deleted successfully"
					),
					{ variant: "success" }
				);
			},
			(error) => {
				setOpen(false);
				setIsDeleting(false);
				enqueueSnackbar(
					getTranslation(
						"An error occurred while deleting operation",
						"Une erreur est survenue lors de la suppression de l'op�ration",
						"Ein Fehler ist aufgetreten beim L�schen der Operation"
					),
					{ variant: "error" }
				);
			}
		);
	};

	console.log("Meeting Data:", meetingData);

	return (
		<div>
			<Table
				auto
				dense
				loading={loading}
				title={getTranslation(
					"Drafts Meeting",
					"Drafts Meeting",
					"Drafts Meeting"
				)}
				colums={columns}
				sortBy="meetingDate"
				rows={rows}
				count={rows?.length}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => handleEditActivity(row)}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				// viewCreate
				//onCreate={()=>handleCreatebuilding()}
				viewSearch
				// onSearch={(searchString) => handleSearchUpcomingMeeting(searchString)}
				// actions={[
				// 	{
				// 		component: (row) => (
				// 			<IconButton onClick={() => handleMinuteReportClick(row)}>
				// 				{" "}
				// 				<Assessment />
				// 			</IconButton>
				// 		),
				// 	},
				// ]}
			/>

			<DeleteDialog
				open={open}
				title={getTranslation(
					"Delete Draft Meeting",
					"Delete Draft Meeting",
					"Delete Draft Meeting"
				)}
				onClose={() => setOpen(false)}
				onSubmit={(e) => handleDeleteDraftMeeting(deleteRow?.draftMeetingId)}
				deleting={isDeleting}
			/>
		</div>
	);
};
export default DraftsMeetings;
