import React, { useState, useEffect } from "react";
import { CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { Get_MeetingInfo_URL } from "../../../constants/apiUrls";
import { Get } from "../../../actions";
import { useNavigate } from "react-router-dom";
import { format } from "../../../heplers/format";
import CardComponent from "../../../components/Card";
import { getTranslation } from "../../../heplers/translationHelper";

const MeetingInformation = ({ meetingId, meetingInfo }) => {
  return (
    <div>
      <CardComponent >
        <CardHeader title={getTranslation("Meeting Information","Informations sur la réunion","Meeting-Informationen")} />
        <CardContent> 
          {/* <Typography variant="label">Meeting Information</Typography> */}
          <Grid container spacing={3} style={{ margin: "20px" }}>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Subject:
              </Typography>
              <Typography variant="overline">{meetingInfo?.subject}</Typography>
            </Grid>

            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Type of Meeting:
              </Typography>
              <Typography variant="overline">
                {meetingInfo?.meetingTypeText}
              </Typography>
            </Grid>

            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Date/Time:
              </Typography>
              <Typography variant="overline">
                {`${format("date", meetingInfo?.meetingDate)}  ${format(
                  "time",
                  meetingInfo?.meetingTime
                )}`}
              </Typography>
            </Grid>

            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Language:
              </Typography>
              <Typography variant="overline">
                {meetingInfo?.language}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Incharge:
              </Typography>
              <Typography variant="overline">
                {meetingInfo?.personInchargeName}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3}>
              <Typography variant="subtitle2" display="inline">
                Address:
              </Typography>
              <Typography variant="overline">
                {meetingInfo?.address}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardComponent>
    </div>
  );
};

export default MeetingInformation;
