import { createRef, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import CardComponent from "../../components/Card";
import {
	Box,
	Button,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid,
	IconButton,
	Typography,
	TextField,
	CardActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { Formik } from "formik";
import * as Yup from "yup";
import { UploadFiles } from "../../components/Upload";
import {
	Get_AllMeetingAgendas_URL,
	Get_CompanyInfo_URL,
	Get_Files_URL,
	Get_MeetingInfo_URL,
	Post_MeetingAcceptMinutes_URL,
	Post_MeetingDeclineMinutes_URL,
	Post_GenerateAuthCode_URL,
	Post_VerifyAuthCode_URL,
	Get_MeetingGetAllMeetingMembers,
	Get_GetAllGuests_URL,
	Post_MeetingAcceptMinutesByMember_URL,
	Post_MeetingSaveMinuteReportPdf_URL,
	Post_MeetingDeclineMinutesByMember_URL,
} from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
import { DeclineCommentsDialog, ReviewAgenda } from "./component/minutes";
import { useDispatch } from "react-redux";
import { Add_Public_ActionButtons } from "../../constants/types";
import { Close, DoneAll } from "@material-ui/icons";

import { useSnackbar } from "notistack";
import { Get, Create } from "../../actions";
import { CompanyInformation, MeetingInformation } from "../public/components";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import Table from "../../components/table";
import Dialog from "../../components/Dialog";
import { useReactToPrint } from "react-to-print";
import { PrintSignedMinuteReport } from "../public/minutes/printSignMinutesReport";
import { MinutesProgressBar } from "./component";
import { savePdf } from "../../heplers/pdfHelper";

const columns = [
	{
		id: "fullName",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Name", "Name", "Name"),
	},
	{
		id: "mail",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Mail", "Mail", "Mail"),
	},
	{
		id: "phone1",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Phone", "Phone", "Phone"),
	},
	{
		id: "representativeStatusText",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Join Status", "Join Status", "Join Status"),
	},
];

const columnsGuest = [
	{
		id: "fullName",
		numeric: false,
		label: getTranslation("Name", "Nom", "Name"),
	},
	{
		id: "mail",
		numeric: false,
		label: getTranslation("Email", "E-mail", "Email"),
	},
];

export const ReviewMinutes = ({ isMember }) => {
	const { meetingId, contactId } = useParams();

	const printComponent = useRef();
	const [meetingInfo, setMeetingInfo] = useState({});
	const [companyInfo, setCompanyInfo] = useState({});
	const [meetingDocuments, setMeetingDocuments] = useState([]);
	const [meetingAgendas, setMeetingAgendas] = useState([]);
	const [isBusy, setBusy] = useState(false);
	const [isBusyDecline, setBusyDecline] = useState(false);
	const [openDeclineCommentsDialog, setOpenDeclineCommentsDialog] =
		useState(false);

	const [isAuthenticated, setAuthenticated] = useState(false);
	const [resourceUnAvailable, setResourceUnAvailable] = useState(false);
	const [loading, setLoading] = useState(true);
	const [attendaant, setAttendants] = useState([]);
	const [guestLoading, setGuestLoading] = useState(false);
	const [guest, setGuest] = useState([]);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchString, setSearchString] = useState("");
	const [preview, setPreview] = useState(true);
	const [meetingInfoLoaded, setMeetingInfoLoaded] = useState(false);

	// const handlePrint = useReactToPrint({
	// 	documentTitle: "Resolution Signed",
	// 	content: () => printComponent.current,
	// });

	const handlePrint=()=>{
		setPreview(false)
		savePdf(
			"Meeting Minute Report.pdf",
			printComponent.current,
			null,
			null,
			false,
			(file) => {
		
			})
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleSearchGuest = (searchString) => {
		setSearchString(searchString);
	};

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	useEffect(() => {
		setLoading(true);
		Get(
			{
				meetingId: meetingId,
			},
			Get_MeetingInfo_URL,
			null,
			(resp) => {
				if (resp.data?.minuteReportStatus < 2) navigate("response/"+getTranslation("Minute Report not generated yet."));
				if (isMember == false && resp.data?.minuteReportStatus === 3)
					if (contactId != resp.data?.minutesFundManagerId)
						navigate("/response/"+getTranslation("You are not allowed to review this meeting report, Please contact admin."));

				if (isMember == false && resp.data?.minuteReportStatus == 4) {
					navigate("/response/"+getTranslation("This minute report is already marked as approved by you."));
				}

				if (isMember == true && resp.data?.minuteReportStatus == 5) {
					navigate("/response/"+getTranslation("This minute report is already marked as approved by you."));
				}

				setMeetingInfo(resp?.data);
				Get(
					{
						meetingId: meetingId,
					},

					Get_MeetingGetAllMeetingMembers,

					null,
					(list) => {
						setAttendants(list.data);
						if (list.data.length == 0) navigate("/response/"+getTranslation("This minute report does not contains any member."));

						if (isMember && resp.data?.minuteReportStatus === 4) {
							var member = list.data.find((att) => att.id == contactId);
							if (member && member.isNeededSignature == false)
								navigate("/response/"+getTranslation("You are a member but not allowed to signed this report."));
							if (member && member.isSigned == true)
								navigate("/response"+getTranslation("This minute report is already marked as approved by you."));
						}
						setMeetingInfoLoaded(true);
						Get(
							{
								meetingId: meetingId,
							},
							Get_Files_URL,
							null,
							(resp) => {
								setMeetingDocuments(resp?.data);
							},
							(error) => {}
						);
						Get(
							{
								meetingId: meetingId,
							},
							Get_AllMeetingAgendas_URL,
							null,
							(resp) => {
								setMeetingAgendas(resp?.data);
							},
							(error) => {}
						);
						Get(
							{ meetingId: meetingId },
							Get_GetAllGuests_URL,
							null,
							(respGuest) => {
								//setGuestLoading(false);
								setGuest(respGuest?.data);
								if (isMember && resp.data?.minuteReportStatus === 4) {
									var member = respGuest.data.find(
										(att) => att.id == contactId
									);
									if (member && member.isNeededSignature == false)
									navigate("/response/"+getTranslation("You are a member but not allowed to signed this report."));

									if (member && member.isSigned == true)
									navigate("/response"+getTranslation("This minute report is already marked as approved by you."));

								}
							},
							(error) => {
								//setGuestLoading(false);
								enqueueSnackbar("Guest list not loaded", { variant: "error" });
							}
						);
					},
					(error) => {
						console.log("parse table user  error");
					}
				);
			},
			(error) => {}
		);

		Get(
			{
				meetingId: meetingId,
			},
			Get_CompanyInfo_URL,
			null,
			(resp) => {
				setCompanyInfo(resp?.data);
			},
			(error) => {}
		);
	}, [meetingId]);

	const accept = async () => {
		setPreview(false)
		setBusy(true)
		setTimeout(()=>{
			if (isMember) {
				setBusy(true);
				Create(
					{
						meetingId: meetingId,
						contactId: contactId,
					},
					Post_MeetingAcceptMinutesByMember_URL,
					null,
					(resp) => {
						enqueueSnackbar(resp.data, { variant: "success" });
						navigate("/minutesApproved");
						setBusy(false);
						setPreview(true)
					},
					(error) => {
						enqueueSnackbar(error.data, { variant: "error" });
						setBusy(false);
						setPreview(true)
	
					}
				);
			} else {
				savePdf(
					"Meeting Minute Report.pdf",
					printComponent.current,
					null,
					null,
					false,
					(file) => {
						var reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onloadend = function () {
							var base64data = reader.result;
						Create(
							{
								meetingId: meetingId,
								minuteReportStatus: meetingInfo?.minuteReportStatus,
								// minutes: content,
								minutesPdf: base64data.split(",")[1],
							},
							Post_MeetingAcceptMinutes_URL,
							null,
							(resp) => {
								enqueueSnackbar(resp.data, { variant: "success" });
								navigate("/minutesApproved");
								setBusy(false);
								setPreview(true)
	
							},
							(error) => {
								enqueueSnackbar(error.data, { variant: "error" });
								setBusy(false);
								setPreview(true);
	
							}
						);
					}
				
			})
		}
		},1000)
		
}

	const handleSavePdf = async (printOnly) => {
		setBusy(true)
		setPreview(false);
    savePdf(
      "Meeting Minute Report.pdf",
      printComponent.current,
      null,
      null,
      true,
      (file) => {
        if (printOnly == false) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            var base64data = reader.result;
            Create(
              {
                meetingId: meetingId,
                minuteReportStatus: meetingInfo?.minuteReportStatus,
                // minutes: content,
                minutesPdf: base64data.split(",")[1],
              },
              Post_MeetingSaveMinuteReportPdf_URL,
              null,
              (resp) => {
                // enqueueSnackbar(resp.data, { variant: "success" });
                setBusy(false);
                setPreview(true);
              },
              (error) => {
                // enqueueSnackbar(error.data, { variant: "error" });
                setBusy(false);
                setPreview(true);
              }
            );
          };
        }
      }
    );
    // const content = printComponent.current;
    // setBusy(true);
    // var newHtmldoc = new jsPDF({
    // 	orientation: "p",
    // 	unit: "pt",
    // 	format: "a4",
    // });
    // newHtmldoc.html(content, {
    // 	callback: (doc) => {
    // 		var base64 = doc.output("datauristring");
    // 		Create(
    // 			{
    // 				meetingId: meetingId,
    // 				minuteReportStatus: meetingInfo?.minuteReportStatus,
    // 				// minutes: content,
    // 				minutesPdf: base64.split(",")[1],
    // 			},
    // 			Post_MeetingSaveMinuteReportPdf_URL,
    // 			null,
    // 			(resp) => {
    // 				// enqueueSnackbar(resp.data, { variant: "success" });
    // 				setBusy(false);
    // 			},
    // 			(error) => {
    // 				// enqueueSnackbar(error.data, { variant: "error" });
    // 				setBusy(false);
    // 			}
    // 		);
    // 		// console.log(base64);
    // 		//   doc.save("Meeting Report.pdf")
    // 		//   setIsPrinting(false)
    // 	},
    // });
  };

	const decline = (comments) => {
		setBusyDecline(true);

		Create(
			{
				meetingId: meetingId,
				comments: comments,
				contactId: contactId,
			},
			isMember
				? Post_MeetingDeclineMinutesByMember_URL
				: Post_MeetingDeclineMinutes_URL,
			null,
			(resp) => {
				// enqueueSnackbar(getTranslation(resp.data, resp.data, resp.data), {
				// 	variant: "success",
				// });
				// setBusyDecline(false);
				// setOpenDeclineCommentsDialog(false);
				navigate("/response/"+getTranslation(resp.data, resp.data, resp.data));
			},
			(error) => {
				enqueueSnackbar(error.data, { variant: "error" });
				setBusyDecline(false);
				setOpenDeclineCommentsDialog(false);
			}
		);
	};

	const ConfirmDecline = () => {
		setOpenDeclineCommentsDialog(true);
	};

	const initialValues = {
		otp: "",
	};

	const basicValidationSchema = Yup.object().shape({
		otp: Yup.string().required("otp is required"),
	});

	return (
    <>
      {meetingInfoLoaded == false ? (
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <CircularProgress />
          <br />
          <p>
            {" "}
            {getTranslation(
              "Loading Meeting Minutes Report Information, Please wait",
              "Loading Meeting Minutes Report Information, Please wait",
              "Loading Meeting Minutes Report Information, Please wait"
            )}{" "}
          </p>
        </Box>
      ) : (
        <CardComponent
          style={{
            paddingTop: "30px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "30px",
          }}
        >
          <Typography variant="h4" align="center">
            {getTranslation("Minutes Report")}
          </Typography>
          <br />
          <br />
          {/* here treacker */}
          <MinutesProgressBar
            meetingInfo={meetingInfo}
            getTranslation={getTranslation}
          />
          {/* here treacker closed */}
          <br />
          <br />
          <Box display="flex" alignItems={"flex-end"} justifyContent="flex-end">
            <ButtonWithLoading
              title={
                <>
                  <DoneAll style={{ color: "green" }} />
                  &nbsp;&nbsp;&nbsp;
                  <Typography>
                    {getTranslation(
                      "Sign digitally",
                      "Sign digitally",
                      "Sign digitally"
                    )}
                  </Typography>
                </>
              }
              variant="contained"
              color="primary"
              loading={isBusy}
              onClick={() => accept()}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <ButtonWithLoading
              title={
                <>
                  <Close style={{ color: "red" }} />
                  &nbsp;&nbsp;&nbsp;
                  <Typography>
                    {getTranslation(
                      "Mark as Declined",
                      "Mark as Declined",
                      "Mark as Declined"
                    )}
                  </Typography>
                </>
              }
              variant="contained"
              color="default"
              loading={isBusyDecline}
              onClick={() => ConfirmDecline()}
              //disabled={meetingInfo?.minuteReportStatus!=2}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <ButtonWithLoading
              title={
                <>
                  {/* <DoneAll style={{ color: "green" }} />
														&nbsp;&nbsp;&nbsp; */}
                  <Typography>
                    {getTranslation(
                      "Manual Signing",
                      "Manual Signing",
                      "Manual Signing"
                    )}
                  </Typography>
                </>
              }
              variant="contained"
              color="primary"
              //loading={isBusy}
              onClick={() => {
                setPreview(false);
                setTimeout(() => {
					if(!isMember) handleSavePdf(false);
					else handleSavePdf(true);

                }, 1000);
              }}
            />
          </Box>

          <br />
          <br />

          <MeetingInformation meetingInfo={meetingInfo} />
          <br />
          <br />
          <CompanyInformation companyInfo={companyInfo} />
          <br />
          <br />
          <Table
            dense
            auto
            title={getTranslation("Attendants", "Attendants", "Attendants")}
            colums={columns}
            count={attendaant?.length}
            rows={attendaant}
            page={0}
            rowsPerPage={10}
            handleChangePage={(event, newPage) => {}}
            handleChangeRowsPerPage={(e) => {}}
          />

          <br />
          <br />

          <Table
            auto
            size="small"
            colums={columnsGuest}
            sortBy="fullName"
            rows={guest}
            count={guest?.length}
            title={getTranslation("Guests", "Guests", "Guests")}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={(event, newPage) =>
              handleChangePage(event, newPage)
            }
            handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            onSearch={(searchString) => handleSearchGuest(searchString)}
          />
          <br />
          <br />
          <CardComponent>
            <CardHeader
              title={getTranslation(
                "Uploaded Documents",
                "Uploaded Documents",
                "Uploaded Documents"
              )}
            />
            <CardContent>
              <UploadFiles
                uploadedFiles={meetingDocuments}
                getFileName={(file) => {
                  return file.fileName;
                }}
                onAddFile={(file) => {
                  console.log(file);
                }}
                disabled
              />
            </CardContent>
          </CardComponent>
          <br />
          <br />
          <DeclineCommentsDialog
            open={openDeclineCommentsDialog}
            onClose={() => setOpenDeclineCommentsDialog(false)}
            loading={isBusyDecline}
            onSubmit={decline}
          />

          <ReviewAgenda
            agendas={meetingAgendas}
            meetingId={meetingId}
            contactId={contactId}
            isMember={isMember}
            minuteReportStatus={meetingInfo?.minuteReportStatus}
            ref={printComponent}
            preview={preview}
			companyInfo={companyInfo}
			meetingInfo={meetingInfo}
			attendants={attendaant}
			guests={guest}
          />
        </CardComponent>
      )}
    </>
  );
};
