import {
  Box,
  Typography,
  CardActions,
  Card,
  CardContent,
  Grid,
  CardHeader,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import ReactWaterMark from "react-watermark-component";
import { format, parseHTML } from "../../../heplers/format";
import { GenerateQRCode } from "../../../heplers/generateQR";
import { circularResolutionType } from "../../scanner/scannerTypes";
import { CompanyInformation } from "../components";
import CardComponent from "../../../components/Card";
import { getTranslation } from "../../../heplers/translationHelper";
import BackgroundDraftPrintImage from "../../../assests/images/draft.png";

export const PrintSignedCircularResolution = React.forwardRef(
  ({ personalInfo, circularResolution, memberId, companyInfo,isDraft }, ref) => {
    const [QrImage, setQRImage] = useState();
    const [draftImage, setDraftImage] = useState();

    useEffect(() => {
      if (personalInfo) {
        GenerateQRCode({
          type: circularResolutionType,
          value: {
            memberId: memberId,
          },
        })
          .then((resp) => {
            setQRImage(resp);
          })
          .catch((error) => {});
      }
    }, [personalInfo]);
     // Function to convert image to base64
     const convertImageToBase64 =async  () => {
      // Create a new FileReader instance
      //const response = await fetch('../../../assests/images/draft.png'); // Replace with the actual path to your image file
      const response = await fetch(BackgroundDraftPrintImage);
        const blob = await response.blob();
      const reader = new FileReader();
  
      // Read the file as Data URL
      reader.readAsDataURL(blob);
  
      // Handle the FileReader's load event
      reader.onload = () => {
        // Get the base64-encoded string
        const base64String = reader.result.split(',')[1];
      console.log("akjdhjkahdklasd",reader.result)

        setDraftImage(base64String)
      };
  
      // Handle errors
      reader.onerror = (error) => {
        console.error('Error converting image to base64:', error);
      };
    };
    useEffect(() => {
      convertImageToBase64();
    }, []);
    return (
      <div ref={ref}>
        {isDraft?<>
        <div class="top-watermark">Draft</div>
        <div class="watermark">Draft</div>
        <div class="bottom-watermark">Draft</div>
        </>:null
  }
        <Typography
          variant="h2"
          style={{
            fontFamily: "Revalia",
            // fontStyle: "normal",
            // fontSize: "16px",
            color: "#233044",
            textAlign: "center",
          }}
        >
          Circular Resolution Decision
        </Typography>
        <br />
        <CardComponent>
          <CardHeader
            title={getTranslation(
              "Company Information",
              "Company Information",
              "Company Information"
            )}
          />
          <CardContent>
            <table width="100%">
              <tr>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Company Name:",
                      "Company Name:",
                      "Company Name:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.companyName}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Company Reference Code:",
                      "Company Reference Code:",
                      "Company Reference Code:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.referenceCode}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Investor Relation:",
                      "Investor Relation:",
                      "Investor Relation:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.investorRelationName}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "RCS Number:",
                      "RCS Number:",
                      "RCS Number:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.rcsNumber}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "InCorporation Date:",
                      "InCorporation Date:",
                      "InCorporation Date:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {format("date", companyInfo?.basic?.incorporationDate)}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("Street:", "Street:", "Street:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.street}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("City:", "City:", "City:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.city}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("Zipcode:", "Zipcode:", "Zipcode:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.zipCode}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("Country:", "Country:", "Country:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.country}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("RBE:", "RBE:", "RBE:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.rbe}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("Group:", "Group:", "Group:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.companyGroup?.title}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Financial year Ends:",
                      "Financial year Ends:",
                      "Financial year Ends:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.finnancialYearEnds}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td item lg={12} md={12}>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Legal Form:",
                      "Legal Form:",
                      "	Legal Form:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.basicLegalForm?.legalForm}
                  </Typography>
                </td>
              </tr>
            </table>
          </CardContent>
        </CardComponent>
        <br />
        <CardComponent
          style={{
            height: "auto",
            // background: "#233044",
            // color: "#FFFFFF",
            borderRadius: "8px",
            border: "1px solid gray",
          }}
        >
          <CardContent>
            <Typography
              component="h2"
              style={{
                fontWeight: "bold",
              }}
            >
              {getTranslation("Signee", "Signee", "Signee")}
            </Typography>
            <table width="100%">
              {/*Grid 1 */}
              <tr>
                <td>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "10px",
                      fontStyle: "normal",
                      textAlign: "left",
                      marginLeft: "20px",
                    }}
                  >
                    {personalInfo?.fullName}
                  </Typography>
                </td>
                <td>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "10px",
                      fontStyle: "normal",
                      textAlign: "left",
                      marginLeft: "20px",
                    }}
                  >
                    {personalInfo?.mail}
                  </Typography>
                </td>
                <td>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "10px",
                      fontStyle: "normal",
                      textAlign: "left",
                      marginLeft: "20px",
                    }}
                  >
                    {personalInfo?.city}
                  </Typography>
                </td>
                <td>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "10px",
                      fontStyle: "normal",
                      textAlign: "left",
                      marginLeft: "20px",
                    }}
                  >
                    {personalInfo?.phone1}
                  </Typography>
                </td>
              </tr>
            </table>
          </CardContent>
        </CardComponent>
        <table width="100%" style={{ marginTop: "50px" }}>
          <tr>
            <td>
              <Typography
                component="h2"
                style={{
                  fontWeight: "bold",
                }}
              >
                Subject:
              </Typography>
              <Typography component="p">
                {circularResolution?.subject}
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
                component="h2"
              >
                Resolution
              </Typography>

              <Typography component="p">
                {parseHTML(circularResolution?.resolution)}
              </Typography>
            </td>
          </tr>
        </table>
        {isDraft ? null : (
          <table width="100%" style={{ marginTop: "100px" }}>
            <tr>
              <td></td>
              <td align="right">
                <Typography style={{ fontSize: "10px" }}>
                  This QR code is system generated and is only for Ceres
                  employees
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>
                  _________________
                  <h4>&emsp;Sign.</h4>
                </Typography>
                <Typography style={{ fontSize: "9px" }}>
                  Please Sign the document and upload.
                </Typography>
              </td>
              <td align="right">
                {QrImage ? (
                  <img
                    src={QrImage}
                    alt="Image"
                    style={{ width: "100px", height: "100px" }}
                  />
                ) : null}
              </td>
            </tr>
          </table>
        )}
      </div>
    );
  }
);
