import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { UploadFiles } from "../../../../components/Upload";
import MeetingInformation from "../../../public/components/MeetingInformation";
import { Guest } from "../adhoc/guest";
import {
  Get_MeetingInfo_URL,
  Get_GetAllGuestsWithPagination_URL,
  Post_FileInsert_URL,
  Get_Files_URL,
  Post_FileDelete_URL,
  Get_AllAgendasWithPagination_URL,
  GET_AllContactList_URL,
  Post_AddMeetingGuest_URL,
  Post_RemoveMeetingGuest_URL,
  Get_GetAllGuests_URL,
  Get_AllMeetingDocuments_URL,
  Post_AddNewMeetingDocument_URL,
  Post_RemoveMeetingDocument_URL,
  Get_AgendaTemplateList_URL,
  Get_AllAgendaTemplateItems_URL,
  Get_AllMeetingAgendas_URL,
  Post_ChangeAgendaByTemplate_URL,
  Post_AddNewAgenda_URL,
  Post_DeleteAgenda_URL,
  Post_UpdateMeetingMemberReminder_URL,
  Post_UpdateRegularMeetingAgendaInterval_URL,
  Get_AllMeetingRemindermembers_URL,
} from "../../../../constants/apiUrls";
import { useNavigate, useParams } from "react-router";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../../../actions";
import { AskAgenda } from "../../../public/invitationProcess";
import { AskAgendaList } from "../../../public/invitationProcess/askAgendaList";
import { UpdateRegularMeetingInfo } from "./components/updateMeetingInfo";
import { BoardMembers } from "./boardMembers";
import { AddGuest } from "./components/guest/addGuest";
import { GuestList } from "./components/guest/guestList";
import CardComponent from "../../../../components/Card";
import Dialog from "../../../../components/Dialog";
import { DocumentList } from "./components/documents/documentList";
import { Autocomplete } from "@material-ui/lab";
import { AskingAgendaList } from "./components/agenda/askingAgendaList";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { SelectionCcList } from "./components/reminders/selectionCcList";
import { AutoUpdateField } from "../../../../components/autoUpdateField";
import { getTranslation } from "../../../../heplers/translationHelper";

export const RegularMeetingDetails = () => {
  ///////////////// States Management //////////////////////

  const { meetingId, managementCompanyId } = useParams();
  const [meetingInfo, setMeetingInfo] = useState({});
  const [loadingMeetingInfo, setLoadingMeetingInfo] = useState(false);
  //////////////////////////////////////////////////////// GUEST HANDLING //////////////////////
  const [guestListWithPagination, setGuestListWithPagination] = useState();
  const [guestLoading, setGuestLoading] = useState(false);
  //////////////////////////////////////////////////////// AGENDA HANDLING //////////////////////
  const [agendaListWithPagination, setAgendaListWithPagination] = useState();
  const [agendaLoading, setAgendaLoading] = useState(false);
  //////////////////////////////////////////////////////// Contact HANDLING //////////////////////
  const [contacts, setContacts] = useState([]);
  const [contactLoading, setContactLoading] = useState(false);

  const [filesList, setFilesList] = useState();

  const [meetingDocuments, setMeetingDocuments] = useState([]);

  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [filterItems, setFilterItems] = useState({
    meetingId: meetingId,
    searchString: "",
    pageNumber: 0,
    pageSize: 10,
  });

  useEffect(() => {
    loadMeetingInfo({ meetingId: meetingId });
    loadGuests();
  }, [meetingId]);
  /////////////////////// GET MEETING INFO ///////////////////////
  const loadMeetingInfo = (values) => {
    setLoadingMeetingInfo(true);
    Get(
      values,
      Get_MeetingInfo_URL,
      history,
      (resp) => {
        setMeetingInfo(resp?.data);
        setLoadingMeetingInfo(false);
        setAskingAgendaInterval(resp?.data?.askingAgendaInterval);
      },
      (error) => {
        enqueueSnackbar("Meeting info not loaded", { variant: "error" });
      }
    );
  };
  /////////////////////// GET Guest List  ///////////////////////

  const loadGuests = () => {
    setGuestLoading(true);
    Get(
      { meetingId },
      Get_GetAllGuests_URL,
      history,
      (resp) => {
        setGuestLoading(false);
        setGuestListWithPagination(resp?.data);
      },
      (error) => {
        setGuestLoading(false);
        enqueueSnackbar("Guest list not loaded", { variant: "error" });
      }
    );
  };
  /////////////////////// GET Contacts  ///////////////////////
  const loadContacts = () => {
    setContactLoading(true);
    Get(
      {},
      GET_AllContactList_URL,
      null,
      (resp) => {
        setContacts(resp.data);
        setContactLoading(false);
      },
      (error) => {}
    );
  };
  useEffect(() => {
    loadContacts();
  }, []);

  ///////////////////////////////////////////////// Submit Guest /////////////////////////////

  const handAddGuest = (values) => {
    Create(
      { contactId: values.contactId, meetingId: meetingId },
      Post_AddMeetingGuest_URL,
      null,
      (resp) => {
        enqueueSnackbar("Guest Added", { variant: "success" });
        loadGuests();
      },
      (error) => {
        enqueueSnackbar("Can't Add guest for now. Sorry for inconvinience", {
          variant: "error",
        });
      }
    );
  };

  const handleDeleteGuest = (guest) => {
    Create(
      {
        contactId: guest.id,
        meetingId: meetingId,
      },
      Post_RemoveMeetingGuest_URL,
      null,
      (resp) => {
        enqueueSnackbar("Guest Removed", { variant: "success" });
        loadGuests();
      },
      (error) => {
        enqueueSnackbar("Can't Remove guest for now. Sorry for inconvinience", {
          variant: "error",
        });
      }
    );
  };

  /////////////////////// GET Files  ///////////////////////

  useEffect(() => {
    loadFiles({ meetingId: meetingId });
    loadMeetingdocuments({ meetingId: meetingId });
  }, [meetingId]);
  const loadFiles = (values) => {
    Get(
      values,
      Get_Files_URL,
      history,
      (resp) => {
        // const arr=[]
        // resp?.data.forEach(file=>{
        //   arr.push({fileName:file.fileName})
        // })
        setFilesList(resp?.data);
      },
      (error) => {
        enqueueSnackbar("Files not loaded", { variant: "error" });
      }
    );
  };

  const handleAddFile = (file) => {
    Create(
      {
        meetingId: meetingId,
        fileName: file,
      },
      Post_FileInsert_URL,
      null,
      (resp) => {},
      (error) => {}
    );
  };

  const handleDeleteFile = (file) => {
    Create(
      { fileName: file },
      Post_FileDelete_URL,
      null,
      (resp) => {},
      (error) => {}
    );
  };
  const handleFileChange = (file) => {};

  ///////////////////////////  Meeting Documents
  const loadMeetingdocuments = (values) => {
    Get(
      values,
      Get_AllMeetingDocuments_URL,
      history,
      (resp) => {
        setMeetingDocuments(resp?.data);
      },
      (error) => {
        enqueueSnackbar("Meeting Documents not loaded", { variant: "error" });
      }
    );
  };

  const addNewMeetingDocument = (values, actions) => {
    values.meetingId = meetingId;
    Create(
      values,
      Post_AddNewMeetingDocument_URL,
      null,
      (resp) => {
        loadMeetingdocuments({ meetingId });
        enqueueSnackbar("New document is added.", { variant: "success" });
      },
      (error) => {
        enqueueSnackbar("Meeting Documents not added", { variant: "error" });
      }
    );
  };

  const removeMeetingDocument = (document) => {
    Create(
      { meetingDocumentId: document.id },
      Post_RemoveMeetingDocument_URL,
      null,
      (resp) => {
        loadMeetingdocuments({ meetingId });
        enqueueSnackbar("Meeting Document is removed.", { variant: "success" });
      },
      (error) => {
        enqueueSnackbar("System was not able to delete this meeting document", {
          variant: "error",
        });
      }
    );
  };

  //////////////////////         Agenda

  const [templates, setTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [openSelectedTemplate, setOpenSelectedTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [agendasLoading, setAgendasLoading] = useState(false);
  const [agendas, setAgendas] = useState(false);
  const [askingAgendaInterval, setAskingAgendaInterval] = useState();
  const [reminderMembers, setReminderMembers] = useState([]);
  const [meetingMembers, setMeetingMembers] = useState([]);
  const [reminderMembersLoading, setReminderMembersLoading] = useState(false);
  
  useEffect(() => {
    loadTemplates();
    loadAgendas();
  }, []);

  const loadAgendas = () => {
    setAgendasLoading(true);
    Get(
      {
        meetingId,
      },
      Get_AllMeetingAgendas_URL,
      null,
      (resp) => {
        setAgendas(resp.data);
        setAgendasLoading(false);
      },
      (error) => {}
    );
  };

  const loadTemplates = () => {
    setTemplatesLoading(true);
    Get(
      {},
      Get_AgendaTemplateList_URL,
      null,
      (resp) => {
        setTemplates(resp.data);
        setTemplatesLoading(false);
      },
      (error) => {}
    );
  };

  const replaceWithNewTemplate = () => {
    Create(
      { meetingId: meetingId, templateId: selectedTemplate.id },
      Post_ChangeAgendaByTemplate_URL,
      null,
      (resp) => {
        enqueueSnackbar("Agendas has been replaced.", { variant: "success" });
        setSelectedTemplate(null);
        setOpenSelectedTemplate(false);
        loadAgendas();
      },
      (error) => {
        enqueueSnackbar("System was not able to replace.", {
          variant: "error",
        });
      }
    );
  };

  const addNewAgenda = (values, actions) => {
    values.meetingId = meetingId;
    Create(
      values,
      Post_AddNewAgenda_URL,
      null,
      (resp) => {
        enqueueSnackbar("Agendas is added.", { variant: "success" });
        setSelectedTemplate(null);
        loadAgendas();
      },
      (error) => {
        enqueueSnackbar("System was not able to add.", { variant: "error" });
      }
    );
  };

  const removeAgenda = (agenda) => {
    Create(
      { id: agenda.id },
      Post_DeleteAgenda_URL,
      null,
      (resp) => {
        enqueueSnackbar("Agendas is removed.", { variant: "success" });
        setSelectedTemplate(null);
        loadAgendas();
      },
      (error) => {
        enqueueSnackbar("System was not able to remove.", { variant: "error" });
      }
    );
  };

  ///////////////////////     Reminders

  useEffect(() => {
    loadMeetingRemindermembers();
  }, [meetingId]);

  const loadMeetingRemindermembers = () => {
    setReminderMembersLoading(true);
    // Insert URL in the following action
    Get(
      {
        meetingId,
      },
      Get_AllMeetingRemindermembers_URL,
      null,
      (resp) => {
        setReminderMembers(resp.data);
        setReminderMembersLoading(false);
      },
      (error) => {}
    );
  };

  const addNewReminderCC = (values) => {
    values.meetingId = meetingId;
    Create(
      values,
      Post_UpdateMeetingMemberReminder_URL,
      null,
      (resp) => {
        enqueueSnackbar("Reminder CC Updated.", { variant: "success" });
        loadMeetingRemindermembers();
      },
      (error) => {
        enqueueSnackbar("System was not able to add.", { variant: "error" });
      }
    );
  };

  return (
    loadingMeetingInfo? <Box display='flex' justifyContent="center" mt={'300px'} ><CircularProgress/> </Box>:
    <Paper>
     
      <Box p={2}>
        <Typography variant="h5">
          {getTranslation("Update Regular Meeting")}
        </Typography>
        <Grid container>
          <Grid md={12} lg={12}>
            <UpdateRegularMeetingInfo
              meetingInfo={meetingInfo}
              managementCompanyId={managementCompanyId}
              loading={loadingMeetingInfo}
            />
          </Grid>
          <Grid md={12} lg={12}>
            {meetingInfo ? (
              meetingInfo?.meetingType === 0 ||
              meetingInfo?.meetingType === 2 ? (
                <BoardMembers
                  title={getTranslation(
                    "Board Members",
                    "Membres du conseil d'administration",
                    "Vorstandsmitglieder"
                  )}
                  meetingType={meetingInfo?.meetingType}
                  MCId={managementCompanyId}
                  onSelectionChange={(members) => {}}
                  onMembersLoaded={(members) => {
                    setMeetingMembers(members);
                  }}
                  meetingId={meetingId}
                />
              ) : (
                <BoardMembers
                  title={getTranslation(
                    "Shareholder Members",
                    "Membres actionnaires",
                    "Aktionärsmitglieder"
                  )}
                  meetingType={meetingInfo?.meetingType}
                  MCId={managementCompanyId}
                  onSelectionChange={(members) => {}}
                  meetingId={meetingId}
                  onMembersLoaded={(members) => {
                    setMeetingMembers(members);
                  }}
                />
              )
            ) : null}
          </Grid>
          <Grid md={12} lg={12}>
            <CardComponent
              title={getTranslation("Guests", "Invitées", "Gäste")}
            >
              <CardContent>
                <AddGuest
                  contactList={contacts}
                  onAddNewGuest={(contactId) => {
                    handAddGuest({
                      contactId: contactId,
                      meetingId: meetingId,
                    });
                  }}
                  onSubmitGuest={(guest) => {
                    //setFieldValue("guests",[...values.guests,guest]);
                    handAddGuest({ contactId: guest.id, meetingId: meetingId });
                    setContacts([...contacts, guest]);
                    loadGuests();
                  }}
                />
                <GuestList
                  guests={guestListWithPagination}
                  onDelete={handleDeleteGuest}
                />
              </CardContent>
            </CardComponent>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12}>
            <CardComponent
              title={getTranslation("Documents", "Documents", "Unterlagen")}
            >
              <CardContent>
                <DocumentList
                  contacts={contacts}
                  documents={meetingDocuments}
                  onAddNewDocument={addNewMeetingDocument}
                  onDeleteDocument={removeMeetingDocument}
                />
              </CardContent>
            </CardComponent>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12}>
            <CardComponent
              title={getTranslation("Agenda", "Ordre du jour", "Agenda")}
            >
              <CardContent>
                <br />
                <br />
                <FormControl>
										<FormControlLabel
											label={getTranslation(
												"Asking Agenda Allowed",
												"Asking Agenda Allowed",
												"Asking Agenda Allowed"
											)}
											labelPlacement="start"
											control={
												<Checkbox
													id="isAskingAgendaAllowed"
													checked={meetingInfo.isAskingAgendaAllowed}
													// onChange={handleChange}
                          disabled
												/>
											}
										/>
									</FormControl>
                  <br/>
                  <br/>
                  {meetingInfo?.isAskingAgendaAllowed?
                <AutoUpdateField
                  disabled={false}
                  onUpdate={() => {
                    Create(
                      {
                        meetingId: meetingId,
                        newInterval: askingAgendaInterval,
                      },
                      Post_UpdateRegularMeetingAgendaInterval_URL,
                      null,
                      (resp) => {
                        enqueueSnackbar("Agenda Interval Updated.");
                      },
                      (error) => {}
                    );
                  }}
                  style={{ width: "400px" }}
                >
                  <TextField
                    fullWidth
                    id="askingAgendaInterval"
                    label={getTranslation(
                      "Interval (days before)",
                      "Intervalle (jours avant)",
                      "Intervall (Tage vorher)"
                    )}
                    required
                    size="small"
                    variant="outlined"
                    name="periodicity"
                    type="number"
                    value={askingAgendaInterval}
                    onChange={(e) => {
                      setAskingAgendaInterval(e.target.value);
                    }}
                    // {...getFieldProps("askingAgendaInterval")}
                    // error={touched.askingAgendaInterval && Boolean(errors.askingAgendaInterval)}
                    // helperText={touched.askingAgendaInterval && errors.askingAgendaInterval}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </AutoUpdateField>
                :null}
                <br />
                <br />
                <Autocomplete
                  style={{ width: "400px" }}
                  options={templates}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  onChange={(e, value) => {
                    setSelectedTemplate(value);
                    setOpenSelectedTemplate(true);
                    // loadTemplateItems(value.id, (agendas) => {
                    //   setFieldValue("agendas", agendas)
                    // })
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={getTranslation("Template", "Modèle", "Vorlage")}
                      variant="outlined"
                    />
                  )}
                />
                <br />
                <AskingAgendaList
                  agendas={agendas}
                  onNewAgendaAdded={(agenda) => addNewAgenda(agenda)}
                  onAgendaRemoved={(agenda) => removeAgenda(agenda)}
                  onUpdateAgenda={(index, agenda) => {
                    // const arr = values.agendas;
                    // arr[index] = agenda;
                    // setFieldValue("agendas", arr)
                  }}
                />
              </CardContent>
            </CardComponent>
          </Grid>
          {meetingInfo?.meetingType <= 1 ? (
            <Grid xs={12} sm={12} md={12} lg={12}>
              <CardComponent
                title={getTranslation(
                  "Reminder CC",
                  "Rappel CC",
                  "Erinnerung CC"
                )}
              >
                <CardContent>
                <FormControl>
										<FormControlLabel
											label={getTranslation(
												"Send Reminder",
												"Envoyer un rappel",
												"Eine Erinnerung senden"
											)}
											labelPlacement="start"
											control={
												<Checkbox
													id="isReminderEnabled"
													checked={meetingInfo.isReminderEnabled}
													// onChange={handleChange}
                          disabled
												/>
											}
										/>
									</FormControl>
                  <br/>
                  <br/>
                  {meetingInfo.isReminderEnabled?
                  <TextField
												style={{ width: "400px" }}
												id="reminderInterval"
												label={getTranslation(
													"Interval (days)",
													"Intervalle (jours)",
													"Intervall (Tage)"
												)}
												required
												size="small"
												type="number"
												variant="outlined"
												value={meetingInfo.reminderInterval}
												disabled
                        // error={
												// 	touched.reminderInterval &&
												// 	Boolean(errors.reminderInterval)
												// }
												// helperText={
												// 	touched.reminderInterval && errors.reminderInterval
												// }

												// InputLabelProps={{}}
											/>:null}
                      <br/>
                  <br/>
                  {meetingInfo.isReminderEnabled?
                      <TextField
												fullWidth
												id="reminderText"
												label={getTranslation("Text", "Texte", "Text")}
												required
												size="small"
												variant="outlined"
                        value={meetingInfo.reminderText}
												InputLabelProps={{}}
												multiline
												rows={10}
                        disabled
											/>
                      :null}
                      <br/>
                  <br/>
                  <SelectionCcList
                    selectedMembers={reminderMembers}
                    members={meetingMembers}
                    onAddCC={(member) => {
                      addNewReminderCC({
                        contactId: member.id,
                        sendReminderCC: true,
                      });
                      // const index = values.meetingMembers.findIndex(x => x == member);
                      // const arr = values.meetingMembers;
                      // member.sendReminderCC = true;
                      // arr[index] = member;
                      // setFieldValue("meetingMembers", arr)
                    }}
                    onRemoveCC={(member) => {
                      addNewReminderCC({
                        contactId: member.id,
                        sendReminderCC: false,
                      });
                    }}
                  />
                </CardContent>
              </CardComponent>
            </Grid>
          ) : null}

          <Grid xs={12} sm={12} md={12} lg={12}>
            <CardComponent
              title={getTranslation(
                "Upload Document",
                "Télécharger un document",
                "Dokument hochladen"
              )}
            >
              <CardContent>
                <UploadFiles
                  uploadedFiles={filesList}
                  history={history}
                  onAddFile={(file) => {
                    handleAddFile(file);
                    // setFieldValue("files", [...values.files, { fileName: file }])
                  }}
                  //onAddFile={handleAddFile}
                  onDeleteFile={(file) => {
                    handleDeleteFile(file);
                    // const arr = values.files.filter(x => x.fileName == file);
                    // setFieldValue("files", arr)
                  }}
                  getFileName={(file) => file.fileName}
                />
              </CardContent>
            </CardComponent>
          </Grid>
        </Grid>

        <Dialog
          open={openSelectedTemplate}
          onClose={() => setOpenSelectedTemplate(false)}
        >
          <Typography variant="subtitle2">
            All of agendas will be lost and templates will be inserted.
          </Typography>
          <Typography variant="subtitle2">Do you wish to continue?</Typography>
          <Box
            mt={5}
            pr={1}
            pb={1}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ButtonWithLoading
              title={"Confirm"}
              size="medium"
              variant="contained"
              color="primary"
              onClick={replaceWithNewTemplate}
            />
          </Box>
        </Dialog>
      </Box>

    </Paper>
  );
};
