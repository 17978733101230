import {
  Box,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useState } from "react";
import { FieldSelection } from ".";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import Card from "../../../../components/Card";
import * as Yup from "yup";
import TablePreview from "./tablePreview";
import { useEffect } from "react";
import { Close } from "@material-ui/icons";
import { useMemo } from "react";

const AddTable = ({ editTable, forms, formSubmitting, onCancel, formLoading, onSubmit }) => {
  const [formFields, setFormFields] = useState([]);
  const [columns, setColumns] = useState({});

  useEffect(() => {
    if (editTable) {
      setColumns(editTable)
      var temp = forms.find(x => x.id === editTable.formTemplateId).formFields;
      editTable.tableColumns.forEach(x => {
        var index = temp.findIndex(f => f.id == x.id);
        console.log("hdgfsdfds", temp, x, index)
        if (index > 0) {
          temp[index].isSelected = true;
        }
      })
      setFormFields(temp);
    }
    else {
      setColumns({ tableColumns: [] });
    }

  }, [editTable])
  ///////////// Handling Fields //////////////
  const onNext = (arr, index) => {
    console.log("ngsdjfdsf", arr);
    var currArr = arr[index];
    var target = arr[index + 1];
    //// Swapiing sort value
    var temp = currArr.sort;
    currArr.sort = target.sort;
    target.sort = temp;

    arr[index] = currArr;
    arr[index + 1] = target;


    return setColumns({ ...columns, tableColumns: arr });
  };
  const onPrevious = (arr, index) => {
    var currArr = arr[index];
    var target = arr[index - 1];
    //// Swapiing sort value
    var temp = currArr.sort;
    currArr.sort = target.sort;
    target.sort = temp;

    arr[index] = currArr;
    arr[index - 1] = target;

    return setColumns({ ...columns, tableColumns: arr });
  };
  const handleRemoveField = (field) => {
    var newColumns = columns.tableColumns.filter((column) => column.sort !== field.sort);
    setColumns({ ...columns, tableColumns: newColumns });

    var temp = formFields
    temp.forEach(x => {
      if (x.id === field.id) {
        x.isSelected = false;
      }
    })
    setFormFields(temp);
  }

  //////////////

  const initialValues = {
    id: 0,
    title: "",
    formTemplateId: 0,
    tableColumns: [],
    /////////
    forms: {},
  };
  const basicValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    formTemplateId: Yup.number().min(1).required("Form Template is required"),
  });
  const [defaultValues,setDefaultValues] = useState(initialValues);
  useEffect(() => {
    if (editTable)
      setDefaultValues(editTable)
    else
      setDefaultValues(initialValues)
  },[editTable])
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={defaultValues}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          console.log("jksdgfsdf", columns)
          actions.setSubmitting(true);
          values.tableColumns = columns?.tableColumns;
          onSubmit(values, actions);
      setDefaultValues(initialValues)

        }}
      >
        {({
          values,
          errors,
          touched,
          getFieldProps,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                background: "white",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  ms={12}
                  md={4}
                  lg={4}
                  style={{ borderRight: "1px solid" }}
                >
                  <Card>
                    <CardHeader title={editTable ? "Update Table" : "Add Table"} />
                    <CardContent>
                      <TextField
                        label="Heading"
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="normal"
                        name="title"
                        value={values.title}
                        {...getFieldProps("title")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errors.title && touched.title}
                        helperText={errors.title && touched.title}
                      />
                      <Autocomplete
                        id="formTemplateId"
                        loading={formLoading}
                        options={forms}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, value) => {
                          setFieldValue("formTemplateId", value.id);
                          setFieldValue("forms", value);
                          setFormFields(value.formFields);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Form"
                            variant="outlined"
                            size="small"
                            name="formTemplateId"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              errors.formTemplateId && touched.formTemplateId
                            }
                            helperText={
                              errors.formTemplateId && touched.formTemplateId
                            }
                          />
                        )}
                      />
                      <FieldSelection
                        fields={formFields}
                        onFieldChange={(fields) => {
                          setFormFields(fields)
                          var temp = fields.filter(x => x.isSelected === true)
                          console.log("jksdbfsdf", fields, temp)
                          setColumns({ ...columns, tableColumns: temp });
                        }}
                      />
                      <Box
                        mt={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {editTable ?
                          <IconButton onClick={() => {
                            setFormFields(null);
                            onCancel()
                          }}>
                            <Close />
                          </IconButton> : null}
                        <ButtonWithLoading
                          loading={formSubmitting}
                          onClick={handleSubmit}
                          variant="contained"
                          color="primary"
                          title={editTable ? "Update Table" : "Submit"}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} ms={12} md={8} lg={8}>
                  <Card title="Preview">
                    <CardContent>
                      {console.log("hjsgdfjsdf", columns)}
                      <TablePreview
                        columns={columns.tableColumns}
                        onNext={onNext}
                        onPrevious={onPrevious}
                        onCancel={handleRemoveField}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddTable;
