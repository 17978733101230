import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import React, { useState } from "react";
import { Formik } from "formik";
import Dialog from "../../../components/Dialog";
import * as Yup from "yup";
import { getTranslation } from "../../../heplers/translationHelper";

export const DeclineDialog = ({
  circularResolutionId,
  memberId,
  isOpen,
  onClose,
  onSubmit,
  handleCancel,
}) => {
  const initialValues = {
    memberId: memberId,
    circularResolutionId: circularResolutionId,
    descision: false,
    comments: "",
  };

  const basicValidationSchema = Yup.object().shape({
    comments: Yup.string().required("Company name is required"),
  });
  return (
    <Dialog
      open={isOpen}
      title={getTranslation("Please Write down your comments","Veuillez écrire vos commentaires","Bitte schreiben Sie Ihre Kommentare auf")}
      onClose={onClose}
    >
      <div style={{ width: "500px" }}>
        <Formik
        enableReinitialize
          initialValues={initialValues}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            onSubmit(values, actions);
           // actions.resetForm();
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
            handleChange,
          }) => (
            <form>
              <Card>
                <CardContent>
                  <Grid>
                    <TextField
                      fullWidth
                      id="comments"
                      label={getTranslation("Comments","commentaires","Bemerkungen")}
                      required
                      variant="outlined"
                      comments="comments"
                      {...getFieldProps("comments")}
                      error={touched.comments && Boolean(errors.comments)}
                      helperText={touched.comments && errors.comments}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      rows={10}
                      multiline
                    />
                  </Grid>
                </CardContent>
                <CardActions>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ButtonWithLoading
                      style={{ margin: "5px" }}
                      title={getTranslation("Send","Envoyer","Schicken")}
                      size="large"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    />
                    <ButtonWithLoading
                      title={getTranslation("Cancel","Annuler","Stornieren")}
                      size="large"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={onClose}
                    />
                  </Box>
                </CardActions>
              </Card>{" "}
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};
