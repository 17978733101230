import React from "react";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../../heplers/translationHelper";
import Table from "../../../../components/table";

import { Get } from "../../../../actions";
import { Get_AllCompanyBelongsToList_URL } from "../../../../constants/apiUrls";
//name, address, zipcode, Town
const columns = [
  {
    id: "companyName",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Company Name", "Nom de l'entreprise", "Name der Firma"),
  },
  {
    id: "representativeRole",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Representative Role", "Rôle de représentant", "Repräsentative Rolle"),
  },
];



export const GetAllCompanyBelongsTo = ({companyId}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companyBelongsToList, setCompanyBelongsToList]=useState([])

  ///////////// Get Meeting Member ///////////


  useEffect(()=>{
    Get(
      {companyId:companyId},
      Get_AllCompanyBelongsToList_URL,
      null,
      resp=>{
        setCompanyBelongsToList(resp.data)
      },
      error=>{
        enqueueSnackbar("Network Error while getting Company Relations", { variant: "error" });
      }
    )
  },[companyId])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  return (
    <Table
      auto
      dense
      title={getTranslation(
        "Company Belongs To",
        "La société appartient à",
        "Unternehmen gehört"
      )}
      colums={columns}
      sortBy="companyName"
      rows={companyBelongsToList}
      count={companyBelongsToList.length}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
      handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
      viewSearch
      onSearch={(searchString) => setSearchString(searchString)}
    />
  );
};
