import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../heplers/translationHelper";
import Table from "../../../components/table";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Card, CardContent, CardHeader, IconButton, Typography } from "@material-ui/core";
import { DeclineReasonDialog } from "./declineReasonDialog";
import { Create, Get } from "../../../actions";
import { Get_AllUnConfirmedMeetingsOfMember_URL, Get_MeetingMember_URL, Post_MeetingMemberDescision_URL } from "../../../constants/apiUrls";
import { Box } from "@material-ui/core";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
//name, address, zipcode, Town
const columns = [
  {
    id: "subject",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Subject", "Sujette", "Fach"),
  },
  {
    id: "meetingDate",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Date", "Date", "Datum"),
    format: "date",
  },
  {
    id: "meetingTime",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Time", "Temps", "Zeit"),
    format: "time",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: true,
    label: getTranslation(
      "Company Name",
      "Nom de l'entreprise",
      "Name der Firma"
    ),
  },
  {
    id: "language",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Language", "Langue", "Sprache"),
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Address", "Adresse", "Adresse"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: true,
    label: getTranslation("Actions", "Actions", "Aktionen"),
    align: "right",
  },
];



export const MeetingApproval = () => {
  const history = useNavigate();
  const { contactId } = useParams()
  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [meetingDecision, setMeetingDecision] = useState(true);
  const [declineReasonDialog, setDeclineReasonDialog] = useState(false);
  const [meetingId, setMeetingId] = useState("");
  const [status, setStatus] = useState(true);
  const [selectedMeeting, setSelectedMeeting] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false);

  const [unConfirmedMeetings, setUnConfirmedMeetings] = useState([]);
  const [unConfirmedMeetingsLoading, setUnConfirmedMeetingsLoading] = useState(false);

  // // dummy data  k liye state banai he
  // const [unConfirmedMeetings, setUnConfirmedMeetings] = useState([
  //   {
  //     id: 0,
  //     subject: "Subject 1",
  //     meetingDate: "meetingDate 1",
  //     meetingTime: "meetingTime 1",
  //     companyName: "companyName 1",
  //     language: "language 1",
  //     address: "address 1",
  //     isConfirmed: true,
  //   },
  //   {
  //     id: 1,
  //     subject: "Subject 2",
  //     meetingDate: "meetingDate 2",
  //     meetingTime: "meetingTime 2",
  //     companyName: "companyName 2",
  //     language: "language 2",
  //     address: "address 2",
  //     isConfirmed: true,
  //   },
  //   {
  //     id: 2,
  //     subject: "Subject 3",
  //     meetingDate: "meetingDate 3",
  //     meetingTime: "meetingTime 3",
  //     companyName: "companyName 3",
  //     language: "language 3",
  //     address: "address 3",
  //     isConfirmed: true,
  //   },
  // ])




  ///////////// Get Meeting Member ///////////


  useEffect(() => {
    loadUnConfirmedMeetings()
  }, [])

  const loadUnConfirmedMeetings=()=>{
    setUnConfirmedMeetingsLoading(true)
    Get(
      { contactId: contactId },
      Get_AllUnConfirmedMeetingsOfMember_URL,
      null,
      resp => {
        const newArray = [];
        resp.data.forEach(meeting => {
          meeting.status = true;
          meeting.meetingId=meeting.id;
          meeting.comments=""
          newArray.push(meeting)

        });
        setUnConfirmedMeetings(newArray)
    setUnConfirmedMeetingsLoading(false)
  },
      error => {
        enqueueSnackbar(error?.data, { variant: "error" });
    setUnConfirmedMeetingsLoading(false)
  }
    )
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleSearchUpcomingMeeting = (searchString) => {
    setSearchString(searchString);
  };

  //////////////////////
  const handleDeclinedMeetingsConfirmation = () => {

    
    setSubmitting(true)
    Create(
      {
        contactId:parseInt(contactId),
        MeetingDescisions: unConfirmedMeetings},
      Post_MeetingMemberDescision_URL,
      null,
      resp => {
        enqueueSnackbar("You have made your decision succefully", { variant: "success" })
        setSubmitting(false)
        loadUnConfirmedMeetings();
      },
      error => {
        enqueueSnackbar("Couldn't submit your response", { variant: "error" })
        setSubmitting(false)
      }
    )
  }
  return (
    <Card>
      <CardHeader title={getTranslation(
        "Meetings Pending Decision",
        "Réunions en attente de décision",
        "Sitzungen mit ausstehender Entscheidung",
      )} />
      <CardContent>
        <Table
          auto
          dense
          loading={unConfirmedMeetingsLoading}
          colums={columns}
          sortBy="meetingDate"
          rows={unConfirmedMeetings}
          count={unConfirmedMeetings.length}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
          handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          viewSearch
          onSearch={(searchString) => setSearchString(searchString)}
          actions={[
            {
              component: (row) => {
                return (
                  <>
                    <ToggleButtonGroup
                      size="small"
                      id="meetingType"
                      exclusive
                      value={row.status}
                      onChange={(e, decision) => {
                        const arr=unConfirmedMeetings;
                        let index = arr.findIndex(x => x.id === row.id);
                        if(decision){
                          arr[index]={...row,status:true,comments:""}
                        }else{
                          setSelectedMeeting(row)
                          setDeclineReasonDialog(true)
                        }
                      }}
                    >
                      <ToggleButton value={true} aria-label="left aligned">
                        <Typography>{getTranslation("Accept","J'accepte","Annehmen")}</Typography>
                      </ToggleButton>
                      <ToggleButton value={false} aria-label="centered">
                        <Typography>{getTranslation("Decline","Déclin","Ablehnen")}</Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </>
                );
              },
            },
          ]}
        />
        <br />
        <Box display="flex" alignItems="center" justifyContent="center">
          <ButtonWithLoading
          disabled={unConfirmedMeetings.length===0}
            title={isSubmitting ? getTranslation("Confirming Decisions", "Confirmation des décisions", "Entscheidungen bestätigen") : getTranslation("Confirm", "Confirmer", "Bestätigen Sie")}
            loading={isSubmitting}
            variant="outlined"
            size="small"
            style={{
              background: unConfirmedMeetings.length===0 ? "gray" : "red",
              color: "white"
            }}
            onClick={() => handleDeclinedMeetingsConfirmation()}
          />
        </Box>
        <DeclineReasonDialog
          isOpen={declineReasonDialog}
          onClose={() => setDeclineReasonDialog(false)}
          onSubmit={(comments) => {
            const arr=unConfirmedMeetings;
            const index=arr.findIndex(x=>x.id===selectedMeeting.id)
            arr[index]={...selectedMeeting,comments:comments,status:false}
            
            setUnConfirmedMeetings(arr);
            setSelectedMeeting(null)
            setDeclineReasonDialog(false)
          }}
        />
      </CardContent>
    </Card>
  );
};
