import QRCode from "qrcode";
import QrReader from "react-qr-reader";
import { APP_URL, SERVER_URL } from "../constants";
export async function GenerateQRCode(data) {
	console.log("dataaaaaa", data);
	try {
		const stringifiedObj = JSON.stringify(data);
		const base64Data = btoa(stringifiedObj); // Convert data to base64
		console.log("adghsafgdhfsg", base64Data);
		const url = `${APP_URL}public/manualSigning/${base64Data}`;
		return await QRCode.toDataURL(url);
	} catch {
		return "Error";
	}
}
