import Card from "../../../../../../components/Card"
import { CardContent, Typography } from "@material-ui/core";
import { getTranslation } from "../../../../../../heplers/translationHelper";
const UpdateReportComments = ({comments}) => {
    return (
        <Card title={getTranslation("Comments","Comments","Comments")}>
            <CardContent>
                <Typography>
                    {comments=='' || comments==null ? getTranslation("No Comments","No Comments","No Comments") :comments}
                </Typography>
            </CardContent>
        </Card>
    )
}
export default UpdateReportComments;