import { ReportTemplateSubmenu } from "../../../layout/subMenu/meeting"
import { SectionList } from "./components"

const SectionsDashboard=()=>{
    return(
        <>
        <ReportTemplateSubmenu/>
        <SectionList/>
        </>
    )
}
export default SectionsDashboard