import { Button } from "@material-ui/core";
import { useState } from "react";
import { Get } from "../../../../actions";
import { Get_AllForms_URL } from "../../../../constants/apiUrls";
import MeetingInformation from "../../components/MeetingInformation";
import FormIdentification from "./identification";


const ReportsFormInfo = () => {
  return (
    <>
      <MeetingInformation />
      <br/>
      <br/>
      <FormIdentification />
    </>
  );
};
export default ReportsFormInfo