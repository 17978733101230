import { useEffect } from "react";
import { parseMinutesReportHelper } from "../../../../heplers/parseMinutesReportHelper";
import renderHTML from "react-render-html";
import { useState } from "react";

export const RenderAgendaItem = ({ agendaItem }) => {
	const [html, setHtml] = useState();
	useEffect(() => {
		parseMinutesReportHelper(null, agendaItem?.comments, (newHtml) => {
			setHtml(newHtml.replace("<img alt=\"close\"","<br alt=\"close\""));
		});
	}, [agendaItem]);

	return (
		<div
			style={{
				//marginLeft: "70px",
				padding: "20px",
				//border: "0.7px solid lightgrey",
				borderRadius: "10px",
			}}
		>
			{html ? renderHTML(html) : null}
		</div>
	);
};
