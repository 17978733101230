import { createRef, useRef, useState } from "react";
import { useParams } from "react-router";
import { useEffect } from "react";
import CardComponent from "../../components/Card";
import {
	Box,
	Button,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid,
	IconButton,
	Typography,
	TextField,
	CardActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { Formik } from "formik";
import * as Yup from "yup";
import { UploadFiles } from "../../components/Upload";
import {
	Get_AllMeetingAgendas_URL,
	Get_CompanyInfo_URL,
	Get_Files_URL,
	Get_MeetingInfo_URL,
	Post_MeetingAcceptMinutes_URL,
	Post_MeetingDeclineMinutes_URL,
	Post_GenerateAuthCode_URL,
	Post_VerifyAuthCode_URL,
	Get_MeetingGetAllMeetingMembers,
	Get_GetAllGuests_URL,
} from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
import { DeclineCommentsDialog, ReviewAgenda } from "./component/minutes";
import { useDispatch } from "react-redux";
import { Add_Public_ActionButtons } from "../../constants/types";
import { Close, DoneAll, Print } from "@material-ui/icons";

// import jsPDF from "jspdf";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../actions";
import { CompanyInformation, MeetingInformation } from "../public/components";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { MinutesProgressBar } from "./component";
import { savePdf } from "../../heplers/pdfHelper";
import TableComponent from "../../components/table";


const columns = [
  {
    id: "fullName",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Name", "Name", "Name"),
  },
  {
    id: "mail",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Mail", "Mail", "Mail"),
  },
  {
    id: "phone1",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Phone", "Phone", "Phone"),
  },
  {
    id: "representativeStatusText",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Join Status", "Join Status", "Join Status"),
  },
  {
	id:"isSigned",
	label:getTranslation("Is Signed?", "Is Signed?", "Is Signed?"),
	component:({row})=><Typography>{row?.isSigned ? "Signed" : row?.isNeededSignature==false ? "Not Required" : "Not Signed"}</Typography>
  }
];

const columnsGuest = [
  {
    id: "fullName",
    numeric: false,
    label: getTranslation("Name", "Nom", "Name"),
  },
  {
    id: "mail",
    numeric: false,
    label: getTranslation("Email", "E-mail", "Email"),
  },
];

export const ReviewMinutesDone = ({ isValidateAuthenticateEnabled }) => {

	const { meetingId, contactId } = useParams();
	const [meetingInfo, setMeetingInfo] = useState({});
	const [companyInfo, setCompanyInfo] = useState({});
	const [meetingDocuments, setMeetingDocuments] = useState([]);
	const [meetingAgendas, setMeetingAgendas] = useState([]);
	const [attendaant, setAttendants] = useState([]);
	const [guest, setGuest] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [isBusy, setBusy] = useState(false);
	const [openDeclineCommentsDialog, setOpenDeclineCommentsDialog] =
		useState(false);

	const [loading, setLoading] = useState(true);

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		setLoading(true);

		Get(
			{
				meetingId: meetingId,
			},
			Get_MeetingInfo_URL,
			null,
			(resp) => {
				setMeetingInfo(resp?.data);
			
			},
			(error) => {}
		);

		Get(
			{
				meetingId: meetingId,
			},
			Get_CompanyInfo_URL,
			null,
			(resp) => {
				setCompanyInfo(resp?.data);
			},
			(error) => {}
		);
		Get(
			{
				meetingId: meetingId,
			},
			Get_Files_URL,
			null,
			(resp) => {
				setMeetingDocuments(resp?.data);
			},
			(error) => {}
		);
		Get(
			{
				meetingId: meetingId,
			},
			Get_AllMeetingAgendas_URL,
			null,
			(resp) => {
				setMeetingAgendas(resp?.data);
			},
			(error) => {}
		);

		Get(
			{
				meetingId: meetingId,
			},

			Get_MeetingGetAllMeetingMembers,

			null,
			(list) => {
				setAttendants(list.data);
				
			},
			(error) => {
				console.log("parse table user  error");
			}
		);

		Get(
			{ meetingId: meetingId },
			Get_GetAllGuests_URL,
			null,
			(respGuest) => {
				//setGuestLoading(false);
				setGuest(respGuest?.data);
			},
			(error) => {
				//setGuestLoading(false);
				enqueueSnackbar("Guest list not loaded", { variant: "error" });
			}
		);
	}, [meetingId]);

	const accept = async () => {
		const content = printComponent.current;
		setBusy(true);
		savePdf("Minute Report.pdf", content, null, null, true, (file) => {
      setBusy(false);
    });
		
	};


	const printComponent = useRef();
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	return (
    <>
      {" "}
      <CardComponent
        style={{
          paddingTop: "30px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "30px",
        }}
      >
       
            <Typography variant="h4" align="center">
              {getTranslation("Minutes Report")}
            </Typography>
            <br />
            <br />
            {/* here treacker */}
            <MinutesProgressBar
              meetingInfo={meetingInfo}
              getTranslation={getTranslation}
            />
            <br />
            <Box
              display="flex"
              alignItems={"flex-end"}
              justifyContent="flex-end"
            >
              <ButtonWithLoading
                title={
                  <>
                    <Print  />
                    &nbsp;&nbsp;&nbsp;
                    <Typography>
                      {getTranslation(
                        "Print Report",
                        "Print Report",
                        "Print Report"
                      )}
                    </Typography>
                  </>
                }
                variant="contained"
                color="primary"
                loading={isBusy}
                onClick={() => accept()}
              />
            </Box>
            <br />
            <MeetingInformation meetingInfo={meetingInfo} />
            <br />
            <br />
            <CompanyInformation companyInfo={companyInfo} />
			<br />
          <br />
          <TableComponent
            dense
            auto
            title={getTranslation("Attendants", "Attendants", "Attendants")}
            colums={columns}
            count={attendaant?.length}
            rows={attendaant}
            page={0}
            rowsPerPage={10}
			handleChangePage={(event, newPage) =>
				handleChangePage(event, newPage)
			  }
			  handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          />

          <br />
          <br />

          <TableComponent
            auto
            size="small"
            colums={columnsGuest}
            sortBy="fullName"
            rows={guest}
            count={guest?.length}
            title={getTranslation("Guests", "Guests", "Guests")}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={(event, newPage) =>
              handleChangePage(event, newPage)
            }
            handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
			viewSearch
            // onSearch={(searchString) => handleSearchGuest(searchString)}
          />
            <br />
            <br />
            <CardComponent>
              <CardHeader
                title={getTranslation(
                  "Uploaded Documents",
                  "Uploaded Documents",
                  "Uploaded Documents"
                )}
              />
              <CardContent>
                <UploadFiles
                  uploadedFiles={meetingDocuments}
                  getFileName={(file) => {
                    return file.fileName;
                  }}
                  onAddFile={(file) => {
                    console.log(file);
                  }}
                  disabled
                />
              </CardContent>
            </CardComponent>

            <br />
            <br />
<div style={{display:"none"}}>
            <ReviewAgenda
              agendas={meetingAgendas}
              ref={printComponent}
              preview={false}
              companyInfo={companyInfo}
              meetingInfo={meetingInfo}
			  attendants={attendaant}
			  guests={guest}
            />
			</div>
        

      </CardComponent>
    </>
  );
};
