import { Box, Grid, TextField } from "@material-ui/core";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useState } from "react";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { DatePicker } from "../../../components/DatePicker";
import { getTranslation } from "../../../heplers/translationHelper";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
export const FilterSearch = ({
  contactList,
  companyList,
  userList,
  onSearch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const date = new Date();
  const year = date.getFullYear();
  const [meetingType, setMeetingType] = useState("all");
  const initialValue = {
    meetingType: meetingType,
    from: new Date(),
    to: new Date(year, 11, 31),
    contacts: [],
    appUsers: [],
    companies: [],
    ////////
  };
  const basicValidationScheme = Yup.object().shape({
    from: Yup.date().required("From Date is required"),
    to: Yup.date().required("To Date is required"),
  })
  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={basicValidationScheme}
        onSubmit={(values, actions) => {
          onSearch(values);
        }}
      >
        {({
          errors,
          isSubmitting,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <ToggleButtonGroup
              size="small"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              value={meetingType}
              exclusive
              onChange={(event, type) => {
                if (type !== null && typeof type !== "undefined") {
                  setMeetingType(type);
                  setFieldValue("meetingType", type);
                } else setMeetingType("all");
              }}
              aria-label="text alignment"
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="board">Board</ToggleButton>
              <ToggleButton value="shareholder">Shareholder</ToggleButton>
            </ToggleButtonGroup>
            <br />
            <Grid container spacing={2}>
              {/* Select Contact */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Autocomplete
                  multiple
                  id="contacts"
                  size="small"
                  options={contactList}
                  getOptionLabel={(option) => option?.fullName}
                  onChange={(event, value) => {
                    setFieldValue("contacts", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Contact"
                      placeholder="Filter"
                    />
                  )}
                />
              </Grid>
              {/* Select Company */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Autocomplete
                  multiple
                  size="small"
                  id="tags-standard"
                  options={companyList}
                  getOptionLabel={(option) => option?.title}
                  onChange={(e, companies) => {
                    setFieldValue("companies", companies);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Company"
                      placeholder="Filter"
                    />
                  )}
                />
              </Grid>
              {/* select User */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Autocomplete
                  multiple
                  size="small"
                  id="tags-standard"
                  options={userList}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, users) => {
                    setFieldValue("appUsers", users);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Users"
                      placeholder="Filter"
                    />
                  )}
                />
              </Grid>
              {/* Date from */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    size="small"
                    id="from"
                    label={getTranslation("From")}
                    value={values.from}
                    onChange={(value) => {
                      console.log("hgdfjgdsf", value);
                      setFieldValue("from", value);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "From",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    error={touched.from && Boolean(errors.from)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {/* Date to */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    size="small"
                    id="to"
                    label={getTranslation("To")}

                    value={values.to}
                    onChange={(newDate) => {
                      setFieldValue("to", newDate);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "To",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    error={touched.from && Boolean(errors.from)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Box
              style={{
                margin: "10px 0px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <ButtonWithLoading
                title={getTranslation("Search")}
                //loading={isSubmitting}
                onClick={() => {
                  if (Object.keys(errors).length > 0) {
                    enqueueSnackbar(
                      "Some fields are missing.",
                      {
                        variant: "error",
                      }
                    );
                  } else handleSubmit();
                }}
                color="primary"
                variant="contained"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
