import React, { useState, useEffect } from "react";
import ReactDOM, {
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { Get, Create } from "../../actions";
import { Box, Grid, Paper, TextField, CardActions } from "@material-ui/core";
import { Formik } from "formik";
import Table from "../../components/table";
import Card from "../../components/Card";
import {
	Get_AllAssetOperation_URL,
	Create_AssetOperation_URL,
	DELETE_Operation_URL,
	Get_AllFundOperation_URL,
} from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
import {
	AssetSubmenu,
	FundSubmenu,
	ManagementCompaniesSubmenu,
} from "../../layout/subMenu";

const Operation = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { assetId, fundId, companyId } = useParams();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	let spvDetailFundOperation = localStorage.getItem("spvDetailFundOperation");
	if (spvDetailFundOperation == null) {
		localStorage.setItem("spvDetailFundOperation", 10);
		spvDetailFundOperation = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvDetailFundOperation);
	const [rows, setRows] = useState([]);
	const [loadingOperations, setloadingOperations] = useState(false);

	const location = useLocation();

	const columns = [
		{
			id: location.pathname.includes("/assets/") ? "assetTitle" : "fundTitle",
			numeric: false,
			disablePadding: true,
			label: location.pathname.includes("/assets/")
				? getTranslation("Asset Title", "Asset Title", "Asset Title")
				: getTranslation("Fund Title", "Fund Title", "Fund Title"),
		},
		{
			id: "amountIn",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Amount In", "Montant en", "Betrag in"),
        align:'right',
        format:'number'
		},

		{
			id: "amountOut",
			numeric: true,
			disablePadding: false,
			label: getTranslation("Amount Out", "Montant sortant", "Betrag aus"),
      align:'right',
			format:'number'
		},

		{
			id: "date",
			numeric: false,
			disablePadding: false,
			label: getTranslation("Date", "Date", "Datum"),
			format: "date",
		},
		{
			id: "endDate",
			numeric: false,
			disablePadding: false,
			label: getTranslation("End Date", "Date de fin", "Endtermin"),
			format: "date",
		},

		{
			id: "actions",
			numeric: true,
			disablePadding: false,
			align: "right",
			label: getTranslation("Actions", "Actions", "Aktionen"),
		},
	];

	useEffect(() => {
		loadOperations();
	}, [page, rowsPerPage, searchString]);

	const loadOperations = () => {
		setloadingOperations(true);
		if (assetId) {
			Get(
				{
					assetId: assetId,
					searchString,
					pageNumber: page,
					pageSize: rowsPerPage,
				},
				Get_AllAssetOperation_URL,
				history,
				(resp) => {
					setRows(resp.data);
					setloadingOperations(false);
				},
				(error) => {}
			);
		} else if (fundId) {
			Get(
				{
					fundId: fundId,
					searchString,
					pageNumber: page,
					pageSize: rowsPerPage,
				},
				Get_AllFundOperation_URL,
				history,
				(resp) => {
					setRows(resp.data);
					setloadingOperations(false);
				},
				(error) => {}
			);
		}
	};

	// const { activities } = useSelector((state) => state.activities);
	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleDeleteActivity = (row) => {
		const deleteArray = [];
		deleteArray.push(row.id);
		Create(
			{ ids: deleteArray },
			DELETE_Operation_URL,
			history,
			(resp) => {
				setOpen(false);
				enqueueSnackbar("Operation Deleted", {
					variant: "success",
				});
				loadOperations();
			},
			(error) => {
				enqueueSnackbar("Sometihing went wrong", {
					variant: "error",
				});
			}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvDetailFundOperation", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleDetailCompany = (row) => {
		history(`/operations/${row.id}/basic`);
	};
	const handleSearchOperation = (searchString) => {
		setSearchString(searchString);
	};
	const handleCreateCompany = (event) => {
		setCreateCompany(true);
	};
	const handleCreateCompanyClose = (event) => {
		setCreateCompany(false);
	};
	const handleCreateAssetOperation = async (values, actions) => {
		let id = 0;
		let url = "";
		if (assetId) {
			id = assetId;
			url = Get_AllAssetOperation_URL;
		} else if (fundId) {
			id = fundId;
			url = Get_AllFundOperation_URL;
		}
		Create(
			values,
			url,
			history,
			(response) => {
				actions.setSubmitting(false);
				enqueueSnackbar("Operation Created", {
					variant: "success",
				});
				loadOperations();
			},
			(error) => {
				actions.setSubmitting(false);
				enqueueSnackbar("Something went wrong", {
					variant: "error",
				});
			}
		);
	};

	return (
		<div>
			{assetId ? (
				<AssetSubmenu assetId={assetId} companyId={companyId} />
			) : fundId ? (
				<FundSubmenu fundId={fundId} companyId={companyId} />
			) : (
				<ManagementCompaniesSubmenu />
			)}
			<Table
				dense
				loading={loadingOperations}
				title={getTranslation("Operations", "Opérations", "Operationen")}
				colums={columns}
				sortBy="title"
				rows={rows?.data}
				peration
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				// viewEdit
				// onEdit={(row)=>handleDetailCompany(row)}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				viewSearch
				onSearch={(searchString) => handleSearchOperation(searchString)}
			/>
		</div>
	);
};

export default Operation;
