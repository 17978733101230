import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  IconButton,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import Card from "../../../components/Card";

import ButtonWithLoading from "../../../components/ButtonWithLoading";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Get } from "../../../actions";
import {
  Get_AllCeresServiceTypes_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import DialogComponent from "../../../components/Dialog";
import { Cancel } from "@material-ui/icons";

const CreateService = ({ editService, companyId,open,onClose,onSubmit}) => {
  const [ceresServiceTypes, setCeresServiceTypes] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    loadAllCeresServiceTypes();
  }, []);

  const loadAllCeresServiceTypes = async () => {
    await Get(
      {},
      Get_AllCeresServiceTypes_URL,
      history,
      (response) => {
        setCeresServiceTypes(response.data);
      },
      (error) => {}
    );
  };


  const initialValues = {
    id:0,
    title: "",
  };

  const basicValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
  });


  const defaultValue = editService ? editService : initialValues;
  return (
    <DialogComponent 
    title={editService?"Edit Service":"Add Service"}
    open={open}
    onClose={onClose}
    >
    <Card>
      {/* <CardHeader title='Details' /> */}
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    label={getTranslation("Title","Titre","Titel")}
                    required
                    size="small"
                    variant="outlined"
                    name="title"
                    {...getFieldProps("title")}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {editService?
                <IconButton onClick={onClose}>
                  <Cancel />
                </IconButton>
                :null}
                <ButtonWithLoading
                  title={editService ? "Update" : "Create"}
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
    </DialogComponent>
  );
};

export default CreateService;
