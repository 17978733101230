import { CardContent, Grid } from "@material-ui/core";
import Card from "../../../../components/Card";
import { Collapser } from "../../../../components/Collapser";
import Previewform from "../../../reports/form/components/previewForm";

const FormList = ({ forms, disableFieldsConfig, onSubmit }) => {
	console.log("formssss", forms);
	return (
		<>
			{forms.map((form, index) => {
				let title = form?.title ? form?.title : form?.reportingTitle;
				const submitedTitle = form.isSubmited ? " Submited" : "";
				title = title + submitedTitle;
				return (
					<>
						<br />
						<Collapser title={title}>
							<Grid container>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Previewform
										fields={
											form?.formFields ? form?.formFields : form.reportingValues
										}
										disableConfig={disableFieldsConfig}
										onSubmit={(fields,actions) => {
											form.reportingValues = fields;
											onSubmit(form, index,actions);
										}}
									/>
								</Grid>
							</Grid>
						</Collapser>
					</>
				);
			})}
		</>
	);
};
export default FormList;
