import React from "react";
import {
	CardContent,
	CardHeader,
	Grid,
	Typography,
	TextField,
} from "@material-ui/core";
import { getTranslation } from "../../../../heplers/translationHelper";
import CardComponent from "../../../../components/Card";
import { useState } from "react";
import { useEffect } from "react";
import {
	GET_AllContactList_URL,
	GET_AllContacts_URL,
	Get_MeetingGetAllMeetingMembers,
	Post_AddMeetingGuest_URL,
	Get_GetAllGuests_URL,
	Post_RemoveMeetingGuest_URL,
	Get_RegularMeetingMCBoardMembers_URL,
	Post_Meeting_UpdateGuestSignatureNeeded,
	Post_Meeting_UpdateMemberSignatureNeeded,
} from "../../../../constants/apiUrls";
import { Get, Create } from "../../../../actions";
import Table from "../../../../components/table";
import { AddGuest } from "../regular/components/guest/addGuest";
import { useNavigate, useParams } from "react-router";
import { useSnackbar } from "notistack";
import { GuestList } from "../regular/components/guest/guestList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";

const columns = [
	{
		id: "fullName",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Name", "Name", "Name"),
	},
	{
		id: "mail",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Mail", "Mail", "Mail"),
	},
	{
		id: "phone1",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Phone", "Phone", "Phone"),
	},
	{
		id: "representativeStatusText",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Join Status", "Join Status", "Join Status"),
	},
];

export const ListAttendatns = (meetingId) => {
	const [attendaant, setAttendants] = useState();
	const [personList, setPersonList] = useState();

	const { enqueueSnackbar } = useSnackbar();
	const [contacts, setContacts] = useState([]);
	const [guestLoading, setGuestLoading] = useState(false);
	const [guestListWithPagination, setGuestListWithPagination] = useState();
	const [tempSelectedMembers, setTempSelectedMembers] = useState([]);
	const [selectedValue, setSelectedValue] = useState(null);
	const [loading, setLoading] = useState(false);

	const [tempSelectedMembersGuest, setTempSelectedMembersGuest] = useState([]);

	const handleOnChange = (event, newValue) => {
		setSelectedValue(newValue);
	};

	console.log("selected", selectedValue);
	console.log("contactssss", personList);

	console.log("huhuaaa", personList);
	useEffect(() => {
		getAllMeetingAttendants();
		getAllContactList();
	}, []);

	useEffect(() => {
		loadGuests();
	}, [meetingId]);

	console.log("bxhasghsafghcfasg", tempSelectedMembersGuest);
	const getAllMeetingAttendants = async (cId) => {
		setLoading(true);
		await Get(
			{
				meetingId: meetingId?.meetingId,
			},

			Get_MeetingGetAllMeetingMembers,

			null,
			(resp) => {
				setAttendants(resp.data);
				setLoading(false);
				if (resp?.data.length > 0) {
					let signatureRequiredArrayAttendant = resp?.data.filter(
						(obj) => obj.isNeededSignature == true
					);
					console.log(
						"gsafghsavxgsacgfscxgfscfx",
						signatureRequiredArrayAttendant
					);
					setTempSelectedMembers(signatureRequiredArrayAttendant);
				}
			},
			(error) => {
				console.log("parse table user  error");
				setLoading(false);
			}
		);
	};

	const getAllContactList = async (cId) => {
		await Get(
			{
				//meetingId: meetingId?.meetingId,
			},
			GET_AllContactList_URL,
			null,
			(resp) => {
				setPersonList(resp.data);
				setContacts(resp.data);
			},
			(error) => {
				console.log("parse table user  error");
			}
		);
	};

	const loadGuests = () => {
		setGuestLoading(true);
		Get(
			{ meetingId: meetingId?.meetingId },
			Get_GetAllGuests_URL,
			null,
			(resp) => {
				setGuestLoading(false);
				setGuestListWithPagination(resp?.data);
				if (guestListWithPagination.length > 0) {
					let signatureRequiredArray = guestListWithPagination.filter(
						(obj) => obj.isNeededSignature == true
					);
					console.log("filterarray", signatureRequiredArray);
					setTempSelectedMembersGuest(signatureRequiredArray);
				}
			},
			(error) => {
				setGuestLoading(false);
				//enqueueSnackbar("Guest list not loaded", { variant: "error" });
			}
		);
	};

	const handAddGuest = (values) => {
		Create(
			{ contactId: values.contactId, meetingId: meetingId?.meetingId },
			Post_AddMeetingGuest_URL,
			null,
			(resp) => {
				enqueueSnackbar("Guest Added", { variant: "success" });
				loadGuests();
			},
			(error) => {
				enqueueSnackbar("Can't Add guest for now. Sorry for inconvinience", {
					variant: "error",
				});
			}
		);
	};

	const handleDeleteGuest = (guest) => {
		Create(
			{
				contactId: guest.id,
				meetingId: meetingId?.meetingId,
			},
			Post_RemoveMeetingGuest_URL,
			null,
			(resp) => {
				enqueueSnackbar("Guest Removed", { variant: "success" });
				loadGuests();
			},
			(error) => {
				enqueueSnackbar("Can't Remove guest for now. Sorry for inconvinience", {
					variant: "error",
				});
			}
		);
	};

	const handleSignatureGuest = (items) => {
		console.log("huhuhuhuasdasgdhagsfxghascxghascx", items);
		setTempSelectedMembers(items);
	};

	const handleAddSignatureMember = (items) => {
		console.log("items", items);
		let payload = [
			{
				meetingId: meetingId?.meetingId,
				memberId: items.id,
				isNeededSignature: true,
			},
		];

		Create(
			payload,
			Post_Meeting_UpdateMemberSignatureNeeded,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Member Added For Signature",
						"Member Added For Signature",
						"Member Added For Signature"
					),
					{ variant: "success" }
				);
			},
			(error) => {
				enqueueSnackbar("SomeThing went wrong", {
					variant: "error",
				});
			}
		);
	};

	const handleDeleteSignatureMember = (items) => {
		console.log("items", items);
		let payload = [
			{
				meetingId: meetingId?.meetingId,
				memberId: items.id,
				isNeededSignature: false,
			},
		];

		Create(
			payload,
			Post_Meeting_UpdateMemberSignatureNeeded,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Member Removed For Signature",
						"Member Removed For Signature",
						"Member Removed For Signature"
					),
					{ variant: "success" }
				);
			},
			(error) => {
				enqueueSnackbar("SomeThing went wrong", {
					variant: "error",
				});
			}
		);
	};

	const handleAddSignatureGuest = (items) => {
		console.log("guest adding", items);
		let payload = [
			{
				meetingId: meetingId?.meetingId,
				guestId: items.id,
				isNeededSignature: true,
			},
		];

		Create(
			payload,
			Post_Meeting_UpdateGuestSignatureNeeded,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Guest Added For Signature",
						"Guest Added For Signature",
						"Guest Added For Signature"
					),
					{ variant: "success" }
				);

				console.log("jaksgdhjagsdhjasgd", tempSelectedMembersGuest);
			},
			(error) => {
				enqueueSnackbar("SomeThing went wrong", {
					variant: "error",
				});
			}
		);
	};

	const handleDeleteSignatureGuest = (items) => {
		console.log("items", items);
		let payload = [
			{
				meetingId: meetingId?.meetingId,
				guestId: items.id,
				isNeededSignature: false,
			},
		];

		Create(
			payload,
			Post_Meeting_UpdateGuestSignatureNeeded,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Guest Removed For Signature",
						"Guest Removed For Signature",
						"Guest Removed For Signature"
					),
					{ variant: "success" }
				);
			},
			(error) => {
				enqueueSnackbar("SomeThing went wrong", {
					variant: "error",
				});
			}
		);
	};
	return (
		<div>
			<div style={{ display: "flex" }}></div>
			<br />
			<Table
				dense
				auto
				title={getTranslation("Attendants", "Attendants", "Attendants")}
				colums={columns}
				loading={loading}
				// rows={activities?.data}
				count={attendaant?.length}
				//sortBy="company"
				rows={attendaant}
				//count={netAssetValue?.netAssetValueList?.length}
				page={0}
				rowsPerPage={10}
				handleChangePage={(event, newPage) => {
					//handleChangePage(event, newPage)
				}}
				enableCheckbox
				selected={tempSelectedMembers}
				handleChangeRowsPerPage={(e) => {}}
				onSelectionChange={(items) => handleSignatureGuest(items)}
				onSelectionChecked={(items) => handleAddSignatureMember(items)}
				onSelectionUnChecked={(items) => handleDeleteSignatureMember(items)}
				checkedLabel={getTranslation("Signature", "Signature", "Signature")}
			/>
			<br />
			<br />
			<CardComponent>
				<CardHeader title={getTranslation("Guests", "Guests", "Guests")} />
				<CardContent>
					<AddGuest
						contactList={contacts}
						onAddNewGuest={(contactId) => {
							handAddGuest({
								contactId: contactId,
								meetingId: meetingId,
							});
						}}
						onSubmitGuest={(guest) => {
							//setFieldValue("guests",[...values.guests,guest]);
							handAddGuest({ contactId: guest.id, meetingId: meetingId });
							setContacts([...contacts, guest]);
							loadGuests();
						}}
					/>
					<GuestList
						meetingId={meetingId?.meetingId}
						guests={guestListWithPagination}
						onDelete={handleDeleteGuest}
						signautureRequired={true}
						tempSelectedMembersGuest={tempSelectedMembersGuest}
						setTempSelectedMembersGuest={setTempSelectedMembersGuest}
						handleAddSignatureGuest={handleAddSignatureGuest}
						handleDeleteSignatureGuest={handleDeleteSignatureGuest}
					/>
				</CardContent>
			</CardComponent>
		</div>
	);
};
