import { useEffect, useState } from "react"
import { Get } from "../../../actions"
import { useParams } from "react-router"
import { Get_AllToDoListByContactId, Update_Todo_ListItem_Status } from "../../../constants/apiUrls"
import Table from "../../../components/table"
import { getTranslation } from "../../../heplers/translationHelper"
import { Checkbox } from "@material-ui/core"
import AlertDialog from "../../../components/DeleteDialog"


export const ReviewTodos=()=>{
    const {contactId}=useParams()
    const [toDoList,setToDoList]=useState([]);
    const [loadingToDo,setLoadingToDo]=useState(true);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [changingStatus, setChangingStatus] = useState(null);

    
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvMeetings", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

const columns = [
  {
    id: "meetingTitle",
    //numeric: false,
    disablePadding: true,
    label: getTranslation(
      "Meeting Subject",
      "Meeting Subject",
      "Meeting Subject"
    ),
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Description", "Description", "Beschreibung"),
  },
  {
    id: "dueDate",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Due Date", "Date d'échéance", "Geburtstermin"),
    format: "date",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions", "Actions", "Aktionen"),
  },
];

    useEffect(() => {
        setLoadingToDo(true)
      Get(
        { contactId },
        Get_AllToDoListByContactId,
        null,
        (resp) => {
            setToDoList(resp.data)
            setLoadingToDo(false)
        },
        (error) => {}
      );
    }, [contactId]);

	const onCheckStatusChanged = (row, newStatus) => {
		const model = { id: row.id, newStatus: newStatus };
		setChangingStatus(model);
		setOpenConfirmDialog(true);
		// else {
		// 	setOpenConfirmDialog(true);
		// }
	};

	const handleStatusChange = (model) => {
        setSubmitting(true)
		Get(
			{ ...model, status: model.newStatus },
			Update_Todo_ListItem_Status,
			null,
			(resp) => {
				// const temp = toDoList;
				// const index = temp.findIndex((x) => x.id == model.id);
				// temp[index] = { ...temp[index], status: model.newStatus };
                const temp=toDoList.filter(x=>x.id!=model.id)
				setToDoList([...temp]);
				setChangingStatus(null);
				setOpenConfirmDialog(false);
                setSubmitting(false)
			},
			(error) => {
                setSubmitting(false)
				setChangingStatus(null);
			}
		);
	};

    return (
      <>
        <Table
          title={getTranslation("To Do's", "To Do's", "To Do's")}
          dense
          auto
          loading={loadingToDo}
          colums={columns}
          sortBy="dueDate"
          rows={toDoList}
          count={toDoList?.length}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(event, newPage) =>
            handleChangePage(event, newPage)
          }
          handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          // viewDelete
          // onDelete={(row) => handleOpenDeleteDialog(row)}
          // viewEdit
          // onEdit={(row) => handleEditPersonRepresentative(row)}
          actions={[
            {
              component: (row) => {
                return (
                  <Checkbox
                    checked={row.status}
                    onChange={(e) =>
                      onCheckStatusChanged(row, e.target.checked)
                    }
                  />
                );
              },
            },
          ]}
        />
        <AlertDialog
          confirmButtonText="Confirm"
          onClose={() => {
            setOpenConfirmDialog(false);
            setChangingStatus(null);
          }}
          onSubmit={() => handleStatusChange(changingStatus)}
          title="Confirmation"
          text={getTranslation("You will not be able to revert it back. Are you sure to mark it done?","You will not be able to revert it back. Are you sure to mark it done?","You will not be able to revert it back. Are you sure to mark it done?")}
          open={openConfirmDialog}
          // deleting={changingStatus!=null}
          deleting={submitting}
        />
      </>
    );
}