import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  IconButton,
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import Card from "../../../../components/Card";
import { Autocomplete } from "@material-ui/lab";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Get } from "../../../../actions";
import {
  GET_AllCompaniesList_URL,
  Get_AllCompanyRepresentativeContacts_URL,
} from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../../heplers/translationHelper";
import { representativeRoles } from "../../../../constants/representativeRoles";
import { Cancel } from "@material-ui/icons";


const CreateCompanyRepresentative = ({
  onCancel,
  companyId,
  onSubmit,
  editCompanyRepresentative,
}) => {
  console.log("kjgsdkjgsfd", editCompanyRepresentative);
  const [companyRepresentatvies, setCompanyRepresentatvies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    Get(
      {},
      GET_AllCompaniesList_URL,
      history,
      (response) => {
        setCompanies(response.data);
      },
      (error) => { }
    );
  }, []);

  useEffect(() => {
    Get(
      {
        id: selectedCompany,
      },
      Get_AllCompanyRepresentativeContacts_URL,
      history,
      (response) => {
        if (response.data.length === 0)
          enqueueSnackbar(
            getTranslation(
              "No representative found for selected company",
              "Aucun représentant trouvé pour l'entreprise sélectionnée",
              "Für das ausgewählte Unternehmen wurde kein Vertreter gefunden"
            ),
            {
              variant: "error",
              preventDuplicate: false,
            }
          );
        setCompanyRepresentatvies(response.data);
      },
      (error) => { }
    );
  }, [selectedCompany]);

  const initialValues = {
    companyId: companyId,
    representedByPersonId: 0,
    representativeRole: "",
    representedByCompanies: {
      companyId: "",
      contactId: 0,
      representativeRole: "",
      /////////// extra states
    },
    company: {},
    contact: {},
  };

  const basicValidationSchema = Yup.object().shape({
    representedByCompanies: Yup.object({
      representativeRole:Yup.string().required("Role is required"),
      companyId: Yup.string().required("Company is required"),
      contactId: Yup.number().min(1, "Represented By Person is required").required("Represented By Person is required"),
      // representativeRole: Yup.string().when("contactId", {
      //   is: (val) => val !== 0,
      //   then: Yup.string().required("Representative Role is required"),
      // }),
    }),
  });
  const defaultValue = editCompanyRepresentative
    ? {
      companyId: companyId,
      representedByCompanies: {
        companyId: editCompanyRepresentative.companyId,
        contactId: editCompanyRepresentative.contact.id,
        representativeRole: representativeRoles.find(x => x.value === editCompanyRepresentative.representativeRole),
      },
      company: editCompanyRepresentative.company,
      contact: editCompanyRepresentative.contact,
    }
    : initialValues;
  return (
    <Card
      title={getTranslation(
        "Add Company Representative",
        "Ajouter un représentant de l'entreprise",
        "Unternehmensvertreter hinzufügen"
      )}
    >
      {/* <CardHeader title='Details' /> */}
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
          setTouched
        }) => (
          <form>
            {console.log("hjghjugfasdfsdf", errors)}
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Autocomplete
                    name="representedByCompanies.companyId"
                    value={values.company}
                    options={companies}
                    getOptionLabel={(option) => option.companyName}
                    size="small"
                    onChange={(e, value) => {
                      setFieldValue("company", value);
                      setFieldValue("representedByCompanies.company", value);
                      setFieldValue(
                        "representedByCompanies.companyId",
                        value?.id
                      );
                      setSelectedCompany(value?.id);
                    }}
                    //   onInputChange={(e, value) => setSearchCompaniesString(value)}
                    renderInput={(params) => (
                      <div>
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Select Company",
                          "Select Company",
                          "Select Company"
                        )}
                        variant="outlined"
                      />
                      <ErrorMessage
                      name='representedByCompanies.companyId'
                      component='div'
                      style={{ color: "#f44336", fontSize: "13px", margin:"4px 0px 0px 4px" }}
                      />
                    </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>

                  <Autocomplete
                    name="representedByCompanies.representativeRole"
                    value={selectedRole}
                    options={representativeRoles}
                    getOptionLabel={(option) =>
                      getTranslation(
                        option.defaultEnglish,
                        option.defaultFrench,
                        option.defaultGerman
                      )
                    }
                    size="small"
                    onChange={(e, value) => {
                      setFieldValue(
                        "representedByCompanies.representativeRole",
                        value.id
                      );
                      setSelectedRole(value); // Update local state
                    }}
                    defaultValue={representativeRoles[0]}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          label={getTranslation(
                            "Select Role",
                            "Sélectionnez un rôle",
                            "Rolle auswählen"
                          )}
                          variant="outlined"
                        />
                        <ErrorMessage
                        name='representedByCompanies.representativeRole'
                        component='div'
                        style={{ color: "#f44336", fontSize: "13px", margin:"4px 0px 0px 4px" }}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Autocomplete
                    name="representedByCompanies.contactId"
                    value={selectedRepresentative}
                    options={companyRepresentatvies}
                    getOptionLabel={(option) => option.firstName + " " + option.name}
                    size="small"
                    onChange={(e, value) => {
                    //   setFieldValue("representedByCompanies.contactId", value?.id);
                    //   setFieldValue("representedByPersonId", value?.id);
                    //   setSelectedRepresentative(value);
                    // }}

                    setFieldValue("representedByCompanies.contactId", value ? value.id : ""); 
                    setFieldValue("representedByPersonId", value ? value.id : ""); 
                    setTouched("representedByCompanies.contactId", true); 
                    setTouched("representedByPersonId", true); 
                  }}
                  onBlur={() => {
                    setTouched("representedByCompanies.contactId", true);
                    setTouched("representedByPersonId", true);
                  }}
                    renderInput={(params) => (
                      <div>
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Select Representative",
                          "Select Representative",
                          "Select Representative"
                        )}
                        variant="outlined"
                      
                      />
                      <ErrorMessage
                      name='representedByCompanies.contactId'
                      component='div'
                      style={{ color: "#f44336", fontSize: "13px", margin:"4px 0px 0px 4px" }}
                      />
                    </div>
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {editCompanyRepresentative ?
                  <IconButton onClick={onCancel}>
                    <Cancel />
                  </IconButton>
                  : null}
                <ButtonWithLoading
                  title={
                    editCompanyRepresentative
                      ? getTranslation(
                        "Update",
                        "Mettre à jour",
                        "Aktualisieren"
                      )
                      : getTranslation("Create", "Créer", "Schaffen")
                  }
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={() => {
                 handleSubmit();
                  }}
                />
              </Box>
            </CardActions>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default CreateCompanyRepresentative;

