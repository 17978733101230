import { RegularUpcoming, RegularPrevious, DraftedMeeting } from "./component";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Collapser } from "../../components/Collapser";
import { useEffect, useState } from "react";
import { Get } from "../../actions";
import {
	Get_AllPreviousMeetings_URL,
	Get_AllUpcomingMeetings_URL,
	Get_ManagementCompanyFeeAgreement_URL,
	Get_Meeting_GetAllRegularMeetingDraft,
} from "../../constants/apiUrls";
import { MeetingAuditLog } from "./component/auditLog";
import { getTranslation } from "../../heplers/translationHelper";
import { Alert, AlertTitle } from "@material-ui/lab";
import { MeetingsSubmenu } from "../../layout/subMenu";
import HorizontalNonLinearStepper from "./component/regular/StepperRegularMeeting";
import { useDispatch, useSelector } from "react-redux";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { Box } from "@material-ui/core";

export const RegularMeeting = () => {
	const { managementCompanyId } = useParams();
	const [upComingMeetingLoading, setUpcomingMeetingLoading] = useState(false);
	const [upComingMeetings, setUpComingMeetings] = useState([]);
	const [previousMeetingLoading, setPreviousMeetingLoading] = useState(false);
	const [doesRegularServiceExist, setDoesRegularServiceExist] = useState(false);
	const [previousMeetings, setPreviousMeetings] = useState([]);
	const [draftsMeeting, setDraftsMeetings] = useState([]);
	const [draftsMeetingLoading, setDraftsMeetingLoading] = useState(false);
	const [meeting, setMeeting] = useState();
	const [collaperController, setCollapserController] = useState(true);
	const history = useNavigate();

	const [meetingIdLog, setMeetingIdLog] = useState();
	const [openMeetingLogDialog, setOpenMeetingLogDialog] = useState(false);

	const loadRegularMeetings = (filterValues) => {
		setUpcomingMeetingLoading(true);
		filterValues.MCId = managementCompanyId;
		Get(
			filterValues,
			Get_AllUpcomingMeetings_URL,
			null,
			(resp) => {
				setUpComingMeetings(resp?.data);
				setUpcomingMeetingLoading(false);
			},
			(error) => {
				setUpcomingMeetingLoading(false);
			}
		);
	};

	const loadPreviousMeetings = (filterValues) => {
		setPreviousMeetingLoading(true);
		filterValues.MCId = managementCompanyId;
		Get(
			filterValues,
			Get_AllPreviousMeetings_URL,
			null,
			(resp) => {
				setPreviousMeetings(resp?.data);
				setPreviousMeetingLoading(false);
			},
			(error) => {
				setPreviousMeetingLoading(false);
			}
		);
	};

	const loadDraftsMeetings = (filterValues) => {
		setDraftsMeetingLoading(true);
		filterValues.SPVId = managementCompanyId;
		Get(
			filterValues,
			Get_Meeting_GetAllRegularMeetingDraft,
			null,
			(resp) => {
				setDraftsMeetingLoading(false);
				setDraftsMeetings(resp.data);
			},
			(error) => {
				setDraftsMeetingLoading(false);
			}
		);
	};

	useEffect(() => {
		loadFeeAgreement({ id: managementCompanyId });
	}, [managementCompanyId]);

	// load Fee agreement
	const loadFeeAgreement = () => {
		Get(
			{ id: managementCompanyId },
			Get_ManagementCompanyFeeAgreement_URL,
			null,
			(resp) => {
				setMeeting(resp.data);
			},
			(error) => {}
		);
	};

	/////////// check if any regular service exist
	const checkAnyRegularService = (services) => {
		if (services?.length > 0) {
			services.forEach((service) => {
				if (service.feeType === 1) {
					setDoesRegularServiceExist(true);
					return;
				}
			});
		}
	};

	const handleMeetingDetails = (meeting) => {
		const url = `${meeting.id}`;
		history(url);
	};

	const handleShowMeetingLogs = (meetingId) => {
		setMeetingIdLog(meetingId);
		setOpenMeetingLogDialog(true);
	};

	return (
		<div>
			<MeetingsSubmenu managementCompanyId={managementCompanyId} />
			{meeting?.feeAgreementStatus === 7 ? (
				<Alert severity="error">
					<AlertTitle>
						{getTranslation(
							"Fee Agreement does not exist",
							"Le contrat d'honoraires n'existe pas",
							"Honorarvereinbarung existiert nicht"
						)}
					</AlertTitle>
				</Alert>
			) : doesRegularServiceExist ? (
				<Alert severity="error">
					{getTranslation(
						"Fee Agreement not signed BUT you can generate meetings",
						"Accord d'honoraires non signé MAIS vous pouvez générer des rendez-vous",
						"Gebührenvereinbarung nicht unterzeichnet, ABER Sie können Meetings generieren"
					)}
				</Alert>
			) : null}
			<Box display='flex' justifyContent='right'>
			<ButtonWithLoading
				title={getTranslation(
					"Generate Regular Meeting",
					"Generate Regular Meeting",
					"Generate Regular Meeting"
				)}
				color="primary"
				variant="contained"
				size="large"
				onClick={() =>
					history(
						`/meetings/${managementCompanyId}/regular/generatenewRegularMeeting/new`
					)
				}
				style={{marginRight:"20px"}}
			/>
			<ButtonWithLoading
				title={getTranslation(
					"Generate Adhoc Meeting",
					"Generate Adhoc Meeting",
					"Generate Adhoc Meeting"
				)}
				color="primary"
				variant="contained"
				size="large"
				onClick={() =>
					history(
						`/meetings/${managementCompanyId}/regular/generatenewRegularMeeting/adhoc`
					)
				}
			/>
			</Box>
			

			<br />
			<br />

			<Collapser
				title={getTranslation(
					"Upcoming Regular Meetings",
					"Réunions ordinaires à venir",
					"Kommende regelmäßige Treffen"
				)}
			>
				<RegularUpcoming
					managementCompanyId={managementCompanyId}
					rows={upComingMeetings}
					onFilter={(filter) => {
						loadRegularMeetings(filter);
					}}
					loading={upComingMeetingLoading}
					onAdd={handleMeetingDetails}
					onShowlogs={handleShowMeetingLogs}
				/>
			</Collapser>
			<br />
			<br />
			<Collapser
				title={getTranslation(
					"Previous Regular Meetings",
					"Réunions ordinaires précédentes",
					"Frühere regelmäßige Treffen"
				)}
			>
				<RegularPrevious
					managementCompanyId={managementCompanyId}
					rows={previousMeetings}
					onFilter={(filter) => {
						loadPreviousMeetings(filter);
					}}
					loading={previousMeetingLoading}
				/>
			</Collapser>

			<br />
			<br />

			<Collapser
				title={getTranslation(
					"Drafts Meeting",
					"Drafts Meeting",
					"Drafts Meeting"
				)}
			>
				<DraftedMeeting
					managementCompanyId={managementCompanyId}
					rows={draftsMeeting}
					onFilter={(filter) => {
						loadDraftsMeetings(filter);
					}}
					loading={draftsMeetingLoading}
				/>
			</Collapser>
			<br />
			<br />

			{/* {meeting?.feeAgreementStatus === 7 ? null : (
				<Collapser
					open={collaperController}
					title={getTranslation(
						"Generate Regular Meetings",
						"Générer des réunions régulières",
						"Generieren Sie regelmäßige Meetings"
					)}
				>
					<HorizontalNonLinearStepper
						collaperController={collaperController}
						setCollapserController={setCollapserController}
						managementCompanyId={managementCompanyId}
					/>
				</Collapser>
			)} */}
			<MeetingAuditLog
				open={openMeetingLogDialog}
				meetingId={meetingIdLog}
				onClose={() => {
					setOpenMeetingLogDialog(false);
					setMeetingIdLog(null);
				}}
			/>
		</div>
	);
};
