import React, { useEffect, useState } from "react";
import { Get, Create } from "../../../../../actions";
import Table from "../../../../../components/table";
import DeleteDialog from "../../../../../components/DeleteDialog";
import { useSnackbar } from "notistack";
import {
  Get_AllCircularResolutionsTemplatesWithPagination_URL,
  Post_DeleteCircularResolutionTemplate_URL,
  Post_InsertCircularResolutionTemplate_URL,
  Post_UpdateCircularResolutionTemplate_URL,
} from "../../../../../constants/apiUrls";
import { AddTemplateDialog } from "./addTemplateDialog";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { SettingsSubmenu } from "../../../../../layout/subMenu";

const columns = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Title","Titre","Titel"),
  },
  {
    id: "language",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Language","Langue","Sprache"),
  },
  {
    id: "legalForm",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Legal Form","Forme juridique","Rechtsform"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions","Actions","Aktionen"),
  },
];
export const TemplateList = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [editTemplate, setEditTemplate] = useState(null);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  //////////////// states for add template dialog //////////////////

  const [templateListLoading, setTemplateListLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [templateId, setTemplateId] = useState("");

  ////////////// Load Templates with Pagination /////////////
  useEffect(() => {
    loadTemplates();
  }, [page, rowsPerPage, searchString]);

  const loadTemplates = () => {
    setTemplateListLoading(true);
    Get(
      {
        searchString,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      Get_AllCircularResolutionsTemplatesWithPagination_URL,
      null,
      (resp) => {
        setTemplateListLoading(false);
        setTemplateList(resp?.data);
      },
      (error) => {
        setTemplateListLoading(false);
      }
    );
  };
  ////////////////////////////////// Handlers /////////////////////////////
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearchTemplate = (searchString) => {
    setSearchString(searchString);
  };

  const handleAddTemplate = () => {
    setEditTemplate(null);
    setOpenTemplateDialog(true);
  };

  const handleDeleteDialog = (deleteItem) => {
    setOpenDelete(true);
    setDeleteRow(deleteItem);
  };

  const handleOnEditTemplate = (template) => {
    setTemplateId(template?.id);
    setEditTemplate(template);
    setOpenTemplateDialog(true);
  };
  ////////////////////// delete /////////////////////////
  const onDelete = async () => {
    setDeleteProgress(true);
    await Create(
      {
        ids: [deleteRow.id],
      },
      Post_DeleteCircularResolutionTemplate_URL,
      null,
      (resp) => {
        enqueueSnackbar("Template Deleted", { variant: "success" });
        loadTemplates();
        setDeleteProgress(false);
        setOpenDelete(false);
      },
      (error) => {
        enqueueSnackbar("Template not deleted", { variant: "error" });
        setDeleteProgress(false);
      }
    );
  };

  ///////////////////////////////// Submit Template /////////////
  const handleSubmitTemplate = async (values, actions) => {
    await Create(
      values,
      editTemplate
        ? Post_UpdateCircularResolutionTemplate_URL
        : Post_InsertCircularResolutionTemplate_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        if (editTemplate) {
          setEditTemplate(null);
        }
        setOpenTemplateDialog(false);
        editTemplate
          ? enqueueSnackbar("Template Updated", { variant: "success" })
          : enqueueSnackbar("Template Added", { variant: "success" });
        loadTemplates();
      },
      (error) => {
        actions.setSubmitting(false);
        editTemplate
          ? enqueueSnackbar("Can't Updated Template", { variant: "error" })
          : enqueueSnackbar("Template can't be Added", { variant: "error" });
      }
    );
  };

  return (
    <div>
      <SettingsSubmenu/>
      <Table
        dense
        loading={templateListLoading}
        title={getTranslation("Circular Resolution Templates","Modèles de résolution circulaire","Vorlagen für Zirkularbeschlüsse")}
        colums={columns}
        sortBy="title"
        rows={templateList?.data}
        count={templateList.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewEdit
        onEdit={(row) => handleOnEditTemplate(row)}
        viewDelete
        onDelete={(row) => handleDeleteDialog(row)}
        viewSearch
        onSearch={(searchString) => handleSearchTemplate(searchString)}
        viewCreate
        onCreate={handleAddTemplate}
      />
      <AddTemplateDialog
        open={openTemplateDialog}
        onSubmit={handleSubmitTemplate}
        onClose={() => {
          setOpenTemplateDialog(false);
        }}
        edit={editTemplate}
        templateId={templateId}
      />
      <DeleteDialog
        open={openDelete}
        deleting={deleteProgress}
        title={getTranslation("Delete Template","Supprimer le modèle","Vorlage löschen")}
        onClose={() => setOpenDelete(false)}
        onSubmit={(e) => {
          onDelete();
        }}
      />
    </div>
  );
};
