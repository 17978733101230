import React, { useState, useEffect } from "react";
import Table from "../../../../../../components/table";
import { Get, Create } from "../../../../../../actions";
import { useSnackbar } from "notistack";
import { AddReminder } from "./addReminer";
import { Typography } from "@material-ui/core";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import CardComponent from "../../../../../../components/Card";

export const SelectionCcList = ({
	members,
	selectedMembers,
	enableCheckbox,
	onAddCC,
	onRemoveCC,
}) => {
	const columns = [
		{
			id: "fullName",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Name", "Nom", "Name"),
		},
		{
			id: "mail",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Email", "E-mail", "Email"),
		},
	];
	const [page, setPage] = useState(0);
	let selectionCCList = localStorage.getItem("selectionCCList");
	if (selectionCCList == null) {
		localStorage.setItem("selectionCCList", 10);
		selectionCCList = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(selectionCCList);
	const [searchString, setSearchString] = useState("");

	const handleNewItem = async (item) => {};
	const handleChangePage = (e, newPage) => {
		setPage(0);
	};
	const handleChangeRowsPerPage = (e, rowsPerPage) => {
		localStorage.setItem("selectionCCList", e.target.value);
		setRowsPerPage(rowsPerPage);
	};

	return (
		<CardComponent title="Attach Member as CC">
			{/* <AddReminder/> */}

			<br />
			<br />
			<Table
				auto
				size="small"
				dense
				//loading={membersLoading}
				// title={title}
				colums={columns}
				//sortBy="fullName"
				rows={members}
				count={members?.length}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewSearch
				enableCheckbox
				selected={selectedMembers}
				onSelectionChecked={onAddCC}
				onSelectionUnChecked={onRemoveCC}
				onSelectionChange={(selected) => {
					if (selected.length > 0) {
						selected.forEach((item) => {
							onAddCC(item);
						});
					} else {
						members.forEach((item) => {
							onRemoveCC(item);
						});
					}
				}}
			/>
		</CardComponent>
	);
};
