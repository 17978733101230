import React from 'react';
import WeedooLogo from "../assests/images/AURETO.svg"
import WeedooLogoAlternate from "../assests/images/Aureto Alternate.svg"

export const Logo = (props) => {
  return <img alt="Logo" src={WeedooLogo} {...props} />;
  // return <div style={{fontSize:"20px",color:"white"}}>AURETO</div>
};


export const LogoAlternate = (props) => {
  return <img alt="Logo" src={WeedooLogoAlternate} {...props} />;
  // return <div style={{fontSize:"20px",color:"white"}}>AURETO</div>
};
