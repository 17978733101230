import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
} from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../components/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Get } from "../../../actions";
import {
  GET_AllCompaniesList_URL,
  Get_AllCompanyRepresentativeContacts_URL,
} from "../../../constants/apiUrls";
import { CountrySelection } from "../../../constants/countries";
import { getTranslation } from "../../../heplers/translationHelper";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const representativeRoles = ["President", "Secretary", "Ordinary Member"];

const CreateCompanyRepresentative = (props) => {
  const { companyId } = props;
  const [companyRepresentatvies, setCompanyRepresentatvies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const history = useNavigate();
  const dispatch = useDispatch();
  const getSelectedCompany = (company) => {};

  useEffect(() => {
    Get(
      {},
      GET_AllCompaniesList_URL,
      history,
      (response) => {
        setCompanies(response.data);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    Get(
      {
        id: selectedCompany,
      },
      Get_AllCompanyRepresentativeContacts_URL,
      history,
      (response) => {
        setCompanyRepresentatvies(response.data);
      },
      (error) => {}
    );
  }, [selectedCompany]);

  const initialValues = {
    // title: "",
    companyId: null,
    company: null,
  };

  const basicValidationSchema = Yup.object().shape({
    // title: Yup.string().required(getTranslation("Company is required","La société est requise","Firma ist erforderlich")),
    companyId: Yup.string().required(getTranslation("Represented By Person is required","Représenté par une personne est requis","Vertreten durch Person ist erforderlich")),
    company: Yup.object().required(getTranslation("Represented By Person is required","Représenté par une personne est requis","Vertreten durch Person ist erforderlich")),
  });

  const defaultValue = initialValues;
  return (
    <Card>
      {/* <CardHeader title='Details' /> */}
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          props.onSubmit(values, actions);
          actions.resetForm();
          // dispatch(userLogin(values)).then((res) => {
          //   const errors = checkResponse(res);
          //   if (!errors) {
          //     authUtils.setSession(res.data.accessToken);
          //     authUtils.setUserRefreshToken(res.data.refreshToken);
          //     dispatch(setUserData(res.data.user));
          //     history("/dashboard");
          //     enqueueSnackbar("Login Successfully", { variant: "success" });

          //     actions.setSubmitting(false);
          //   } else {
          //     actions.setSubmitting(false);
          //     enqueueSnackbar("Please check your Email or Password", {
          //       variant: "error",
          //     });
          //   }
          // });
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
        }) => (
          <form>
            <CardContent>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label={getTranslation("Title","Titre","Titel")}
                    required
                    size="small"
                    variant="outlined"
                    name="title"
                    {...getFieldProps("title")}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Autocomplete
                    name="companyId"
                    value={values?.company}
                    options={companies}
                    getOptionLabel={(option) => option.companyName}
                    // getOptionSelected={(selected, value) => {
                    //    return selected.id === value}

                    // }

                    size="small"
                    onChange={(e, value) => {
                      setFieldValue("company", value);
                      setFieldValue("companyId", value?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation("Select Company","Sélectionnez l'entreprise","Unternehmen auswählen")}
                        variant="outlined"
                        error={touched.company && Boolean(errors.company)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <ButtonWithLoading
                  title={getTranslation("Create","Créer","Schaffen")}
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default CreateCompanyRepresentative;
