import React, { Suspense } from "react";
import GlobalStyles from "./components/GlobalStyles";
import { BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import AppRoutes from "./routes";
import { SnackbarProvider, useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close style={{color:'white'}} />
    </IconButton>
  );
}

const App = () => {
return (
    <SnackbarProvider
    maxSnack={5}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    autoHideDuration={10000}
    disableWindowBlurListener
    preventDuplicate
    action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
  >
   <React.Fragment>
      <GlobalStyles />
      <BrowserRouter>
      <Suspense fallback={<h1>loading...</h1>}>
      <Routes>
        {AppRoutes()}
      </Routes>
      </Suspense>
      </BrowserRouter>
    </React.Fragment>
  </SnackbarProvider>
    
  );
};

export default App;
