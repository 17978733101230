import { Box, CardContent, CircularProgress, TextField } from "@material-ui/core";
import { forwardRef, useState } from "react";
import { useEffect } from "react";
import Card from "../../../../../../components/Card";
import { parseHelper } from "../../../../../../heplers/parserHelper";
import renderHTML from "react-render-html";
// import { jsPDF } from "jspdf";
import { useRef } from "react";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { Create, DownloadFile, Get } from "../../../../../../actions";
import { Get_SendNotificationForReview_URL, Post_UpdateStatusOfReport_URL } from "../../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { SERVER_URL } from "../../../../../../constants";
import CardComponent from "../../../../../../components/Card";
import { savePdf } from "../../../../../../heplers/pdfHelper";
import { useNavigate } from "react-router";
const MeetingReports = forwardRef(({ meetingReport, meetingId, meeting, enabled },ref) => {
  const [renderedHtml, setRenderedHtml] = useState("<div></div>");
  const pdfRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const [isBusy, setBusy] = useState(false);
  const nav=useNavigate()
  useEffect(() => {
    if (typeof meetingReport != "undefined") {
      setBusy(true)
      parseHelper(meetingId, meetingReport.reportHtml, (html) => {
        setRenderedHtml(html);
        setBusy(false)
      });
    }
  }, [meetingReport]);

  // converting html to pdf using jsPDf library
  const [isPrinting, setIsPrinting] = useState(false);

  const handleApprove = () => {
    const content = ref.current;
    setBusy(true);
    setIsPrinting(true);
    savePdf("Meeting Report.pdf", content, null, null, false, (file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var base64data = reader.result;
        console.log("kjahkjdsahjkashkd", base64data);
          Create({
            meetingId: meetingId,
            status: 1,
            comments: comments,
            reportPdfBase64: base64data.split(',')[1]
          },
            Post_UpdateStatusOfReport_URL,
            null,
            resp => {
              enqueueSnackbar(resp.data, { variant: "success" })
              nav("/response/"+resp.data)
              
            },
            error => {
              enqueueSnackbar("Error while approving your meeting report.", { variant: "error" })
              setBusy(false)

            }
          )
        };
      });
    // var newHtmldoc = new jsPDF({
    //   orientation: 'p',
    //   unit: 'pt',
    //   format: 'a4'
    // });
    // newHtmldoc.html(content, {
    //   callback: (doc) => {
    //     var base64 = doc.output("datauristring");

    //     Create({
    //       meetingId: meetingId,
    //       status: 1,
    //       comments: comments,
    //       reportPdfBase64: base64.split(',')[1]
    //     },
    //       Post_UpdateStatusOfReport_URL,
    //       null,
    //       resp => {
    //         enqueueSnackbar(resp.data, { variant: "success" })
    //         setBusy(false)

    //       },
    //       error => {
    //         enqueueSnackbar("Error while approving your meeting report.", { variant: "error" })
    //         setBusy(false)

    //       }
    //     )
    //     // console.log(base64);
    //     doc.save("Meeting Report.pdf")
    //     setIsPrinting(false)
    //   }
    // });
  };

  const handleDeclined = () => {
    setBusy(true)
    Create({
      meetingId: meetingId,
      status: 0,
      comments: comments,
    },
      Post_UpdateStatusOfReport_URL,
      null,
      resp => {
        enqueueSnackbar(resp.data, { variant: "success" })
        setBusy(false)
      },
      error => {
        enqueueSnackbar("Error while declining your meeting report.", { variant: "error" })
        setBusy(false)

      }
    )
  }
  const [comments, setComments] = useState("");

  const downloadReport = () => {
    if (meetingReport?.reportStatus) {
      DownloadFile(meetingReport?.reportPdf)
    }
  }


  return <>
    <CardComponent  title={getTranslation("Report", "Signaler", "Bericht")}>
      <CardContent>
        {isPrinting || isBusy ? <div style={{ textAlign: 'center', marginTop: '50px' }}> <CircularProgress /></div> 
            : <>
              {enabled ? <>
                <TextField
                  label={getTranslation(
                    "Comments",
                    "commentaires",
                    "Bemerkungen"
                  )}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />

                <Box
                  m={2}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <ButtonWithLoading

                    title={getTranslation("Approve", "Approuver", "Genehmigen")}
                    onClick={() => {
                      handleApprove()
                    }}
                    variant="contained"
                    color="primary"
                    loading={isBusy}
                  />
                  <ButtonWithLoading
                    style={{
                      margin: "10px",
                    }}

                    title={getTranslation("Decline", "Déclin", "Ablehnen")}
                    onClick={() => {
                      handleDeclined();
                    }}
                    variant="contained"
                    color="secondary"
                    loading={isBusy}
                  />
                </Box>

                
              </>
                : null}
              <div >
              
                {renderHTML(renderedHtml)}
              </div>
            </>
        }
      </CardContent>
    </CardComponent>
    
    <div style={{ display: 'none' }}>
      <div ref={ref}
        style={isPrinting ? { fontSize: '9px', width: '595px', padding: '10px' } : {}}>
        {renderHTML(renderedHtml)}
      </div>
    </div>
  </>;
});
export default MeetingReports;
