import React, { useState } from "react";
import CardComponent from "../../../../components/Card";
import { CardContent, Box } from "@material-ui/core"; // Updated import
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { getTranslation } from "../../../../heplers/translationHelper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	titleContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	toggleButtonGroup: {
		width: "60%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	toggleButton: {
		borderRadius: "5px",
		cursor: "pointer",
		padding: "10px",
	},
	testSmsButton: {
		backgroundColor: "rgb(229,60,22)",
		color: "white",
	},
	activeLanguage: {
		background: "#FB5E39",
		color: "white",
	},
}));

export default function TextEditor({
	translatedText,
	selectedLanguage,
	reminderKey,
	openTestSmsDialoge,
	handleLanguage,
	title,
	children,
}) {
	const [selectLanguage, setSelectedLanguage] = useState("");
	const classes = useStyles();

	const handleLanguageChange = (event, newLanguage) => {
		if (newLanguage) {
			setSelectedLanguage(newLanguage);
			handleLanguage(newLanguage);
		}
	};

	const renderTitle = () => {
		return (
			<Box className={classes.titleContainer}>
				{getTranslation(translatedText, translatedText, translatedText)}
				<Box className={classes.toggleButtonGroup}>
					<Box
						className={`${classes.toggleButton} ${classes.testSmsButton}`}
						onClick={() => openTestSmsDialoge()}
						style={{ visibility: !title ? "hidden" : "visible" }}
					>
						<p>{getTranslation(title, title, title)}</p>
					</Box>

					<ToggleButtonGroup
						value={selectLanguage}
						exclusive
						onChange={handleLanguageChange}
						aria-label="language"
						style={{ marginLeft: "auto" }}
					>
						<ToggleButton
							value="english"
							aria-label="english"
							style={{
								backgroundColor:
									selectedLanguage === "english" ? "rgb(229,60,22)" : "#D9D9D9",
								color: selectedLanguage === "english" ? "white" : "grey",
							}}
						>
							EN{" "}
						</ToggleButton>
						<ToggleButton
							value="french"
							aria-label="french"
							style={{
								backgroundColor:
									selectedLanguage === "french" ? "rgb(229,60,22)" : "#D9D9D9",
								color: selectedLanguage === "french" ? "white" : "grey",
							}}
						>
							FR
						</ToggleButton>
						<ToggleButton
							value="german"
							aria-label="german"
							style={{
								backgroundColor:
									selectedLanguage === "german" ? "rgb(229,60,22)" : "#D9D9D9",
								color: selectedLanguage === "german" ? "white" : "grey",
							}}
						>
							DE
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>
			</Box>
		);
	};

	return <CardComponent title={renderTitle()}>{children}</CardComponent>;
}
