import React, { useState } from "react";
import {
  AppBar,
  Box,
  Typography,
  Button,
  Toolbar,
  Avatar,
  Menu,
  IconButton,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { HiOutlineLogout, HiUserCircle } from "react-icons/hi";
import useStyles from "../../assests/styles/layout/topbar";
import SubMenus from "./submenu";
import { Logout } from "../../actions";
import authUtils from "../../utils/authUtils";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import { ScannerButton } from "../../components/ButttonsWithIcons";
import { ScanDocument } from "../../views/scanner/scanDialog";
import {
  EnglishLanguageIcon,
  FrenchLanguageIcon,
  GermanLanguageIcon,
} from "../../components/Icons";
import { useNavigate } from "react-router";

function TopBar({ history, className, navOpen, handleDrawerOpen, ...rest }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate=useNavigate();

  var [profileMenu, setProfileMenu] = useState(null);

  const { user } = useSelector((state) => state.auth);
  const [scanner, setScanner] = useState(false);
  const handleNavigateToProfile = () => {
    navigate("/account/profile");
    setProfileMenu(null);
  };
  const handleScan = () => {
    setScanner(true);
  };
  const handleClose = () => {
    setScanner(false);
  };
  const handleLogout = () => {
    dispatch(Logout()).then((resp) => {
      console.log("logout")
      authUtils.logout();
      navigate("/login");
    });
  };

  const handleLanguageSelection = (e) => {
    localStorage.setItem("lang", e.target.value);
    window.location.reload(true);
  };
  return (
    <AppBar
      className={clsx(classes.root, {
        [classes.rootshift]: !navOpen,
      })}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, navOpen && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Box width="100%">
          <SubMenus />
        </Box>

        <Box display="flex" alignItems="right" justifyContent="align-right">
          <Select
            style={{
              marginRight: "20px",
              width: "160px",
              height: "34px",
              borderRadius: "17px",
            }}
            variant="outlined"
            defaultValue={localStorage.getItem("lang")}
            onChange={(e) => handleLanguageSelection(e)}
          >
            <MenuItem value="en">
              <IconButton style={{ marginRight: "10px" }}>
                <EnglishLanguageIcon />
              </IconButton>
              English
            </MenuItem>
            <MenuItem value="fr">
              <IconButton style={{ marginRight: "10px" }}>
                <FrenchLanguageIcon />
              </IconButton>
              French
            </MenuItem>
            <MenuItem value="gr">
              <IconButton style={{ marginRight: "10px" }}>
                <GermanLanguageIcon />
              </IconButton>
              German
            </MenuItem>
          </Select>
          <ScannerButton
            // aria-haspopup="true"
            // aria-controls="profile-menu"
            //variant="circular"
            //className={classes.avatar}
            onClick={() => handleScan()}
          />
          <ScanDocument isOpen={scanner} onClose={handleClose} />
          <IconButton
            aria-haspopup="true"
            aria-controls="profile-menu"
            variant="circular"
            className={classes.avatar}
            onClick={(e) => {
              setProfileMenu(e.currentTarget);
            }}
          >
            {user?.firstName?.slice(0, 1)}
            {user?.lastName?.slice(0, 1)}
          </IconButton>

          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            disableAutoFocusItem
            className={classes.menuBar}
            MenuListProps={{ className: classes.profileMenuList }}
          >
            <MenuItem>
              <Box
                onClick={handleNavigateToProfile}
                display="flex"
                alignItems="center"
              >
                <HiUserCircle className={classes.icon} />
                <Typography variant="body1">My Account</Typography>
              </Box>
            </MenuItem>
            <MenuItem>
              <Box display="flex" alignItems="center" onClick={handleLogout}>
                <HiOutlineLogout className={classes.icon} />
                <Typography variant="body1">Logout</Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
