import React, { useState, useEffect } from "react";
import Table from "../../components/table";
import { Get, Create } from "../../actions";
import { getTranslation } from "../../heplers/translationHelper";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { MeetingSubMenu, SettingsSubmenu } from "../../layout/subMenu";
import {
	Get_GetAllMeetingRoomWithPagination_URL,
	Post_AddMeetingRoom_URL,
	Post_UpdateMeetingRoom_URL,
	Post_DeleteMeetingRoom_URL,
} from "../../constants/apiUrls";
import { useNavigate } from "react-router";
import { DialogContent, Typography } from "@material-ui/core";
import { EditButton } from "../../components/ButttonsWithIcons";
import DialogComponent from "../../components/Dialog";
import { Form, Formik } from "formik";
import { MeetingInfoDialog } from "./component";

const columns = [
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Title", "Title", "Title"),
	},
	{
		id: "meetingLink",
		numeric: false,
		disablePadding: true,
		label: getTranslation("MeetingLink", "MeetingLink", "MeetingLink"),
	}
];

export const MeetingRoom = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [rows, setRows] = useState([]);
	const [loadingMeetingRooms, setLoadingMeetingRooms] = useState(false);
	const [editMeetingRoom, setEditMeetingRoom] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: "MeetingRoom", url: "/meetings/meetingroom" },
		});
	}, []);

	useEffect(() => {
		loadMeetingRooms();
	}, [page, rowsPerPage, searchString]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleSearchMeetingRoom = (searchString) => {
		setSearchString(searchString);
	};

	//GET
	const loadMeetingRooms = () => {
		setLoadingMeetingRooms(true);
		Get(
			{
				searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_GetAllMeetingRoomWithPagination_URL,
			history,
			(resp) => {
				console.log(
					"jhdsajhbjfds Get_GetAllMeetingRoomWithPagination_URL",
					resp?.data
				);
				setRows(resp?.data);
				setLoadingMeetingRooms(false);
			},
			(error) => {
				console.log(
					"jhdsajhbjfds Get_GetAllMeetingRoomWithPagination_URL",
					error
				);
				setLoadingMeetingRooms(false);
			}
		);
	};

	//CREATE AND UPDATE
	const handleUpsertMeetingRoom = async (values, actions) => {
		Create(
			values,
			editMeetingRoom ? Post_UpdateMeetingRoom_URL : Post_AddMeetingRoom_URL,
			history,
			(response) => {
				actions.setSubmitting(false);
				if (editMeetingRoom) {
					enqueueSnackbar("Meeting room information updated", {
						variant: "success",
					});
					setEditMeetingRoom(null);
				} else {
					enqueueSnackbar("Meeting room Created", {
						variant: "success",
					});
				}
				setOpenDialog(false)
				loadMeetingRooms();
			},
			(error) => {
				console.log("kjhdsgakjm handleCreateManagementCompany", error);
				actions.setSubmitting(false);
				enqueueSnackbar(error?.data, {
					variant: "error",
				});
			}
		);
	};

	// EDIT

	const handleMeetingRoom = (row) => {
		setEditMeetingRoom(row);
		setOpenDialog(true)
	};

	return (
		<>
			<SettingsSubmenu />
			{/* 
			<CreateManagementCompany
				onSubmit={handleCreateMeetingRoom}
				editMeetingRoom={editMeetingRoom}
				onCancel={() => {
					setEditMeetingRoom(null);
				}}
			/> */}

			<Table
				dense
				loading={loadingMeetingRooms}
				colums={columns}
				sortBy="title"
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => handleMeetingRoom(row)}
				viewSearch
				onSearch={(searchString) => handleSearchMeetingRoom(searchString)}
				viewCreate
				onCreate={()=>setOpenDialog(true)}
				actions={[
					{
						component: (row) => <EditButton />,
					},
				]}
			/>
			<MeetingInfoDialog
			meetingRoom={editMeetingRoom}
			openDialog={openDialog}
			setOpenDialog={setOpenDialog}
			onClose={()=>{
				setOpenDialog(false)
				setEditMeetingRoom(null)
			}}
			onSubmit={handleUpsertMeetingRoom}
			/>
		</>
	);
};

