const initState = {
	activeState: null,
	meetingData: null,
	isController: true,
};

const draftMeetingReducer = (state = initState, action) => {
	// switch (action.type) {
	// 	case "SET_ACTIVE_STATE":
	// 		return {
	// 			...state,
	// 			activeState: action.payload.activeState,
	// 		};

	// 	default:
	// 		return state;
	// }
	switch (action.type) {
		case "SET_ACTIVE_STATE":
			return {
				...state,
				activeState: action.payload.activeState,
			};

		case "SET_MEETING_DATA":
			return {
				...state,
				meetingData: action.payload.meetingData,
			};
		// Handle other action types if needed
		case "SET_CONTROLLER":
			return {
				...state,
				isController: action.payload.value,
			};

		default:
			return state;
	}
};

export default draftMeetingReducer;
