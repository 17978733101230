import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Get, Create } from "../../actions";
import { AutoUpdateField } from "../../components/autoUpdateField";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { DatePicker } from "../../components/DatePicker";
import { FormLoader } from "../../components/FormLoader";
import { ClassicTextEditor } from "../../components/TextEditor";
import { UploadFiles } from "../../components/Upload";
import {
  GET_AllContactList_URL,
  GET_AllUsersWithoutAuthorization_URL,
  GET_AllUsers_URL,
  Get_CircularResolutionUploadedFiles_URL,
  Get_GetCircularResolutionMembers_URL,
  Get_NotifyeeList_URL,
  Get_SingleCircularResolution_URL,
  Post_AddNewFileCircularResolution_URL,
  Post_ChangeSponsorCircularResolution_URL,
  Post_DeleteNotifyess_URL,
  Post_InsertNotifyess_URL,
  Post_SendEmailForConfirmationCircularResolution_URL,
  Post_SendEmailForConfirmation_URL,
  Post_UpdateCircularResolutionDeadline_URL,
  Post_UpdateCircularResolutionResolutionText_URL,
  Post_UpdateCircularResolutionSubject_URL,
} from "../../constants/apiUrls";
import { parseHTML } from "../../heplers/format";
import { AddPerson, Notifyee } from "./component/circularResolution/components/notifyee";
import { SelectDecisionMember } from "./component/circularResolution/components/selectDecisionMaker";
import { SelectMember } from "./component/circularResolution/components/SelectMembers";
import Card from "../../components/Card";
import { Autocomplete } from "@material-ui/lab";
import { getTranslation } from "../../heplers/translationHelper";
export const UpdateCircularResolution = () => {
  const { circularResolutionId } = useParams();
  const [circularResolution, setCircularResolution] = useState({});
  const [circularResolutionCopy, setCircularResolutionCopy] = useState({});
  const [isSubmitting, setIsSubmitting] = useState();
  const [isSubmitConfirmation, setIsSubmitConfirmation] = useState(false);
  const [enableEditing, setEnableEditing] = useState(true);
  const [contactList, setContactList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [notifyees, setNotifyees] = useState([]);
  const [contactListLoading, setContactListLoading] = useState(false);

  const [circularResolutionMembers, setCircularResolutionMembers] = useState(
    []
  );
  const [circularResolutionFiles, setCircularResolutionFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    loadCircularResolutionInfo();
    loadCircularResolutionMembers();
    loadCircularResolutionFiles();
  }, [circularResolutionId]);
  const loadCircularResolutionInfo = () => {
    Get(
      { id: circularResolutionId },
      Get_SingleCircularResolution_URL,
      null,
      (resp) => {
        setEnableEditing(resp?.data?.status);
        setCircularResolution(resp?.data);
        setCircularResolutionCopy(resp?.data);
      },
      (error) => {}
    );
  };

  const loadCircularResolutionMembers = () => {
    Get(
      { id: circularResolutionId },
      Get_GetCircularResolutionMembers_URL,
      null,
      (resp) => {
        setCircularResolutionMembers(resp?.data);
      },
      (error) => {}
    );
  };

  const loadCircularResolutionFiles = () => {
    Get(
      { CrId: circularResolutionId },
      Get_CircularResolutionUploadedFiles_URL,
      null,
      (resp) => {
        setCircularResolutionFiles(resp?.data);
      },
      (error) => {}
    );
  };

  const addNewCircularResolutionFile = (fileAddress) => {
    Create(
      { fileName: fileAddress, circularResolutionId: circularResolutionId },
      Post_AddNewFileCircularResolution_URL,
      null,
      (resp) => {
        //setCircularResolutionFiles([...circularResolutionFiles,file])
      },
      (error) => {}
    );
  };

  const updateSubject = (newSubject) => {
    setIsSubmitting(true);
    Create(
      { value: newSubject, circularResolutionId: circularResolutionId },
      Post_UpdateCircularResolutionSubject_URL,
      null,
      (resp) => {
        circularResolution.subject = newSubject;
        setCircularResolution({ ...circularResolution, subject: newSubject });
        setIsSubmitting(false);

        //setCircularResolutionFiles([...circularResolutionFiles,file])
      },
      (error) => {}
    );
  };

  const updateResolution = (newResolution) => {
    setIsSubmitting(true);

    Create(
      { value: newResolution, circularResolutionId: circularResolutionId },
      Post_UpdateCircularResolutionResolutionText_URL,
      null,
      (resp) => {
        setCircularResolution({
          ...circularResolution,
          resolution: newResolution,
        });
        setIsSubmitting(false);
        enqueueSnackbar("Resolution text updated.",{variant:"success"})
        //setCircularResolutionFiles([...circularResolutionFiles,file])
      },
      (error) => {}
    );
  };

  const updateDeadline = (newDeadline) => {
    setIsSubmitting(true);

    Create(
      { value: newDeadline, circularResolutionId: circularResolutionId },
      Post_UpdateCircularResolutionDeadline_URL,
      null,
      (resp) => {
        setCircularResolution({ ...circularResolution, deadline: newDeadline });
        setIsSubmitting(false);

        //setCircularResolutionFiles([...circularResolutionFiles,file])
      },
      (error) => {}
    );
  };

  const sendConfirmationEmail = () => {
    setIsSubmitConfirmation(true);

    Get(
      { id: circularResolutionId },
      Post_SendEmailForConfirmationCircularResolution_URL,
      null,
      (resp) => {
        enqueueSnackbar("Email sent for confirmation from sponser.", {
          variant: "success",
        });
        // setCircularResolution({...circularResolution,'deadline':newDeadline})
        setIsSubmitConfirmation(false);

        //setCircularResolutionFiles([...circularResolutionFiles,file])
      },
      (error) => {
        enqueueSnackbar(
          "Email not sent for confirmation from sponser. Please try again",
          { variant: "error" }
        );
      }
    );
  };

  const updateSponser = (newSponser) => {
    setIsSubmitting(true);

    Create(
      {
        newSponsorId: newSponser.id,
        circularResolutionId: circularResolutionId,
      },
      Post_ChangeSponsorCircularResolution_URL,
      null,
      (resp) => {
        // setCircularResolution({ ...circularResolution, deadline: newDeadline });
        setIsSubmitting(false);
        enqueueSnackbar("Sponsor has changed.", { variant: "info" });
        //setCircularResolutionFiles([...circularResolutionFiles,file])
      },
      (error) => {}
    );
  };

  useEffect(() => {
    setContactListLoading(true);
    Get(
      {},
      GET_AllContactList_URL,
      null,
      (resp) => {
        setContactList(resp.data);
        setContactListLoading(false);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    setContactListLoading(true);
    Get(
      {},
      GET_AllUsersWithoutAuthorization_URL,
      null,
      (resp) => {
        setUserList(resp.data);
        setContactListLoading(false);
      },
      (error) => {}
    );
  }, []);


  return (
    <FormLoader>
      <Card title={getTranslation("Generate Circular Resolution","Générer une résolution circulaire","Kreisauflösung generieren")}>
        {/* <CardHeader title='Details' /> */}
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled
                label={getTranslation("Ceres Fee","Frais Cérès","Ceres-Gebühr")}
                fullWidth
                value={circularResolution?.companyCeresServiceTitle}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <AutoUpdateField
                disabled={enableEditing}
                saving={isSubmitting}
                onUpdate={() => {
                  updateSubject(circularResolutionCopy?.subject);
                }}
                onCancel={() => {
                  setCircularResolutionCopy(circularResolution);
                }}
              >
                <TextField
                  fullWidth
                  disabled={enableEditing}
                  id="subject"
                  label={getTranslation("Subject","Sujette","Fach")}
                  required
                  size="small"
                  variant="outlined"
                  name="subject"
                  value={circularResolutionCopy?.subject}
                  onChange={(e) => {
                    // circularResolutionCopy.subject=e.target.value
                    setCircularResolutionCopy({
                      ...circularResolutionCopy,
                      subject: e.target.value,
                    });
                    //setCircularResolutionCopy(prev => ({ ...prev, subject: e.target.value }))
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </AutoUpdateField>
            </Grid>
            <Grid item xs={6} sm={6} md={5} lg={5}>
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="right"
                justifyContent="align-right"
              >
                <ButtonWithLoading
                  title={getTranslation("Send Confirmation Email","Envoyer un e-mail de confirmation","Bestätigungs-E-Mail senden")}
                  size="large"
                  variant="contained"
                  loading={isSubmitConfirmation}
                  onClick={sendConfirmationEmail}
                  disabled={enableEditing}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {enableEditing === false ? (
                <ClassicTextEditor
                  title={getTranslation("Resolution", "Résolution", "Auflösung")}
                  disabled={enableEditing}
                  text={circularResolutionCopy?.resolution}
                  showButton
                  isSubmitting={isSubmitting}
                  onChange={(text) => {
                    updateResolution(text)
                    // setCircularResolutionCopy({
                    //   ...circularResolutionCopy,
                    //   resolution: text,
                    // });
                  }}
                />
                // <AutoUpdateField
                //   disabled={enableEditing}
                //   saving={isSubmitting}
                //   onUpdate={() =>
                //     updateResolution(circularResolutionCopy?.resolution)
                //   }
                //   onCancel={() => {
                //     setCircularResolutionCopy(circularResolution);
                //   }}
                // >
                  
                // </AutoUpdateField>
              ) : (
                <>
                  <Typography variant="h4">Resolution:</Typography>
                  <Typography
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      padding: "20px",
                      border: "1px solid grey",
                    }}
                    component="p"
                  >
                    {parseHTML(circularResolutionCopy?.resolution)}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <TextField
                disabled
                label={getTranslation("Sponser","Parrain","Sponsor")}
                fullWidth
                value={circularResolution?.sponsorName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <TextField
                fullWidth
                id="language"
                label={getTranslation("Language","Langue","Sprache")}
                required
                size="small"
                variant="outlined"
                name="language"
                disabled
                value={circularResolution?.language}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Autocomplete
                options={userList}
                loading={userList.length > 0}
                value={circularResolution?.personIncharge ? circularResolution?.personIncharge : {}}
                getOptionLabel={(option) => option.name}
                size="small"
                // onChange={(e, value) => {

                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getTranslation("Person Incharge","Personne en charge","Verantwortlicher")}
                    variant="outlined"
                    // error={touched.sponsorId && Boolean(errors.sponsorId)}
                    // helperText={touched.sponsorId && errors.sponsorId}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {userList.length === 0 && circularResolution?.personIncharge ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3}>
              <AutoUpdateField
                saving={isSubmitting}
                onUpdate={() =>
                  updateDeadline(circularResolutionCopy?.deadline)
                }
                onCancel={() => {
                  setCircularResolutionCopy(circularResolution);
                }}
              >
                <DatePicker
                fullWidth
                  id="deadline"
                  label={getTranslation("Deadline","Date limite","Termin")}
                  disabled={enableEditing}
                  value={circularResolutionCopy?.deadline}
                  onChange={(value) => {
                    setCircularResolutionCopy({
                      ...circularResolutionCopy,
                      deadline: value,
                    });
                  }}
                />
              </AutoUpdateField>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SelectMember
                isApproved={circularResolution?.status}
                enableCheckbox={true}
                type="board"
                circularResolutionId={circularResolutionId}
                title={getTranslation("Board Members","Membres du conseil d'administration","Vorstandsmitglieder")}
                selectedMembers={circularResolutionMembers}
                onSelectionChange={(members) =>
                  setCircularResolutionMembers(members)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SelectMember
                isApproved={circularResolution?.status}
                enableCheckbox={true}
                type="shareholder"
                circularResolutionId={circularResolutionId}
                title={getTranslation("Shareholders","Actionnaires","Aktionärinnen")}
                selectedMembers={circularResolutionMembers}
                onSelectionChange={(members) =>
                  setCircularResolutionMembers(members)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SelectDecisionMember
                options={circularResolutionMembers}
                circularResolutionId={circularResolutionId}
                isApproved={circularResolution?.status}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card title={getTranslation("Notifees","Notifiés","Benachrichtigungen")}>
                <CardContent>
                  <Notifyee
                    contactList={contactList}
                    circularResolutionId={circularResolutionId}
                    disabled={circularResolution?.status}
                  />

                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card title={getTranslation("Upload Documents","Télécharger des documents","Dokumente hochladen" )}>
                <CardContent>
                  <UploadFiles
                    disabled={circularResolution?.status}
                    uploadedFiles={circularResolutionFiles}
                    onAddFile={(file) => {
                      addNewCircularResolutionFile(file);
                    }}
                    getFileName={(file) => {
                      return file.fileName;
                    }}
                    onDeleteFile={(file) => {
                      // setFieldValue(
                      //   "files",
                      //   values.files.filter(
                      //     (x) => x.fileAddress != file.fileAddress
                      //   )
                      // );
                    }}
                  />
                </CardContent>
              </Card>

            </Grid>
         
          </Grid>
          <br />
          <br />
        </CardContent>
      </Card>
    </FormLoader>
  );
};
