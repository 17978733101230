import Card from "../../../../../components/Card";
import { Box, CardContent } from "@material-ui/core";
import {
  UpdateReportComments,
  UpdateReportTable,
  MeetingReports,
} from "./components";
import CardComponent from "../../../../../components/Card";
import { useEffect, useRef } from "react";
import { parseHelper } from "../../../../../heplers/parserHelper";
import { useParams } from "react-router";
import { Get } from "../../../../../actions";
import {
  Get_GetReportInfo_URL,
  Get_SendNotificationForReview_URL,
} from "../../../../../constants/apiUrls";
import { useState } from "react";
import { useSnackbar } from "notistack";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from '../../../../../components/Dialog'
import { getTranslation } from "../../../../../heplers/translationHelper";
import { green, purple,blue } from '@material-ui/core/colors';
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { savePdf } from "../../../../../heplers/pdfHelper";

const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const theme = createTheme({
  palette: {
    primary: green,
  },
});

const UpdateReport = () => {
  const { meetingId } = useParams();
  const [meetingReport, setMeetingReport] = useState();
  const [openPreviewDialog,SetOpenPreviewDialoge]=useState(false)

  const classes = useStyles();
  useEffect(() => {
    loadReportInfo();
  }, []);
  const loadReportInfo = () => {
    Get(
      { meetingId: meetingId },
      Get_GetReportInfo_URL,
      null,
      (resp) => {
        setMeetingReport(resp.data);
      },
      (error) => {}
    );
  };

  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const sendNotificationForReview = () => {
    setIsSendingNotification(true);
    Get(
      { meetingId: meetingId },
      Get_SendNotificationForReview_URL,
      null,
      (resp) => {
        setIsSendingNotification(false);
        enqueueSnackbar(resp.data, { variant: "success" });
      },
      (error) => {
        setIsSendingNotification(false);
        enqueueSnackbar(error.data, { variant: "error" });
      }
    );
  };


  const printRef=useRef();
  const handlePrint=()=>{
    savePdf("Meeting Report.pdf",printRef.current,null,null,true,file=>{

    })
  }

  const renderCardTitle = () => {
    return (
      <Box className={classes.titleContainer}>
        {getTranslation("Update Report Data","Update Report Data","Update Report Data")}
      <ColorButton variant="contained" color="primary"   startIcon={<AssessmentIcon/>} className={classes.margin} onClick={handlePrint}>
      {getTranslation("Preview Report","Preview Report","Preview Report")}
        
      </ColorButton>
      </Box>
    );
  };

  return (
    // <Card title="Update Report Data">
    <Card title={renderCardTitle()}>
      {/* <Dialog fullScreen open={openPreviewDialog} onClose={()=>SetOpenPreviewDialoge(false)} title={getTranslation("Report","Report","Report")}>
      <MeetingReports meetingReport={meetingReport} meetingId={meetingId} />
      </Dialog> */}
      <CardContent>
        <UpdateReportTable />
        <br />
        <UpdateReportComments comments={meetingReport?.reportDeclineComments} />
        <br />
        <Box
          pr={1}
          pb={1}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ButtonWithLoading
            title={getTranslation("Send to fund manager for review","Send to fund manager for review","Send to fund manager for review")}
            size="small"
            variant="contained"
            color="primary"
            loading={isSendingNotification}
            onClick={sendNotificationForReview}
            disabled={meetingReport?.reportStatus}
          />
        </Box>
        <MeetingReports meetingReport={meetingReport} meetingId={meetingId} ref={printRef} />
      </CardContent>
    </Card>
  );
};
export default UpdateReport;
