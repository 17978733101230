import React, { useState, useEffect } from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import Table from "../../components/table";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Get, Create } from "../../actions";
import { Get_FundOperationsSummary_URL } from "../../constants/apiUrls";
import { FundOperations } from "./component/fund";
import { getTranslation } from "../../heplers/translationHelper";
import {
	ManagementCompaniesSubmenu,
	AssetSubmenu,
	FundSubmenu,
	CompanySubmenu,
} from "../../layout/subMenu/meeting";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { paymentTypes } from "../../constants/typeOfPayment";
import { Typography } from "@material-ui/core";
import { format } from "../../heplers/format";

const Fund = () => {
	const columns = [
		{
			id: "title",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Fund Title", "Titre de l'actif", "Asset-Titel"),
		},
		{
			id: "paymentTypeText",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Payment Type", "Type de paiement", "Zahlungsart"),
		},
		{
			id: "backPayment",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Back Payment", "Remboursement", "Rückerstattung"),
			component: (row) => {
				return (
					<Typography>{format("number", row?.row?.backPayment)}</Typography>
				);
			},
			align:'right',
			format:'number'
		},
		{
			id: "invested",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Invested", "Investi", "Investiert"),
			component: (row) => {
				return <Typography>{format("number", row?.row?.invested)}</Typography>;
			},
			align:'right',
			format:'number'
		},

		{
			id: "balance",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Balance", "Solde", "Balance"),
			component: (row) => {
				return <Typography>{format("number", row?.row?.balance)}</Typography>;

			},
			align:'right',
			format:'number'
		},
	];

	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const params = useParams();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	let spvDetailFundSummary = localStorage.getItem("spvDetailFundSummary");
	if (spvDetailFundSummary == null) {
		localStorage.setItem("spvDetailFundSummary", 10);
		spvDetailFundSummary = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvDetailFundSummary);
	const [rows, setRows] = useState([]);
	const [switchValue, setSwitchValue] = useState(0);
	const [loadingManagementCompanyFunds, setloadingManagementCompanyFunds] =
		useState(false);
	const { managementCompanyId } = useParams();
	useEffect(() => {
		loadManagementCompanyFunds({
			managementCompanyId: managementCompanyId,
			searchString: searchString,
			pageNumber: page,
			pageSize: rowsPerPage,
		});
	}, [page, rowsPerPage, searchString, switchValue]);

	const loadManagementCompanyFunds = () => {
		setloadingManagementCompanyFunds(true);
		Get(
			{
				managementCompanyId: managementCompanyId,
				searchString: searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_FundOperationsSummary_URL,
			history,
			(resp) => {
				// setRows(resp.data);
				setRows(responseData(resp.data));
				setloadingManagementCompanyFunds(false);
			},
			(error) => {}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvDetailFundSummary", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleDetailManagementCompanyFund = (row) => {
		history(`/managementCompanies/${row.id}/${row.companyId}/basic`);
	};
	const handleSearchManagementCompanyFund = (searchString) => {
		setSearchString(searchString);
	};
	const handleCreateCompany = (event) => {
		setCreateCompany(true);
	};
	const handleCreateCompanyClose = (event) => {
		setCreateCompany(false);
	};

	var responseData = (resp) => {
		return resp.flatMap((data) => {
			return data.payments.map((payment) => {
				return {
					title: data.title,
					typeOfPayment: payment.typeOfPayment,
					paymentTypeText: payment.paymentTypeText,
					invested: payment.invested,
					backPayment: payment.backPayment,
					balance: payment.balance,
				};
			});
		});
	};

	return (
		<div>
			{params.assetId ? (
				<AssetSubmenu assetId={params.assetId} />
			) : params.fundId ? (
				<FundSubmenu fundId={params.fundId} />
			) : params.managementCompanyId ? (
				<ManagementCompaniesSubmenu
					managementCompanyId={params.managementCompanyId}
					companyId={params.companyId}
				/>
			) : (
				<CompanySubmenu companyId={"4444"} />
			)}
			<ToggleButtonGroup
				size="small"
				style={{
					margin: "10px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
				exclusive
				value={switchValue}
				onChange={(e, value) => {
					if (value !== null && typeof value !== "undefined") {
						setSwitchValue(value);
					}
				}}
			>
				<ToggleButton value={0}>
					{getTranslation(
						"Fund Operations",
						"Opérations sur les fonds",
						"Fund-Operationen"
					)}
				</ToggleButton>
				<ToggleButton value={1}>
					{getTranslation("Summary", "Résumé", "Zusammenfassung")}
				</ToggleButton>
			</ToggleButtonGroup>
			{switchValue === 0 ? (
				<FundOperations />
			) : (
				<Table
					dense
					loading={loadingManagementCompanyFunds}
					title={getTranslation("Summary", "Résumé", "Zusammenfassung")}
					colums={columns}
					sortBy="title"
					rows={rows}
					count={rows?.length}
					page={page}
					rowsPerPage={rowsPerPage}
					handleChangePage={(event, newPage) =>
						handleChangePage(event, newPage)
					}
					handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
					// viewEdit
					// onEdit={(row)=>handleDetailManagementCompanyFund(row)}
					viewSearch
					onSearch={(searchString) =>
						handleSearchManagementCompanyFund(searchString)
					}
				/>
			)}
		</div>
	);
};

export default Fund;
