
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  IconButton,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../../components/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Get } from "../../../../actions";
import {
  GET_AllCompaniesList_URL,
  GET_AllContactList_URL,
} from "../../../../constants/apiUrls";
import { getTranslation } from "../../../../heplers/translationHelper";
import { representativeRoles } from "../../../../constants/representativeRoles";
import { AssetSubmenu, CompanySubmenu, FundSubmenu, ManagementCompaniesSubmenu } from "../../../../layout/subMenu";
import { Cancel } from "@material-ui/icons";
import { ErrorMessage } from "formik";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CreateCompanyRepresentative = ({
  onCancel,
  fundId,
  assetId,
  managementCompanyId,
  companyId,
  editPersonRepresentative,
  onSubmit,
}) => {
  console.log("ajhgshjasgdhjasd",companyId);
  const [companyRepresentatvies, setCompanyRepresentatvies] = useState();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const getSelectedCompany = (company) => { };
  const params = useParams()
  useEffect(() => {
    Get(
      {},
      GET_AllCompaniesList_URL,
      history,
      (response) => {
        setCompanies(response.data);
      },
      (error) => { }
    );
  }, []);

  useEffect(() => {
    Get(
      {},
      GET_AllContactList_URL,
      history,
      (response) => {
        setCompanyRepresentatvies(response.data);
      },
      (error) => { }
    );
  }, []);

  const initialValues = {
    companyId: companyId,
    contactId: editPersonRepresentative
      ? editPersonRepresentative.contactId
      : 0,
      representativeRole: editPersonRepresentative
      ? editPersonRepresentative.representativeRole
      : representativeRoles[0].value,
      id: editPersonRepresentative ? editPersonRepresentative.id : 0,
    //company: editPersonRepresentative ? editPersonRepresentative.company:{},
    representedByPersons: {
      id: editPersonRepresentative ? editPersonRepresentative.id : 0,
      companyId: companyId,
      contactId: editPersonRepresentative
        ? editPersonRepresentative.contactId
        : 0,
      contact: editPersonRepresentative
        ? editPersonRepresentative.contact
        : null,
      representativeRole: editPersonRepresentative
        ? editPersonRepresentative.representativeRole
        : representativeRoles[0].value,
      representativeRoleItem: editPersonRepresentative
        ? representativeRoles.find(
            (x) => x.value == editPersonRepresentative.representativeRole
          )
        : representativeRoles[0],
    },
  };

  const basicValidationSchema = Yup.object().shape({
    representedByPersons: Yup.object({
      //companyId: Yup.string().required("Company is required"),
  contactId: Yup.number().min(1, "Represented By Person is required").required("Represented By Person is required"),
      representativeRole: Yup.string().required("Represented role is required"),
    }),
  });
  const defaultValue = initialValues;
  return (
    <>
      {params.assetId ? (
        <AssetSubmenu  assetId={params.assetId} companyId={companyId} />
      ) : params.fundId ? (
        <FundSubmenu fundId={params.fundId} companyId={params.companyId} />
      ) : params.managementCompanyId ? (
        <ManagementCompaniesSubmenu managementCompanyId={params.managementCompanyId} companyId={companyId}/>
      ) : (
        <CompanySubmenu companyId={companyId} />
      )}
      <Card
        title={getTranslation(
          "Add Person Representative",
          "Ajouter un représentant de personne",
          "Personenvertreter hinzufügen"
        )}
      >
        <Formik
          enableReinitialize
          initialValues={defaultValue}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            onSubmit(values, actions);
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
             setTouched
          }) => (
            <Form>
              {console.log("mnvbdfjsdfdsf", values)}
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Autocomplete
                      {...getFieldProps("representedByPersons.contactId")}
                      value={values.representedByPersons.contact}
                      name="representedByPersons.contactId"
                      options={companyRepresentatvies}
                      size="small"
                      getOptionLabel={(option) => {
                        return option.fullName;
                      }}
                      onChange={(e, value) => {
                        setFieldValue(
                          "representedByPersons.contactId",
                          value?.id
                        );
                        setFieldValue("representedByPersons.contact", value);
                        setTouched("representedByPersons.contactId", true); 
                        setTouched("representedByPersons.contact", true); 
                      }}
                      onBlur={() => {
                        setTouched("representedByPersons.contactId", true);
                        setTouched("representedByPersons.contact", true);
                      }}
                      // error={
                      //   touched.representedByPersons?.contactId &&
                      //   Boolean(errors.representedByPersons?.contactId)
                      // }
                      // helperText={
                      //   touched.representedByPersons?.contactId &&
                      //   errors.representedByPersons?.contactId
                      // }
                      //   onInputChange={(e, value) => setSearchCompaniesString(value)}
                      // renderInput={(params) => (
                      //   <TextField
                      //     {...params}
                      //     label={getTranslation(
                      //       "Select Representative",
                      //       "Sélectionnez un représentant",
                      //       "Vertreter auswählen"
                      //     )}
                      //     variant="outlined"
                      //   />
                      // )}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            {...params}
                            label={getTranslation(
                              "Select Representative",
                              "Sélectionnez un représentant",
                              "Vertreter auswählen"
                            )}
                            variant="outlined"
                            error={(touched.representedByPersons?.contactId || touched.representedByPersons?.contact) && Boolean(errors.representedByPersons?.contactId || errors.representedByPersons?.contact)}
                            helperText={(touched.representedByPersons?.contactId || touched.representedByPersons?.contact) && (errors.representedByPersons?.contactId || errors.representedByPersons?.contact)}
                          />
                          {/* <ErrorMessage
                            name="representedByPersons.contactId"
                            component="div"
                            style={{ color: "#f44336", fontSize: "13px", margin: "4px 0px 0px 5px" }}
                          /> */}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Autocomplete
                      value={values.representedByPersons.representativeRoleItem}
                      name="representedByPersons.representativeRole"
                      options={representativeRoles}
                      getOptionLabel={(option) =>
                        getTranslation(
                          option.value,
                          option.defaultFrench,
                          option.defaultGerman
                        )
                      }
                      size="small"
                      onChange={(e, value) => {
                        if(value){
                        console.log("kjsdhifsdf", value);
                        setFieldValue(
                          "representedByPersons.representativeRole",
                          value.id
                        );
                        setFieldValue(
                          "representedByPersons.representativeRoleItem",
                          value
                        );
                        setFieldValue("representativeRole", value.id);
                        }
                      }}
                      //   onInputChange={(e, value) => setSearchCompaniesString(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getTranslation(
                            "Select Role",
                            "Sélectionnez un rôle",
                            "Rolle auswählen"
                          )}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box
                  pr={1}
                  pb={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {editPersonRepresentative ? (
                    <IconButton onClick={onCancel}>
                      <Cancel />
                    </IconButton>
                  ) : null}
                  <ButtonWithLoading
                    title={
                      editPersonRepresentative
                        ? getTranslation(
                            "Update",
                            "Mettre à jour",
                            "Aktualisieren"
                          )
                        : getTranslation("Create", "Créer", "Erstellen")
                    }
                    size="small"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    //onClick={handleSubmit}
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </Box>
              </CardActions>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
};

export default CreateCompanyRepresentative;

