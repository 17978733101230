import React, { useState, useRef, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getTranslation } from "../../../../heplers/translationHelper";
import { HtmlEditor } from "../../../../components/HtmlEditor";
import {
	FormControl,
	Checkbox,
	FormControlLabel,
	Typography,
	TextField,
	Grid,
	Card,
	CardContent,
	CardHeader,
	Box,
	ButtonGroup,
	Button,
} from "@material-ui/core";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { Get, Create } from "../../../../actions";
import { makeStyles } from "@material-ui/core/styles";

import {
	Get_Email_Template_URL,
	Post_Update_Email_Template_URL,
} from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { TagButtons } from "./TagButtons";
import TextEditor from "./TextEditorCard";
import { FormLoader } from "../../../../components/FormLoader";

const useStyles = makeStyles((theme) => ({
	responsiveButtonGroup: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		margin: "5px",
		flexWrap: "wrap",
	},
	fixedButton: {
		margin: "5px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		[theme.breakpoints.down("sm")]: {
			flex: "0 0 120px",
		},
	},
}));

const MeetingMails = () => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [type, setType] = useState("MeetingAgenda");
	const [enableReloading, setEnableReloading] = useState(false);
	const [template, setTemplate] = useState("");
	const [templateLoading, setTemplateLoading] = useState(true);
	const [subject, setSubject] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState("english");
	const editorRef = useRef(null);

	useEffect(() => {
		GetReminderTemplate(type);
	}, [type, selectedLanguage]);

	const handleLanguage = (language) => {
		setSelectedLanguage(language);
	};

	const openTestSmsDialoge = () => {
		console.log("huzefa");
	};

	const handleChange = (event, newAlignment) => {
		setType(newAlignment);
	};
	const GetReminderTemplate = (type) => {
		setTemplateLoading(true)
		Get(
			{ reminderKey: type },
			Get_Email_Template_URL,
			null,
			(resp) => {
				//setReminderTempateText(resp?.data?.template);

				let templateValue;
				let templateSubject;

				switch (selectedLanguage) {
					case "english":
						templateValue = resp?.data?.template;
						templateSubject = resp?.data?.subject;
						break;
					case "french":
						templateValue = resp?.data?.templateFrench;
						templateSubject = resp?.data?.subjectFrench;
						break;
					case "german":
						templateValue = resp?.data?.templateGerman;
						templateSubject = resp?.data?.subjectGerman;
						break;
					default:
						templateValue = resp?.data?.template;
						templateSubject = resp?.data?.subject;
				}

				setTemplate(resp?.data);
				setSubject(templateSubject);

				setEnableReloading(true);
		setTemplateLoading(false)

			},
			(error) => {
		setTemplateLoading(false)
		enqueueSnackbar(error.data, { variant: "error" });
			}
		);
	};

	console.log("fytdfsfddf", template);

	const appendTemplate = (template, text, setFieldValue) => {
		editorRef.current.insertContent(text);
	};


	const initialValues = {
		template: template.template ? template.template : "",
		templateGerman: template?.templateGerman ? template?.templateGerman : "",
		templateFrench: template?.templateFrench ? template?.templateFrench : "",
		subject: template?.subject ? template?.subject : "",
		subjectGerman: template?.subjectGerman ? template?.subjectGerman : "",
		subjectFrench: template?.subjectFrench ? template?.subjectFrench : "",
	};

	const basicValidationSchema = Yup.object().shape({
		//template: Yup.string().required("Reminder text is required"),
		subject: Yup.string().required(),
	});

	function getTitleByType(type) {
		switch (type) {
			case "MeetingAgenda":
				return "Meeting Agenda";
			case "MeetingDocument":
				return "Meeting Document";
			case "MeetingInvitation":
				return "Meeting Invitation";
			case "MeetingMinutes":
				return "Meeting Minutes";
			case "MeetingComments":
				return "Meeting Comments";
			case "MeetingReminder":
				return "Meeting Reminder";
			default:
				return "ffgd ";
		}
	}

	const UpdateTemplate = (values, actions) => {
		console.log(values);
		actions.setSubmitting(true);
		const {
			template,
			subject,
			templateFrench,
			templateGerman,
			subjectFrench,
			subjectGerman,
		} = values;

		const placeholderRegex = /\[Submit_URL\]/g;
		const matches = template?.match(placeholderRegex);
		const matchesFrench = templateFrench?.match(placeholderRegex);
		const matchesGerman = templateGerman?.match(placeholderRegex);

		let selectedLanguageMatches;
		let errorMessage;

		if (selectedLanguage === "english") {
			selectedLanguageMatches = matches;
			errorMessage = "English Template must contain one tap of Submit Button";
		} else if (selectedLanguage === "french") {
			selectedLanguageMatches = matchesFrench;
			errorMessage = "French Template must contain one tap of Submit Button";
		} else if (selectedLanguage === "german") {
			selectedLanguageMatches = matchesGerman;
			errorMessage = "German Template must contain one tap of Submit Button";
		} else {
			//alert("no");
		}

		if (!selectedLanguageMatches || selectedLanguageMatches.length !== 1) {
			enqueueSnackbar(
				getTranslation(errorMessage, errorMessage, errorMessage),
				{
					variant: "error",
				}
			);
			actions.setSubmitting(false);
			return;
		}

		const payload = {
			emailTemplateKey: type,
			dayOfMonth: 1,
			template: template,
			templateGerman: templateGerman,
			templateFrench: templateFrench,
			subject: subject,
			subjectGerman: subjectGerman,
			subjectFrench: subjectFrench,
		};

		Create(
			payload,
			Post_Update_Email_Template_URL,
			null,
			(resp) => {
				actions.setSubmitting(false);
				enqueueSnackbar(`${getTitleByType(type)} Template Saved.`, {
					variant: "success",
				});
				GetReminderTemplate(type);
			},
			(error) => {
				actions.setSubmitting(false);
				enqueueSnackbar(error?.data, { variant: "error" });
			}
		);
	};

	const getTemplateBasedOnLanguage = (language) => {
		switch (language) {
			case "english":
				return template.template;
			case "french":
				return template.templateFrench;
			case "german":
				return template.templateGerman;
			default:
				return template.template;
		}
	};

	const getSubjectFieldName = (language) => {
		switch (language) {
			case "english":
				return "subject";
			case "french":
				return "subjectFrench";
			case "german":
				return "subjectGerman";
			default:
				return "subject";
		}
	};

	const getSubjectValue = (values, language) => {
		switch (language) {
			case "english":
				return values.subject;
			case "french":
				return values.subjectFrench;
			case "german":
				return values.subjectGerman;
			default:
				return values.subject;
		}
	};

	return (
		<>
			<ToggleButtonGroup
				style={{
					display: "flex",
					alignItems: "center",
					flexWrap: "wrap",
					justifyContent: "center",
				}}
				color="primary"
				value={type}
				exclusive
				onChange={handleChange}
				aria-label="Platform"
			>
				<ToggleButton value="MeetingAgenda">
					{getTranslation("Meeting Agenda", "Meeting Agenda", "Meeting Agenda")}
				</ToggleButton>
				<ToggleButton value="MeetingDocument">
					{getTranslation(
						"Meeting Document",
						"Meeting Document",
						"Meeting Document"
					)}
				</ToggleButton>
				<ToggleButton value="MeetingInvitation">
					{getTranslation(
						"Meeting Invitation",
						"Meeting Invitation",
						"Meeting Invitation"
					)}
				</ToggleButton>
				<ToggleButton value="MeetingMinutes">
					{getTranslation(
						"Meeting Minutes",
						"Meeting Minutes",
						"Meeting Minutes"
					)}
				</ToggleButton>
				<ToggleButton value="MeetingComments">
					{getTranslation(
						"Meeting Comments",
						"Meeting Comments",
						"Meeting Comments"
					)}
				</ToggleButton>
				<ToggleButton value="MeetingReminder">
					{getTranslation(
						"Meeting Reminder",
						"Meeting Reminder",
						"Meeting Reminder"
					)}
				</ToggleButton>
			</ToggleButtonGroup>

			<br />
			<FormLoader progress={templateLoading}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={basicValidationSchema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(true)
					UpdateTemplate(values, actions);
				}}
			>
				{({
					values,
					setFieldValue,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
					getFieldProps,
				}) => (
					<>
						<Form>
							<>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={3} lg={3}>
										<TextField
											fullWidth
											variant="outlined"
											required
											id="subject"
											label="subject"
											//name="subject"
											name={getSubjectFieldName(selectedLanguage)}
											size="small"
											//value={values.subject}
											//{...getFieldProps("subject")}
											value={getSubjectValue(values, selectedLanguage)}
											//{...getFieldProps(getSubjectFieldName(selectedLanguage))}
											error={touched.subject && Boolean(errors.subject)}
											helperText={touched.subject && errors.subject}
											InputLabelProps={{
												shrink: true,
											}}
											onChange={(e)=>setFieldValue("subject",e.target.value)}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={9} lg={9}></Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<Typography variant="h5">
											{getTranslation(
												"Reminder Text",
												"Texte de rappel",
												"Erinnerungstext"
											)}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<TagButtons
											appendTemplate={appendTemplate}
											classes={classes}
											values={values}
											setFieldValue={setFieldValue}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<TextEditor
											translatedText={type}
											selectedLanguage={selectedLanguage}
											reminderKey={"vshgfasgh"}
											openTestSmsDialoge={openTestSmsDialoge}
											handleLanguage={handleLanguage}
											//title="send"
										>
											<HtmlEditor
												ref={editorRef}
												//text={template}
												text={getTemplateBasedOnLanguage(selectedLanguage)}
												onChange={(text) => {
													setEnableReloading(true);
													switch (selectedLanguage) {
														case "english":
															setFieldValue("template", text);

															break;
														case "french":
															setFieldValue("templateFrench", text);

															break;
														case "german":
															setFieldValue("templateGerman", text);

															break;
														default:
															setFieldValue("template", text);
													}

													//setFieldValue("template", text);
													//setEnableReloading(false);
													handleSubmit();
												}}
												isLoading={isSubmitting}
												isSubmitting={isSubmitting}
											/>
										</TextEditor>
									</Grid>
									{/* <Grid item xs={12} sm={12} md={12} lg={12}>
										<Card>
											<CardHeader
												title={type}
												style={{
													background: "#233044",
													color: "#FFFFFF",
												}}
											/>
											<CardContent>
												<HtmlEditor
													ref={editorRef}
													text={template}
													onChange={(text) => {
														setEnableReloading(true);
														setFieldValue("template", text);
														setEnableReloading(false);
														handleSubmit();
													}}
													isLoading={false}
													isSubmitting={isSubmitting}
												/>

												<br />
											</CardContent>
										</Card>
									</Grid> */}
								</Grid>
							</>
						</Form>
					</>
				)}
			</Formik>
			</FormLoader>
			
		</>
	);
};

export default MeetingMails;
