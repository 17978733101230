import React, { useState, useEffect } from "react";
import Table from "../../../components/table";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../heplers/translationHelper";
export const AskAgendaList = ({ agendaList, meetingId, loading }) => {

  const columns = [
   
    {
      id: "subject",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Subject","Sujette","Fach"),
    },

    {
      id: "comments",
      numeric: false,
      disablePadding: true,
      label: getTranslation("Agenda Content","Contenu de l'ordre du jour","Agenda-Inhalt"),
    },
  ];

  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [createCompany, setCreateCompany] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [loadingManagementCompanyFunds, setloadingManagementCompanyFunds] =
    useState(false);
  const { managementCompanyId } = useParams();
  const [selectedGuest, setSelectedGuest] = useState();

  ///////////////////////////// Pagination Handlers ///////////////////////////////
  // useEffect(() => {
  //   if (meetingId)
  //     onFilter({
  //       meetingId: meetingId,
  //       searchString,
  //       pageNumber: page,
  //       pageSize: rowsPerPage,
  //     });
  //   else {
  //     onFilter({
  //       searchString,
  //       pageNumber: page,
  //       pageSize: rowsPerPage,
  //     });
  //   }
  // }, [page, rowsPerPage, searchString]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleSearchUpcomingMeeting = (searchString) => {
    setSearchString(searchString);
  };

  const handleSearchManagementCompanyFund = (searchString) => {
    setSearchString(searchString);
  };
  return (
    <div>
      <Table
      title={getTranslation("Agendas","Agendas","Agendas")}
      auto
        // loading={loading}
        colums={columns}
        sortBy="subject"
        rows={agendaList}
        count={agendaList?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        // viewEdit
        // onEdit={(row) => setSelectedGuest(row)}
        // viewSearch
        // onSearch={(searchString) =>
        //   handleSearchManagementCompanyFund(searchString)
        // }
      />
    </div>
  );
};
