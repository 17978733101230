import { ReportTemplateSubmenu } from "../../../layout/subMenu/meeting"
import { TableList } from "./components"

const TableDashboard=()=>{
    return(
        <>
        <ReportTemplateSubmenu/>
        <TableList/>
        </>
    )
}
export default TableDashboard