import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, TextField } from "@material-ui/core";
import {
  PrintButton,
  MessageButton,
  CreateButton,
} from "../../components/ButttonsWithIcons";
import Table from "../../components/table";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import checkResponse from "../../heplers/responseHelper";
import DeleteDialog from "../../components/DeleteDialog";
import FundOperations from "./component/FundOperations";
import { Autocomplete } from "@material-ui/lab";
import {
  CompaniesRepresentative,
  PersonsRepresentative,
  CreateCompanyRepresentative,
  CreatePersonRepresentative,
} from "./component";
import {
  Get_AllBoardRepresentative_URL,
  Create_BoardCompanyRepresentative_URL,
  Create_BoardPersonRepresentative_URL,
  Delete_CompanyRepresentative_URL,
  Delete_PersonRepresentative_URL,
  Update_PersonRepresentative_URL,
  Create_UpdateCompanyRepresentative_URL,
} from "../../constants/apiUrls";
import { Get, Create } from "../../actions";
import { getTranslation } from "../../heplers/translationHelper";

const Board = ({ area }) => {
  const { assetId,companyId,managementCompanyId } = useParams();
  console.log("huzzz",companyId)
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAddCompanyRepresentative, setOpenAddCompanyRepresentative] =
    useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [searchPersonString, setSearchPersonString] = useState("");
  const [page, setPage] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [representative, setRepresentative] = useState();
  const [loadingRepresentative, setLoadingRepresentative] = useState(false);
  const [deletingRepresentative, setDeletingRepresentative] = useState(false);
  const [deletingType, setDeletingType] = useState("");
  const [deletingItem, setDeletingItem] = useState();
  const [editPersonRepresentative,setEditPersonRepresentative]=useState();
  const [editCompanyRepresentative,setEditCompanyRepresentative]=useState(null);

  // Loading Data
  useEffect(() => {
    loadRepresentatives();
  }, [companyId]);

  const loadRepresentatives = async () => {
    setLoadingRepresentative(true);
    Get(
      {
        companyId: companyId,
      },
      Get_AllBoardRepresentative_URL,
      history,
      (response) => {
        setLoadingRepresentative(false);
        setRepresentative(response.data);
      },
      (error) => {
        setLoadingRepresentative(false);
      }
    );
  };

  const handleOpenDeleteDialog = (row) => {
    setDeleteRow(row);
    setOpen(true);
  };

  const handleEditActivity = (row) => {
    history(`/funds/add/${row.id}`);
    // dispatch(editActivityType(row));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleCreateCompanyRepresentative = async (values, actions) => {
    if(editCompanyRepresentative){
      console.log("jakshjkdasldhlasjkdhasd",values,actions)
      values.id=editCompanyRepresentative.id;
      values.representedByPersonId=values.representedByCompanies.contactId;
      values.representativeRole=values.representedByCompanies.representativeRole;
      values.companyId=values.representedByCompanies.companyId;
    }
    Create(
      values,
      editCompanyRepresentative
        ? Create_UpdateCompanyRepresentative_URL
        : Create_BoardCompanyRepresentative_URL,
      history,
      (resp) => {
        actions.setSubmitting(false);
        editCompanyRepresentative
          ? enqueueSnackbar(getTranslation("CompanyRepresentativeUpdated"), {
            variant: "success",
          })
          : enqueueSnackbar(getTranslation("CompanyRepresentativeCreated"), {
            variant: "success",
          });
        loadRepresentatives();
        setEditCompanyRepresentative(null)
        actions.resetForm();
        },
      (error) => {
        enqueueSnackbar(getTranslation("Something went wrong"), {
          variant: "error",
        });
        actions.setSubmitting(false);
      }
    );
  };

  const handleCreatePersonRepresentative = async (values, actions) => {
    const payload = editPersonRepresentative
      ? values.representedByPersons
      : values;
    Create(
      payload,
      editPersonRepresentative
        ? Update_PersonRepresentative_URL
        : Create_BoardPersonRepresentative_URL,
      history,
      (resp) => {
        actions.setSubmitting(false);
        editPersonRepresentative
          ? enqueueSnackbar(getTranslation("Person Representative Updated"), {
              variant: "success",
            })
          : enqueueSnackbar(getTranslation("Person Representative Created"), {
              variant: "success",
            });
            setEditPersonRepresentative(null)
        loadRepresentatives();
      },
      (error) => {
        enqueueSnackbar(getTranslation("Something went wrong"), {
          variant: "error",
        });
        actions.setSubmitting(false);
      }
    );
  };

  const handleDeleteCompany = async (row) => {
    setDeletingRepresentative(true);
    await Create(
      {
        ids: [row.id],
      },
      Delete_CompanyRepresentative_URL,
      history,
      (resp) => {
        enqueueSnackbar(getTranslation("Company representative deleted"), {
          variant: "success",
        });
        loadRepresentatives();
        setDeletingRepresentative(false);
        setOpenDelete(false);
      },
      (error) => {
        enqueueSnackbar(getTranslation("Something went wrong"), { variant: "error" });
        setDeletingRepresentative(false);
      }
    );
  };

  const handleDeleteContact = async (row) => {
    setDeletingRepresentative(true);
    await Create(
      {
        ids: [row.id],
      },
      Delete_PersonRepresentative_URL,
      history,
      (resp) => {
        enqueueSnackbar(getTranslation("Person representative deleted"), {
          variant: "success",
        });
        loadRepresentatives();
        setDeletingRepresentative(false);
        setOpenDelete(false);
      },
      (error) => {
        enqueueSnackbar(getTranslation("Something went wrong"), { variant: "error" });
        setDeletingRepresentative(false);
      }
    );
  };

  const handleDeleteDialog = (type, deleteItem) => {
    setDeletingType(type);
    setOpenDelete(true);
    setDeletingItem(deleteItem);
  };

  const handleEditPersonRepresentative=(row)=>{
    setEditPersonRepresentative(row)
  }
  const handleEditCompanyRepresentative=(row)=>{
    console.log("hjafghfasda",row)
    setEditCompanyRepresentative(row)
  }
  return (
    <div>
      <CreatePersonRepresentative
        onCancel={() => setEditPersonRepresentative(null)}
        editPersonRepresentative={editPersonRepresentative}
        companyId={companyId}
        KFS
        onSubmit={handleCreatePersonRepresentative}
      />
      <PersonsRepresentative
        representative={representative}
        onDelete={(row) => handleDeleteDialog("person", row)}
        onEdit={(row) => {
          handleEditPersonRepresentative(row);
        }}
      />
      <br></br>
      <CreateCompanyRepresentative
        onCancel={() => setEditCompanyRepresentative(null)}
        editCompanyRepresentative={editCompanyRepresentative}
        companyId={companyId}
        onSubmit={handleCreateCompanyRepresentative}
      />
      <CompaniesRepresentative
        onEdit={(row) => handleEditCompanyRepresentative(row)}
        representative={representative}
        onDelete={(row) => handleDeleteDialog("company", row)}
      />
      <DeleteDialog
        open={openDelete}
        deleting={deletingRepresentative}
        title={getTranslation("Delete Company")}
        onClose={() => setOpenDelete(false)}
        onSubmit={(e) => {
          if (deletingType === "person") handleDeleteContact(deletingItem);
          if (deletingType === "company") handleDeleteCompany(deletingItem);
        }}
      />
    </div>
  );
};

export default Board;
