import { Info } from "./basic";
import Dialog from "../../../components/Dialog";
import React, { useState, useEffect } from "react";
import { getTranslation } from "../../../heplers/translationHelper";

const handleOnCloseDialog = (event, onClose) => {
  onClose();
};

export const AddCompany = ({ isOpen, editCompany, onClose, onSubmit }) => {
  return (
    <Dialog
      open={isOpen}
      title={getTranslation("Create Company","Créer une entreprise","Unternehmen erstellen")}
      onClose={onClose}
      maxWidth="md"
    >
      <Info isNewRecord={true} onSubmit={onSubmit} />
    </Dialog>
  );
};
