import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";

import {
	Card,
	CardHeader,
	CardContent,
	Typography,
	Grid,
	IconButton,
	Collapse,
	CircularProgress,
} from "@material-ui/core";

import { Create } from "../../actions";

import { useSnackbar } from "notistack";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Post_GenerateMeetings_URL } from "../../constants/apiUrls";
import HorizontalNonLinearStepper from "./component/regular/StepperRegularMeeting";
import { Alert } from "@material-ui/lab";
import { getTranslation } from "../../heplers/translationHelper";

export const GenerateRegularMeetings = () => {
	const { managementCompanyId, data } = useParams();
	const [collaperController, setCollapserController] = useState(true);

	console.log("lkjhgfdsaazaz", data);
	if (data !== "new") {
		// const decodedData = atob(data);

		// // Parse the JSON string to get the original object
		// const originalObject = JSON.parse(decodedData);

		//console.log("11", originalObject);
	}

	//const history = useNavigate();

	return (
		<div>
			<Alert severity="info">
				{getTranslation(
					"By Clicking Next Button the Information will be stored in drafts Automatically",
					"By Clicking Next Button the Information will be stored in drafts Automatically",
					"By Clicking Next Button the Information will be stored in drafts Automatically"
				)}
			</Alert>
			<br />

			<Card>
				<CardHeader
					title={data=="adhoc"? "Generate Adhoc Meeting" : "Generate Regular Meeting"}
					//action={actions()}
					//onClick={handleCollapseClick}
				/>

				<CardContent>
					<HorizontalNonLinearStepper
						collaperController={collaperController}
						setCollapserController={setCollapserController}
						managementCompanyId={managementCompanyId}
						isAdhoc={data=='adhoc'}
					/>
				</CardContent>
			</Card>
		</div>
	);
};
