import { Card, CardContent, CardHeader, Table, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const FillInfo=()=>{
    const classes = useStyles();
    return (
      <>
        <Card>
          <CardHeader title="Identification" />
          <CardContent>
            <TableContainer>
              <Table className={classes.table}>
                <TableRow className={classes.tr}>
                  <TableHead className={classes.th}>label 1</TableHead>
                  <TableHead className={classes.th}>Labek 2</TableHead>
                  <TableHead className={classes.th}>Label 3</TableHead>
                </TableRow>
                <TableRow className={classes.tr}>
                  <TableCell className={classes.td}>
                  </TableCell>
                  <TableCell className={classes.td}></TableCell>
                  <TableCell className={classes.td}></TableCell>
                </TableRow>
                <TableRow className={classes.tr}>
                  <TableCell className={classes.td}></TableCell>
                  <TableCell className={classes.td}></TableCell>
                  <TableCell className={classes.td}></TableCell>
                </TableRow>
                <TableRow className={classes.tr}>
                  <TableCell className={classes.td}></TableCell>
                  <TableCell className={classes.td}></TableCell>
                  <TableCell className={classes.td}></TableCell>
                </TableRow>
                <TableRow className={classes.tr}>
                  <TableCell className={classes.td}></TableCell>
                  <TableCell className={classes.td}></TableCell>
                  <TableCell className={classes.td}></TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </>
    );
}
export default FillInfo;
const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collapse",
    width: "80%",
    border: "1px solid black",
    margin: "15px auto",
  },
  th: {
    border: "1px solid black",
    padding: "5px",
    textAlign: "center",
    backgroundColor: "#ddd",
  },
  td: {
    border: "1px solid black",
    padding: "15px",
    textAlign: "left",
  },
  tr: {
    border: "1px solid black",
    padding: "15px",
    textAlign: "center",
    hover: {
        backgroundColor: "#ddd",
    }
  },
}));

