import React, { useState, useEffect } from "react";
import Table from "../../components/table";
import { CreateNewCompany, Get, Create } from "../../actions";
import {
	DELETE_Company_URL,
	GET_AllCompanies_URL,
} from "../../constants/apiUrls";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import checkResponse from "../../heplers/responseHelper";
import DeleteDialog from "../../components/DeleteDialog";
import { AddCompany } from "./component";
import { getTranslation } from "../../heplers/translationHelper";
const columns = [
	{
		id: "companyName",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Company Name"),
	},
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Group Name"),
		level1: "companyGroup",
	},
	{
		id: "rcsNumber",
		numeric: false,
		disablePadding: true,
		label: getTranslation("RCS"),
		level1: "basic",
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions"),
	},
];
const Clients = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	let companyList = localStorage.getItem("companyList");
	if (companyList == null) {
		localStorage.setItem("companyList", 10);
		companyList = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(companyList);
	const [rows, setRows] = useState([]);
	const [companyLoading, setCompanyLoading] = useState(false);
	const [deleteProgress, setDeleteProgress] = useState(false);
	const [editCompany, setEditCompany] = useState(null);

	//const { companies,loading } = useSelector((state) => state.companies);

	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: "Companies", url: "/companies" },
		});
	}, []);
	useEffect(() => {
		loadCompanies();
	}, [page, rowsPerPage, searchString]);
	const loadCompanies = () => {
		setCompanyLoading(true);
		Get(
			{
				searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			GET_AllCompanies_URL,
			history,
			(resp) => {
				setRows(resp.data);
				setCompanyLoading(false);
			},
			(error) => {}
		);
	};
	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};
	const handleDeleteActivity = (row) => {
		setDeleteProgress(true);
		Create(
			{
				ids: [deleteRow.id],
			},
			DELETE_Company_URL,
			history,
			(resp) => {
				enqueueSnackbar("Company Deleted", { variant: "success" });
				loadCompanies();
				setDeleteProgress(false);
				setOpen(false);
			},
			(error) => {
				enqueueSnackbar(error?.data, { variant: "error" });
				setDeleteProgress(false);
			}
		);
	};
	const handleEditActivity = (row) => {
		const url = `/companies/${row.id}/basic`;
		history(url);
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: row.companyName, url: url },
		});
	};
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("companyList", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleCreateCompany = (event) => {
		setCreateCompany(true);
	};
	const handleCreateCompanyClose = (event) => {
		setCreateCompany(false);
		loadCompanies();
	};
	const handleSearchCompany = (searchString) => {
		setSearchString(searchString);
	};

	const handleCreateCompanySubmit = (values, actions) => {
		dispatch(CreateNewCompany(values, history)).then((response) => {
			const errors = checkResponse(response);
			if (!errors) {
				enqueueSnackbar(getTranslation("Company Created"), {
					variant: "success",
				});
				handleCreateCompanyClose();
			} else {
				enqueueSnackbar(getTranslation("Something went wrong"), {
					variant: "error",
				});
			}
			actions.setSubmitting(false);
		});
	};

	return (
		<div>
			<AddCompany
				editCompany={editCompany}
				isOpen={createCompany}
				onClose={handleCreateCompanyClose}
				onSubmit={handleCreateCompanySubmit}
			/>
			<Table
				dense
				loading={companyLoading}
				title={getTranslation("Companies")}
				colums={columns}
				sortBy="companyName"
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => handleEditActivity(row)}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				viewCreate
				onCreate={() => handleCreateCompany()}
				viewSearch
				onSearch={(searchString) => handleSearchCompany(searchString)}
				failedRowColor="red"
				validateRow={(row) => row.isActive}
			/>
			<DeleteDialog
				open={open}
				deleting={deleteProgress}
				title={getTranslation("Delete Company")}
				onClose={() => setOpen(false)}
				onSubmit={(e) => handleDeleteActivity(deleteRow)}
			/>
		</div>
	);
};

export default Clients;
