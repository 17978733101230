import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Get } from "../../../../actions";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import CardComponent from "../../../../components/Card";
import { UploadFiles } from "../../../../components/Upload";
import {
  Get_GetReportInfo_URL,
  Get_MeetingInfo_URL,
  Get_SendNotificationForReview_URL,
} from "../../../../constants/apiUrls";
import { getTranslation } from "../../../../heplers/translationHelper";
import { MeetingReports } from "../../../meeting/component/regular/attachReport/components";
import MeetingInformation from "../../components/MeetingInformation";
import CompanyDetails from "./companyDetails";

const ReportDetails = () => {
  const { meetingId } = useParams();
  const [meetingInfo, setMeetingInfo] = useState();
  const [meetingReport, setMeetingReport] = useState();
  const printRef=useRef();
  const nav=useNavigate()
  useEffect(() => {
    loadReportInfo();
    loadMeetingInfo();
  }, [meetingId]);
  const loadMeetingInfo = () => {
    Get(
      {
        meetingId: meetingId,
      },
      Get_MeetingInfo_URL,
      null,
      (resp) => {
        setMeetingInfo(resp?.data);
      },
      (error) => {}
    );
  };
  const loadReportInfo = () => {
    Get(
      { meetingId: meetingId },
      Get_GetReportInfo_URL,
      null,
      (resp) => {
        if(resp?.data?.reportStatus)
          nav("/response/Report is already marked as approved.")
        setMeetingReport(resp.data);
      },
      (error) => {}
    );
  };

  return (
    <>
     <CompanyDetails meetingId={meetingId} />
          <br />
          <MeetingInformation meetingInfo={meetingInfo} />
          <br />
          <Card>
            <CardHeader
              title={getTranslation(
                "Upload Documents",
                "Télécharger des documents",
                "Dokumente hochladen"
              )}
            />
            <CardContent>
              <UploadFiles
                getFileName={(fileName) => {
                  console.log(fileName);
                }}
                onAddFile={(file) => {
                  console.log(file);
                }}
              />
            </CardContent>
          </Card>
          <br />
        
          <MeetingReports meetingReport={meetingReport} meetingId={meetingId} enabled ref={printRef} />
    </>
  );
};
export default ReportDetails;
