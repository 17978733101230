import { Button, Dialog, List, ListItem, Typography } from "@material-ui/core";
import { QRCodeReader } from "../../heplers/qrReader";
import { scanCircularResolution, scanMinuteReport } from "./operations";
import { circularResolutionType, minuteReportByFundManagerType } from "./scannerTypes";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { getTranslation } from "../../heplers/translationHelper";
import DialogComponent from "../../components/Dialog";

export const ScanDocument = ({ isOpen, title, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleScanDocument = (result) => {
    if (result) {
      onClose();
      enqueueSnackbar("We read your QR Code sending for processing..",{variant:'info'})
      const data = JSON.parse(result);
      switch (data.type) {
        case circularResolutionType:
          scanCircularResolution(
            data.value,
            (resp) => {
              enqueueSnackbar("Circular Resolution has been signed.", {
                variant: "success",
              });
            },
            (error) => {
              enqueueSnackbar("Circular Resolution has not been signed.", {
                variant: "error",
              });
            }
          );
          break;
          case minuteReportByFundManagerType:
            scanMinuteReport(
              data.value,
              (resp) => {
                enqueueSnackbar("Circular Resolution has been signed.", {
                  variant: "success",
                });
              },
              (error) => {
                enqueueSnackbar("Circular Resolution has not been signed.", {
                  variant: "error",
                });
              }
            );
          break;
        default:
      }
    }
  };

  const onError = (error) => {
    if (error) {
      console.error(error);
    }
  };
  const [permission, setPermission] = useState(false);

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    permissions
      .then((stream) => {
        alert("Permission granted");
        setPermission(true);
      })
      .catch((err) => {
        enqueueSnackbar("Not able to access to camera.", { variant: "error" });
      });
  };

  useEffect(() => {
    if (isOpen) checkPermissions();
  }, []);

  return (
    <>
      <DialogComponent fullWidth maxWidth="sm"  open={isOpen} title={getTranslation("Scan Qr Document","Numériser un document Qr","Qr-Dokument scannen")} onClose={onClose}>
        
        <Typography style={{ textAlign: "center", fontWeight:'bold' }}>{getTranslation("Instructions","Instructions","Instructions")}</Typography>
        <ul style={{ listStyleType:'decimal' }}>
        <li style={{margin:'10px'}} ><Typography>{getTranslation("Scan QR Code of your document using your mobile camera or PC Camera. In case of Windows or MAC OS please download any QR scanner app from app store. Our QR Code works on any scanner.")}</Typography></li >
        <li style={{margin:'10px'}} ><Typography>{getTranslation("Scanner will provide you a link, click on the link or copy it and open it in your browser.")}</Typography></li >
        <li style={{margin:'10px'}} ><Typography>{getTranslation("Once the link is opened the system will ask you for logins. Provide valid logins.")}</Typography></li >
        <li style={{margin:'10px'}} ><Typography>{getTranslation("After Login System will automatically mark document as signed in the system.")}</Typography></li >
        <li style={{margin:'10px'}} ><Typography>{getTranslation("If you are facing any problem, please let us know with problem details and surely we will fix that issue and get back to you.")}</Typography></li >
        </ul>
       
        {/* <QRCodeReader
          onScan={handleScanDocument}
          onError={onError}
          style={{
            width: "500px",
            height: "500px",
          }}
        />
     */}
        <Button
          style={{ width: "50%", height: "10%", margin: "25%" }}
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          Close Scanner
        </Button> 
      </DialogComponent>
    </>
  );
};
