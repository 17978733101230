import { Show_Meeting_Submenu } from "../constants/types";

const initState = {
  menu:[]
};


const submenuReducer = (state = initState, action) => {
  switch (action.type) {
    case Show_Meeting_Submenu:
      state.menu=action.payload
      return {
        ...state
      };
      
    default:
      return { ...state };
  }
};

export default submenuReducer;
