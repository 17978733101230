import axios from "../heplers/apiHelper";
import {
    GET_AllContacts_URL,
    GET_Contact_URL,
    SAVE_Contact_URL,
    DELETE_Contact_URL,
    UPDATE_Contact_URL,
    Get_Contact_BelongsTo_URL,
} from "../constants/apiUrls";
import {
    GET_AllContacts,
    GET_Contacts,
    Contacts__LOADING,
    Contacts_ERROR,
    EDIT_Contact,
  GET_AllContacts_ERROR,
  Get_Contact_BelongsTo
} from "../constants/types"


export const CreateNewContact=(payload,history)=>async dispatch=>{
    try {
        const response = await axios(history).post(SAVE_Contact_URL,payload);
        return response;
    } catch (error) {
        return error.response
    }
}

export const UpdateContact=(payload,history)=>async dispatch=>{
    try {
        const response = await axios(history).post(UPDATE_Contact_URL,payload);
        return response;
    } catch (error) {
        return error.response
    }
}

export const DeleteContact=(payload,history)=>async dispatch=>{
    try {
        const response = await axios(history).post(DELETE_Contact_URL,payload);
        return response;
        
    } catch (error) {
        return error.response
    }
}


export const GetContact=(payload,history)=>async dispatch=>{
    try {
        const response = await axios(history).get(GET_Contact_URL,{params:payload});
        dispatch({
            type:GET_Contacts,
            payload:response.data
        });
    } catch (error) {
        dispatch({
            type:Contacts_ERROR,
            payload:error.response.data
        });
    }
}

export const GetAllContacts=(payload,history)=>async dispatch=>{
    try {
        dispatch({type:Contacts__LOADING});
        const response = await axios(history).get(GET_AllContacts_URL,{params:payload});
        dispatch({
            type:GET_AllContacts,
            payload:response.data
        });
    } catch (error) {
        dispatch({
            type:GET_AllContacts_ERROR,
            payload:error.response
        });
        

    }
}

export const EditContact=(payload)=>async dispatch=>{
    dispatch({
        type:UPDATE_Contact_URL,
        payload:payload
    });

}


export const GetBelongsTo=(payload,history)=>async dispatch=>{
    try {
        const response = await axios(history).get(Get_Contact_BelongsTo_URL,{params:payload});
        dispatch({
            type:Get_Contact_BelongsTo,
            payload:response.data
        });
    } catch (error) {
        dispatch({
            type:Contacts_ERROR,
            payload:error.response.data
        });
    }
}
