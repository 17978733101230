import React from "react";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import Table from "../../../../components/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Create, Get } from "../../../../actions";
import {
	Post_PostponeRegularMeeting_URL,
	Post_TryResolvingMeetingClashes_URL,
} from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { AlarmClockIcon } from "../../../../components/Icons";
import DialogComponent from "../../../../components/Dialog";
import { format } from "../../../../heplers/format";
import { DatePicker } from "../../../../components/DatePicker";
import { MyTimePicker } from "../../../../components/TimePicker";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { Toc } from "@material-ui/icons";
import { getTranslation } from "../../../../heplers/translationHelper";
import ErrorIcon from "@material-ui/icons/Error";
import { ShowClashedContactsDialog } from "./showClashedContactsDialog";
import SyncIcon from "@material-ui/icons/Sync";
import EditIcon from "@material-ui/icons/Edit";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import moment from "moment";

const UpcomingMeetings = ({ rows, loading, onFilter, onAdd, onShowlogs }) => {
	const history = useNavigate();
	const columns = [
		{
			id: "meetingDate",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Date", "Date", "Datum"),
			format: "date",
		},
		{
			id: "meetingTime",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Time", "Temps", "Zeit"),
			format: "time",
		},
		{
			id: "subject",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Subject", "Sujette", "Fach"),
		},
		{
			id: "personInchargeName",
			numeric: true,
			disablePadding: true,
			label: getTranslation(
				"Person Incharge",
				"Personne en charge",
				"Verantwortlicher"
			),
		},
		{
			id: "language",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Language", "Langue", "Sprache"),
		},

		{
			id: "actions",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Action", "action", "Aktion"),
			align: "right",
		},
	];
	const { managementCompanyId } = useParams();

	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let spvUpCommingMeeting = localStorage.getItem("spvUpCommingMeeting");
	if (spvUpCommingMeeting == null) {
		localStorage.setItem("spvUpCommingMeeting", 10);
		spvUpCommingMeeting = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvUpCommingMeeting);
	const [openPostponeDialog, setOpenPostponeDialog] = useState(false);
	const [postponeMeeting, setPostponeMeeting] = useState();
	const [postponeMeetingDate, setPostponeMeetingDate] = useState(new Date());
	const [postponeMeetingTime, setPostponeMeetingTime] = useState(new Date());
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [selectedMeeting, setSelectedMeeting] = useState(null);

	const [openClashDialog, setOpenClashDialog] = useState(false);
	const [sortDirection, setSortDirection] = useState("asc");
	const [sortBy, setSortBy] = useState("meetingDate");

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		onFilter({
			searchString: searchString,
			pageNumber: page,
			pageSize: rowsPerPage,
			sort: sortBy,
			sortDirection: sortDirection == "asc" ? 0 : 1,
		});
	}, [page, rowsPerPage, searchString, sortBy, sortDirection]);

	const handleChangePage = (event, newPage) => {
		console.log("newpage", newPage);
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvUpCommingMeeting", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleSearchUpcomingMeeting = (searchString) => {
		setSearchString(searchString);
	};

	const handleEditActivity = (row) => {
		onAdd(row);
	};
	const handlePostPoneMeeting = (row) => {
		setPostponeMeeting(row);
		setPostponeMeetingDate(row.meetingDate);
		setPostponeMeetingTime(row.meetingTime);
		setOpenPostponeDialog(true);
	};
	const submitPostPoneMeeting = () => {
		if (!postponeMeetingDate || !postponeMeetingTime) {
			enqueueSnackbar("Please Select Both Date And Time", { variant: "error" });
			return;
		}

		if (moment(postponeMeetingDate).isValid()==false ) {
			enqueueSnackbar("Please select a valid date.", { variant: "error" });
			return;
		}

		if (moment(postponeMeetingTime).isValid()==false) {
			enqueueSnackbar("Please select a valid time.", { variant: "error" });
			return;
		}
		setIsSubmiting(true);
		const model = {
			meetingId: postponeMeeting.id,
			meetingDate: postponeMeetingDate,
			meetingTime: postponeMeetingTime,
		};
		Create(
			model,
			Post_PostponeRegularMeeting_URL,
			null,
			(resp) => {
				setOpenPostponeDialog(false);
				setPostponeMeeting(null);

				setIsSubmiting(false);
				enqueueSnackbar("Meeting is postponed.", { variant: "success" });
				onFilter({
					searchString,
					pageNumber: page,
					pageSize: rowsPerPage,
				});
			},
			(error) => {
				setIsSubmiting(false);
				enqueueSnackbar(error, { variant: "error" });
			}
		);
	};
	const syncMeetingClashes = (row) => {
		console.log("jsfjkghsfdg", row);

		Get(
			{ meetingId: row.id },
			Post_TryResolvingMeetingClashes_URL,
			null,
			(resp) => {
				enqueueSnackbar("Meeting Clash resolved.", { variant: "success" });
			},
			(error) => {
				enqueueSnackbar("Couldn't Resolve meeting Clashes. Try Again", {
					variant: "error",
				});
			}
		);
	};

	const handleUpdateReport = (row) => {
		console.log("jksdghfksdf", row);
		var url = `/meetings/${managementCompanyId}/${row.id}/updatereport/`;
		history(url);
	};

	return (
		<div>
			<Table
				dense
				loading={loading}
				title={getTranslation(
					"Upcoming Meetings",
					"Réunions à venir",
					"Kommende Treffen"
				)}
				colums={columns}
				sortBy={sortBy}
				sortDirection={sortDirection}
				onSorting={(newSortBy, direction) => {
					setSortBy(newSortBy);
					setSortDirection(direction);
				}}
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewSearch
				onSearch={(searchString) => handleSearchUpcomingMeeting(searchString)}
				actions={[
					{
						component: (row) => (
							<IconButton onClick={() => handleEditActivity(row)}>
								<EditIcon />
							</IconButton>
						),
					},
					{
						component: (row) => (
							<IconButton onClick={() => handlePostPoneMeeting(row)}>
								<AlarmClockIcon />
							</IconButton>
						),
					},
					{
						component: (row) => (
							<IconButton onClick={() => onShowlogs(row.id)}>
								<Toc />
							</IconButton>
						),
					},
					{
						component: (row) =>
							row.hasClash ? (
								<IconButton onClick={() => syncMeetingClashes(row)}>
									<SyncIcon />
								</IconButton>
							) : null,
					},
					{
						component: (row) =>
							row.hasClash ? (
								<IconButton
									onClick={() => {
										setSelectedMeeting(row);
										setOpenClashDialog(true);
									}}
								>
									<ErrorIcon color="error" />
								</IconButton>
							) : null,
					},
					{
						component: (row) => (
							<IconButton onClick={() => handleUpdateReport(row)}>
								<AssignmentOutlinedIcon />
							</IconButton>
						),
					},
				]}
			/>
			<DialogComponent
				title={getTranslation(
					"Postpone Meeting",
					"Reporter la réunion",
					"Reporter la réunion"
				)}
				open={openPostponeDialog}
				onClose={() => setOpenPostponeDialog(false)}
			>
				<Box display="flex" alignItems="center" justifyContent="center" my={4}>
					<Typography
						variant="subtitle2"
						style={{
							fontWeight: "bold",
							display: "inline-block",
							marginRight: "5px",
						}}
					>
						Current Meeting:{" "}
					</Typography>
					<Typography variant="string">
						{postponeMeeting
							? `${format("date", postponeMeeting?.meetingDate)} ${format(
									"time",
									postponeMeeting?.meetingTime
							  )}`
							: null}
					</Typography>
				</Box>

				<Grid container spacing={2}>
					<Grid item>
						<DatePicker
							label={getTranslation("Date", "Date", "Datum")}
							value={postponeMeetingDate}
							required
							onChange={(value) => {
								setPostponeMeetingDate(value);
							}}
						/>
					</Grid>
					<Grid item>
						<MyTimePicker
							label={getTranslation("Time", "Temps", "Zeit")}
							value={postponeMeetingTime}
							required
							onChange={(value) => {
								const formatedTime = moment(value).format("MM-DD-YYYY HH:mm");
								setPostponeMeetingTime(formatedTime);

							}}
							KeyboardButtonProps={{
								"aria-label": "Meeting Time",
							  }}
						/>
					</Grid>
				</Grid>
				<Box display="flex" alignItems="center" justifyContent="center" my={4}>
					<ButtonWithLoading
						title={getTranslation("Confirm", "Confirmer", "Bestätigen Sie")}
						size="sm"
						variant="contained"
						loading={isSubmiting}
						onClick={submitPostPoneMeeting}
					/>
				</Box>
			</DialogComponent>
			<ShowClashedContactsDialog
				open={openClashDialog}
				onClose={() => setOpenClashDialog(false)}
				meetingId={selectedMeeting?.id}
			/>
		</div>
	);
};
export default UpcomingMeetings;
