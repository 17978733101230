import { List, ListItem, ListItemText, Box, makeStyles } from "@material-ui/core";
import CardComponent from "../../../../components/Card";
import { bg } from "date-fns/locale";
import {DeleteButton} from "../../../../components/ButttonsWithIcons"

import { useState } from "react";


const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    alignItems: "center",
  },
  count: {
    marginRight: theme.spacing(1),
  },
}));

export const AgendaList = ({ agendas, selectedAgenda, setSelectedAgenda,deleteBtn,disabled }) => {
  const classes = useStyles();


  return (
    <CardComponent title="Agendas" style={{ height: '95%' }}>
      <Box maxHeight="100%" overflow="auto">
        <List>
          {agendas?.map((agenda, index) => (
            <>
            <ListItem
              button
              onClick={() => setSelectedAgenda(agenda)}
              selected={selectedAgenda?.id === agenda.id}
              key={agenda.id}
              className={classes.listItem}
            >
              <span className={classes.count}>{index + 1}.</span>
              <ListItemText primary={agenda.subject} />
              {disabled?null:
              <DeleteButton onClick={()=>deleteBtn(agenda.id,agenda)} />}
            </ListItem>
            </>
          ))}
        </List>
      </Box>
    </CardComponent>
  );
};

// <button onClick={()=>deleteBtn(agenda.id)}>delete</button>



