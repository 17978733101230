import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CalendarListView } from "./components";

export const Calendar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "Clear_All_BreadCrumb" });
    dispatch({
      type: "Add_BreadCrumb",
      payload: { title: "Calendar", url: "/calendar" },
    });
  }, []);
  return (
    <>
      <CalendarListView />
    </>
  );
};
