import React, { useState, useEffect } from "react";
import Table from "../../components/table";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../heplers/translationHelper";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "../../components/DatePicker";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { AlarmClockIcon } from "../../components/Icons";
import DialogComponent from "../../components/Dialog";
import { MyTimePicker } from "../../components/TimePicker";
import {
  Get_AllUnConfirmedMeetings_URL,
  Get_AskForApprovalOfMeetings_URL,
  Post_ConfirmationOfAutoGeneratedMeetings_URL,
  Post_PostponeRegularMeeting_URL,
} from "../../constants/apiUrls";
import { Create, Get } from "../../actions";
import moment from "moment";
import { format } from "../../heplers/format";
import { MeetingSubMenu } from "../../layout/subMenu";
const columns = [
  {
    id: "subject",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Subject", "Sujette", "Fach"),
  },
  {
    id: "language",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Language", "Langue", "Sprache"),
  },
  {
    id: "personInchargeName",
    numeric: false,
    disablePadding: true,
    label: getTranslation(
      "Person Incharge",
      "Personne en charge",
      "Verantwortlicher"
    ),
  },
  {
    id: "meetingDate",
    format: "date",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Date", "date", "Termin"),
  },
  {
    id: "meetingTime",
    format: "time",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Time", "heure", "zeit"),
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Address", "Adresse", "Adresse"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions", "Actions", "Aktionen"),
  },
];

export const AutoGeneratedMeetings = () => {
  ////// Current Date to get meetings for 1 year ahead
  const date = new Date();
  const year = date.getFullYear() + 1;

  const [postponeMeeting, setPostponeMeeting] = useState();
  const [postponeMeetingDate, setPostponeMeetingDate] = useState(new Date());
  const [postponeMeetingTime, setPostponeMeetingTime] = useState(new Date());
  const [openPostponeDialog, setOpenPostponeDialog] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isConfriming, setIsConfirming] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [unConfirmedMeetings, setUnConfirmedMeetings] = useState([]);
  const [unConfirmedMeetingsLoading, setUnConfirmedMeetingsLoading] =
    useState(false);
  const [toDate, setToDate] = useState(
    new Date(year, date.getMonth(), date.getDay())
  );
  const [fromDate, setFromDate] = useState("01/01/0001");

  useEffect(() => {
    loadUnConfirmedMeetings({
      to: toDate,
      from: fromDate,
      searchString: searchString,
      pageNumber: page,
      rowsPerPage: rowsPerPage,
    });
  }, [page, rowsPerPage, searchString]);

  const loadUnConfirmedMeetings = () => {
    setUnConfirmedMeetingsLoading(true);
    Get(
      {
        to: toDate,
        from: fromDate,
        searchString: searchString,
        pageNumber: page,
        rowsPerPage: rowsPerPage,
      },
      Get_AllUnConfirmedMeetings_URL,
      null,
      (resp) => {
        setUnConfirmedMeetingsLoading(false);
        setUnConfirmedMeetings(resp?.data);
      },
      (error) => {
        setUnConfirmedMeetingsLoading(false);
        enqueueSnackbar("Unconfirmed meetings error" + error?.data, {
          variant: "error",
        });
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearch = (searchString) => {
    setSearchString(searchString);
  };

  const handlePostPoneMeeting = (row) => {
    setPostponeMeeting(row);
    setPostponeMeetingDate(row.meetingDate);
    setPostponeMeetingTime(row.meetingTime);
    setOpenPostponeDialog(true);
  };

  const submitPostPoneMeeting = () => {
    setIsSubmiting(true);
    const model = {
      meetingId: postponeMeeting.id,
      meetingDate: postponeMeetingDate,
      meetingTime: moment(postponeMeetingTime).format("kk:mm"),
    };
    Create(
      model,
      Post_PostponeRegularMeeting_URL,
      null,
      (resp) => {
        setOpenPostponeDialog(false);
        setPostponeMeeting(null);

        setIsSubmiting(false);
        enqueueSnackbar("Meeting is postponed.", { variant: "success" });
        loadUnConfirmedMeetings();
      },
      (error) => {
        setIsSubmiting(false);
        enqueueSnackbar(error.data, { variant: "error" });
      }
    );
  };

  ////////// Confirm Meetings decision ////////

  const handleConfirmMeetings = () => {
    setIsConfirming(true);
    Create(
      {},
      Post_ConfirmationOfAutoGeneratedMeetings_URL,
      null,
      (resp) => {
        setIsConfirming(false);
        enqueueSnackbar("All meetings have been confirmed", {
          variant: "success",
        });
      },
      (error) => {
        enqueueSnackbar("Confirmation Error: " + error.data, {
          variant: "error",
        });
        setIsConfirming(false);
      }
    );
  };

  const handleAskForApproval = () => {
    setIsSubmiting(true);
    Get(
      {},
      Get_AskForApprovalOfMeetings_URL,
      null,
      (resp) => {
        setIsSubmiting(false);
        enqueueSnackbar("A mail is sent to members for meetings approval", {
          variant: "success",
        });
      },
      (error) => {
        setIsSubmiting(false);
        enqueueSnackbar("Error: " + error.data, { variant: "error" });
      }
    );
  };

  ///////////// Ask for approval ////////

  return (
    <>
      <MeetingSubMenu />
      <Card>
        <CardHeader
          title={getTranslation(
            "Auto Generated Meetings",
            "Réunions générées automatiquement",
            "Automatisch generierte Meetings"
          )}
        />
        <CardContent>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <DatePicker
              size="small"
              label="From"
              value={fromDate}
              onChange={(fromDate) => {
                if (typeof fromDate !== "undefined") {
                  if (fromDate === null) {
                    setFromDate("01/01/1900");
                  } else {
                    setFromDate(fromDate);
                  }
                }
              }}
            />
            <DatePicker
              style={{
                marginLeft: "10px",
                marginRight: "20px",
              }}
              value={toDate}
              label="To"
              onChange={(toDate) => {
                if (typeof toDate !== "undefined") {
                  if (toDate === null) {
                    setToDate(new Date(year, date.getMonth(), date.getDay()));
                  } else {
                    setToDate(toDate);
                  }
                }
              }}
            />
            <IconButton onClick={() => loadUnConfirmedMeetings()}>
              <FilterListOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
          <br />
          <Table
            dense
            auto
            loading={unConfirmedMeetingsLoading}
            colums={columns}
            sortBy="title"
            rows={unConfirmedMeetings.data}
            count={unConfirmedMeetings.totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={(event, newPage) =>
              handleChangePage(event, newPage)
            }
            handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            viewSearch
            onSearch={(searchString) => handleSearch(searchString)}
            actions={[
              {
                component: (row) => (
                  <IconButton onClick={() => handlePostPoneMeeting(row)}>
                    <AlarmClockIcon />
                  </IconButton>
                ),
              },
            ]}
          />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="10px"
          >
            <ButtonWithLoading
              disabled={unConfirmedMeetings.totalCount === 0}
              title={
                isSubmiting
                  ? getTranslation(
                      "Please Wait",
                      "S'il vous plaît, attendez",
                      "Warten Sie mal"
                    )
                  : getTranslation(
                      "Ask for Approval",
                      "Demander l'approbation",
                      "Bitte um Genehmigung"
                    )
              }
              style={{
                background:
                  unConfirmedMeetings.totalCount === 0 ? "gray" : "red",
                margin: "10px",
                color: "white",
              }}
              loading={isSubmiting}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => handleAskForApproval()}
            />
            <ButtonWithLoading
              disabled={unConfirmedMeetings.totalCount === 0}
              title={
                isConfriming
                  ? getTranslation(
                      "Please Wait",
                      "S'il vous plaît, attendez",
                      "Warten Sie mal"
                    )
                  : getTranslation("Confirm", "Confirmer", "Bestätigen Sie")
              }
              style={{
                background:
                  unConfirmedMeetings.totalCount === 0 ? "gray" : "#233044",
                color: "white",
              }}
              loading={isConfriming}
              variant="outlined"
              color="red"
              size="small"
              onClick={() => handleConfirmMeetings()}
            />
          </Box>
        </CardContent>
      </Card>
      <DialogComponent
        title={getTranslation(
          "Postpone Meeting",
          "Reporter la réunion",
          "Reporter la réunion"
        )}
        open={openPostponeDialog}
        onClose={() => setOpenPostponeDialog(false)}
      >
        <Box display="flex" alignItems="center" justifyContent="center" my={4}>
          <Typography
            variant="subtitle2"
            style={{
              fontWeight: "bold",
              display: "inline-block",
              marginRight: "5px",
            }}
          >
            {getTranslation(
              "Current Meeting : ",
              "Réunion en cours : ",
              "Aktuelles Treffen : "
            )}
          </Typography>
          <Typography variant="string">
            {postponeMeeting
              ? `${format("date", postponeMeeting?.meetingDate)} ${format(
                  "time",
                  postponeMeeting?.meetingTime
                )}`
              : null}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item>
            <DatePicker
              label={getTranslation("Date", "Date", "Datum")}
              value={postponeMeetingDate}
              required
              onChange={(value) => {
                setPostponeMeetingDate(value);
              }}
            />
          </Grid>
          <Grid item>
            <MyTimePicker
              label={getTranslation("Time", "Temps", "Zeit")}
              value={postponeMeetingTime}
              required
              onChange={(value) => {
                setPostponeMeetingTime(value);
              }}
            />
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="center" my={4}>
          <ButtonWithLoading
            title={getTranslation("Confirm", "Confirmer", "Bestätigen Sie")}
            size="sm"
            variant="contained"
            loading={isConfriming}
            onClick={submitPostPoneMeeting}
          />
        </Box>
      </DialogComponent>
    </>
  );
};
