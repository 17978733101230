import React, { useState, useEffect } from "react";
import {
	Box,
	IconButton,
	Paper,
	TextField,
	Typography,
	Tooltip,
} from "@material-ui/core";
import Table from "../../components/table";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { EditButton } from "../../components/ButttonsWithIcons";
// import {
//   getActivities,
//   deleteActivity
// } from "../../actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import DeleteDialog from "../../components/DeleteDialog";
import { Get, Create } from "../../actions";
import {
	Get_AllManagementCompaniesWithPagination_URL,
	Post_DeleteFund_URL,
	Post_CreateManagementCompany_URL,
	Post_DeleteManagementCompanies_URL,
	Post_UpdateManagementCompany_URL,
} from "../../constants/apiUrls";

import { CreateManagementCompany } from "./component";
import { getTranslation } from "../../heplers/translationHelper";

const columns = [
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Title", "Titre", "Titel"),
	},
	{
		id: "investorRelation",
		numeric: false,
		disablePadding: true,
		label: getTranslation(
			"Investors Relation",
			"Relation Investisseurs",
			"Investors-Relation"
		),
	},
	{
		id: "feeAgreementStatusTitle",
		numeric: false,
		disablePadding: true,
		label: getTranslation(
			"FeeAgreement Status",
			"Statut de l'accord de frais",
			"Status der Gebührenvereinbarung"
		),
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const Funds = [
	{
		id: "001",
		title: "Company",
		investerRelation: "Asghar",
		manager: "Khan",
	},
];

const ListFunds = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	const [deleteProgress, setDeleteProgress] = useState(false);
	let spv = localStorage.getItem("spv");
	if (spv == null) {
		localStorage.setItem("spv", 10);
		spv = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spv);
	const [rows, setRows] = useState([]);
	const [loadingFunds, setloadingFunds] = useState(false);
	const [editSPV, setEditSPV] = useState(null);
	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: "SPV", url: "/managementCompanies" },
		});
	}, []);

	useEffect(() => {
		loadFunds();
	}, [page, rowsPerPage, searchString]);

	const loadFunds = () => {
		setloadingFunds(true);
		Get(
			{
				searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_AllManagementCompaniesWithPagination_URL,
			history,
			(resp) => {
				setRows(resp.data);
				setloadingFunds(false);
			},
			(error) => {}
		);
	};

	//const { activities } = useSelector((state) => state.activities);
	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleDeleteActivity = (row) => {
		setDeleteProgress(true);
		Create(
			{
				ids: [deleteRow.id],
			},
			Post_DeleteManagementCompanies_URL,
			history,
			(resp) => {
				enqueueSnackbar("Management Company Deleted", { variant: "success" });
				loadFunds();
				setDeleteProgress(false);
				setOpen(false);
			},
			(error) => {
				// enqueueSnackbar(error?.data, { variant: "error" });
				enqueueSnackbar(
					getTranslation(
						"Can't delete spv, because of related data that exists.",
						"Can't delete spv, because of related data that exists.",
						"Can't delete spv, because of related data that exists."
					),
					{ variant: "error" }
				);
				setDeleteProgress(false);
			}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spv", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleDetailCompany = (row) => {
		const url = `/managementCompanies/${row.id}/${row.companyId}/basic`;
		history(url);
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: row.title, url: url },
		});
	};
	const handleSearchFund = (searchString) => {
		setSearchString(searchString);
	};
	const handleCreateCompany = (event) => {
		setCreateCompany(true);
	};
	const handleCreateCompanyClose = (event) => {
		setCreateCompany(false);
	};
	const handleCreateManagementCompany = async (values, actions) => {
		Create(
			values,
			editSPV
				? Post_UpdateManagementCompany_URL
				: Post_CreateManagementCompany_URL,
			history,
			(response) => {
				actions.setSubmitting(false);
				editSPV
					? enqueueSnackbar("Management Company Updated", {
							variant: "success",
					  })
					: enqueueSnackbar("Management Company Created", {
							variant: "success",
					  });
				setEditSPV(null);
				loadFunds();
			},
			(error) => {
				actions.setSubmitting(false);
				enqueueSnackbar("Something went wrong", {
					variant: "error",
				});
			}
		);
	};
	const handleDeleteManagementCompanies = (row) => {
		setOpen(true);
		setDeleteRow(row);
	};
	const handleEditSPV = (row) => {
		setEditSPV(row);
	};

	return (
		<div>
			<CreateManagementCompany
				editSPV={editSPV}
				onSubmit={handleCreateManagementCompany}
				onCancel={() => setEditSPV(null)}
			/>
			<Table
				dense
				loading={loadingFunds}
				title="SPV"
				colums={columns}
				sortBy="title"
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => handleDetailCompany(row)}
				viewSearch
				onSearch={(searchString) => handleSearchFund(searchString)}
				viewDelete
				onDelete={(row) => handleDeleteManagementCompanies(row)}
				actions={[
					{
						component: (rowData) => (
							<Tooltip
								title={getTranslation(
									"Edit Title",
									"Modifier le titre",
									"Titel bearbeiten"
								)}
								arrow
								placement="left-start"
							>
								<IconButton onClick={() => handleEditSPV(rowData)}>
									<EditButton />
								</IconButton>
							</Tooltip>
						),
					},
				]}
			/>
			<DeleteDialog
				open={open}
				title={getTranslation("Delete SPV", "Supprimer SPV", "SPV löschen")}
				deleting={deleteProgress}
				onClose={() => setOpen(false)}
				onSubmit={(e) => handleDeleteActivity(deleteRow)}
			/>
		</div>
	);
};

export default ListFunds;
