import { Card, CardContent, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Create, Get } from "../../../actions";
import Table from "../../../components/table";
import {
  Post_AddNewHoliday_URL,
  Get_AllHolidays_URL,
  Post_DeleteHoliday_URL,
  Post_UpdateHoliday_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import { SettingsSubmenu } from "../../../layout/subMenu";
import AddHoliday from "./addHoliday";
const columns = [
  {
    id: "startDate",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Start Date", "Date de début", "Startdatum"),
    format: "date",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: true,
    label: getTranslation("End Date", "Date de fin", "Enddatum"),
    format: "date",
  },
  {
    id: "comments",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Holiday", "Vacances", "Urlaub"),
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions", "Actions", "Aktionen"),
  },
];

const HolidayList = () => {
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddHolidayDialog, setOpenAddHolidayDialog] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsLoading, setRowsLoading] = useState(false);
  const [editHoliday, setEditHoliday] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  //////////// Get All Holidays ////////////
  useEffect(() => {
    loadHolidays();
  }, [searchString, page, rowsPerPage]);
  const loadHolidays = () => {
    setRowsLoading(true);
    Get(
      {},
      Get_AllHolidays_URL,
      null,
      (resp) => {
        setRowsLoading(false);
        setRows(resp.data);
      },
      (error) => {
        setRowsLoading(false);
        enqueueSnackbar("Error loading Holidays", { variant: "error" });
      }
    );
  };

  /////////// Delate Holidays //////////////
  const handleDelete = (row) => {
    setSubmitting(true);
    Create(
      {
        id: row.id,
      },
      Post_DeleteHoliday_URL,
      null,
      (resp) => {
        setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Holiday Deleted Successfully",
            "Vacances supprimées avec succès",
            "Urlaub erfolgreich gelöscht"
          ),
          { variant: "success" }
        );
        var newRows = rows.filter((r) => r.id !== row.id);
        setRows(newRows);
      },
      (error) => {
        setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Error Deleting Holiday",
            "Erreur lors de la suppression des vacances",
            "Fehler beim Löschen des Urlaubs"
          ),
          { variant: "error" }
        );
      }
    );
  };

  //////////// Add Holiday ////////////
  const handleSubmitHoliday = (values, actions) => {
    setSubmitting(true);
    Create(
      values,
      editHoliday ? Post_UpdateHoliday_URL : Post_AddNewHoliday_URL,
      null,
      (resp) => {
        setSubmitting(false);
        actions.resetForm();
        setOpenAddHolidayDialog(false);
        editHoliday ? enqueueSnackbar("Updated Successfully", { variant: "success" }) : enqueueSnackbar("Added Successfully", { variant: "success" });
        
        if (editHoliday) {
          setEditHoliday(null);
          
        } 
        loadHolidays();

      },
      (error) => {
        setSubmitting(false);
        actions.setErrors(error.response.data);
      }
    );
  };
  ////////// Edit Holiday //////////////
  const handleEditHoliday = (row) => {
    setEditHoliday(row);
    setOpenAddHolidayDialog(true);
  };
  return (
    <>
      <SettingsSubmenu />
      <Card>
        <CardContent>
          <Typography
            variant="h3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getTranslation(
              "No Meetings on Weekends",
              "Pas de réunions les week-ends",
              "Keine Treffen am Wochenende"
            )}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Table
        auto
        dense
        loading={rowsLoading}
        title={getTranslation(
          "Holiday List",
          "Liste des jours fériés",
          "Feiertagsliste"
        )}
        colums={columns}
        rows={rows}
        count={rows?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(e,newPage) => handleChangePage(e,newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewSearch
        onSearch={(searchString) => setSearchString(searchString)}
        viewEdit
        onEdit={(row) => handleEditHoliday(row)}
        viewDelete
        onDelete={(row) => handleDelete(row)}
        viewCreate
        onCreate={() => {
          setEditHoliday(null);
          setOpenAddHolidayDialog(true)
        }}
      />
      <AddHoliday
        isSubmitting={submitting}
        editDay={editHoliday}
        open={openAddHolidayDialog}
        onClose={() => setOpenAddHolidayDialog(false)}
        onSubmit={(values, actions) => handleSubmitHoliday(values, actions)}
      />
    </>
  );
};
export default HolidayList;
