import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { borders } from "@material-ui/system";
import { makeStyles, Typography } from "@material-ui/core";
import { APP_URL } from "../../constants";
import { useSelector } from "react-redux";
import { getTranslation } from "../../heplers/translationHelper";
import { useParams } from "react-router";
const useStyles = makeStyles((theme) => ({
  menuItem: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "2px " + theme.palette.common.white + " solid",
    "&:hover": {
      borderBottom: "2px " + theme.palette.primary.dark + " solid",
    },
  },
  selected: {
    borderBottom: "2px " + theme.palette.primary.dark + " solid",
    backgroundColor: theme.palette.common.white,
  },
}));

function SubMenus(props) {
  const [hideTopMenu, setHideTopMenu] = useState(true);

  const [submenu, setSubmenu] = useState();
  const [selectedMenu, setSelectedMenu] = useState("");
  let location = useLocation();
  const { menu } = useSelector((state) => state.submenues);

  // useEffect(() => {
  //   // let match = location.pathname.startsWith('/clients');
  //   // setSubmenu(clientSubmenu);
  //   // match ? setHideTopMenu(false) : setHideTopMenu(true);
  //   if (location.pathname.startsWith("/companies/")) {
  //     setSubmenu(companySubmenu);
  //     setHideTopMenu(false);
  //     // match ? setHideTopMenu(false) : setHideTopMenu(true);
  //   } else if (location.pathname.startsWith("/assets/")) {
  //     setSubmenu(assetSubmenu);
  //     setHideTopMenu(false);
  //     // match ? setHideTopMenu(false) : setHideTopMenu(true);
  //   } else if (location.pathname.startsWith("/funds/")) {
  //     setSubmenu(fundSubmenu);
  //     setHideTopMenu(false);
  //     // match ? setHideTopMenu(false) : setHideTopMenu(true);
  //   } else if (location.pathname.startsWith("/managementCompanies/")) {
  //     setSubmenu(managementCompanySubmenu);
  //     setHideTopMenu(false);
  //     // match ? setHideTopMenu(false) : setHideTopMenu(true);
  //   } else if (location.pathname.startsWith("/meetings")) {
  //     setSubmenu(meetingsSubmenu(managementCompanyId));
  //     setHideTopMenu(false);
  //   } else if (location.pathname.startsWith("/settings/")) {
  //     setSubmenu(settingSubmenu);
  //     setHideTopMenu(false);
  //     // match ? setHideTopMenu(false) : setHideTopMenu(true);
  //   } else {
  //     setHideTopMenu(true);
  //   }
  // }, [location]);

  useEffect(()=>{
    if(menu && menu.length>0)
    {
      console.log("jkagsashdjahsdj",menu)
      setSubmenu(menu);
      setHideTopMenu(false);
    }else{
      setSubmenu(null);
      setHideTopMenu(true);
    }
  },[menu])
  return (
    <>
      {hideTopMenu === false ? (
        <List style={flexContainer}>
          {submenu.map((item) => (
            <ListItemLink
              color="inherit"
              to={item.link}
              primary={item.title}
              selected={location.pathname.endsWith(item.link)}
              key={item.key}
            />
          ))}
        </List>
      ) : (
        <Typography color="secondary" variant="h5">
          {getTranslation("Welcome", "Bienvenue", "Willkommen")}
        </Typography>
      )}
    </>
  );
}

const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
};

function ListItemLink(props) {
  const { primary, to, selected } = props;
  const classes = useStyles();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        selected={selected}
        classes={{ selected: classes.selected, className: classes.menuItem }}
      >
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export default SubMenus;

//  Company Sub menus

const companySubmenu = [
  { key: "companyBasic", title: "Basics", link: "basic" },
  { key: "companyBoard", title: "Board", link: "board" },
  { key: "companyManagers", title: "Managers", link: "managers" },
  { key: "companyShareholders", title: "Shareholders", link: "shareholders" },
];

//  Asset Sub menus

// const assetSubmenu = [
//   { key: "assetBasic", title: "Basics", link: "basic" },
//   { key: "assetBoard", title: "Board", link: "board" },
//   { key: "assetManagers", title: "Managers", link: "managers" },
//   { key: "assetShareholders", title: "Shareholders", link: "shareholders" },
//   {
//     key: "assetNetAssetValue",
//     title: "Net Asset Value",
//     link: "netassetvalue",
//   },
//   { key: "assetOperations", title: "Operations", link: "operations" },
//   { key: "assetFeeAgreement", title: "Fee Agreement", link: "feeAgreement" },
// ];

//  Funds Sub menus

// const fundSubmenu = [
//   { key: "fundBasic", title: "Basics", link: "basic" },
//   { key: "fundBoard", title: "Board", link: "board" },
//   { key: "fundManagers", title: "Manager", link: "managers" },
//   { key: "fundShareholders", title: "Shareholders", link: "shareholders" },
//   { key: "fundNetAssetValue", title: "Net Asset Value", link: "netassetvalue" },
//   { key: "fundOperations", title: "Operations", link: "operations" },
//   { key: "fundFeeAgreement", title: "Fee Agreement", link: "feeAgreement" },
// ];

//  Management Company Sub menus

const managementCompanySubmenu = [
  { key: "managementCompanyBasic", title: "Basics", link: "basic" },
  { key: "managementCompanyBoard", title: "Board", link: "board" },
  { key: "managementCompanyManagers", title: "Managers", link: "managers" },
  {
    key: "managementCompanyShareholders",
    title: "Shareholders",
    link: "shareholders",
  },
  {
    key: "managementCompanyFeeAgreement",
    title: "Fee Agreement",
    link: "feeAgreement",
  },
  { key: "managementCompanyFunds", title: "Funds", link: "funds" },
  { key: "managementCompanyAssets", title: "Assets", link: "assets" },
];

//  Meetings Sub menus

// const meetingsSubmenu = (managementCompanyId) => managementCompanyId ? [
//   {
//     key: "regularMeetings",
//     title: "Regular",
//     link: `/meetings/${managementCompanyId}/regular`,
//   },
//   {
//     key: "adhocMeetings",
//     title: "Adhoc",
//     link: `/meetings/${managementCompanyId}/adhoc`,
//   },
//   {
//     key: "circularResolution",
//     title: "Circular Resolution",
//     link: `/meetings/${managementCompanyId}/circularResolution`,
//   },
// ]:[
//   {
//     key: "spv",
//     title: "SPV",
//     link: "/meetings",
//   },
//   {
//     key: "autoGenerated",
//     title: "Aut Generated Meetings",
//     link: "/meetings/autogenerated",
//   },
// ];

//  Settings Sub menus

const settingSubmenu = [
  { key: "general", title: "General", link: "general" },
  { key: "agendaTemplate", title: "Agenda", link: "agendatemplate" },
  { key: "circularResolution", title: "CR", link: "crtemplates" },
];

// Meetings 2.1 Submenu SPV and Autogenerated meetings


