import SearchBar from "material-ui-search-bar";
import { HtmlEditor } from "../../../../components/HtmlEditor";
import { parseMinutesReportHelper } from "../../../../heplers/parseMinutesReportHelper";
import { useState, useRef } from "react";
import { useEffect } from "react";

export const ItemEditor = ({
	selectedAgenda,
	updateAgenda,
	openTodoDialog,
	disabled,
}) => {
	const [html, setHtml] = useState("");
	const [renderingHtml, setRenderingHtml] = useState(false);
	
	const editorRef = useRef(null);
	useEffect(() => {
		if (selectedAgenda?.comments){
			setRenderingHtml(true)
			parseMinutesReportHelper(null, selectedAgenda?.comments, (newHtml) => {
				setHtml(newHtml);
				setRenderingHtml(false)
			});}
		else setHtml("");
	}, [selectedAgenda]);
	return (
		<>
			{/* <SearchBar /> */}
			{/* <br /> */}
			<HtmlEditor
				ref={editorRef}
				disabled={typeof selectedAgenda?.comments == "undefined" || disabled}
				isLoading={renderingHtml}
				text={html}
				onChange={(text) => updateAgenda({ ...selectedAgenda, comments: text })}
				onClickEvent={(e) =>
					e.target.getAttribute("data-edit-todolist-id")
						? openTodoDialog(e.target.getAttribute("data-edit-todolist-id"))
						: null
				}
			/>
		</>
	);
};
