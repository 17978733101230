import CardComponent from "../../../../components/Card";
import {
	Box,
	CardActionArea,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from "@material-ui/core";
import { RenderAgendaItem } from "./renderAgendaItem";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import React, { useEffect, useState } from "react";
import { GenerateQRCode } from "../../../../heplers/generateQR";
import { minuteReportByFundManagerType } from "../../../scanner/scannerTypes";
import { getTranslation } from "../../../../heplers/translationHelper";
import { Logo, LogoAlternate } from "../../../../components/Logo";
import { format } from "../../../../heplers/format";

export const ReviewAgenda = React.forwardRef(({ agendas,meetingId,contactId,isMember,minuteReportStatus,companyInfo,meetingInfo,attendants,guests,preview }, ref) => {
	const [QrImage, setQRImage] = useState();

    useEffect(() => {
      if (meetingId && contactId)
        GenerateQRCode({
          type: minuteReportByFundManagerType,
          value: {
            meetingId: meetingId,
            contactId: contactId,
			isMember:isMember,
            minuteReportStatus: minuteReportStatus,
			
          },
        })
          .then((resp) => {
            setQRImage(resp);
          })
          .catch((error) => {});
    }, []);
	return (
    <div
      ref={ref}
      height="313mm"
      style={{
        background: "#FFFF",
        backgroundPosition: "center",
        height: "100%",
        padding: "30px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box width="100%" display="flex" justifyContent="center">
        <div>
          {/* <Logo /> */}

          <Typography
            style={{
              // fontFamily: "Sacramento",
              fontSize: "24px",
              fontStyle: "normal",
              textAlign: "center",
            }}
          >
            {getTranslation("Minute Report", "Minute Report", "Minute Report")}
          </Typography>
        </div>
      </Box>
      <br />
      {preview ? null : (<>
        <CardComponent>
          <CardHeader
            title={getTranslation(
              "Company Information",
              "Company Information",
              "Company Information"
            )}
          />
          <CardContent>
            <table width="100%">
              <tr>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Company Name:",
                      "Company Name:",
                      "Company Name:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.companyName}
                  </Typography>
                </td>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Company Reference Code:",
                      "Company Reference Code:",
                      "Company Reference Code:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.referenceCode}
                  </Typography>
                </td>
              
              </tr>

              <tr>
              <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Investor Relation:",
                      "Investor Relation:",
                      "Investor Relation:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.investorRelationName}
                  </Typography>
                </td>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "RCS Number:",
                      "RCS Number:",
                      "RCS Number:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.rcsNumber}
                  </Typography>
                </td>
               
              </tr>
              <tr>
              <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "InCorporation Date:",
                      "InCorporation Date:",
                      "InCorporation Date:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {format("date", companyInfo?.basic?.incorporationDate)}
                  </Typography>
                </td>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("Street:", "Street:", "Street:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.street}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("City:", "City:", "City:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.city}
                  </Typography>
                </td>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("Zipcode:", "Zipcode:", "Zipcode:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.zipCode}
                  </Typography>
                </td>
               
              </tr>
              <tr>
              <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("Country:", "Country:", "Country:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.country}
                  </Typography>
                </td>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("RBE:", "RBE:", "RBE:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.rbe}
                  </Typography>
                </td>
               
              </tr>
              <tr>
              <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("Group:", "Group:", "Group:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.companyGroup?.title}
                  </Typography>
                </td>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Financial year Ends:",
                      "Financial year Ends:",
                      "Financial year Ends:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.finnancialYearEnds}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Legal Form:",
                      "Legal Form:",
                      "	Legal Form:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                    {companyInfo?.basic?.basicLegalForm?.legalForm}
                  </Typography>
                </td>
              </tr>
            </table>
          </CardContent>
        </CardComponent>

        <CardComponent>
        <CardHeader title={getTranslation("Meeting Information","Informations sur la réunion","Meeting-Informationen")} />

          <CardContent>
            <table width="100%">
              <tr>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Subject:",
                      "Subject:",
                      "Subject:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                  {meetingInfo?.subject}
                  </Typography>
                </td>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Type of Meeting:",
                      "Type of Meeting:",
                      "Type of Meeting:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                  {meetingInfo?.meetingTypeText}
                  </Typography>
                </td>
              
              </tr>

              <tr>
              <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Date/Time:",
                      "Date/Time:",
                      "Date/Time:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                  {`${format("date", meetingInfo?.meetingDate)}  ${format(
                  "time",
                  meetingInfo?.meetingTime
                )}`}
                  </Typography>
                </td>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Language:",
                      "Language:",
                      "Language:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                  {meetingInfo?.language}
                  </Typography>
                </td>
               
              </tr>
              <tr>
              <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation(
                      "Incharge:",
                      "Incharge:",
                      "Incharge:"
                    )}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                  {meetingInfo?.personInchargeName}
                  </Typography>
                </td>
                <td  width="50%">
                  <Typography variant="subtitle2" display="inline">
                    {getTranslation("Address:", "Address:", "Address:")}
                  </Typography>
                  <Typography variant="overline" style={{ fontWeight: "bold" }}>
                  {meetingInfo?.address}
                  </Typography>
                </td>
              </tr>

            </table>
          </CardContent>
        </CardComponent>
        <CardComponent>
        <CardHeader title={getTranslation("Attendants","Attendants","Attendants")} />

          <CardContent>
            <table width="100%" style={{borderCollapse:'collapse'}}>
              <thead>
                <tr style={{borderCollapse:'collapse',backgroundColor:"rgba(240, 240, 240, 0.8)"}}>
                  <th align="left" style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}>{getTranslation("Name","Name","Name")}</th>
                  <th align="left" style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}>{getTranslation("Mail","Mail","Mail")}</th>
                  <th align="left" style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}>{getTranslation("Phone","Phone","Phone")}</th>
                  <th align="left" style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}>{getTranslation("Join Status","Join Status","Join Status")}</th>
                  <th align="left" style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}>{getTranslation("Is Signed?","Is Signed?","Is Signed?")}</th>
                </tr>
              </thead>
              <tbody>
                {attendants?.map(attendant=><tr>
                  <td style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}><Typography>{attendant?.fullName}</Typography></td>
                  <td style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}><Typography>{attendant?.mail}</Typography></td>
                  <td style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}><Typography>{attendant?.phone1}</Typography></td>
                  <td style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}><Typography>{attendant?.representativeStatusText}</Typography></td>
                  <td style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}><Typography>{attendant?.isSigned ? "Signed" : attendant?.isNeededSignature==false ? "Not Required" : "Not Signed"}</Typography></td>
                </tr>)}
              </tbody>
            
            </table>
          </CardContent>
        </CardComponent>
        <CardComponent>
        <CardHeader title={getTranslation("Guests","Guests","Guests")} />

          <CardContent>
            <table width="100%" style={{borderCollapse:'collapse'}}>
              <thead>
                <tr style={{borderCollapse:'collapse',backgroundColor:"rgba(240, 240, 240, 0.8)"}}>
                  <th align="left" style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}>{getTranslation("Name","Name","Name")}</th>
                  <th align="left" style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}>{getTranslation("Mail","Mail","Mail")}</th>
                  <th align="left" style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}>{getTranslation("Phone","Phone","Phone")}</th>
                  {/* <th align="left" style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}>{getTranslation("Join Status","Join Status","Join Status")}</th> */}
                </tr>
              </thead>
              <tbody>
                {guests?.map(attendant=><tr>
                  <td style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}><Typography>{attendant?.fullName}</Typography></td>
                  <td style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}><Typography>{attendant?.mail}</Typography></td>
                  <td style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}><Typography>{attendant?.phone1}</Typography></td>
                  {/* <td style={{border: "1px solid rgba(0, 0, 0, 0.2)", padding: '6px', textAlign: 'center', fontWeight: 'bold', borderCollapse: 'collapse'}}><Typography>{attendant?.representativeStatusText}</Typography></td> */}
                </tr>)}
              </tbody>
            
            </table>
          </CardContent>
        </CardComponent>
        </>)}
      <br />

      <CardComponent>
        <CardHeader title={getTranslation("Agenda's","Agenda's","Agenda's")} />

          <CardContent>
          {agendas?.map((agenda, index) => (
          <>
            <Typography >{`${
              index + 1
            }. ${agenda?.subject}`}</Typography>
            
          </>
        ))}
          </CardContent>
        </CardComponent>

      <br/>
      <div>
        {agendas?.map((agenda, index) => (
          <>
            <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>{`${
              index + 1
            }. ${agenda?.subject}`}</Typography>
            <RenderAgendaItem agendaItem={agenda} />
          </>
        ))}
      </div>
      {preview ? null : (
        <table width="100%" style={{ marginTop: "100px" }}>
          <tr>
            <td></td>
            <td align="right">
              <Typography style={{ fontSize: "10px" }}>
                This QR code is system generated and is only for Ceres employees
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography>
                _________________
                <h4>&emsp;Sign.</h4>
              </Typography>
              <Typography style={{ fontSize: "9px" }}>
                Please Sign the document and upload.
              </Typography>
            </td>
            <td align="right">
              {QrImage ? (
                <img
                  src={QrImage}
                  alt="Image"
                  style={{ width: "100px", height: "100px" }}
                />
              ) : null}
            </td>
          </tr>
        </table>
      )}
    </div>
  );
});
