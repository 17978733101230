import { Add_Public_ActionButtons } from "../constants/types";

const initState = {
  menu:[]
};


const publicActionButtonsReducer = (state = initState, action) => {
  switch (action.type) {
    case Add_Public_ActionButtons:
      state.menu=action.payload
      return {
        ...state
      };
      
    default:
      return { ...state };
  }
};

export default publicActionButtonsReducer;
