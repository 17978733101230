import React, { useState, useEffect } from "react";
import {
	Typography
  } from "@material-ui/core";
import Table from "../../../../components/table";
import { Get, Create } from "../../../../actions";
import { useSnackbar } from "notistack";
import {
	Get_AllMeetingMembers_URL,
	Get_RegularMeetingMCBoardMembers_URL,
	Get_RegularMeetingMCShareholders_URL,
	Post_InsertNewRegularMeetingMember_URL,
	Post_RemoveRegularMeetingMember_URL,
} from "../../../../constants/apiUrls";
import { getTranslation } from "../../../../heplers/translationHelper";

export const BoardMembers = ({
	MCId,
	selectedMembers,
	meetingType,
	title,
	onSelectionChange,
	meetingId,
	onMembersLoaded,
}) => {
	console.log("mcmc", MCId);
	console.log("selected", selectedMembers);
	const columns = [
    {
      id: "fullName",
      numeric: false,
      label: getTranslation("Name", "Nom", "Name"),
    },
    {
      id: "representativeRole",
      numeric: false,
      label: getTranslation("Role", "Rôle", "Rolle"),
    },
    {
      id: "companyName",
      numeric: false,
      label: getTranslation("Company", "Compagnie", "Gesellschaft"),
    },
    {
      id: "mail",
      numeric: false,
      label: getTranslation("Email", "E-mail", "Email"),
    },
    {
      id: "representativeStatusText",
      numeric: false,
      label: getTranslation(
        "Presence Type",
        "Type de présence",
        "Anwesenheitstyp"
      ),
      component: ({ row }) => {
		console.log("kjasdlhkajshsad",row)
        const item = tempSelectedMembers?.find((x) => x.id == row.id);
        if (item)
          return <Typography>{item.representativeStatusText}</Typography>;
		  return "";
      },
    },
  ];

	const [members, setMembers] = useState([]);
	const [tempSelectedMembers, setTempSelectedMembers] = useState([]);
	const [page, setPage] = useState(0);
	let GmRegularMeetingBoardMember = localStorage.getItem(
		"GmRegularMeetingBoardMember"
	);
	if (GmRegularMeetingBoardMember == null) {
		localStorage.setItem("GmRegularMeetingBoardMember", 10);
		GmRegularMeetingBoardMember = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(GmRegularMeetingBoardMember);
	const [searchString, setSearchString] = useState("");
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (meetingId) {
			Get(
				{ meetingId },
				Get_AllMeetingMembers_URL,
				null,
				(resp) => {
					onMembersLoaded(resp.data);
					setTempSelectedMembers(resp.data);
				},
				(error) => {}
			);
		}
	}, [meetingId]);

	useEffect(() => {
		loadMeetingTypeMembers({ MCId: MCId });
	}, [meetingType]);

	const loadMeetingTypeMembers = (values) => {
		Get(
			values,
			meetingType === 0 || meetingType === 2
				? Get_RegularMeetingMCBoardMembers_URL
				: Get_RegularMeetingMCShareholders_URL,
			null,
			(resp) => {
				const arr = [];
				resp.data.forEach((item) => {
					item.memberId = item.id;
					arr.push(item);
				});
				setMembers(arr);
			},
			(error) => {
				console.log("Error loading meeting type members");
			}
		);
	};

	const handleOnSelectionChange = (items) => {
		if (onSelectionChange) onSelectionChange(items);
	};

	const handleNewItem = async (item) => {
		if (meetingId) {
			Create(
				{ memberId: item.id, meetingId: meetingId },
				Post_InsertNewRegularMeetingMember_URL,
				null,
				(resp) => {
					enqueueSnackbar("Member has been added.", { variant: "success" });
					setTempSelectedMembers([...tempSelectedMembers, item]);
				},
				(error) => {
					enqueueSnackbar(error?.data, { variant: "error" });
				}
			);
		}
	};

	const handleRemoveItem = async (item) => {
		if (meetingId) {
			Create(
				{
					contactId: item.id,
					meetingId: meetingId,
				},
				Post_RemoveRegularMeetingMember_URL,
				null,
				(resp) => {
					enqueueSnackbar("Member is removed.", { variant: "success" });

					setTempSelectedMembers(
						tempSelectedMembers.filter((x) => x.id !== item.id)
					);
				},
				(error) => {
					enqueueSnackbar(error?.data, { variant: "error" });
				}
			);
		}
	};

	const handleChangePage = (newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e) => {
		localStorage.setItem("GmRegularMeetingBoardMember", e.target.value);
		setRowsPerPage(parseInt(e.target.value));
		setPage(0);
	};

	const handleSearchMember = (searchString) => {
		setSearchString(searchString);
	};

	console.log("jhsgfgsdfdsf", members);
	return (
		<>
			<Table
				size="small"
				dense
				title={title}
				colums={columns}
				sortBy="fullName"
				rows={members}
				count={members.length}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewSearch
				onSearch={(searchString) => {
					handleSearchMember(searchString);
				}}
				enableCheckbox
				selected={selectedMembers ? selectedMembers : tempSelectedMembers}
				onSelectionChange={(items) => handleOnSelectionChange(items)}
				onSelectionChecked={handleNewItem}
				onSelectionUnChecked={handleRemoveItem}
			/>
		</>
	);
};
