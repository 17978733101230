import React, { useState, useEffect } from "react";
import {
	CardContent,
	CardActions,
	Grid,
	TextField,
	Box,
	Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../components/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/table";
import { Get, Create } from "../../actions";
import {
	GET_AssetNetAssetValue_URL,
	UPDATE_NetAssetValue_URL,
	Post_CreateNetAssetValue_URL,
	GET_FundNetAssetValue_URL,
} from "../../constants/apiUrls";
import { Line } from "react-chartjs-2";
import { getTranslation } from "../../heplers/translationHelper";
import {
	AssetSubmenu,
	FundSubmenu,
	ManagementCompaniesSubmenu,
} from "../../layout/subMenu";

const columns = [
	{
		id: "firstCapitalCall",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Date", "Date", "Datum"),
	},
	{
		id: "nav",
		numeric: false,
		disablePadding: true,
		label: "NAV",
	},
	{
		id: "navExhausted",
		numeric: false,
		disablePadding: true,
		label: getTranslation(
			"NAV inkl Auschüttung",
			"NAV inkl Auschüttung",
			"NAV inkl. Auschüttung"
		),
	},
];

const NetAssetValue = ({ onSubmit }) => {
	const { enqueueSnackbar } = useSnackbar();
	const history = useNavigate();
	const dispatch = useDispatch();
	const [netAssetValue, setNetAssetValue] = useState({});
	const [loadingNetAssetValue, setLoadingNetAssetValue] = useState(false);
	const { assetId, fundId, companyId } = useParams();
	const [page, setPage] = useState(null);
	let spvNetAssetValue = localStorage.getItem("spvNetAssetValue");
	if (spvNetAssetValue == null) {
		localStorage.setItem("spvNetAssetValue", 10);
		spvNetAssetValue = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvNetAssetValue);

	useEffect(() => {
		loadNetAssetValue();
	}, []);
	const loadNetAssetValue = async () => {
		setLoadingNetAssetValue(true);
		let id = 0;
		let url = "";
		if (assetId) {
			id = assetId;
			url = GET_AssetNetAssetValue_URL;
		} else if (fundId) {
			id = fundId;
			url = GET_FundNetAssetValue_URL;
		}
		await Get(
			{ id: id },
			url,
			history,
			(resp) => {
				setNetAssetValue(resp?.data);
				setLoadingNetAssetValue(false);
			},
			(error) => {}
		);
	};

	const handleSubmitTitle = async (values, actions) => {
		Create(
			values,
			UPDATE_NetAssetValue_URL,
			history,
			(resp) => {
				actions.setSubmitting(false);
				enqueueSnackbar("Updated Successfully", { variant: "success" });
			},
			(error) => {
				enqueueSnackbar("Something Went Wrong", { variant: "error" });
			}
		);
	};

	const handleCreateNewNetAssetValueItem = async (values, actions) => {
		Create(
			values,
			Post_CreateNetAssetValue_URL,
			history,
			(resp) => {
				actions.setSubmitting(false);
				loadNetAssetValue();
			},
			(error) => {}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvNetAssetValue", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(1);
	};

	const chartData = {
		labels: ["1", "2", "3", "4", "5", "6"],
		datasets: [
			{
				label: getTranslation("Date", "Date", "Datum"),
				data: netAssetValue?.netAssetValueList?.map((item) => {
					return item.firstCapitalCall;
				}),
				fill: false,
				backgroundColor: "rgb(51, 204, 255)",
				borderColor: "rgba(51, 204, 255, 0.2)",
				yAxisID: "y-axis-1",
			},
			{
				label: "NAV",
				data: netAssetValue?.netAssetValueList?.map((item) => {
					return item.nav;
				}),
				fill: false,
				backgroundColor: "rgb(237, 125, 49)",
				borderColor: "rgba(237, 125, 49, 0.2)",
				yAxisID: "y-axis-1",
			},
			{
				label: getTranslation(
					"NAV inkl Auschüttung",
					"NAV inkl Auschüttung",
					"NAV inkl Auschüttung"
				),
				data: netAssetValue?.netAssetValueList?.map((item) => {
					return item.navExhausted;
				}),
				fill: false,
				backgroundColor: "rgb(165, 165, 165)",
				borderColor: "rgba(165, 165, 165, 0.2)",
				yAxisID: "y-axis-1",
			},
		],
	};

	const options = {
		scales: {
			y: {
				display: false,
				id: "y-axis-1",
			},
		},
	};

	const initialValues = {
		id: 0,
		name: "",
	};

	const basicValidationSchema = Yup.object().shape({
		name: Yup.string().required("Net value title is required"),
	});
	const defaultValue =
		loadingNetAssetValue === false ? netAssetValue : initialValues;

	const initialNetAssetValueItemValues = {
		netAssetValueId: netAssetValue.id,
		firstCapitalCall: "",
		nav: 0,
		navExhausted: 0,
	};
	const NetAssetValueItemValidationSchema = Yup.object().shape({
		firstCapitalCall: Yup.string().required("Capital Call is required"),
		nav: Yup.number().required("NAV is required"),
		navExhausted: Yup.number().required("NAV Exhausted is required"),
	});
	const defaultNetAssetValueItem = initialNetAssetValueItemValues;
	return (
		<>
			{assetId ? (
				<AssetSubmenu assetId={assetId} companyId={companyId} />
			) : fundId ? (
				<FundSubmenu fundId={fundId} companyId={companyId} />
			) : (
				<ManagementCompaniesSubmenu />
			)}
			<Card>
				{/* <CardHeader title='Details' /> */}
				<Formik
					enableReinitialize
					initialValues={defaultValue}
					validationSchema={basicValidationSchema}
					onSubmit={(values, actions) => {
						actions.setSubmitting(true);
						handleSubmitTitle(values, actions);
						actions.resetForm();
						// dispatch(userLogin(values)).then((res) => {
						//   const errors = checkResponse(res);
						//   if (!errors) {
						//     authUtils.setSession(res.data.accessToken);
						//     authUtils.setUserRefreshToken(res.data.refreshToken);
						//     dispatch(setUserData(res.data.user));
						//     history("/dashboard");
						//     enqueueSnackbar("Login Successfully", { variant: "success" });

						//     actions.setSubmitting(false);
						//   } else {
						//     actions.setSubmitting(false);
						//     enqueueSnackbar("Please check your Email or Password", {
						//       variant: "error",
						//     });
						//   }
						// });
					}}
				>
					{({
						errors,
						touched,
						values,
						handleSubmit,
						isSubmitting,
						getFieldProps,
						setFieldValue,
					}) => (
						<form>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={4} lg={4}>
										<TextField
											fullWidth
											label={getTranslation("Title", "Titre", "Titel")}
											required
											size="small"
											variant="outlined"
											name="name"
											{...getFieldProps("name")}
											error={touched.title && Boolean(errors.title)}
											helperText={touched.title && errors.title}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
								</Grid>
							</CardContent>
							<CardActions>
								<Box
									pr={1}
									pb={1}
									width="100%"
									display="flex"
									alignItems="center"
									justifyContent="flex-end"
								>
									<ButtonWithLoading
										title={getTranslation(
											"Update",
											"Mettre à jour",
											"Aktualisieren"
										)}
										size="small"
										variant="contained"
										color="primary"
										loading={isSubmitting}
										onClick={handleSubmit}
									/>
								</Box>
							</CardActions>
						</form>
					)}
				</Formik>
			</Card>

			<Card>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Formik
							enableReinitialize
							initialValues={defaultNetAssetValueItem}
							validationSchema={NetAssetValueItemValidationSchema}
							onSubmit={(values, actions) => {
								actions.setSubmitting(true);
								handleCreateNewNetAssetValueItem(values, actions);
								actions.resetForm();
								// dispatch(userLogin(values)).then((res) => {
								//   const errors = checkResponse(res);
								//   if (!errors) {
								//     authUtils.setSession(res.data.accessToken);
								//     authUtils.setUserRefreshToken(res.data.refreshToken);
								//     dispatch(setUserData(res.data.user));
								//     history("/dashboard");
								//     enqueueSnackbar("Login Successfully", { variant: "success" });

								//     actions.setSubmitting(false);
								//   } else {
								//     actions.setSubmitting(false);
								//     enqueueSnackbar("Please check your Email or Password", {
								//       variant: "error",
								//     });
								//   }
								// });
							}}
						>
							{({
								errors,
								touched,
								values,
								handleSubmit,
								isSubmitting,
								getFieldProps,
								setFieldValue,
							}) => (
								<form>
									<CardContent>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={4} lg={4}>
												<TextField
													fullWidth
													label={getTranslation(
														"First Capital Call",
														"Premier appel de capitaux",
														"Erster Kapitalaufruf"
													)}
													required
													size="small"
													variant="outlined"
													name="firstCapitalCall"
													{...getFieldProps("firstCapitalCall")}
													error={
														touched.firstCapitalCall &&
														Boolean(errors.firstCapitalCall)
													}
													helperText={
														touched.firstCapitalCall && errors.firstCapitalCall
													}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={12} md={4} lg={4}>
												<TextField
													fullWidth
													label="NAV"
													required
													size="small"
													variant="outlined"
													name="nav"
													{...getFieldProps("nav")}
													error={touched.nav && Boolean(errors.nav)}
													helperText={touched.nav && errors.nav}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>

											<Grid item xs={12} sm={12} md={4} lg={4}>
												<TextField
													fullWidth
													label={getTranslation(
														"NAV inkl Auschüttung",
														"NAV inkl Auschüttung",
														"NAV inkl Auschüttung"
													)}
													required
													size="small"
													variant="outlined"
													name="navExhausted"
													{...getFieldProps("navExhausted")}
													error={
														touched.navExhausted && Boolean(errors.navExhausted)
													}
													helperText={
														touched.navExhausted && errors.navExhausted
													}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
										</Grid>
									</CardContent>
									<CardActions>
										<Box
											pr={1}
											pb={1}
											width="100%"
											display="flex"
											alignItems="center"
											justifyContent="flex-end"
										>
											<ButtonWithLoading
												title={getTranslation("Insert", "Insérer", "Einfügung")}
												size="small"
												variant="contained"
												color="primary"
												loading={isSubmitting}
												onClick={handleSubmit}
											/>
										</Box>
									</CardActions>
								</form>
							)}
						</Formik>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Table
							dense
							title={getTranslation(
								"Net Asset values",
								"Valeurs nettes d'inventaire",
								"Nettoinventarwerte"
							)}
							colums={columns}
							loading={loadingNetAssetValue}
							// rows={activities?.data}
							// count={activities?.totalCount}
							sortBy="company"
							rows={netAssetValue?.netAssetValueList}
							count={netAssetValue?.netAssetValueList?.length}
							page={page}
							rowsPerPage={rowsPerPage}
							handleChangePage={(event, newPage) =>
								handleChangePage(event, newPage)
							}
							handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Line data={chartData} options={options} />
					</Grid>
				</Grid>
				{/* <CardHeader title='Details' /> */}
			</Card>
		</>
	);
};

export default NetAssetValue;
