import React, { useState, useEffect } from "react";
import { Box, Paper, TextField, Typography } from "@material-ui/core";
import Table from "../../components/table";
// import {
//   getActivities,
//   deleteActivity
// } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import checkResponse from "../../heplers/responseHelper";
import DeleteDialog from "../../components/DeleteDialog";
import { Get, Create } from "../../actions";
import {
	Get_AllFundsWithPagination_URL,
	Post_DeleteFund_URL,
	Post_CreateFund_URL,
} from "../../constants/apiUrls";

import { CreateFund } from "./component";
import { create } from "yup/lib/Reference";
import { getTranslation } from "../../heplers/translationHelper";

const columns = [
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Title", "Titre", "Titel"),
	},
	{
		id: "investorRelationName",
		numeric: false,
		disablePadding: true,
		list: true,
		label: getTranslation(
			"Investors Relation",
			"Relation Investisseurs",
			"Investors-Relation"
		),
		component: ({ row }) => {
			return (
				<Typography>{row?.company?.basic?.investorRelationName}</Typography>
			);
		},
	},

	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const Funds = [
	{
		id: "001",
		title: "Company",
		investerRelation: "Asghar",
		manager: "Khan",
	},
];

const ListFunds = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	let companysFundsList = localStorage.getItem("companysFundsList");
	if (companysFundsList == null) {
		localStorage.setItem("companysFundsList", 10);
		companysFundsList = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(companysFundsList);
	const [rows, setRows] = useState([]);
	const [loadingFunds, setloadingFunds] = useState(false);

	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: "Funds", url: "/funds" },
		});
	}, []);

	useEffect(() => {
		loadFunds({
			searchString: searchString,
			pageNumber: page,
			pageSize: rowsPerPage,
		});
	}, [page, rowsPerPage, searchString]);

	const loadFunds = () => {
		setloadingFunds(true);
		Get(
			{
				searchString: searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_AllFundsWithPagination_URL,
			null,
			(resp) => {
				setRows(resp.data);
				setloadingFunds(false);
			},
			(error) => {}
		);
	};

	// const { activities } = useSelector((state) => state.activities);
	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleDeleteActivity = (row) => {
		const deleteArray = [];
		deleteArray.push(row.id);
		Create(
			{ ids: deleteArray },
			Post_DeleteFund_URL,
			history,
			(resp) => {
				setOpen(false);
				enqueueSnackbar(
					getTranslation("Fund Deleted", "Fonds supprimé", "Fonds gelöscht"),
					{
						variant: "success",
					}
				);
				loadFunds();
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Unable to delete fund because its being used by some company",
						"Impossible de supprimer le fonds car il est utilisé par une entreprise",
						"Guthaben kann nicht gelöscht werden, da es von einem Unternehmen verwendet wird"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("companysFundsList", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleDetailCompany = (row) => {
		const url = `/funds/${row.id}/${row.companyId}/basic`;
		history(url);
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: row.title, url: url },
		});
	};
	const handleSearchFund = (searchString) => {
		setSearchString(searchString);
	};
	const handleCreateCompany = (event) => {
		setCreateCompany(true);
	};
	const handleCreateCompanyClose = (event) => {
		setCreateCompany(false);
	};
	const handleCreateFund = async (values, actions) => {
		Create(
			values,
			Post_CreateFund_URL,
			history,
			(response) => {
				actions.setSubmitting(false);
				enqueueSnackbar(
					getTranslation("Fund Created", "Fonds créé", "Fonds erstellt"),
					{
						variant: "success",
					}
				);
				loadFunds();
			},
			(error) => {
				actions.setSubmitting(false);
				enqueueSnackbar("Something went wrong", {
					variant: "error",
				});
			}
		);
	};
	return (
		<div>
			<CreateFund onSubmit={handleCreateFund} />
			<Table
				dense
				loading={loadingFunds}
				title={getTranslation("Funds", "Fonds", "Mittel")}
				colums={columns}
				sortBy="title"
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => handleDetailCompany(row)}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				viewSearch
				onSearch={(searchString) => handleSearchFund(searchString)}
			/>
			<DeleteDialog
				open={open}
				title={getTranslation(
					"Delete Fund",
					"Supprimer le fonds",
					"Fonds löschen"
				)}
				onClose={() => setOpen(false)}
				onSubmit={(e) => handleDeleteActivity(deleteRow)}
			/>
		</div>
	);
};

export default ListFunds;
