import {
	Box,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	Autocomplete,
	ToggleButton,
	ToggleButtonGroup,
} from "@material-ui/lab";
import { Form, Formik } from "formik";
import { DatePicker } from "../../../../../../components/DatePicker";
import { MyTimePicker } from "../../../../../../components/TimePicker";
import { LanguageSelection } from "../../../../../../constants/languages";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { BoardMembers } from "../../boardMembers";
import * as Yup from "yup";
import { useEffect } from "react";
import { Create, Get } from "../../../../../../actions";
import {
	Get_AllManagementCompanyFeeServicesByType_URL,
	GET_AllUsersWithoutAuthorization_URL,
	Post_Meeting_UpsertRegularMeetingDraft,
} from "../../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { useState } from "react";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import { getDateIgnoreTimezone } from "../../../../../../heplers/dateHelper";
import moment from "moment";
import { useDispatch } from "react-redux";
const AddRegularMeetingInfo = ({
	managementCompanyId,
	NextStep,
	initialValues,
}) => {
	console.log("ini", initialValues);
	const { enqueueSnackbar } = useSnackbar();
	const [ceresServices, setCeresServices] = useState([]);
	const [userList, setUserList] = useState([]);
	const [nextButtonClicked, setNextButtonClicked] = useState(false);
	const dispatch = useDispatch();
	const [draftLoading, setDraftLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		Get(
			{},
			GET_AllUsersWithoutAuthorization_URL,
			null,
			(resp) => {
				setUserList(resp.data);
				setLoading(false);
			},
			(error) => {
				setLoading(false);
			}
		);
	}, []);
	useEffect(() => {
		loadCeresServices();
	}, [managementCompanyId]);

	const loadCeresServices = () => {
		setLoading(true);
		Get(
			{
				id: managementCompanyId,
				feeType: initialValues?.isAdhoc ? 0 : 1,
			},
			Get_AllManagementCompanyFeeServicesByType_URL,
			null,
			(resp) => {
				console.log("kjdhfdsf", resp);
				setCeresServices(resp.data);
				setLoading(false);
			},
			(error) => {
				enqueueSnackbar(error.data, { variant: "error" });
				setLoading(false);
			}
		);
	};

	// const initialValues = {
	//   subject: "",
	//   address: "",
	//   meetingTime: new Date(),
	//   meetingDuration: new Date(),
	//   meetingDate: new Date(),
	//   language: "",
	//   personInchargeId: "",
	//   meetingType: 0,
	//   meetingMembers: [],
	// };
	const basicValidationSchema = Yup.object().shape({
		subject: Yup.string().required("Field is required"),
		address: Yup.string().required("Field is required"),
		meetingTime: Yup.string().required("Field Time is requied"),
		meetingDate: Yup.string().required("Field is required"),
		language: Yup.string().required("Field is required"),
		periodicity: Yup.number()
			.min(10, "Minimum 10 days allowed.")
			.required("Field is required"),
		personInchargeId: Yup.string().required("Field is required"),
		companyCeresServiceId: Yup.number().min(1).required("Field is required"),
	});

	const setActiveState = (activeState) => ({
		type: "SET_ACTIVE_STATE",
		payload: {
			activeState,
		},
	});

	const setMeetingData = (meetingData) => ({
		type: "SET_MEETING_DATA",
		payload: {
			meetingData,
		},
	});

	const getCeresService = () =>
		ceresServices.length
			? ceresServices.find((x) => x.id == initialValues.companyCeresServiceId)
			: {};

	const getPersonIncharge = () =>
		userList.length
			? userList.find((x) => x.id == initialValues.personInchargeId)
			: {};
	return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={basicValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        if (values.meetingMembers.length == 0) {
          enqueueSnackbar(
            getTranslation(
              "At least 1 member should be selected.",
              "At least 1 member should be selected.",
              "At least 1 member should be selected."
            ),
            { variant: "error" }
          );
          setSubmitting(false);

          return;
        }
        // if (!values.meetingDate || isNaN(values.meetingDate.getTime())) {
        // 	enqueueSnackbar(
        // 		getTranslation(
        // 			"Please Provide a Valid Date Format",
        // 			"Please Provide a Valid Date Format",
        // 			"Please Provide a Valid Date Format"
        // 		),
        // 		{ variant: "error" }
        // 	);
        // 	return;
        // }
        // if (!values.meetingTime || isNaN(values.meetingTime.getTime())) {
        // 	enqueueSnackbar(
        // 		getTranslation(
        // 			"Please Provide a Valid Time Format",
        // 			"Please Provide a Valid Time Format",
        // 			"Please Provide a Valid Time Format"
        // 		),
        // 		{ variant: "error" }
        // 	);
        // 	return;
        // }
        //values.meetingDate = moment(values.meetingDate).format("MM-DD-YYYY");
        // values.meetingTime = moment(values.meetingTime).format(
        // 	"MM-DD-YYYY HH:mm"
        // );
        // values.meetingDuration = moment(values.meetingDuration).format(
        // 	"MM-DD-YYYY HH:mm"
        // );

        //neww
        if (!values.meetingDate) {
          enqueueSnackbar(
            getTranslation(
              "Please Provide a Valid Date Format",
              "Please Provide a Valid Date Format",
              "Please Provide a Valid Date Format"
            ),
            { variant: "error" }
          );
          setSubmitting(false);

          return;
        }
        if (!values.meetingTime) {
          enqueueSnackbar(
            getTranslation(
              "Please Provide a Valid Time Format",
              "Please Provide a Valid Time Format",
              "Please Provide a Valid Time Format"
            ),
            { variant: "error" }
          );
          setSubmitting(false);

          return;
        }
        let payloadObj = {
          meetingDraftVm: { ...values, activeStep: 1 },
        };
        console.log("guest draft", payloadObj);
        setDraftLoading(true);
        Create(
          payloadObj,
          Post_Meeting_UpsertRegularMeetingDraft,
          null,
          (resp) => {
            values.draftMeetingId = resp?.data;
            NextStep(values);
          },
          (error) => {
            setDraftLoading(false);
            enqueueSnackbar("Something Went Wrong", {
              variant: "error",
            });
          }
        );
      }}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue,
        handleChange,
      }) => (
        <Form>
          {console.log("balala", values)}
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            {/* Step 1 */}
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                size="small"
                id="meetingType"
                value={values.meetingType}
                exclusive
                onChange={(event, newAlignment) => {
                  if (newAlignment) {
                    setFieldValue("meetingType", newAlignment);
                    setFieldValue("meetingMembers", []);
                  } else {
                    setFieldValue("meetingType", 0);
                  }
                }}
              >
                <ToggleButton value={0} aria-label="left aligned">
                  <Typography>
                    {getTranslation("Board", "Planche", "Tafel")}
                  </Typography>
                </ToggleButton>
                <ToggleButton value={1} aria-label="centered">
                  <Typography>
                    {getTranslation("Shareholder", "Actionnaire", "Aktionär")}
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                options={ceresServices}
                getOptionLabel={(option) => option.ceresServiceTitle}
                size="small"
                value={
                  values.companyCeresService
                    ? values.companyCeresService
                    : getCeresService()
                }
                onChange={(e, value) => {
                  setFieldValue("companyCeresServiceId", value?.id);
                  setFieldValue("companyCeresService", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getTranslation(
                      "Ceres Service",
                      "Service Cérès",
                      "Ceres-Dienst"
                    )}
                    variant="outlined"
                    error={
                      touched.companyCeresServiceId &&
                      Boolean(errors.companyCeresServiceId)
                    }
					focused={Boolean(errors.companyCeresServiceId)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                fullWidth
                id="subject"
                label={getTranslation("Subject", "Sujette", "Fach")}
                required
                size="small"
                variant="outlined"
                name="subject"
                {...getFieldProps("subject")}
                error={touched.subject && Boolean(errors.subject)}
                helperText={touched.subject && errors.subject}
                InputLabelProps={{
                  shrink: true,
                }}
				focused={Boolean(errors.subject)}

              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                options={userList}
                getOptionLabel={(option) => option.name}
                size="small"
                value={
                  values.personIncharge
                    ? values.personIncharge
                    : getPersonIncharge()
                }
                onChange={(e, value) => {
                  setFieldValue("personInchargeId", value?.id);
                  setFieldValue("personIncharge", value);
                }}
                //value={values.personIncharge}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getTranslation(
                      "Person Incharge",
                      "Personne en charge",
                      "Verantwortlicher"
                    )}
                    variant="outlined"
                    error={
                      touched.personInchargeId &&
                      Boolean(errors.personInchargeId)
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <LanguageSelection
                fullWidth
                id="language"
                label={getTranslation("Language", "Langue", "Sprache")}
                required
                size="small"
                variant="outlined"
                name="language"
                selected={values.language}
                onChange={(e, language) => setFieldValue("language", language)}
                error={touched.language && Boolean(errors.language)}
                helperText={touched.language && errors.language}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {values?.isAdhoc ? null : (
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  fullWidth
                  id="periodicity"
                  label={getTranslation(
                    "Periodicity (Days)",
                    "Périodicité (jours)",
                    "Periodizität (Tage)"
                  )}
                  required
                  size="small"
                  variant="outlined"
                  name="periodicity"
                  type="number"
                  {...getFieldProps("periodicity")}
                  error={touched.periodicity && Boolean(errors.periodicity)}
                  helperText={touched.periodicity && errors.periodicity}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <DatePicker
                fullWidth
                variant="outlined"
                label={getTranslation(
                  "Meeting Date",
                  "Date de la réunion",
                  "Datum des Treffens"
                )}
                value={values.meetingDate}
                onChange={(value) => {
                  //setFieldValue("meetingDate", value);
                  const formattedDate = moment(value).format("MM-DD-YYYY");
                  setFieldValue("meetingDate", formattedDate);
                }}
                required
                error={touched.meetingDate && Boolean(errors.meetingDate)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <MyTimePicker
                fullWidth
                id="meetingTime"
                variant="inline"
                size="small"
                // id="Incorporation-Date-inline"
                label={getTranslation("Time", "Temps", "Zeit")}
                name="meetingTime"
                value={values.meetingTime}
                onChange={(value) => {
                  //setFieldValue("meetingTime", value);
                  const formatedTime = moment(value).format("MM-DD-YYYY HH:mm");
                  setFieldValue("meetingTime", formatedTime);
                }}
                KeyboardButtonProps={{
                  "aria-label": "Meeting Time",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                error={touched.meetingTime && Boolean(errors.meetingTime)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <MyTimePicker
                fullWidth
                id="meetingDuration"
                variant="inline"
                size="small"
                // id="Incorporation-Date-inline"
                label={getTranslation("Time", "Temps", "Zeit")}
                name="meetingDuration"
                value={values.meetingDuration}
                onChange={(value) => {
                  //setFieldValue("meetingTime", value);
                  const formatedTime = moment(value).format("MM-DD-YYYY HH:mm");
                  setFieldValue("meetingDuration", formatedTime);
                }}
                KeyboardButtonProps={{
                  "aria-label": "Meeting Time",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                error={touched.meetingTime && Boolean(errors.meetingTime)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                multiline
                label="Address"
                required
                size="small"
                variant="outlined"
                {...getFieldProps("address")}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {values.meetingType === 0 ? (
                <BoardMembers
                  title={getTranslation(
                    "Board Members",
                    "Membres du conseil d'administration",
                    "Vorstandsmitglieder"
                  )}
                  meetingType={0}
                  MCId={managementCompanyId}
                  onSelectionChange={(members) => {
                    setFieldValue("meetingMembers", members);
                    console.log("akjsdakshda", members);
                  }}
                  selectedMembers={values.meetingMembers}
                />
              ) : (
                <BoardMembers
                  title={getTranslation(
                    "Shareholder Members",
                    "Membres actionnaires",
                    "Aktionärsmitglieder"
                  )}
                  meetingType={1}
                  MCId={managementCompanyId}
                  onSelectionChange={(members) =>
                    setFieldValue("meetingMembers", members)
                  }
                  selectedMembers={values.meetingMembers}
                />
              )}
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            m={2}
          >
            {/* <div style={{ marginRight: "10px" }}>
							<ButtonWithLoading
								title={getTranslation(
									"Save as Draft",
									"Save as Draft",
									"Save as Draft"
								)}
								color="primary"
								variant="contained"
								size="large"
								//onClick={handleSubmit}
								loading={draftLoading}
								onClick={() => {
									setNextButtonClicked(false);
									handleSubmit();
								}}
							/>
						</div> */}

            <ButtonWithLoading
              title={getTranslation("Next", "Suivant", "Weiter")}
              color="primary"
              variant="contained"
              size="large"
              //onClick={handleSubmit}
              loading={isSubmitting}
              onClick={() => {
                handleSubmit();
              }}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default AddRegularMeetingInfo;
