import React, { useState } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
	item: {
		display: "block",
		paddingTop: 0,
		paddingBottom: 0,
		color: theme.palette.common.black,
		paddingLeft:'2px'
	},
	itemLeaf: {
		display: "flex",
		paddingTop: 0,
		paddingBottom: 0,
		color: theme.palette.common.black,
	},
	button: {
		color: theme.palette.common.white,
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
		paddingLeft:"16px"
	},
	buttonLeaf: {
		color: theme.palette.common.white,
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
		fontWeight: theme.typography.fontWeightRegular,
		"&.depth-0": {
			"& $title": {
				fontWeight: theme.typography.fontWeightMedium,
				color: theme.palette.common.black,
			},
		},
	},
	icon: {
		display: "flex",
		alignItems: "center",
		marginRight: theme.spacing(1),
		color: theme.palette.primary.contrastText,
	},
	title: {
		marginRight: "auto",
		color: theme.palette.common.white,
	},
	active: {
		color: theme.palette.common.white,
		//backgroundColor: theme.palette.primary.dark,
		backgroundColor: "#a9b1c2",
		"&:hover": {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.primary.dark,
		},
		"& $title ": {
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.common.white,
		},
		"& $icon": {
			color: theme.palette.common.white,
		},
	},
	collapse: {},
}));

function NavItem({
	title,
	href,
	depth,
	children,
	icon: Icon,
	className,
	open: openProp,
	info: Info,
	...rest
}) {
	const classes = useStyles();
	const [open, setOpen] = useState(openProp);
	const location = useLocation();
	console.log("lasvdhgasghdascfgdsa", location);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const isSelected = href && location.pathname.includes(href);

	let paddingLeft = 8;

	if (depth > 0) {
		paddingLeft = 32 + 8 * depth;
	}

	const style = { paddingLeft };

	if (children) {
		return (
			<ListItem
				//className={clsx(classes.item, className)}
				className={clsx(classes.item, className, isSelected && classes.active)}
				disableGutters
				key={title}
				{...rest}
			>
				<Button className={classes.button} onClick={handleToggle} style={style}>
					{Icon && <Icon className={classes.icon} size="20" />}
					<span className={classes.title}>{title}</span>
					{open ? (
						<ExpandLessIcon className="" color="inherit" />
					) : (
						<ExpandMoreIcon color="inherit" />
					)}
				</Button>
				<Collapse className={classes.collapse} in={open}>
					{children}
				</Collapse>
			</ListItem>
		);
	}

	return (
		<ListItem
			//className={clsx(classes.itemLeaf, className)}
			className={clsx(
				classes.itemLeaf,
				className,
				isSelected && classes.active
			)}
			disableGutters
			key={title}
			{...rest}
		>
			<Button
				activeClassName={classes.active}
				className={classes.buttonLeaf}
				component={RouterLink}
				style={style}
				to={href}
			>
				{Icon && <Icon className={classes.icon} size="20" />}
				<span className={classes.title}>{title}</span>
				{Info && <Info className={""} />}
			</Button>
		</ListItem>
	);
}

NavItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	depth: PropTypes.number.isRequired,
	href: PropTypes.string,
	icon: PropTypes.any,
	info: PropTypes.any,
	open: PropTypes.bool,
	title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
	open: false,
};

export default NavItem;
