import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router";
import {
	AskDocuments,
	SelectGuests,
	AddRegularMeetingInfo,
	Minutes,
	AskReminders,
	AskAgenda,
	SelectFiles,
	AttachReport,
} from "./components/regularMeeting";
import { Create, Get } from "../../../../actions";
import { Get_DraftMeetingTemplate, Post_GenerateAdhocMeetings_URL, Post_GenerateMeetings_URL } from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { getTranslation } from "../../../../heplers/translationHelper";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	button: {
		marginRight: theme.spacing(1),
	},
	completed: {
		display: "inline-block",
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

function getSteps() {
	return [
		"Meeting Info",
		"Guests",
		"Reporting",
		"Minutes",
		"Documents",
		"Agendas",
		"Reminders",
		"Files",
	];
}

export default function HorizontalNonLinearStepper({
	collaperController,
	setCollapserController,
	managementCompanyId,
	isAdhoc
}) {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const { managementCompanyIdd, data } = useParams();
	const [activeStep, setActiveStep] = useState(0);
	const [completed, setCompleted] = useState({});
	const focusRefer=useRef();
	

	useEffect(() => {
		if (data && data!=='new') {
			Get(
				{ draftMeetingId: data },
				Get_DraftMeetingTemplate,
				null,
				(resp) => {
					setActiveStep(resp?.data?.activeStep);
					setRegularMeetingModel(resp?.data);
				},
				(error) => {}
			);

			// const decodedData = atob(data);
			// // Parse the JSON string to get the original object
			// var originalObject = JSON.parse(decodedData);
		
		}else{
			setRegularMeetingModel(initialValues);
		}
		
	}, []);
	// useEffect(() => {
	// 	setActiveStep(activeState !== null ? activeState : 0);
	// 	setRegularMeetingModel(meetingData === null ? initialValues : meetingData);
	// }, [activeState, meetingData]);

	const initialValues = {
		subject: "",
		//meetingTime: new Date(),
		meetingTime: moment(new Date()).format("MM-DD-YYYY HH:mm"),
		meetingDuration: moment(new Date(0, 0, 0, 1)).format("MM-DD-YYYY HH:mm"),
		//meetingDate: new Date(),
		meetingDate: moment(new Date()).format("MM-DD-YYYY"),
		meetingType: 0,
		language: "",
		personInchargeId: "",
		reminderInterval: 0,
		isReminderEnabled: false,
		reminderText: "",
		managementCompanyId: managementCompanyId,
		companyCeresServiceId: 0,
		address: "",
		isDocumentRequired: false,
		isAskingAgendaAllowed: false,
		askingAgendaInterval: 0,
		periodicity: isAdhoc ? 300 : 0,
		guests: [],
		meetingDocuments: [],
		agendas: [],
		meetingMembers: [],
		files: [],
		reportingUsers: [],
		reportFundManagerId: "",
		reportIntervalDays: 0,
		reportCollectDataBefore: 0,
		reportTemplateId: 0,
		fundManager: {},
		isAdhoc:isAdhoc
	};
	const [regularMeetingModel, setRegularMeetingModel] = useState(initialValues);
	console.log("dfgdfghdfhh", regularMeetingModel);
	const steps = getSteps();
	function getStepContent(step) {
		switch (step) {
			case 0:
				return (
					<AddRegularMeetingInfo
						managementCompanyId={managementCompanyId}
						NextStep={handleNext}
						BackStep={handleBack}
						initialValues={regularMeetingModel}
					/>
				);
			case 1:
				return (
					<SelectGuests
						NextStep={handleNext}
						BackStep={handleBack}
						initialValues={regularMeetingModel}
					/>
				);
			case 2:
				return (
					<AttachReport
						NextStep={handleNext}
						BackStep={handleBack}
						initialValues={regularMeetingModel}
					/>
				);
			case 3:
				return (
					<Minutes
						NextStep={handleNext}
						BackStep={handleBack}
						initialValues={regularMeetingModel}
					/>
				);
			case 4:
				return (
					<AskDocuments
						NextStep={handleNext}
						BackStep={handleBack}
						initialValues={regularMeetingModel}
					/>
				);
			case 5:
				return (
					<AskAgenda
						NextStep={handleNext}
						BackStep={handleBack}
						initialValues={regularMeetingModel}
					/>
				);
			case 6:
				return (
					<AskReminders
						NextStep={handleNext}
						BackStep={handleBack}
						initialValues={regularMeetingModel}
					/>
				);
			case 7:
				return (
					<SelectFiles
						onSubmit={handleSubmitRegularMeeting}
						BackStep={handleBack}
						initialValues={regularMeetingModel}
					/>
				);
			default:
				return "Unknown step";
		}
	}

	const handleSubmitRegularMeeting = (values, actions) => {
		const payload = { ...values };

		// Remove the id field from each guest in the Guesty array
		payload.guests = payload.guests.map((guest) => {
			const { id, ...guestWithoutId } = guest;
			return guestWithoutId;
		});
		console.log("generatemeeting", payload);
		payload.meetingDate = moment(payload.meetingDate).format(
			"MM-DD-YYYY hh:mm a"
		);
		payload.meetingTime = moment(payload.meetingTime).format(
			"MM-DD-YYYY hh:mm a"
		);
		payload.meetingDuration = moment(payload.meetingDuration).format(
			"MM-DD-YYYY hh:mm a"
		);
		Create(
			payload,
			payload.isAdhoc ? Post_GenerateAdhocMeetings_URL: Post_GenerateMeetings_URL,
			null,
			(resp) => {
				actions.setSubmitting(false);
				enqueueSnackbar(resp.data, {
					variant: "success",
				});
				setRegularMeetingModel(initialValues);
				setActiveStep(0);
			},
			(error) => {
				actions.setSubmitting(false);
				if (error.data?.message) {
					enqueueSnackbar(error.data?.message, {
						variant: "error",
					});
				} else
					enqueueSnackbar(error.data, {
						variant: "error",
					});
			}
		);
	};

	const totalSteps = () => {
		return steps.length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const handleNext = (values) => {
		setRegularMeetingModel(values);
		const newActiveStep = isLastStep()
			? steps.findIndex((step, i) => !(i in completed))
			: activeStep + 1;
		setActiveStep(newActiveStep);

		focusRefer.current.scrollIntoView({
			behavior:'smooth'
		})

	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		focusRefer.current.scrollIntoView({
			behavior:'smooth'
		})
	};

	return (
		<div className={classes.root} ref={focusRefer}>
			{regularMeetingModel.isAdhoc?
			<Box style={{textAlign:'center',width:'100%'}}>
				<Alert color="info" >{getTranslation("This is adhoc meeting")}</Alert>
			</Box>:null}
			<Stepper nonLinear activeStep={activeStep}>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepButton completed={index < activeStep}>{label}</StepButton>
					</Step>
				))}
			</Stepper>
			<div>
				<div>
					<Typography className={classes.instructions}>
						{getStepContent(activeStep)}
					</Typography>
					<div>
						{activeStep !== steps.length &&
							(completed[activeStep] ? (
								<Typography variant="caption" className={classes.completed}>
									Step {activeStep + 1} already completed
								</Typography>
							) : null)}
					</div>
				</div>
				{/* )} */}
			</div>
		</div>
	);
}

