import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import MeetingInformation from "../public/components/MeetingInformation";
import {
  Get_PowerOfAttorney_URL,
  Get_PowerOfAttorneyMeeting_URL,
} from "../../constants/apiUrls";
import { useNavigate, useParams } from "react-router";
import { useSnackbar } from "notistack";
import { Get } from "../../actions";
import { getTranslation } from "../../heplers/translationHelper";
export const SignAttorney = () => {
  const { powerOfAttorneyId } = useParams();
  const [powerOfAttornet, setPowerOfAttorney] = useState();
  const [powerOfAttornetMeeting, setPowerOfAttorneyMeeting] = useState();
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const Id = "994a1ac2-e07c-4665-52a5-08d98581a965";

  //////////////////////////////////////////////////////// Get Power Of Attorney ///////////////////////////////
  useEffect(() => {
    loadPowerOfAttorney({ Id });
  }, [Id]);
  const loadPowerOfAttorney = (values) => {
    Get(
      values,
      Get_PowerOfAttorney_URL,
      history,
      (resp) => {
        setPowerOfAttorney(resp?.data);
        enqueueSnackbar("Power Of Attorney", { variant: "success" });
      },
      (error) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    );
  };
  //////////////////////////////////////////////////////// Get Power Of Attorney meetings///////////////////////////////
  useEffect(() => {
    loadPowerOfAttorneyMeeting({ POAId: powerOfAttorneyId });
  }, [powerOfAttorneyId]);
  const loadPowerOfAttorneyMeeting = (values) => {
    Get(
      values,
      Get_PowerOfAttorneyMeeting_URL,
      history,
      (resp) => {
        setPowerOfAttorneyMeeting(resp?.data);
        enqueueSnackbar("Power Of Attorney", { variant: "success" });
      },
      (error) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    );
  };
  return (
    <div>
      <MeetingInformation meetingInfo={powerOfAttornetMeeting} />
      <Card style={{ margin: "1%", height: "106px" }}>
        <CardHeader title={getTranslation("Member Information","Informations sur les membres","Mitgliederinformation")} />
        <CardContent>
          <Grid container style={{ width: "80%", marginLeft: "2%" }}>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h6">First Name</Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h5">{powerOfAttornet?.firstName}</Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h6">Last Name</Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h5">{powerOfAttornet?.lastName}</Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h6">Email</Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h5">{powerOfAttornet?.mail}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
