import { Box, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import { ObjectSchema } from "yup";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import * as Yup from "yup";

const AddField = ({ editValue,onSubmit }) => {
  console.log("insdie add edit",editValue)
  
  const initialValues = {
   // id: editValue?editValue.id:0,
   id: editValue ? editValue.id : generateUniqueID(),
    title: editValue?editValue.title:"",
    dataType: editValue?editValue.dataType:"text",
    sort: editValue?editValue.sort:0,
  };


  function generateUniqueID() {
   return Math.floor(Math.random() * 1000000)
  }



  
  const validationSchema = Yup.object({
    title: Yup.string()
    .matches(
      /^[a-zA-Z\sÀ-ÿ\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]+$/,
      "Only English, French, and German characters are allowed"
    )
    .required("Company is required"),
  });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        onSubmit(values, actions);
      }}
    >
      {({
        values,
        handleSubmit,
        getFieldProps,
        isSubmitting,
        setSubmitting,
        touched,
        errors
      }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                id="title"
                label="Field Name"
                fullWidth
                variant="outlined"
                size="small"
                {...getFieldProps("title")}
                error={touched.title && Boolean(errors.title)}
                helperText={touched.title && errors.title}
                 />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Select
                style={{
                  marginRight: "20px",

                  height: "34px",
                }}
                value={values.dataType}
                id="dataType"
                label="Field Type"
                fullWidth
                variant="outlined"
                size="small"
                {...getFieldProps("dataType")}
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="password">Password</MenuItem>
                <MenuItem value="time">Time</MenuItem>
                <MenuItem value="url">URL</MenuItem>
                <MenuItem value="tel">Telephone</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <ButtonWithLoading
                title={editValue?"Update Field":"Add Field"}
                loading={isSubmitting}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default AddField;
