import { Get } from "../actions";
import { Get_ToDoListItems_URL } from "../constants/apiUrls";
import { format } from "./format";
import ReactDOMServer from 'react-dom/server'
import EditIcon from '@material-ui/icons/Edit';
import {AlarmClockIcon} from "../components/Icons"
import { EditSvg } from "../components/Icons";

export const parseMinutesReportHelper = async (meetingId,html, onChange) => {
    var parser = new DOMParser();
    var htmlDoc = parser.parseFromString(html, "text/html");
    var todolist = htmlDoc.querySelectorAll("[data-todolist-id]");
    var updateTodoTable = htmlDoc.querySelectorAll("[data-table-todolist-id]");
    await updateParseTodo(updateTodoTable)
    await parseTodo(todolist);
    var XML = new XMLSerializer();
    var divNode = document.createElement("div");
    divNode.innerHTML = htmlDoc.body.innerHTML;
    divNode.style = `width:100%;`;
    var editIcons = htmlDoc.querySelectorAll("[data-edit-todolist-id]");
    editIcons.forEach(element => {
      element.outerHTML="<br/>"
    });
    onChange(XML.serializeToString(htmlDoc));
  };

const parseTodo = async (sectionHtmlDOM) => {
    for await (const x of sectionHtmlDOM) {
    
    await Get(
            {
                toDoId: x.getAttribute("data-todolist-id"),
            },
            Get_ToDoListItems_URL,
            null,
            (resp) => {
                x.outerHTML = renderTable(resp?.data,x.getAttribute("data-todolist-id"));
                x.style = "";
            },
            (error) => {
                console.log("jhsdbgfsdfg error", error);
            }
        );
    }

  
  };

  const updateParseTodo = async (sectionHtmlDOM) => {
    for await (const x of sectionHtmlDOM) {
    
    await Get(
            {
                toDoId: x.getAttribute("data-table-todolist-id"),
            },
            Get_ToDoListItems_URL,
            null,
            (resp) => {
                x.outerHTML = renderUpdateTable(resp?.data,x.getAttribute("data-table-todolist-id"));
            },
            (error) => {
                console.log("jhsdbgfsdfg error", error);
            }
        );
    }

  
  };

  const renderTable = (toDoItems,todoId) => {
    
    return ReactDOMServer.renderToString(<><EditSvg data-edit-todolist-id={todoId}/> <table data-table-todolist-id={todoId} style={{width:"100%",borderCollapse:'collapse'}}><thead>
      <tr style={{ backgroundColor: "rgba(240, 240, 240, 0.8)",borderCollapse:'collapse' }}>
      <th align="left" style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            textAlign: 'center',
            fontWeight: 'bold',
            borderCollapse:'collapse'
          }}>Person Incharge</th>
      <th align="left" style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            textAlign: 'center',
            fontWeight: 'bold',
            borderCollapse:'collapse'
          }}>Description</th>
      <th style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            textAlign: 'center',
            fontWeight: 'bold',
            borderCollapse:'collapse'
          }}>Due Date</th>
      </tr>
      {toDoItems.map(item => {
        return <tr>
          <td align="left" style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            padding: "6px",
            textAlign: "center",
            borderCollapse:'collapse'
          }}>{item.personInchargeName}</td>
          <td align="left" style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            padding: "6px",
            textAlign: "center",
            borderCollapse:'collapse'
          }}>{item.description}</td>
          <td style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            padding: "6px",
            textAlign: "center",
            borderCollapse:'collapse'
          }}>{format("date",item.dueDate)}</td>
        </tr>
      })}
    </thead></table></>)
  }
  
  
  const renderUpdateTable = (toDoItems,todoId) => {
    
    return ReactDOMServer.renderToString(<table data-table-todolist-id={todoId} style={{width:"100%",borderCollapse:'collapse'}}><thead>
      <tr style={{ backgroundColor: "rgba(240, 240, 240, 0.8)",borderCollapse:'collapse' }}>
      <th align="left" style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            textAlign: 'left',
            fontWeight: 'bold',
            borderCollapse:'collapse'
          }}>Person Incharge</th>
      <th align="left" style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            textAlign: 'left',
            fontWeight: 'bold',
            borderCollapse:'collapse'
          }}>Description</th>
      <th style={{
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            textAlign: 'center',
            fontWeight: 'bold',
            borderCollapse:'collapse'
          }}>Due Date</th>
      </tr>
      {toDoItems.map(item => {
        return <tr>
          <td align="left" style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            padding: "6px",
            textAlign: "left",
            borderCollapse:'collapse'
          }}>{item.personInchargeName}</td>
          <td align="left" style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            padding: "6px",
            textAlign: "left",
            borderCollapse:'collapse'
          }}>{item.description}</td>
          <td style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            padding: "6px",
            textAlign: "center",
            borderCollapse:'collapse'
          }}>{format("date",item.dueDate)}</td>
        </tr>
      })}
    </thead></table>)
  }
  