import { ManagementCompaniesIcon, GearIcon, CompaniesIcon, ContactsIcon, AssetsIcon, FundsIcon, ServicesIcon, ServiceProviderIcon, SettingsIcon, userManagementIcon, MeetingsIcon } from "../../components/Icons";
import { getTranslation } from "../../heplers/translationHelper";
import { AssessmentOutlined, CalendarTodayOutlined } from "@material-ui/icons";


const navConfig = [
  {
    subheader: "",
    items: [
      {
        title: "SPV",
        icon: ManagementCompaniesIcon,
        href: "/managementcompanies",
      },
      {
        title: getTranslation("Meetings","Réunions","Treffen"),
        icon: MeetingsIcon,
        href: "/meetings",
      },
      {
        title: getTranslation("Companies","Entreprises","Firmen"),
        icon: CompaniesIcon,
        href: "/companies",
      },
      {
        title: getTranslation("Contacts","Contacts","Kontakte"),
        icon: ContactsIcon,
        href: "/contacts",
      },
      {
        title: getTranslation("Assets","Actifs","Vermögenswerte"),
        icon: AssetsIcon,
        href: "/assets",
      },
      {
        title: getTranslation("Funds","Fonds","Mittel"),
        icon: FundsIcon,
        href: "/funds",
      },
      {
        title: getTranslation("Fees","Frais","Gebühren"),
        icon: ServicesIcon,
        href: "/services",
      },
      {
        title: getTranslation("Service Providers","Les fournisseurs de services","Dienstleister"),
        icon: ServiceProviderIcon,
        href: "/companyServices",
      },
      {
        title: getTranslation("Calendar","Calendrier","Kalender"),
        icon: CalendarTodayOutlined,
        href: "/calendar",
      },
      {
        title: getTranslation("Report Templates","Modèle de rapport","Bericht Vorlage"),
        icon: AssessmentOutlined,
        href: "/reports/reportTemplates",
      },
      {
        title: getTranslation("Settings","Réglages","Einstellungen"),
        icon: SettingsIcon,
        href: "/settings/general",
      },
      {
        title: getTranslation("User Management","Gestion des utilisateurs","Benutzerverwaltung"),
        icon: userManagementIcon,
        href: "/userManagement",
      },
    ],
  },
];

export default navConfig;
