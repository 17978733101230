import React, { useState } from "react";

import Table from "../../../../components/table";
import { useNavigate } from "react-router-dom";
import { getTranslation } from "../../../../heplers/translationHelper";
import { Typography } from "@material-ui/core";
import { representativeRoles } from "../../../../constants/representativeRoles";

const columns = [
	{
		id: "contactName",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Name"),
	},
	{
		id: "representativeRole",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Role"),
		component: ({ row }) => {
			const selectedRepresentative = representativeRoles.find(
				(x) =>
					x.value == row.representativeRole || x.id == row.representativeRole
			);
			return <Typography>{selectedRepresentative.value}</Typography>;
		},
	},
	{
		id: "companyName",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Company"),
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions"),
	},
];

const Persons = ({ onEdit, onDelete, representative, loading }) => {
	const history = useNavigate();

	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [page, setPage] = useState(0);
	let spvDetailBoardPerson = localStorage.getItem("spvDetailBoardPerson");
	if (spvDetailBoardPerson == null) {
		localStorage.setItem("spvDetailBoardPerson", 10);
		spvDetailBoardPerson = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvDetailBoardPerson);

	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleEditActivity = (row) => {
		history(`/funds/add/${row.id}`);
		// dispatch(editActivityType(row));
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvDetailBoardPerson", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};

	const handleSearchPerson = (searchString) => {};
	const handleEditPersonRepresentative = (row) => {
		if (onEdit) onEdit(row);
	};

	return (
		<>
			<div>
				<Table
					dense
					title={getTranslation("Persons")}
					colums={columns}
					auto
					rows={representative?.representedByPersons}
					// rows={activities?.data}
					// count={activities?.totalCount}
					sortBy="name"
					count={representative?.representedByPersons?.length}
					page={page}
					rowsPerPage={rowsPerPage}
					handleChangePage={(event, newPage) =>
						handleChangePage(event, newPage)
					}
					handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
					viewDelete
					onDelete={(row) => onDelete(row)}
					viewSearch
					viewEdit
					onEdit={(row) => handleEditPersonRepresentative(row)}
				/>
			</div>
		</>
	);
};

export default Persons;
