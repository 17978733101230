import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

const ButtonWithLoading = ({
	title,
	size,
	variant,
	loading,
	loadingColor,
	color,
	...rest
}) => {
	return (
		<Button
			size={size}
			color={color}
			variant={variant}
			disabled={loading}
			startIcon={
				loading ? (
					<CircularProgress
						variant="indeterminate"
						thickness={3}
						size={22}
						color={loadingColor ? loadingColor : color}
					/>
				) : null
			}
			{...rest}
		>
			{title}
		</Button>
	);
};

export default ButtonWithLoading;
