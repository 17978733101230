import React, { useState, useEffect } from "react";
import Table from "../../../../components/table";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Toc } from "@material-ui/icons";
import { getTranslation } from "../../../../heplers/translationHelper";
import EditIcon from "@material-ui/icons/Edit";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";

const columns = [
	{
		id: "subject",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Subject", "Sujette", "Fach"),
	},
	{
		id: "language",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Language", "Langue", "Sprache"),
	},
	{
		id: "personInchargeName",
		numeric: false,
		disablePadding: true,
		label: getTranslation(
			"Person Incharge",
			"Personne en charge",
			"Verantwortlicher"
		),
	},
	{
		id: "meetingTime",
		numeric: false,
		disablePadding: true,
		format: "dateTime",
		label: getTranslation("Date/Time", "Date/Heure", "Terminzeit"),
	},
	{
		id: "address",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Address", "Adresse", "Adresse"),
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const Funds = [
	{
		id: "001",
		title: "Company",
		investerRelation: "Asghar",
		manager: "Khan",
	},
];

const AdhocMeetingList = ({
	managementCompanyId,
	meetings,
	loading,
	onFilter,
	onShowlogs,
	onAdd,
}) => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [createCompany, setCreateCompany] = useState(false);
	let spvAdhocMeeting = localStorage.getItem("spvAdhocMeeting");
	if (spvAdhocMeeting == null) {
		localStorage.setItem("spvAdhocMeeting", 10);
		spvAdhocMeeting = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(spvAdhocMeeting);
	const [rows, setRows] = useState([]);
	const [loadingFunds, setloadingFunds] = useState(false);

	var d = new Date();
	d.setMonth(d.getMonth() - 3);
	const [fromDate, setFromDate] = useState(d);
	var to = new Date();
	to.setMonth(to.getMonth() + 3);
	const [toDate, setToDate] = useState(to);

	useEffect(() => {
		onFilter({
			MCId: managementCompanyId,
			searchString: searchString,
			from: fromDate,
			to: toDate,
			pageNumber: page,
			pageSize: rowsPerPage,
		});
	}, [page, rowsPerPage, searchString]);

	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("spvAdhocMeeting", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleSearchFund = (searchString) => {
		setSearchString(searchString);
	};
	const handleEditActivity = (row) => {
		onAdd(row);
	};
	const handleUpdateReport = (row) => {
		console.log("jksdghfksdf", row);
		var url = `/meetings/${managementCompanyId}/${row.id}/updatereport/`;
		history(url);
	};
	return (
		<div>
			<Table
				dense
				loading={loading}
				//title="Meetings"
				colums={columns}
				sortBy="meetingDate"
				rows={meetings?.data}
				count={meetings?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				// viewEdit
				// onEdit={(row)=>handleEditActivity(row)}
				// viewDelete
				//onDelete={(row) => handleOpenDeleteDialog(row)}
				viewSearch
				onSearch={(searchString) => handleSearchFund(searchString)}
				actions={[
					{
						component: (row) => (
							<IconButton onClick={() => handleEditActivity(row)}>
								<EditIcon />
							</IconButton>
						),
					},
					{
						component: (row) => (
							<IconButton onClick={() => onShowlogs(row.id)}>
								<Toc />
							</IconButton>
						),
					},
					{
						component: (row) => (
							<IconButton onClick={() => handleUpdateReport(row)}>
								<AssignmentOutlinedIcon />
							</IconButton>
						),
					},
				]}
			/>
		</div>
	);
};

export default AdhocMeetingList;
