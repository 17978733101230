import React, { useState, useEffect } from "react";
import Table from "../../../../../../components/table";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { Post_Meeting_UpdateGuestSignatureNeeded } from "../../../../../../constants/apiUrls";
import { Create } from "../../../../../../actions";

export const GuestList = ({
	meetingId,
	signautureRequired,
	enableDelete,
	guests,
	onDelete,
	tempSelectedMembersGuest,
	setTempSelectedMembersGuest,
	handleAddSignatureGuest,
	handleDeleteSignatureGuest,
}) => {
	const columns = [
		{
			id: "fullName",
			numeric: false,
			label: getTranslation("Name", "Nom", "Name"),
		},
		{
			id: "mail",
			numeric: false,
			label: getTranslation("Email", "E-mail", "Email"),
		},
		{
			id: "actions",
			numeric: false,
			disablePadding: true,
			align: "right",
			label: getTranslation("Actions", "Actions", "Aktionen"),
		},
	];

	console.log("ghsfgxhs", guests);
	console.log("temp");

	const { enqueueSnackbar } = useSnackbar();
	const [page, setPage] = useState(0);
	let GmRegularMeetingGuest = localStorage.getItem("GmRegularMeetingGuest");
	if (GmRegularMeetingGuest == null) {
		localStorage.setItem("GmRegularMeetingGuest", 10);
		GmRegularMeetingGuest = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(GmRegularMeetingGuest);
	const [searchString, setSearchString] = useState("");

	// useEffect(() => {
	// 	setTempSelectedMembers(guests);
	// });

	//console.log("dsajhgdhasfdhgsafdghfas", tempSelectedMembers);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("GmRegularMeetingGuest", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleSearchGuest = (searchString) => {
		setSearchString(searchString);
	};

	// const handleAddSignatureGuest = (items) => {
	// 	console.log("items", items);
	// 	let payload = [
	// 		{
	// 			meetingId: meetingId,
	// 			guestId: items.id,
	// 			isNeededSignature: true,
	// 		},
	// 	];

	// 	Create(
	// 		payload,
	// 		Post_Meeting_UpdateGuestSignatureNeeded,
	// 		null,
	// 		(resp) => {
	// 			enqueueSnackbar(
	// 				getTranslation(
	// 					"Guest Added For Signature",
	// 					"Guest Added For Signature",
	// 					"Guest Added For Signature"
	// 				),
	// 				{ variant: "success" }
	// 			);
	// 		},
	// 		(error) => {
	// 			enqueueSnackbar("SomeThing went wrong", {
	// 				variant: "error",
	// 			});
	// 		}
	// 	);
	// };

	// const handleDeleteSignatureGuest = (items) => {
	// 	console.log("items", items);
	// 	let payload = [
	// 		{
	// 			meetingId: meetingId,
	// 			guestId: items.id,
	// 			isNeededSignature: false,
	// 		},
	// 	];

	// 	Create(
	// 		payload,
	// 		Post_Meeting_UpdateGuestSignatureNeeded,
	// 		null,
	// 		(resp) => {
	// 			enqueueSnackbar(
	// 				getTranslation(
	// 					"Guest Removed For Signature",
	// 					"Guest Removed For Signature",
	// 					"Guest Removed For Signature"
	// 				),
	// 				{ variant: "success" }
	// 			);
	// 		},
	// 		(error) => {
	// 			enqueueSnackbar("SomeThing went wrong", {
	// 				variant: "error",
	// 			});
	// 		}
	// 	);
	// };

	return (
		<>
			<Table
				auto
				size="small"
				colums={columns}
				sortBy="fullName"
				rows={guests}
				count={guests?.length}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				enableCheckbox={signautureRequired ? signautureRequired : false}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewDelete
				onDelete={(row) => onDelete(row)}
				viewSearch
				onSearch={(searchString) => handleSearchGuest(searchString)}
				onSelectionChange={(items) => setTempSelectedMembersGuest(items)}
				onSelectionChecked={(items) => handleAddSignatureGuest(items)}
				onSelectionUnChecked={(items) => handleDeleteSignatureGuest(items)}
				checkedLabel={getTranslation("Signature", "Signature", "Signature")}
				selected={tempSelectedMembersGuest}
			/>
		</>
	);
};
