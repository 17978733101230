// React imports
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	TextField,
} from "@material-ui/core";
import {
	Alert,
	Autocomplete,
	ToggleButton,
	ToggleButtonGroup,
} from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Create, Get } from "../../../actions";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { DatePicker } from "../../../components/DatePicker";
// local imports
import Table from "../../../components/table";
import {
	GET_AllCompaniesList_URL,
	GET_AllCompanies_URL,
	GET_AllContactList_URL,
	Get_AllManagementCompaniesWithPagination_URL,
	GET_AllUsersWithoutAuthorization_URL,
	Post_GetAllMeetings_URL,
	Post_TryResolvingMeetingClashes_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import { FilterSearch } from "./filterSearch";
import format from "../../../heplers/format";
import { ShowClashedContactsDialog } from "../../meeting/component/regular/showClashedContactsDialog";
import { useSnackbar } from "notistack";
import SyncIcon from "@material-ui/icons/Sync";
import ErrorIcon from "@material-ui/icons/Error";

const columns = [
	//   {
	//     id: "back",
	//     component: ({ row }) => <ArrowBack />,
	//   },
	{
		id: "meetingDate",
		numeric: false,
		disablePadding: true,
		format: "date",
		label: "Date",
	},
	{
		id: "meetingDateDay",
		numeric: false,
		disablePadding: true,
		label: "Day",
	},
	{
		id: "meetingTime",
		numeric: false,
		disablePadding: true,
		format: "time",
		label: "Time",
	},
	{
		id: "subject",
		numeric: false,
		disablePadding: true,
		label: "Subject",
	},
	{
		id: "managementCompanyName",
		numeric: false,
		disablePadding: true,
		label: "Company",
	},
	{
		id: "meetingDuration",
		numeric: false,
		disablePadding: true,
		format: "time",
		label: "Duration",
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Action", "action", "Aktion"),
		align: "right",
	},
];

const CalendarListView = () => {
	const date = new Date();
	const year = date.getFullYear();
	const [page, setPage] = useState(0);
	let CalenderList = localStorage.getItem("CalenderList");
	if (CalenderList == null) {
		localStorage.setItem("CalenderList", 10);
		CalenderList = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(CalenderList);
	const [meetingList, setMeetingList] = useState([]);
	const [openClashDialog, setOpenClashDialog] = useState(false);

	const [filter, setFilter] = useState({
		meetingType: "",
		from: new Date(),
		to: new Date(year, 11, 31),
		contacts: [],
		appUsers: [],
		companies: [],
	});
	const { enqueueSnackbar } = useSnackbar();
	const [selectedMeeting, setSelectedMeeting] = useState(null);

	////// List required in dropdown //////
	const [contactsList, setContactsList] = useState([]);
	const [appUsersList, setAppUsersList] = useState([]);
	const [companiesList, setCompaniesList] = useState([]);
	///////////////// Pagination Handlers /////////////////
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("CalenderList", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	///////////////// Get All Meetings /////////////////
	useEffect(() => {
		getAllMeetings(filter);
	}, [filter]);
	const getAllMeetings = () => {
		console.log("jksdkjfgdsf", filter);
		Create(
			filter,
			Post_GetAllMeetings_URL,
			null,
			(resp) => {
				setMeetingList(resp.data);
			},
			(err) => {}
		);
	};
	////////// After Applying Filter ///////////////
	const handleSearchMeetings = (values) => {
		{
			console.log("hgdfjgdsf", values);
		}

		let contactIds = [];
		let companyIds = [];
		let userIds = [];
		(values?.contacts).forEach((contact) => {
			contactIds = [...contactIds, contact.id];
		});

		(values?.appUsers).forEach((appUser) => {
			userIds = [...userIds, appUser.id];
		});
		////////// making
		(values?.companies).forEach((company) => {
			companyIds = [...companyIds, company.id];
		});
		setFilter({
			meetingType: values.meetingType,
			from: values.from,
			to: values.to,
			contacts: contactIds,
			appUsers: userIds,
			companies: companyIds,
		});
	};
	////////// Get All Contcats ///////////////
	useEffect(() => {
		Get(
			{},
			GET_AllContactList_URL,
			null,
			(resp) => {
				setContactsList(resp.data);
			},
			(error) => {}
		);
		Get(
			{
				page: 0,
				pageSize: 10000,
			},
			Get_AllManagementCompaniesWithPagination_URL,
			null,
			(resp) => {
				setCompaniesList(resp.data.data);
			},
			(error) => {}
		);
		Get(
			{},
			GET_AllUsersWithoutAuthorization_URL,
			null,
			(resp) => {
				setAppUsersList(resp.data);
			},
			(error) => {}
		);
	}, []);

	const syncMeetingClashes = (row) => {
		Get(
			{ meetingId: row },
			Post_TryResolvingMeetingClashes_URL,
			null,
			(resp) => {
				enqueueSnackbar("Meeting Clash resolved.", { variant: "success" });
			},
			(error) => {
				enqueueSnackbar("Couldn't Resolve meeting Clashes. Try Again", {
					variant: "error",
				});
			}
		);
	};

	return (
		<>
			<Card>
				<CardHeader
					title="Calendar"
					style={{
						backgroundColor: "#1E293A",
						color: "white",
					}}
				/>
				<CardContent>
					<FilterSearch
						contactList={contactsList}
						companyList={companiesList}
						userList={appUsersList}
						onSearch={(values) => handleSearchMeetings(values)}
					/>
					<br />
					<ToggleButtonGroup>
						<ToggleButton disabled>Calendar</ToggleButton>
						<ToggleButton selected>list</ToggleButton>
					</ToggleButtonGroup>
					<br />
					<br />
					<Alert severity="info">Calender View coming soon</Alert>
					<br />
					<br />
					<Table
						dense
						auto
						colums={columns}
						sortBy="meetingDate"
						rows={meetingList}
						count={meetingList?.length}
						page={page}
						rowsPerPage={rowsPerPage}
						handleChangePage={(event, newPage) =>
							handleChangePage(event, newPage)
						}
						handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
						actions={[
							{
								component: (row) =>
									row.hasClash ? (
										<IconButton onClick={() => syncMeetingClashes(row.id)}>
											<SyncIcon />
										</IconButton>
									) : null,
							},
							{
								component: (row) =>
									row.hasClash ? (
										<IconButton
											onClick={() => {
												setSelectedMeeting(row);
												setOpenClashDialog(true);
											}}
										>
											<ErrorIcon color="error" />
										</IconButton>
									) : null,
							},
						]}
					/>
				</CardContent>
			</Card>
			<ShowClashedContactsDialog
				open={openClashDialog}
				onClose={() => setOpenClashDialog(false)}
				meetingId={selectedMeeting?.id}
			/>
		</>
	);
};
export default CalendarListView;
