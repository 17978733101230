import { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { getTranslation } from "../../../../heplers/translationHelper";
import { useParams } from "react-router";
import { useEffect } from "react";
import { Get } from "../../../../actions";
import { Get_CompanyInfo_URL } from "../../../../constants/apiUrls";
const CompanyDetails = ({ meetingId }) => {
  const [companyDetails, setCompanyDetails] = useState([]);
  useEffect(() => {
    loadCompanyInfo();
  }, []);
  const loadCompanyInfo = () => {
    Get(
      {
        meetingId: meetingId,
      },
      Get_CompanyInfo_URL,
      null,
      (resp) => {
        setCompanyDetails(resp?.data);
      },
      (error) => {}
    );
  };
  return (
    <Card>
      <CardHeader title="Company" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation(
              "Company Name: ",
              "Nom de l'entreprise",
              "Name der Firma"
            )}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.companyName}
              </span>
            </u>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation(
              "Company Reference Cod: ",
              "Code de référence de l'entreprise: ",
              "Firmenreferenz Cod: "
            )}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.basic?.referenceCode}
              </span>
            </u>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation(
              "Relationship Manage: ",
              "Gestionnaire de relation: ",
              "Beziehungsmanager: "
            )}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.basic?.investorRelationName}
              </span>
            </u>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation("RCS Numbe: ")}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.basic?.rcsNumber}
              </span>
            </u>
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation(
              "Incorporation Date: ",
              "Date de constitution: ",
              "Gründungsdatum: "
            )}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.basic?.incorporationDate}
              </span>
            </u>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation("Street: ", "Rue: ", "Straße: ")}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.basic?.street}
              </span>
            </u>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation("City: ", "ville: ", "Stadt: ")}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.basic?.city}
              </span>
            </u>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation("ZIP Code: ", "Code postal: ", "Postleitzahl: ")}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {companyDetails?.basic?.zipCode}
              </span>
            </u>
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation("RBE: ")}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {companyDetails?.basic?.rbe}
              </span>
            </u>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation("Group: ", "groupe: ", "Gruppe: ")}
            <u>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                <u>
                  <span style={{ fontWeight: "bold" }}>
                    {companyDetails?.companyGroup?.title}
                  </span>
                </u>
              </span>
            </u>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation("Country: ", "Pays: ", "Land: ")}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.basic?.country}
              </span>
            </u>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation(
              "Financial Year End: ",
              "Fin d'année financière: ",
              "Ende des Geschäftsjahres: "
            )}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.basic?.finnancialYearEnds}
              </span>
            </u>
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={3}>
            {getTranslation(
              "Legal Form: ",
              "Forme juridique: ",
              "Rechtsform: "
            )}
            <u>
              <span style={{ fontWeight: "bold" }}>
                {companyDetails?.basic?.basicLegalForm?.legalForm}
              </span>
            </u>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default CompanyDetails;
