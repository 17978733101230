import { Box, CardContent, Grid } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { Get, Create } from "../../../../../../actions";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import Card from "../../../../../../components/Card";
import {
	GET_AllContacts_URL,
	GET_AllContactList_URL,
	Post_Meeting_UpsertRegularMeetingDraft,
} from "../../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { AddGuest } from "../guest/addGuest";
import { GuestList } from "../guest/guestList";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

const SelectGuests = ({ NextStep, BackStep, initialValues }) => {
	const [contacts, setContacts] = useState([]);
	const [guests, setGuests] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [nextButtonClicked, setNextButtonClicked] = useState(false);
	const [draftLoading, setDraftLoading] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		setGuests(initialValues.guests);
	}, [initialValues.guests]);
	useEffect(() => {
		loadContacts();
	}, []);
	const loadContacts = () => {
		Get(
			{},
			GET_AllContactList_URL,
			null,
			(resp) => {
				const arr = [];
				resp?.data?.forEach((item) => {
					item.contactId = item.id;
					// item.memberId=item.id;
					arr.push(item);
				});
				setContacts(arr);
			},
			(error) => {}
		);
	};

	const setActiveState = (activeState) => ({
		type: "SET_ACTIVE_STATE",
		payload: {
			activeState,
		},
	});

	const setMeetingData = (meetingData) => ({
		type: "SET_MEETING_DATA",
		payload: {
			meetingData,
		},
	});

	const setController = (value) => ({
		type: "SET_CONTROLLER",
		payload: {
			value,
		},
	});

	const saveToDraft = (payload) => {
		setDraftLoading(true);
		let payloadObj = {
			meetingDraftVm: { ...payload, activeStep: 2 },
		};
		console.log("guest draft", payloadObj);

		Create(
			payloadObj,
			Post_Meeting_UpsertRegularMeetingDraft,
			null,
			(resp) => {
				// dispatch(setActiveState(null));
				// dispatch(setMeetingData(null));
				// //	dispatch(setController(false));

				setDraftLoading(false);
				NextStep(payload);

				//window.location.reload();

				//enqueueSnackbar("Updated Successfully", { variant: "success" });
			},
			(error) => {
				enqueueSnackbar("Something Went Wrong", { variant: "error" });
				setDraftLoading(false);
			}
		);
	};

	return (
		<Card title={getTranslation("Guests", "Invitées", "Gäste")}>
			<CardContent>
				<AddGuest
					contactList={contacts}
					persons={guests}
					onPersonChanged={(persons) => {
						setGuests(persons);
					}}
					onSubmitGuest={(guest) => {
						guest.contactId = guest.id;
						setGuests([...guests, guest]);
						setContacts([...contacts, guest]);
					}}
				/>
				<GuestList
					guests={guests}
					onDelete={(row) => {
						setGuests(guests.filter((x) => x.id !== row.id));
					}}
				/>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="space-between"
					m={2}
				>
					<ButtonWithLoading
						title={getTranslation("Back", "Précédent", "Zurück")}
						color="primary"
						variant="contained"
						size="large"
						onClick={() => BackStep()}
					/>

					<Box display="flex">
						<ButtonWithLoading
							title={getTranslation("Next", "Suivant", "Weiter")}
							color="primary"
							variant="contained"
							size="large"
							loading={draftLoading || nextButtonClicked}
							onClick={() => {
								setNextButtonClicked(true);
								window.scrollTo(0,0);

								//NextStep({ ...initialValues, guests: guests });
								saveToDraft({ ...initialValues, guests: guests });
							}}
						/>
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};

export default SelectGuests;
