import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import Table from "../../../components/table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import DeleteDialog from "../../../components/DeleteDialog";
import { Autocomplete } from "@material-ui/lab";
import { getTranslation } from "../../../heplers/translationHelper";

const columns = [
	{
		id: "fund",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Fund Name", "Nom du fonds", "Fondsname"),
	},
	{
		id: "operations",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Operations", "Opérations", "Operationen"),
	},
	{
		id: "amountIn",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Amount In", "Montant en", "Betrag in"),
    align:'right',
    format:'number'
	},
	{
		id: "amountOut",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Amount Out", "Montant sortant", "Betrag aus"),
    align:'right',
    format:'number'
	},
	{
		id: "interest",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Interest Rate", "Taux d'intérêt", "Zinssatz"),
    align:'right',
    format:'number'
	},
	{
		id: "endDate",
		numeric: false,
		disablePadding: true,
		label: getTranslation("End Date", "Date de fin", "Endtermin"),
	},
	{
		id: "periodicity",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Periodicity", "Périodicité", "Periodizität"),
	},
	{
		id: "comment",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Comment", "Commenter", "Kommentar"),
	},
	{
		id: "date",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Date", "Date", "Datum"),
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		align: "right",
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const clients = [
	{
		fund: "Fund 1",
		operations: "Insurance",
		amountIn: "1000000",
		amountOut: "20000",
		interest: "7.6%",
		endDate: "31/12/2025",
		periodicity: "Yearly",
		comment: "",
		date: "15/12/2019",
	},
	{
		fund: "IT Funds",
		operations: "Investment",
		amountIn: "25000000",
		amountOut: "50000",
		interest: "10%",
		endDate: "6/12/30",
		periodicity: "Monthly",
		comment: "",
		date: "6/12/18",
	},
	{
		fund: "Fund 6",
		operations: "Interest",
		amountIn: "70000",
		amountOut: "20000",
		interest: "4.3%",
		endDate: "22/2/2022",
		periodicity: "Yearly",
		comment: "",
		date: "22/2/2020",
	},
];

const FundOperations = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState(false);
	const [deleteRow, setDeleteRow] = useState({});
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [rows, setRows] = useState([]);

	const { activities } = useSelector((state) => state.activities);

	const handleOpenDeleteDialog = (row) => {
		setDeleteRow(row);
		setOpen(true);
	};

	const handleEditActivity = (row) => {
		history(`/clients/add/${row.id}`);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleDeleteActivity = () => {};

	return (
		<div>
			<Table
				dense
				loading={true}
				title={getTranslation(
					"Fund Operations",
					"Opérations de fonds",
					"Fondsoperationen"
				)}
				colums={columns}
				rows={clients}
				count={clients.length}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
				handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				viewEdit
				onEdit={(row) => handleEditActivity(row)}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
			/>
			<DeleteDialog
				open={open}
				title={getTranslation(
					"Delete Activity Type",
					"Supprimer le type d'activité",
					"Aktivitätstyp löschen"
				)}
				onClose={() => setOpen(false)}
				onSubmit={(e) => handleDeleteActivity(deleteRow)}
			/>
			<Box py={1}>
				<Paper>
					<Box py={3} pl={2}>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<Box>
									<Autocomplete
										id="country-auto-complete"
										size="small"
										options={[]}
										//   getOptionLabel={(option) => option.title}
										style={{ width: 300 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label={getTranslation(
													"Select Fund Operation",
													"Sélectionnez l'opération de fonds",
													"Wählen Sie Fondsoperation aus"
												)}
												variant="outlined"
											/>
										)}
									/>
								</Box>
							</Grid>

							<Grid item>
								<Button variant="outlined" size="large">
									{getTranslation("Add", "Ajouter", "Addieren")}
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Paper>
			</Box>
		</div>
	);
};

export default FundOperations;
