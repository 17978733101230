import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { CheckCircle, DoneAll, GroupAdd, LineStyle, PlaylistAdd, Settings, VideoLabel } from "@material-ui/icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { SignatureIcon } from "../../../components/Icons";
export const MinutesProgressBar = ({ meetingInfo, getTranslation }) => {
  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    },
  });

  const [activeStep, setActiveStep] = useState(0);

  useEffect(()=>{
    setActiveStep(meetingInfo?.minuteReportStatus-1);
  },[meetingInfo?.minuteReportStatus])


  const steps = getSteps();

  function getSteps() {
    return ["Generate", "Internal Review", "Fund Manger Review","Signing","Completed"];
  }
  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      "& $line": {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    completed: {
      "& $line": {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })(StepConnector);
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    const icons = {
      1: <LineStyle />,
      2: <DoneAll />,
      3: <DoneAll />,
      4: <SignatureIcon />,
      5: <CheckCircle />,
    };
	console.log("jisdhasjldhkjahdksd",activeStep,String(props.icon))

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {/* 1st step */}
      <div
        style={{
          height: "60px",
          width: "160px",
          //border: "2px solid orange",
          border: `2px solid ${
            meetingInfo?.minuteReportStatus === 0 ||
            meetingInfo?.minuteReportStatus === 1
              ? "orange"
              : "green"
          }`,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>{getTranslation("Generate", "Generate", "Generate")}</div>
        <div
          style={{
            width: "60%",
          }}
        >
          <hr />
        </div>
        {meetingInfo?.minuteReportStatus >= 2 ? (
          <div>{getTranslation("Done", "Done", "Done")}</div>
        ) : (
          <div>
            {getTranslation("In Progress", "In Progress", "In Progress")}
          </div>
        )}
      </div>
      <div
        style={{
          height: "3px",
          width: "90px",
          backgroundColor: "lightgrey",
        }}
      ></div>
      {/* 2nd step */}
      <div
        style={{
          height: "60px",
          width: "250px",
          //border: "2px solid orange",
          border: `2px solid ${
            meetingInfo?.minuteReportStatus === 2
              ? "orange"
              : meetingInfo?.minuteReportStatus === 3
              ? "green"
              : meetingInfo?.minuteReportStatus === 0 ||
                meetingInfo?.minuteReportStatus === 1
              ? "lightgrey"
              : "green"
          }`,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          {getTranslation(
            "Waiting for Internal Review",
            "Waiting for Internal Review",
            "Waiting for Internal Review"
          )}
        </div>
        <div
          style={{
            width: "60%",
          }}
        >
          <hr />
        </div>
        {meetingInfo?.minuteReportStatus == 1 ||
          (meetingInfo?.minuteReportStatus == 2 &&
            meetingInfo?.minuteReportStatus < 3 && (
              <div>
                {getTranslation("In Progress", "In Progress", "In Progress")}
              </div>
            ))}
        {meetingInfo?.minuteReportStatus >= 3 && (
          <div>{getTranslation("Done", "Done", "Done")}</div>
        )}
      </div>
      <div
        style={{
          height: "3px",
          width: "90px",
          backgroundColor: "lightgrey",
        }}
      ></div>
      {/* 3rd step */}
      <div
        style={{
          height: "60px",
          width: "250px",
          //border: "2px solid orange",
          border: `2px solid ${
            meetingInfo?.minuteReportStatus === 3
              ? "orange"
              : meetingInfo?.minuteReportStatus === 4
              ? "green"
              : meetingInfo?.minuteReportStatus === 0 ||
                meetingInfo?.minuteReportStatus === 1
              ? "lightgrey"
              : "lightgrey"
          }`,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          {getTranslation(
            "Waiting for FundManger Review",
            "Waiting for FundManger Review",
            "Waiting for FundManger Review"
          )}
        </div>
        <div
          style={{
            width: "60%",
          }}
        >
          <hr />
        </div>
        {meetingInfo?.minuteReportStatus == 3 &&
          meetingInfo?.minuteReportStatus < 4 && (
            <div>
              {getTranslation("In Progress", "In Progress", "In Progress")}
            </div>
          )}
        {meetingInfo?.minuteReportStatus == 4 && (
          <div>{getTranslation("Done", "Done", "Done")}</div>
        )}
      </div>
      <div
        style={{
          height: "3px",
          width: "90px",
          backgroundColor: "lightgrey",
        }}
      ></div>
      {/* 4th step */}
      <div
        style={{
          height: "60px",
          width: "160px",
          //border: "2px solid orange",
          border: `2px solid ${
            meetingInfo?.minuteReportStatus === 4 ? "green" : "lightgrey"
          }`,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>{getTranslation("Approved", "Approved", "Approved")}</div>
        <div
          style={{
            width: "60%",
          }}
        >
          <hr />
        </div>
        {meetingInfo?.minuteReportStatus == 4 &&
          meetingInfo?.minuteReportStatus < 5 && (
            <div>
              {getTranslation("In Progress", "In Progress", "In Progress")}
            </div>
          )}
        {meetingInfo?.minuteReportStatus == 5 && (
          <div>{getTranslation("Done", "Done", "Done")}</div>
        )}
      </div>
    </div>
  );
};
