import React, { useEffect, useState } from "react";
import Table from "../../../../components/table";
import DeleteDialog from "../../../../components/DeleteDialog";
import AddForm from "./formAdd";
import { Create, Get } from "../../../../actions";
import {
	Get_AllForms_URL,
	Post_DeleteForm_URL,
} from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { Post_UpsertForm_URL } from "../../../../constants/apiUrls";
const columns = [
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: "Form",
	},
	{
		id: "actions",
		align: "right",
		label: "Actions",
	},
];

const FormList = () => {
	///////// Delete Dialog ///////////////
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [deleteRow, setDeleteRow] = useState(null);
	/////////// handler states ///////////
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let reportFormList = localStorage.getItem("reportFormList");
	if (reportFormList == null) {
		localStorage.setItem("reportFormList", 10);
		reportFormList = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(reportFormList);
	const [forms, setForms] = useState([]);
	const [formsLoading, setFormsLoading] = useState(false);

	/////////////// Add Form ///////////////
	const [editForm, setEditForm] = useState(null);
	const [isSubmitting, setSubmitting] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	/////////////// Form Handlers ///////////////
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("reportFormList", event.target.value);
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const handleSearch = (searchString) => {
		setSearchString(searchString);
	};
	const handleDelete = () => {
		setIsDeleting(true);
		Create(
			{
				id: deleteRow?.id,
			},
			Post_DeleteForm_URL,
			null,
			(resp) => {
				setEditForm(null);
				setIsDeleting(false);
				setOpenDeleteDialog(false);
				enqueueSnackbar("Form Deleted Successfully", { variant: "success" });
				var newForms = forms.filter((form) => form.id !== deleteRow.id);
				setForms(newForms);
			},
			(error) => {}
		);
	};

	////// Backend APis //////
	useEffect(() => {
		loadForms();
	}, []);
	const loadForms = () => {
		setFormsLoading(true);
		Get(
			{},
			Get_AllForms_URL,
			null,
			(resp) => {
				setForms(resp.data);
				setFormsLoading(false);
			},
			(error) => {
				setFormsLoading(false);
				enqueueSnackbar(error.message, {
					variant: "error",
					autoHideDuration: 1000,
				});
			}
		);
	};

	const handleAddForm = (values, actions) => {
		setSubmitting(true);
		Create(
			values,
			Post_UpsertForm_URL,
			null,
			(resp) => {
				console.log("jksdgfsdf", resp.data);
				setSubmitting(false);
				editForm
					? enqueueSnackbar("Form Updated Successfully", { variant: "success" })
					: enqueueSnackbar("Form Added Successfully", { variant: "success" });
				actions.setSubmitting(false);
				loadForms();
				setEditForm(null);
				actions.resetForm();
			},
			(error) => {
				setSubmitting(false);
				actions.setSubmitting(false);
				// enqueueSnackbar(error.data, {
				//   variant: "error",
				// });
			}
		);
	};

	const handleCancel = () => {};
	return (
		<>
			<AddForm
				editForm={editForm}
				onSubmit={(values, actions) => handleAddForm(values, actions)}
				submitting={isSubmitting}
				onCancel={() => setEditForm(null)}
			/>
			<br />
			<Table
				auto
				loading={formsLoading}
				title="List of Forms"
				colums={columns}
				sortBy="form"
				rows={forms}
				count={forms.length}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				viewSearch
				handleSearch={handleSearch}
				searchString={searchString}
				viewEdit
				onEdit={(row) => setEditForm(row)}
				viewDelete
				onDelete={(row) => {
					setDeleteRow(row);
					setOpenDeleteDialog(true);
				}}
			/>
			<DeleteDialog
				title="Delete Form"
				deleting={isDeleting}
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				onSubmit={handleDelete}
			/>
		</>
	);
};

export default FormList;
