import { Box, CardContent, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useState } from "react";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import DialogComponent from "../../../components/Dialog";
import { getTranslation } from "../../../heplers/translationHelper";
import * as Yup from "yup";
import { Cancel } from "@material-ui/icons";
import { useEffect } from "react";
import { CountrySelection } from "../../../constants/countries";
const AddLegalForm = ({
  isSubmitting,
  editLegalForm,
  open,
  onClose,
  onSubmit,
}) => {

  const initialValues = {
    isGeneralPartnerNeeded: false,
    legalForm:  "",
    country: "Luxembourg",
  };

  
  const basicValidationSchema = Yup.object().shape({
    legalForm: Yup.string().required("Legal Form is required"),
    country: Yup.string().required("Country is required"),
  });
  const [defaultValue,setDefaultValue] =useState(initialValues);

  useEffect(() => {
    if (editLegalForm!=null)
      setDefaultValue(editLegalForm);
    else
      setDefaultValue(initialValues);
  },[editLegalForm])

  return (
    <DialogComponent
      fullWidth
      title={ editLegalForm
        ? getTranslation(
            "Update Legal Form",
            "Update Legal Form",
            "Update Legal Form"
          )
        : getTranslation(
        "Add Legal Form",
        "Add Legal Form",
        "Add Legal Form"
      )}
      open={open}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form>
            
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    multiline
                    fullWidth
                    label={getTranslation(
                      "Legal Form",
                      "Legal Form",
                      "Legal Form"
                    )}
                    required
                    size="small"
                    variant="outlined"
                    name=""
                    id="legalForm"
                    {...getFieldProps("legalForm")}
                    error={touched.legalForm && Boolean(errors.legalForm)}
                    helperText={touched.legalForm && errors.legalForm}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CountrySelection 
                  selected={values.country}
                  label={getTranslation("Country","Country","Country")} 
                  onChange={(e,country)=>{
                    setFieldValue("country",country)
                  }}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography>{getTranslation("General Partner Needed","General Partner Needed","General Partner Needed")} ?</Typography>
                  <ToggleButtonGroup
                    value={values.isGeneralPartnerNeeded}
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "left",
                    }}
                    exclusive
                    onChange={(e, value) => {
                      if (value !== null && value !== undefined) {
                        setFieldValue("isGeneralPartnerNeeded", value);
                      }
                    }}
                    aria-label="text alignment"
                    name="isPeriod"
                    size="small"
                  >
                    <ToggleButton value={true} aria-label="left aligned">
                      Yes
                    </ToggleButton>
                    <ToggleButton value={false} aria-label="centered">
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>

                </Grid>
              </Grid>
              <br />
              <Box
                pr={1}
                pb={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {editLegalForm ?
                <IconButton onClick={onClose}>
                  <Cancel/>
                </IconButton>  
                :null}
                <ButtonWithLoading
                  title={
                    editLegalForm
                      ? getTranslation(
                          "Update",
                          "Mettre à jour",
                          "Aktualisieren"
                        )
                      : getTranslation("Add", "Ajouter", "Hinzufügen")
                  }
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardContent>
          </Form>
        )}
      </Formik>
    </DialogComponent>
  );
};
export default AddLegalForm;
