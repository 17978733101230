export const paymentTypes = [
    {
        id:0,
        value: "Share Capital",
        defaultEnglish: "Share Capital",
        defaultFrench: "Capital social",
        defaultGerman: "Gesellschaftskapital",
    },
    {
        id:1,
        value: "Capital Reserve",
        defaultEnglish: "Capital Reserve",
        defaultFrench: "Réserve de capital",
        defaultGerman: "Kapitalreserve",
    },
    {
        id:2,
        value: "Bond",
        defaultEnglish: "Bond",
        defaultFrench: "Bon",
        defaultGerman: "Bond",
    },
    {
        id:3,
        value: "Loan",
        defaultEnglish: "Loan",
        defaultFrench: "Prêt",
        defaultGerman: "Loan",
    },
    {
        id:4,
        value: "Divident",
        defaultEnglish: "Divident",
        defaultFrench: "Dividende",
        defaultGerman: "Dividende",
    },
    {
        id:5,
        value: "Interest",
        defaultEnglish: "Interest",
        defaultFrench: "Intérêt",
        defaultGerman: "Zins",
    },
    {
        id:6,
        value: "Repayment",
        defaultEnglish: "Repayment",
        defaultFrench: "Remboursement",
        defaultGerman: "Repayment",
    },
    {
        id:7,
        value: "Other",
        defaultEnglish: "Other",
        defaultFrench: "Autre",
        defaultGerman: "Andere",
    },
];


