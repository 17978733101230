import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import Card from "../../../../components/Card";
import Switch from "../../../../components/Switch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { FormLoader } from "../../../../components/FormLoader";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Create, Get } from "../../../../actions";
import {
  Post_GenerateMeetings_URL,
  Get_AllCeresServices_URL,
  Get_File_URL,
  GET_AllUsersWithoutAuthorization_URL,
  SAVE_Contact_URL,
  GET_AllContactList_URL,
  Get_AllManagementCompanyFeeServicesByType_URL,
  Get_AgendaTemplateList_URL,
  Get_AllAgendaTemplateItems_URL,
} from "../../../../constants/apiUrls";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { MyTimePicker } from "../../../../components/TimePicker";
import { DatePicker } from "../../../../components/DatePicker";
import { BoardMembers } from "./boardMembers";
import { AddAskingAgenda } from "./components/agenda/addAskingAgenda";
import { AskingAgendaList } from "./components/agenda/askingAgendaList";
import { AddDocument } from "./components/documents/addDocument";
import { DocumentList } from "./components/documents/documentList";
import { SelectionCcList } from "./components/reminders/selectionCcList";
import { AddGuest } from "./components/guest/addGuest";
import { GuestList } from "./components/guest/guestList";
import { UploadFiles } from "../../../../components/Upload";
import { LanguageSelection } from "../../../../constants/languages";
import TableComponent from "../../../../components/table";
import { getTranslation } from "../../../../heplers/translationHelper";
import { getDateIgnoreTimezone } from "../../../../heplers/dateHelper";

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Name", "Nom", "Name"),
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Email", "E-mail", "Email"),
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    align: "right",
    label: getTranslation("Actions", "Actions", "Aktionen"),
  },
];
const GenerateRegular = ({ managementCompanyId }) => {
  const [userListLoading, setUserListLoading] = useState(false);
  const [ceresServiceLoading, setCeresServiceLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [ceresServices, setCeresServices] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [filesList, setFilesList] = useState();
  let uniqueId = 1;
  useEffect(() => {
    setUserListLoading(true);
    Get(
      {},
      GET_AllUsersWithoutAuthorization_URL,
      history,
      (resp) => {
        setUserList(resp.data);
        setUserListLoading(false);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    loadCeresServices(1);
    loadContacts();
    loadTemplates();
  }, []);

  const loadCeresServices = (feeType) => {
    setCeresServiceLoading(true);
    Get(
      {
        id: managementCompanyId,
        feeType: feeType,
      },
      Get_AllManagementCompanyFeeServicesByType_URL,
      null,
      (resp) => {
        setCeresServices(resp.data);
        setCeresServiceLoading(false);
      },
      (error) => {}
    );
  };

  const loadContacts = () => {
    setContactsLoading(true);
    Get(
      {},
      GET_AllContactList_URL,
      null,
      (resp) => {
        const arr = [];
        resp.data.forEach((item) => {
          item.contactId = item.id;
          // item.memberId=item.id;
          arr.push(item);
        });
        setContacts(arr);
        setContactsLoading(false);
      },
      (error) => {}
    );
  };

  const loadTemplates = () => {
    setTemplatesLoading(true);
    Get(
      {},
      Get_AgendaTemplateList_URL,
      null,
      (resp) => {
        setTemplates(resp.data);
        setTemplatesLoading(false);
      },
      (error) => {}
    );
  };

  const loadTemplateItems = (templateId, callback) => {
    // setTemplatesLoading(true);
    Get(
      {
        agendaTemplateId: templateId,
      },
      Get_AllAgendaTemplateItems_URL,
      null,
      (resp) => {
        callback(resp.data);
        // setTemplatesLoading(false);
      },
      (error) => {}
    );
  };

  const initialValues = {
    subject: "",
    address: "",
    meetingTime: new Date(),
    meetingDuration: new Date(),
    meetingDate: new Date(),
    language: "",
    personInchargeId: "",
    reminderInterval: 0,
    isReminderEnabled: false,
    reminderText: "",
    meetingType: 0,
    managementCompanyId: managementCompanyId,
    companyCeresServiceId: 0,
    isDocumentRequired: false,
    isAskingAgendaAllowed: false,
    askingAgendaInterval: 0,

    periodicity: 0,
    guests: [],
    meetingDocuments: [],
    agendas: [],
    meetingMembers: [],
    files: [],
  };

  const basicValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Field is required"),
    address: Yup.string().required("Field is required"),
    meetingTime: Yup.string().required("Field Time is requied"),
    meetingDate: Yup.string().required("Field is required"),
    language: Yup.string().required("Field is required"),
    periodicity: Yup.number()
      .min(10, "Minimum 10 days allowed.")
      .required("Field is required"),
    personInchargeId: Yup.string().required("Field is required"),
    companyCeresServiceId: Yup.number().min(1).required("Field is required"),
  });

  return (
    <>
      <FormLoader progress={userListLoading}>
        <Formik
          initialValues={initialValues}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            Create(
              values,
              Post_GenerateMeetings_URL,
              history,
              (resp) => {
                actions.setSubmitting(false);
                enqueueSnackbar(resp.data, {
                  variant: "success",
                });
                actions.resetForm();
              },
              (error) => {
                actions.setSubmitting(false);
                if (error.data?.message) {
                  enqueueSnackbar(error.data?.message, {
                    variant: "error",
                  });
                } else
                  enqueueSnackbar(error.data, {
                    variant: "error",
                  });
              }
            );
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
            handleChange,
          }) => (
            <Form>
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                {/* Step 1 */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToggleButtonGroup
                    size="small"
                    id="meetingType"
                    value={values.meetingType}
                    exclusive
                    onChange={(event, newAlignment) => {
                      setFieldValue("meetingType", newAlignment);
                      setFieldValue("meetingMembers", []);
                    }}
                  >
                    <ToggleButton value={0} aria-label="left aligned">
                      <Typography>Board</Typography>
                    </ToggleButton>
                    <ToggleButton value={1} aria-label="centered">
                      <Typography>Shareholder</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Autocomplete
                    loading={ceresServiceLoading}
                    options={ceresServices}
                    getOptionLabel={(option) => option.ceresServiceTitle}
                    size="small"
                    onChange={(e, value) => {
                      setFieldValue("companyCeresServiceId", value?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Ceres Service",
                          "Service Cérès",
                          "Ceres-Dienst"
                        )}
                        variant="outlined"
                        error={
                          touched.companyCeresServiceId &&
                          Boolean(errors.companyCeresServiceId)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    id="subject"
                    label={getTranslation("Subject", "Sujette", "Fach")}
                    required
                    size="small"
                    variant="outlined"
                    name="subject"
                    {...getFieldProps("subject")}
                    error={touched.subject && Boolean(errors.subject)}
                    helperText={touched.subject && errors.subject}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Autocomplete
                    options={userList}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    onChange={(e, value) => {
                      setFieldValue("personInchargeId", value?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={getTranslation(
                          "Person Incharge",
                          "Personne en charge",
                          "Verantwortlicher"
                        )}
                        variant="outlined"
                        error={
                          touched.personInchargeId &&
                          Boolean(errors.personInchargeId)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <LanguageSelection
                    fullWidth
                    id="language"
                    label={getTranslation("Language", "Langue", "Sprache")}
                    required
                    size="small"
                    variant="outlined"
                    name="language"
                    onChange={(e, language) =>
                      setFieldValue("language", language)
                    }
                    error={touched.language && Boolean(errors.language)}
                    helperText={touched.language && errors.language}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    id="periodicity"
                    label={getTranslation(
                      "Periodicity (Days)",
                      "Périodicité (jours)",
                      "Periodizität (Tage)"
                    )}
                    required
                    size="small"
                    variant="outlined"
                    name="periodicity"
                    type="number"
                    {...getFieldProps("periodicity")}
                    error={touched.periodicity && Boolean(errors.periodicity)}
                    helperText={touched.periodicity && errors.periodicity}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <DatePicker
                    fullWidth
                    variant="outlined"
                    label={getTranslation(
                      "Meeting Date",
                      "Date de la réunion",
                      "Datum des Treffens"
                    )}
                    value={values.meetingDate}
                    onChange={(value) => {
                      setFieldValue("meetingDate", value);
                    }}
                    required
                    error={touched.meetingDate && Boolean(errors.meetingDate)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <MyTimePicker
                    fullWidth
                    id="meetingTime"
                    variant="inline"
                    size="small"
                    // id="Incorporation-Date-inline"
                    label={getTranslation("Time", "Temps", "Zeit")}
                    name="meetingTime"
                    value={values.meetingTime}
                    onChange={(value) => {
                      console.log("akjldljkashdjasd",value)
                      const theDate = getDateIgnoreTimezone(value)
                      setFieldValue("meetingTime", value);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "Meeting Time",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    error={touched.meetingTime && Boolean(errors.meetingTime)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <MyTimePicker
                    fullWidth
                    id="meetingDuration"
                    variant="inline"
                    size="small"
                    // id="Incorporation-Date-inline"
                    label={getTranslation(
                      "Meeting Duration",
                      "Durée de la réunion",
                      "Treffendauer"
                    )}
                    name="meetingDuration"
                    value={values.meetingDuration}
                    onChange={(value) => {
                      setFieldValue("meetingDuration", value);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "Meeting Duration",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    error={
                      touched.meetingDuration && Boolean(errors.meetingDuration)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    multiline
                    label="Address"
                    required
                    size="small"
                    variant="outlined"
                    {...getFieldProps("address")}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {values.meetingType === 0 ? (
                    <BoardMembers
                      title={getTranslation(
                        "Board Members",
                        "Membres du conseil d'administration",
                        "Vorstandsmitglieder"
                      )}
                      meetingType={0}
                      MCId={managementCompanyId}
                      onSelectionChange={(members) =>
                        setFieldValue("meetingMembers", members)
                      }
                      selectedMembers={values.meetingMembers}
                    />
                  ) : (
                    <BoardMembers
                      title={getTranslation(
                        "Shareholder Members",
                        "Membres actionnaires",
                        "Aktionärsmitglieder"
                      )}
                      meetingType={1}
                      MCId={managementCompanyId}
                      onSelectionChange={(members) =>
                        setFieldValue("meetingMembers", members)
                      }
                      selectedMembers={values.meetingMembers}
                    />
                  )}
                </Grid>
                {/* Step 2 */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card title={getTranslation("Guests", "Invitées", "Gäste")}>
                    <CardContent>
                      <AddGuest
                        contactList={contacts}
                        persons={values.guests}
                        onPersonChanged={(persons) => {
                          setFieldValue("guests", persons);
                        }}
                        onSubmitGuest={(guest) => {
                          guest.contactId = guest.id;
                          setFieldValue("guests", [...values.guests, guest]);
                          setContacts([...contacts, guest]);
                        }}
                      />
                      <GuestList
                        guests={values.guests}
                        onDelete={(row) => {
                          setFieldValue(
                            "guests",
                            values.guests.filter((x) => x.id !== row.id)
                          );
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                {/* Step 3 */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card
                    title={getTranslation(
                      "Documents",
                      "Documents",
                      "Unterlagen"
                    )}
                  >
                    <CardContent>
                      <FormControl>
                        <FormControlLabel
                          label={getTranslation(
                            "Asking For Documents",
                            "Demander des documents",
                            "Dokumente anfordern"
                          )}
                          labelPlacement="start"
                          control={
                            <Checkbox
                              id="isDocumentRequired"
                              checked={values.isDocumentRequired}
                              onChange={handleChange}
                            />
                          }
                        />
                      </FormControl>
                      {values.isDocumentRequired ? (
                        <DocumentList
                          contacts={contacts}
                          documents={values.meetingDocuments}
                          onAddNewDocument={(document) => {
                            if (
                              values.meetingDocuments.findIndex(
                                (x) => x.contactId === document.contactId
                              ) < 0
                            ) {
                              document.id = new Date().getTime();
                              const arr = [
                                ...values.meetingDocuments,
                                document,
                              ];
                              setFieldValue("meetingDocuments", arr);
                            } else {
                              enqueueSnackbar("Already Exists", {
                                variant: "error",
                              });
                            }
                          }}
                          onDeleteDocument={(document) =>
                            setFieldValue(
                              "meetingDocuments",
                              values.meetingDocuments.filter(
                                (x) => x.contactId !== document.contactId
                              )
                            )
                          }
                        />
                      ) : null}
                    </CardContent>
                  </Card>
                </Grid>
                {/* Step 4 */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card
                    title={getTranslation("Agenda", "Ordre du jour", "Agenda")}
                  >
                    <CardContent>
                      <FormControl>
                        <FormControlLabel
                          label={getTranslation(
                            "Asking For Agenda",
                            "Demander l'ordre du jour",
                            "Tagesordnung erfragen"
                          )}
                          labelPlacement="start"
                          control={
                            <Checkbox
                              id="isAskingAgendaAllowed"
                              checked={values.isAskingAgendaAllowed}
                              onChange={handleChange}
                            />
                          }
                        />
                      </FormControl>
                      {values.isAskingAgendaAllowed ? (
                        <>
                          <br />
                          <br />
                          <TextField
                            id="askingAgendaInterval"
                            label={getTranslation(
                              "Interval (days before)",
                              "Intervalle (jours avant)",
                              "Intervall (Tage vorher)"
                            )}
                            required
                            size="small"
                            variant="outlined"
                            name="periodicity"
                            type="number"
                            {...getFieldProps("askingAgendaInterval")}
                            error={
                              touched.askingAgendaInterval &&
                              Boolean(errors.askingAgendaInterval)
                            }
                            helperText={
                              touched.askingAgendaInterval &&
                              errors.askingAgendaInterval
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: "400px" }}
                          />
                          <br />
                          <br />
                          <Autocomplete
                            style={{ width: "400px" }}
                            options={templates}
                            getOptionLabel={(option) => option.name}
                            size="small"
                            onChange={(e, value) => {
                              loadTemplateItems(value.id, (agendas) => {
                                setFieldValue("agendas", agendas);
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label={getTranslation(
                                  "Template",
                                  "Modèle",
                                  "Vorlage"
                                )}
                                variant="outlined"
                                error={
                                  touched.template && Boolean(errors.template)
                                }
                                helperText={touched.template && errors.template}
                              />
                            )}
                          />
                          <br />
                          <AskingAgendaList
                            agendas={values.agendas}
                            onNewAgendaAdded={(agenda) =>
                              setFieldValue("agendas", [
                                ...values.agendas,
                                agenda,
                              ])
                            }
                            onAgendaRemoved={(agenda) =>
                              setFieldValue(
                                "agendas",
                                values.agendas.filter((x) => x != agenda)
                              )
                            }
                            onUpdateAgenda={(index, agenda) => {
                              const arr = values.agendas;
                              arr[index] = agenda;
                              setFieldValue("agendas", arr);
                            }}
                          />
                        </>
                      ) : null}
                    </CardContent>
                  </Card>
                </Grid>
                {/* Step 5 */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card
                    title={getTranslation(
                      "Reminders",
                      "Rappels",
                      "Erinnerungen"
                    )}
                  >
                    <CardContent>
                      <FormControl>
                        <FormControlLabel
                          label={getTranslation(
                            "Send Reminder",
                            "Envoyer un rappel",
                            "Eine Erinnerung senden"
                          )}
                          labelPlacement="start"
                          control={
                            <Checkbox
                              id="isReminderEnabled"
                              checked={values.isReminderEnabled}
                              onChange={handleChange}
                            />
                          }
                        />
                      </FormControl>
                      {values.isReminderEnabled ? (
                        <>
                          <br />
                          <br />
                          <TextField
                            style={{ width: "400px" }}
                            id="reminderInterval"
                            label={getTranslation(
                              "Interval (days)",
                              "Intervalle (jours)",
                              "Intervall (Tage)"
                            )}
                            required
                            size="small"
                            type="number"
                            variant="outlined"
                            {...getFieldProps("reminderInterval")}
                            error={
                              touched.reminderInterval &&
                              Boolean(errors.reminderInterval)
                            }
                            helperText={
                              touched.reminderInterval &&
                              errors.reminderInterval
                            }
                            // InputLabelProps={{}}
                          />
                          <br />
                          <br />
                          <TextField
                            fullWidth
                            id="reminderText"
                            label={getTranslation("Text", "Texte", "Text")}
                            required
                            size="small"
                            variant="outlined"
                            {...getFieldProps("reminderText")}
                            error={
                              touched.reminderText &&
                              Boolean(errors.reminderText)
                            }
                            helperText={
                              touched.reminderText && errors.reminderText
                            }
                            InputLabelProps={{}}
                          />
                          <br />
                          <br />

                          <SelectionCcList
                            selectedMembers={values.meetingMembers.filter(
                              (x) => x.sendReminderCC
                            )}
                            members={values.meetingMembers}
                            onAddCC={(member) => {
                              const index = values.meetingMembers.findIndex(
                                (x) => x == member
                              );
                              const arr = values.meetingMembers;
                              member.sendReminderCC = true;
                              arr[index] = member;
                              setFieldValue("meetingMembers", arr);
                            }}
                            onRemoveCC={(member) => {
                              const index = values.meetingMembers.findIndex(
                                (x) => x == member
                              );
                              const arr = values.meetingMembers;
                              member.sendReminderCC = false;
                              arr[index] = member;
                              setFieldValue("meetingMembers", arr);
                            }}
                          />
                        </>
                      ) : null}
                    </CardContent>
                  </Card>
                </Grid>
                {/* Step 6 */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card
                    title={getTranslation(
                      "Upload Document",
                      "Télécharger un document",
                      "Dokument hochladen"
                    )}
                  >
                    <CardContent>
                      <UploadFiles
                        files={values.files}
                        history={history}
                        onAddFile={(file) => {
                          setFieldValue("files", [
                            ...values.files,
                            { fileName: file },
                          ]);
                        }}
                        //onAddFile={handleAddFile}
                        onDeleteFile={(file) => {
                          const arr = values.files.filter(
                            (x) => x.fileName == file
                          );
                          setFieldValue("files", arr);
                        }}
                        getFileName={(file) => file.fileName}
                      />
                    </CardContent>
                    <CardActions>
                      <Box
                        mt={5}
                        pr={1}
                        pb={1}
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ButtonWithLoading
                          title={getTranslation(
                            "Generate",
                            "produire",
                            "Generieren"
                          )}
                          size="medium"
                          variant="contained"
                          color="primary"
                          loading={isSubmitting}
                          onClick={handleSubmit}
                        />
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormLoader>
    </>
  );
};

export default GenerateRegular;
