import {
  Box,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Cancel } from "@material-ui/icons";
import { useMemo } from "react";

const TablePreview =({ columns, onNext, onPrevious, onCancel }) => {
  
  console.log("dtretrtre",columns);
  var _ = require("lodash");
  return (
    <>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {_.sortBy(columns, (column) => column?.sort).map(
                  (column, index, array) => (
                    <TableCell key={index}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        {column?.title}
                        <IconButton
                          onClick={() => {
                            onPrevious(array, index);
                          }}
                        >
                          {index === 0 ? null : <ArrowLeftIcon />}
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            onNext(array, index);
                          }}
                        >
                          {index === columns.length - 1 ? null : (
                            <ArrowRightIcon />
                          )}
                        </IconButton>
                        <IconButton onClick={() => onCancel(column)}>
                          <Cancel />
                        </IconButton>
                      </Box>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
    </>
  );
};
export default TablePreview;
