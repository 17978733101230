import { useParams } from "react-router-dom";
import { Create, Get } from "../../actions";
import {
  Get_AllCircularResolutionsWithPagination_URL,
  Get_ManagementCompanyFeeAgreement_URL,
  Post_InsertCircularResolution_URL,
} from "../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { GenerateCircularResolution } from "./component";
import { Collapser } from "../../components/Collapser";
import { CircularResolutionList } from "./component/circularResolution/components/resolutionList";
import { getTranslation } from "../../heplers/translationHelper";
import { MeetingsSubmenu } from "../../layout/subMenu";
import { Alert, AlertTitle } from "@material-ui/lab";

export const CircularResolution = () => {
  const { managementCompanyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [circularMeetings, setCircularMeetings] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleGenerateCircularMeeting = (meeting, actions) => {
    setIsSubmitting(true);
    Create(
      meeting,
      Post_InsertCircularResolution_URL,
      null,
      (resp) => {
        setIsSubmitting(false);
        actions.resetForm();
        enqueueSnackbar(
          "Circular Resolution generated. Email to sponser for confirmation has been sent.",
          {
            variant: "success",
          }
        );
        loadCircularMeetings();
      },
      (error) => {
        setIsSubmitting(false);
        enqueueSnackbar(error.data, {
          variant: "error",
        });
      }
    );
  };
  var d = new Date();
  d.setMonth(d.getMonth() - 3);
  const [fromDate, setFromDate] = useState(d);
  var to = new Date();
  to.setMonth(to.getMonth() + 3);
  const [toDate, setToDate] = useState(to);
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [defaultOpen, setDefaultOpen] = useState(true);
  const [meeting, setMeeting] = useState();
  const loadCircularMeetings = () => {
    Get(
      {
        managementCompanyId: managementCompanyId,
        searchString: searchString,
        from: fromDate,
        to: toDate,
        pageNumber: page,
        pageSize: pageSize,
      },
      Get_AllCircularResolutionsWithPagination_URL,
      null,
      (resp) => {
        setCircularMeetings(resp.data);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    loadCircularMeetings();
  }, []);

  useEffect(() => {
    loadFeeAgreement({ id: managementCompanyId });
  }, [managementCompanyId]);

  // load Fee agreement
  const loadFeeAgreement = () => {
    Get(
      { id: managementCompanyId },
      Get_ManagementCompanyFeeAgreement_URL,
      null,
      (resp) => {
        setMeeting(resp.data);
      },
      (error) => {}
    );
  };
  return (
    <div>
      <MeetingsSubmenu managementCompanyId={managementCompanyId} />

      {meeting?.feeAgreementStatus === 7 ? (
        <Alert severity="error">
          {getTranslation(
            "Fee Agreement does not exist",
            "Le contrat d'honoraires n'existe pas",
            "Honorarvereinbarung existiert nicht"
          )}
        </Alert>
      ) : meeting?.feeAgreementStatus!=1 ? (
        <Alert severity="error">
          {getTranslation(
            "Fee Agreement not signed but you can generate meetings",
            "Accord d'honoraires non signé mais vous pouvez générer des rendez-vous",
            "Gebührenvereinbarung nicht unterzeichnet, aber Sie können Meetings generieren"
          )}
        </Alert>
      ) : null}
      <br />
      <br />

      <Collapser
        open={defaultOpen}
        title={getTranslation("Resolution", "Résolution", "Auflösung")}
      >
        <CircularResolutionList meetings={circularMeetings} />
      </Collapser>
      <br />
      {meeting?.feeAgreementStatus !== 7 ? (
        <Collapser
          title={getTranslation(
            "Generate Circular Resolution",
            "Générer une résolution circulaire",
            "Kreisauflösung generieren"
          )}
        >
          <GenerateCircularResolution
            managementCompanyId={managementCompanyId}
            isSubmitting={isSubmitting}
            onGenerate={handleGenerateCircularMeeting}
          />
        </Collapser>
      ) : null}
    </div>
  );
};
