import React, { useState } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import Card from "../../../components/Card";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ClassicTextEditor } from "../../../components/TextEditor";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Post_CommentsOfMeeting_URL } from "../../../constants/apiUrls";
import { useNavigate, useParams } from "react-router";
import { Get, Create } from "../../../actions";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../heplers/translationHelper";

export const Comments = () => {
  const { meetingId,teamMemberId } = useParams();
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    comments: "",
    meetingId: meetingId,
    teamMemberId:teamMemberId
  };
  const basicValidationSchema = Yup.object().shape({
    comments: Yup.string().required("Field is required"),
    meetingId: Yup.string().required("Field is required"),
  });
  const defaultValue = initialValues;
  return (
    <Formik
      enableReinitialize
      initialValues={defaultValue}
      validationSchema={basicValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        Create(
          values,
          Post_CommentsOfMeeting_URL,
          history,
          (resp) => {
            actions.setSubmitting(false);
            enqueueSnackbar("Added comments for Meeting", { variant: "info" });
          },
          (error) => {
            actions.setSubmitting(false);
            enqueueSnackbar(error?.data, { variant: "error" });
          }
        );
        actions.resetForm();
      }}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue,
      }) => (
        <Form>
          <Grid align="center">
            <Typography
              style={{
                fontFamily: "Revalia",
                fontStyle: "normal",
                fontSize: "48px",
                color: "#233044",
              }}
            >
              Ceres
            </Typography>
          </Grid>
          <Card
            style={{
              position: "absolute",
              width: "1267px",
              height: "570",
              background: "#FFFFFF",
              borderRadius: "10px",
            }}
          >
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    padding: "15px",
                    background: "#233044",
                    width: "100%",
                    height: "80px",
                  }}
                >
                  <Typography
                    style={{
                      verticalAlign: "middle",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontSize: "24px",
                      color: "#FFFFFF",
                    }}
                  >
                    Comments
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ClassicTextEditor
                    onChange={(content) => {
                      setFieldValue("comments", content);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                margin="10px"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ButtonWithLoading
                  title={getTranslation("Send","Envoyer","Schicken")}
                  size="large"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
