import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Get } from "../../actions";
import Table from "../../components/table";
import { Get_CircularResolutionActivities_URL } from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
export const AuditLog = () => {
  const { circularResolutionId } = useParams();
  const columns = [
    {
      id: "user",
      numeric: "false",
      label: getTranslation("User", "Utilisatrice", "Benutzer"),
    },
    {
      id: "dateAndTime",
      numeric: "false",
      label: getTranslation("Time", "Temps", "Zeit"),
      format: "time",
    },
    {
      id: "dateAndTime",
      numeric: "false",
      label: getTranslation("Date", "Date", "Datum"),
      format: "date",
    },
    {
      id: "activity",
      numeric: "false",
      label: getTranslation("Activity", "Activité", "Aktivität"),
    },
    {
      id: "description",
      numeric: "false",
      label: getTranslation("Description", "La description", "Beschreibung"),
    },
  ];

  const [auditLogs, setAuditLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  useEffect(() => {
    if (circularResolutionId) loadAuditLogCircularResolution();
  }, [circularResolutionId]);

  const loadAuditLogCircularResolution = () => {
    Get(
      { CrId: circularResolutionId },
      Get_CircularResolutionActivities_URL,
      null,
      (resp) => {
        setAuditLogs(resp?.data);
      },
      (error) => {}
    );
  };
  return (
    <Table
      auto
      size="small"
      dense
      //loading={membersLoading}
      title={getTranslation("Audit Log", "Journal d'audit", "Audit-Log")}
      colums={columns}
      //sortBy="fullName"
      rows={auditLogs}
      count={auditLogs.length}
      page={0}
      rowsPerPage={10}
      viewSearch
      handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
      handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
      // enableCheckbox={isApproved? false: enableCheckbox}
      // selected={selectedMembers}
      // //onSelectionChange={(items)=>handleOnSelectionChange(items)}
      // onSelectionChecked={handleNewItem}
      // onSelectionUnChecked={handleRemoveItem}
      // viewDelete={enableDelete}
      // onDelete={onRemove}
    />
  );
};
